import {Injectable} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {AppState} from '../../store/app-state.model';
import {JifUser, JifUserData, UserState} from './redux/user.model';
import {UserActions} from './redux/user.actions';
import {filter, first, map} from 'rxjs/operators';
// tslint:disable:max-line-length
import {
  JiveOrganizationPermissions,
  Permissions,
  PrincipalData,
  PrincipalDataAuthorities,
  PrincipalDataPartnerPermissions,
  PrincipalDataPbxPermissions
} from '@jive/core/identity/v1';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';
import {UserConfigurationService} from '@jive/core/dist/src/user';
import {Observable} from 'rxjs';
import {AuthenticationInfo} from '@jive/core/dist/src/authentication';
import {AppInitOrganizationPermissions} from '../app-init/models';

@Injectable()
export class UserService {
  @select( [ 'auth', 'authInfo' ] ) readonly authInfo$: Observable<AuthenticationInfo>;

  constructor (
    private userActions: UserActions,
    private reduxStore: NgRedux<AppState>,
    private permissionsService: Permissions,
    private http: HttpClient,
    private userConfigService: UserConfigurationService
  ) { }

  getCurrentUser () {
    this.reduxStore.dispatch( this.userActions.setStartAction() );

    return this.reduxStore.select<UserState>( 'user' )
      .pipe(
        filter( this.isUserReady ),
        first()
      );
  }

  // user needs to at least have the principalData set. and the jif and principal
  isUserReady ( userState: UserState ): boolean {
    return ( !!userState?.principalData && !userState?.getJifUserInProgress && !userState.getPrincipalDataInProgress );
  }

  organizationPermissionsCheck ( principalData: PrincipalData, organizationId: string ): AppInitOrganizationPermissions {
    // if you need to set custom permissions for partner, organization or authorities then
    // the getPermissions method here is where to do so
    const permissions = this.permissionsService.getPermissions( principalData, organizationId,
      [ PrincipalDataAuthorities.PLATFORM_ADMIN, PrincipalDataAuthorities.PLATFORM_CUSTOMER_SERVICE, PrincipalDataAuthorities.PLATFORM_VIEW ],
      [ PrincipalDataPartnerPermissions.PARTNER_ADMIN ],
      [ PrincipalDataPbxPermissions.BILLING_ACCESS, PrincipalDataPbxPermissions.BILLING_VIEW_ACCESS ] );
    return {
      permissions,
      canViewOrganization: this.canViewOrganization( permissions )
    };
  }

  /**
   * We need to be able to tell the internal organization resolution logic as to whether the organization
   * requested can be loaded. So here we will take in the permission state for that org and depending on what your
   * app requires, return true or false
   *
   * @param permissions the permissions returned for this organization and user
   */
  canViewOrganization ( permissions: JiveOrganizationPermissions ): boolean {
    return permissions.isAdmin || permissions.hasAccess || permissions.hasAccessFromOrganizationUser;
  }

  isPlatformJifUser ( userRole: JifUser ) {
    return userRole.roles.platformAdmin ||
      userRole.roles.platformCustomerService ||
      userRole.roles.viewAccount;
  }

  isJifUserValid ( jifData: JifUserData ) {
    return this.isPlatformJifUser( jifData.data.user ) || jifData.data.tenants.pbxes.length > 0;
  }

  redirectInvalidUser ( externalUserId: string ) {
    const queryParam = `?externalUserId=${ externalUserId }&organizationMissing=true&lineMissing=true&language=en_US`;

    this.userConfigService.redirectToUnconfiguredUser( queryParam );

  }

  /**
   * JIF used to return 404 when a user did not have an assigned PBX.
   * But now with the query param allowNoPbx, we can get the user info without PBX.
   * We still consider non-platform user without PBX to be an invalid user.
   * So we throw 404 error to mimic the same behavior as before.
   */
  jifUserValidator ( jifData: JifUserData ) {
    if ( this.isJifUserValid( jifData ) ) {
      return jifData;
    }
    const externalUserId = jifData?.data?.user?.externalId ? encodeURIComponent( jifData.data.user.externalId ) : undefined;
    this.redirectInvalidUser( externalUserId );
  }

  isPlatformPrincipalUser ( principalData: PrincipalData ) {
    let result = false;
    if ( principalData.authorities.length > 0 ) {
      principalData.authorities.forEach( ( role ) => {
        if ( role === PrincipalDataAuthorities.PLATFORM_ADMIN ||
          role === PrincipalDataAuthorities.PLATFORM_CUSTOMER_SERVICE ||
          role === PrincipalDataAuthorities.PLATFORM_VIEW ) {
          result = true;
        }
      } );
    }
    return result;
  }

  // PrincipalData.users contain the configuration of the phone setting.
  // If a platform user does not have pbx assignment, it can be empty.
  // But for non-platform user, they have to have the pbx assignment as well as the phone setting.
  isPrincipleUserValid ( principalData: PrincipalData ) {
    return this.isPlatformPrincipalUser( principalData ) || principalData.users.length > 0;
  }

  principalUserValidator ( principalData: PrincipalData ) {
    if ( this.isPrincipleUserValid( principalData ) ) {
      return principalData;
    }
    this.redirectInvalidUser( principalData.username );
  }

  getJifUser ( jiveId: string ) {
    const path = `${ environment.jifUrl }/user/jiveId/${ jiveId }`;
    const queryParam = new HttpParams( { encoder: new HttpUrlEncodingCodec() } )
      .set( 'allowNoPbx', 'true' );
    return this.http.get<JifUserData>( `${ path }?${ queryParam.toString() }`, {} )
      .pipe(
        map( ( jifData: JifUserData ) => this.jifUserValidator( jifData ) ) );
  }
}
