import { Injectable } from '@angular/core';
import { Language } from './language-selector.model';

@Injectable( {
  providedIn: 'root'
} )

export class LanguageSelectorActions {
  static readonly SET_SELECTED_LANGUAGE = 'LANGUAGE:SET_SELECTED';

  constructor () { }

  setSelectedLanguage ( selectedLanguage: Language ) {
    return {
      type: LanguageSelectorActions.SET_SELECTED_LANGUAGE,
      payload: { selectedLanguage: selectedLanguage }
    };
  }
}
