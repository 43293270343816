import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSwitcherComponent } from './app-switcher.component';
import { AppSwitcherListComponent } from './app-switcher-list.component';
import { AppSwitcherApplicationComponent } from './app-switcher-application.component';
import { ListControlsDirective } from './list-controls.directive';

@NgModule( {
  imports: [
    CommonModule
  ],
  declarations: [
    AppSwitcherComponent,
    AppSwitcherListComponent,
    AppSwitcherApplicationComponent,
    ListControlsDirective
  ],
  exports: [
    AppSwitcherComponent,
    AppSwitcherListComponent,
    AppSwitcherApplicationComponent
  ]
} )
export class AppSwitcherModule { }
