import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {InvoiceListActions} from './invoice-list.actions';
import {InvoiceListService} from '../invoice-list.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Action} from '../../../store/action.model';
import {of} from 'rxjs';
import {PaymentActions} from '../../payment/redux/payment.actions';

@Injectable( {
  providedIn: 'root'
} )
export class InvoiceListEpic {

  constructor (
    private invoiceListActions: InvoiceListActions,
    private invoiceListService: InvoiceListService
  ) { }

  getEpics () {

    return combineEpics(
      this.getAndSetInvoiceList.bind( this ),
      this.getInvoiceListAfterPayment.bind( this ) );

  }

  getAndSetInvoiceList ( action$: ActionsObservable<Action<any>> ) {
    return action$
      .pipe(
          ofType(InvoiceListActions.GET_INVOICE_LIST),
        switchMap( ( { payload: { orgId } } ) =>
          this.invoiceListService.getInvoiceList( orgId )
            .pipe(
              map( invoices => invoices ? invoices : undefined ),
              map( this.invoiceListActions.setInvoiceList ),
              catchError( () =>
                of( this.invoiceListActions.setInvoiceList( undefined ) )
              )
            )
        )
      );
  }

  getInvoiceListAfterPayment ( action$: ActionsObservable<Action<any>> ) {
    return action$
      .pipe(
          ofType(PaymentActions.SET_POST_PAYMENT_SUCCESS),
        map( ( { payload: { orgId } } ) => this.invoiceListActions.getInvoiceList( orgId ) )
      );
  }

}
