import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SmsDetailsActions} from './sms-details.actions';
import {SmsDetailsService} from '../sms-details.service';
import {FileService} from '../../file/file.service';

@Injectable( {
  providedIn: 'root'
} )
export class SmsDetailsEpic {

  constructor (
    private smsDetailsActions: SmsDetailsActions,
    private smsDetailsService: SmsDetailsService,
    private fileService: FileService
  ) { }

  getEpics () {

    return combineEpics(
      this.getAndSetSmsDetails.bind( this ),
    );
  }

  getAndSetSmsDetails ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(SmsDetailsActions.GET_SMS_DETAILS),
        switchMap(
          ( { payload: { orgId, year, month, country, language } } ) =>
            this.smsDetailsService.getSmsDetails( orgId, year, month, country, language )
              .pipe(
                map( ( blob: Blob ) => {
                  const monthStr = month.toString().padStart( 2, '0' );
                  this.fileService.openSaveDialog( blob, `${ year }_${ monthStr }.csv` );
                  return this.smsDetailsActions.resetSmsDetails();
                } ),
                catchError( () => {

                  return of( this.smsDetailsActions.setSmsDetails( { data: undefined, filename: undefined } ) );

                } )
              )
        )
      );
  }

}
