import { Inject, Injectable } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { AuthenticationConfig, AuthenticationInfo, AuthStorageStrategy, authUrl, defaultScopesChangedStrategy, isStorageHandler, parseToken, StorageHandlerWrapper } from '@jive/core/authentication';
import { EMPTY, Observable, of, ReplaySubject, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { finalize, first, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { CanActivate } from '@angular/router';
import {AUTHENTICATION_CONFIG_TOKEN} from '../authentication/authentication-config';

export function oauthFlowAuthenticationFactory ( authenticationConfig: AuthenticationConfig ) {
  return new OAuthFlowAuthentication( authenticationConfig );
}

@Injectable( { providedIn: 'root' } )
export abstract class Authentication {

  protected readonly logoutBaseUrl: string;
  protected readonly loginBaseUrl: string;
  protected readonly DEFAULT_VERSION = 'v2';
  protected readonly storageStrategy: AuthStorageStrategy;
  private inProgressLogin: Observable<AuthenticationInfo | undefined> | null;
  protected refreshStrategy?: ( authInfo?: AuthenticationInfo ) => Observable<AuthenticationInfo | undefined>;
  private readonly authInfo: ReplaySubject<AuthenticationInfo | undefined> = new ReplaySubject( 1 );

  constructor ( @Inject( AUTHENTICATION_CONFIG_TOKEN ) protected readonly options: AuthenticationConfig ) {
    this.loginBaseUrl = options.loginBaseUrl || environment.loginBaseUrl;
    this.logoutBaseUrl = options.logoutBaseUrl || environment.logoutBaseUrl;
    // moving to storage strategy specifically for auth, rather then the
    // complicated and ugly StorageHandler it is cleaner to provide a storage interface
    // specific to auth itself. We are providing that interface via an abstract class and
    // providing a default implementation of the local storage strategy already
    if ( isStorageHandler( this.options.store ) ) {
      this.storageStrategy = new StorageHandlerWrapper( this.options.clientId, this.options.store );
    } else {
      this.storageStrategy = this.options.store;
    }
    this.inProgressLogin = null;
  }

  protected clearAuthInfo () {
    this.storageStrategy.removeAuthState();
  }

  protected hasValidAuthInfo ( authInfo?: AuthenticationInfo ) {
    return authInfo && authInfo.expires && authInfo.expires > Date.now();
  }

  get isAuthenticated () {
    return this.hasValidAuthInfo( this.storageStrategy.getAuthInfo() );
  }

  protected get logoutImplementation (): Observable<void> {
    return of( undefined );
  }

  logout ( winRef: Window = window, stopRedirect: boolean = false ) {
    return this.logoutImplementation.pipe(
      first(),
      tap( () => {
        this.clearAuthInfo();

        if ( !stopRedirect && this.options.logoutBaseUrl ) {
          winRef.location.href = this.options.logoutBaseUrl;
        }

      } )
    );
  }

  protected clearAuthInfoFromUrl ( window: Window, force = false ): Observable<void> {
    // this timeout sucks but with Angular for some reason we have to wait for next
    // tick to clear the url completely. Id like to figure out how to remove
    // this and thus JCC-600.
    if ( window.location.hash || force ) {
      return timer().pipe(
        tap( () => {
          window.history.replaceState( '', document.title, window.location.pathname + window.location.search );
        } ),
        mapTo( undefined )
      );
    }
    return of( undefined );
  }

  protected abstract get loginImplementation (): Observable<AuthenticationInfo | undefined>

  login ( win: Window = window, keepAuthInfo = false ): Observable<AuthenticationInfo | undefined> {
    const lastUsedAuthScopes = this.storageStrategy.getScopes();
    const scopeStrategyResult = this.scopesStrategySuccessful( this.options.scopes, lastUsedAuthScopes );
    const currentAuthInfo = this.storageStrategy.getAuthInfo();
    // check if scope strategy passed or if one wasn't provided
    // if it didn't pass than prevent login from starting
    if ( currentAuthInfo && this.isAuthenticated && scopeStrategyResult ) {
      // keepAuthInfo can be provided to no clear the authentication info from the URL

      const currentAuthInfo$ = of( currentAuthInfo ).pipe(
        mergeMap( ( authInfo ) => this.runRefreshTokenStrategyIfApplicable( authInfo ) ),
        tap( ( authInfo ) => this.authInfo.next( authInfo ) )
      );

      if ( keepAuthInfo ) {
        return currentAuthInfo$;
      }
      return currentAuthInfo$.pipe(
        mergeMap( ( authInfo ) =>
          this.clearAuthInfoFromUrl( win ).pipe(
            mapTo( authInfo )
          )
        )
      );
    } else {
      if ( !this.inProgressLogin ) {
        this.inProgressLogin = this.loginImplementation.pipe(
          tap( ( authInfo ) => {
            if ( authInfo ) {
              this.storageStrategy.setAuthState( {
                authInfo,
                scopes: this.options.scopes
              } );
            }
          } ),
          mergeMap( ( authInfo ) =>
            this.clearAuthInfoFromUrl( win, true ).pipe(
              mapTo( authInfo )
            )
          ),
          tap( ( authInfo ) => {
            if ( authInfo ) {
              this.authInfo.next( authInfo );
            }
          } ),
          finalize( () => this.inProgressLogin = null )
        );
      }

      return this.inProgressLogin;
    }
  }

  runRefreshTokenStrategyIfApplicable ( authInfo: AuthenticationInfo | undefined ) {
    if ( this.refreshStrategy ) {
      return this.refreshStrategy( authInfo );
    } else {
      return of( authInfo );
    }
  }

  protected scopesStrategySuccessful ( newScopes: string, oldScopes?: string ) {
    const scopesChangedStrategy = this.options.scopesChangedStrategy || defaultScopesChangedStrategy;
    return scopesChangedStrategy( newScopes, oldScopes );
  }

}

@Injectable( { providedIn: 'root' } )
export class OAuthFlowAuthentication extends Authentication {

  protected get loginImplementation (): Observable<AuthenticationInfo | undefined> {

    if ( !window.location.hash.match( 'access_token' ) ) {

      setTimeout( () => {
        // Make redirect consistently async across browsers to allow any other tasks to complete
        window.location.href = authUrl(
          this.DEFAULT_VERSION,
          this.loginBaseUrl,
          window,
          this.options
        );
      }, 0 );

      return EMPTY;
    }

    return of( parseToken( window.location.hash, this.options.useSpecCompliantTimeParsing ) );

  }
}

@Injectable( { providedIn: 'root' } )
export class AuthGuardService implements CanActivate {

  constructor (
    private readonly authService: Authentication
  ) { }

  canActivate (): Observable<boolean> {
    // return an observable that says whether the user is logged in or not
    return this.authService.login( window )
      .pipe(
        map( authInfo => !!authInfo )
      );
  }
}
