import { API_PROD, API_STAGING, HVOICE_APPS_PROD, HVOICE_APPS_STAGING } from '@jive/core/environment';
import { Observable } from 'rxjs';

export const API_401_ERROR_HOOK_TOKEN = 'Api401ErrorHookToken';

export interface ShouldLogoutState {
  logout: boolean;
}

export interface Api401ErrorHook {
  /**
   * This should be used by apps that require a check before causing a logout. This hook
   * would allow apps to check state and determine whether logging out at that moment is an appropriate
   * response.
   *
   * @returns {boolean}
   */
  shouldLogout ( error: any ): Observable<ShouldLogoutState>;
}

/**
 *
 *
 * @export
 * @param {*} error The error returned from Angular
 * @param {( error: any ) => boolean} isInstanceOf A callback used to determine the instance type
 * of the returned value, since Angular and AngularJs have different instance types you cannot have
 * one function that covers them both. You also cannot use a generic as an instanceof type to check against.
 * @returns {boolean}
 */
export function is401Error ( error: any, isInstanceOf: ( error: any ) => boolean ) {
  return error &&
    isInstanceOf( error ) &&
    error.status === 401;
}

export function isJiveApi ( url: string ) {
  return !!url && [
    API_PROD,
    API_STAGING,
    HVOICE_APPS_PROD,
    HVOICE_APPS_STAGING
  ].some( ( apiBaseUrl: string ) => {
    return !!url.indexOf( apiBaseUrl );
  } );
}
