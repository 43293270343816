import { Component, OnInit } from '@angular/core';

@Component( {
  selector: 'bp-error403',
  templateUrl: './error403.component.html',
  styleUrls: [ './error403.component.scss' ]
} )
export class Error403Component implements OnInit {

  constructor () { }

  ngOnInit () {

  }

}
