import { Selector } from '@angular-redux/store';

export const MESSAGES_SELECTOR = [ 'message', 'messages' ] as Selector<any, any>;

export interface Message {
  key: string;
  expirationTimestamp: string;
}

export interface MessageState {
  loaded: boolean;
  hasError: boolean;
  messages: Message[];
  error: any;
}
