import { Injectable } from '@angular/core';
import { ReceiptEmailState } from './receipt-email.model';
import { PaymentState } from '../../payment/redux/payment.model';

@Injectable( {
  providedIn: 'root'
} )
export class ReceiptEmailActions {
  static readonly RESET_RECEIPT_EMAIL = 'RECEIPT_EMAIL:RESET';
  static readonly GET_RECEIPT_EMAIL = 'RECEIPT_EMAIL:GET';
  static readonly SET_RECEIPT_EMAIL = 'RECEIPT_EMAIL:SET';
  static readonly SET_RECEIPT_EMAIL_DESIRED = 'RECEIPT_EMAIL:SET_DESIRED';
  static readonly POST_RECEIPT_EMAIL = 'RECEIPT_EMAIL:POST';
  static readonly POST_RECEIPT_EMAIL_COMPLETE = 'RECEIPT_EMAIL:POST_COMPLETE';
  static readonly SET_RECEIPT_EMAIL_POST_ERROR = 'RECEIPT_EMAIL:SET_POST_ERROR';

  // when payment is submitted and receipt email needs to be updated
  static readonly POST_RECEIPT_EMAIL_THEN_PAYMENT = 'RECEIPT_EMAIL:POST_THEN_PAYMENT';
  static readonly POST_RECEIPT_EMAIL_COMPLETE_READY_FOR_PAYMENT = 'RECEIPT_EMAIL:POST_COMPLETE_READY_FOR_PAYMENT';
  static readonly POST_RECEIPT_EMAIL_FAILED_READY_FOR_PAYMENT = 'RECEIPT_EMAIL:POST_FAILED_READY_FOR_PAYMENT';

  constructor () { }

  // this set everything except email to undefined
  resetReceiptEmail () {
    return { type: ReceiptEmailActions.RESET_RECEIPT_EMAIL };
  }

  getReceiptEmail ( orgId: string ) {
    return { type: ReceiptEmailActions.GET_RECEIPT_EMAIL, payload: { orgId } };
  }

  setReceiptEmail ( email: string, getSuccess: boolean = true ) {
    return { type: ReceiptEmailActions.SET_RECEIPT_EMAIL, payload: { email, getSuccess } };
  }

  setDesiredReceiptEmail ( desiredEmail: string ) {
    return { type: ReceiptEmailActions.SET_RECEIPT_EMAIL_DESIRED, payload: { desiredEmail } };
  }

  postReceiptEmail ( state: ReceiptEmailState, orgId: string ) {
    return { type: ReceiptEmailActions.POST_RECEIPT_EMAIL, payload: { state, orgId } };
  }

  postReceiptEmailComplete ( email: string ) {
    return { type: ReceiptEmailActions.POST_RECEIPT_EMAIL_COMPLETE, payload: { email, getSuccess: true } };
  }

  setReceiptEmailPostError ( postError: any ) {
    return { type: ReceiptEmailActions.SET_RECEIPT_EMAIL_POST_ERROR, payload: { postError, getSuccess: false } };
  }

  postReceiptEmailThenPayment ( emailState: ReceiptEmailState, orgId: string, paymentState: PaymentState ) {
    return { type: ReceiptEmailActions.POST_RECEIPT_EMAIL_THEN_PAYMENT, payload: { emailState, orgId, paymentState } };
  }

  postReceiptEmailCompleteReadyForPayment ( orgId: string, state: PaymentState, email: string ) {
    return {
      type: ReceiptEmailActions.POST_RECEIPT_EMAIL_COMPLETE_READY_FOR_PAYMENT,
      payload: { orgId, state, email }
    };
  }

  postReceiptEmailFailedReadyForPayment ( error: any, state: PaymentState ) {
    return { type: ReceiptEmailActions.POST_RECEIPT_EMAIL_FAILED_READY_FOR_PAYMENT, payload: { error, state } };
  }
}
