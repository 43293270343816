import { Injectable } from '@angular/core';
import { ScaRequirement, ScaToken } from './cardinal.model';
import { Country } from '../../location-converter/assets/model';

@Injectable( {
  providedIn: 'root'
} )
export class CardinalActions {
  static readonly GENERATE_SCA_TOKEN = 'CARDINAL:GET_SCA_TOKEN';
  static readonly SET_TOKEN_STATE = 'CARDINAL:SET_TOKEN_STATE';
  static readonly SET_SCA_REQUIREMENT_STATE = 'CARDINAL:SET_SCA_REQUIRED_STATE';
  static readonly RESET = 'CARDINAL:RESET';

  constructor () { }

  generateScaToken ( currency: string, country: Country ) {
    return { type: CardinalActions.GENERATE_SCA_TOKEN, payload: { currency, country } };
  }

  setCardinalTokenState ( scaToken: ScaToken, tokenExpiration: Date, error: any ) {
    return { type: CardinalActions.SET_TOKEN_STATE, payload: { scaToken, tokenExpiration, error } };
  }

  setScaRequirementState ( scaRequirement: ScaRequirement ) {
    return { type: CardinalActions.SET_SCA_REQUIREMENT_STATE, payload: { scaRequirement } };
  }

  reset () {
    return { type: CardinalActions.RESET };
  }
}
