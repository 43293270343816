export const US_CURRENCY = 'USD';
export const CA_CURRENCY = 'CAD';
export const MX_CURRENCY = 'MXN';
export const GB_CURRENCY = 'GBP';
export const EUR_CURRENCY = 'EUR';
export const BR_CURRENCY = 'BRL';
export const AU_CURRENCY = 'AUD';

export const US_CURRENCY_SYMBOL = '$';
export const CA_CURRENCY_SYMBOL = 'C$';
export const MX_CURRENCY_SYMBOL = 'MX$';
export const GB_CURRENCY_SYMBOL = '£';
export const EUR_CURRENCY_SYMBOL = '€';
export const BR_CURRENCY_SYMBOL = 'R$';
export const AU_CURRENCY_SYMBOL = 'A$';

export interface CurrencyAndSymbol {
  currency: string;
  symbol: string;
}

export const CURRENCY_SYMBOLS: CurrencyAndSymbol[] = [
  {
    currency: US_CURRENCY,
    symbol: US_CURRENCY_SYMBOL
  },
  {
    currency: CA_CURRENCY,
    symbol: CA_CURRENCY_SYMBOL
  },
  {
    currency: MX_CURRENCY,
    symbol: MX_CURRENCY_SYMBOL
  },
  {
    currency: GB_CURRENCY,
    symbol: GB_CURRENCY_SYMBOL
  },
  {
    currency: EUR_CURRENCY,
    symbol: EUR_CURRENCY_SYMBOL
  },
  {
    currency: BR_CURRENCY,
    symbol: BR_CURRENCY_SYMBOL
  },
  {
    currency: AU_CURRENCY,
    symbol: AU_CURRENCY_SYMBOL
  }
];
