import { Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class ModalActions {
  static readonly SET_MODAL = 'MODAL:SET_MODAL';

  constructor () { }

  setModal ( visible: boolean ) {
    return {
      type: ModalActions.SET_MODAL,
      payload: { visible }
    };
  }
}
