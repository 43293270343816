import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404Component } from './error404/error404.component';
import { Error403Component } from './error403/error403.component';
import { Error400Component } from './error400/error400.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule( {
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [ Error404Component, Error403Component, Error400Component ],
  exports: [ Error404Component, Error403Component, Error400Component ]
} )
export class ErrorsModule {}
