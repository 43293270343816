<div class="payment-error" role="dialog">
  <div class="component-title" [innerHTML]="'ERROR'| translate">
    <!-- Error -->
  </div>
  <div class="payment-error-content">
    <div [innerHTML]="errorMessageKey| translate"></div>
    <div *ngIf="errorCode">
      Error code: {{errorCode}}
    </div>

    <button (click)="close()"
      class="jive-button jive-primary jive-small payment-error-close-button"
      [attr.aria-label]="'CLOSE' | translate"
      type="button"
      [innerHTML]="'CLOSE'| translate">
      <!-- close -->
    </button>
  </div>
</div>
