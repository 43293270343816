import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { INVALID_SCA_TOKEN_ERROR, ScaToken, ScaTokenRequest, ScaTokenWithExpiration } from './redux/cardinal.model';
import { CardinalActions } from './redux/cardinal.actions';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { CARDINAL_SCA_TOKEN } from '../../store/helper';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable( {
  providedIn: 'root'
} )
export class CardinalService {
  @select( CARDINAL_SCA_TOKEN ) scaToken$: Observable<ScaToken>;

  constructor (
    private http: HttpClient,
    private cardinalActions: CardinalActions,
    private reduxStore: NgRedux<AppState> ) { }

  // Since Cardinal required a page reload when the scaToken changes,
  // if there is an active existing scaToken, we have to throw Error.
  getScaTokenWithExpiration ( request: ScaTokenRequest ) {
    return this.scaToken$.pipe(
      first(),
      switchMap( ( scaToken ) => {
        if ( scaToken === undefined ) {
          return this.generateScaToken( request ).pipe( map( ( generatedScaToken ) => {

            if ( generatedScaToken.isScaEnabledForMid ) {
              Cardinal.setup( 'init', {
                'jwt': generatedScaToken.tokenString
              } );
            }

            const tokenExpiration = new Date();
            tokenExpiration.setHours( tokenExpiration.getHours() + 2 );
            this.reduxStore.dispatch( this.cardinalActions.setCardinalTokenState( generatedScaToken, tokenExpiration, undefined ) );
            const result: ScaTokenWithExpiration = { scaToken: generatedScaToken, expiration: tokenExpiration };
            return result;
          } ) );
        } else {
          return new Observable( observer => {
            observer.error( INVALID_SCA_TOKEN_ERROR );
          } );
        }
      } )
    );
  }

  generateScaToken ( request: ScaTokenRequest ) {
    const path = `${ environment.billingPortalBffUrl }/sca-tokens`;

    return this.http.post<ScaToken>( path, request );
  }
}
