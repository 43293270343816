import { Action } from '../../../store/action.model';
import { BillingEmailState } from './billing-email.model';
import { BillingEmailActions } from './billing-email.actions';

export const INITIAL_STATE: BillingEmailState = {
  stored: undefined,
  getInProgress: undefined,
  postInProgress: undefined,
  postSuccess: undefined,
  postError: undefined
};

export function billingEmailReducer (
  lastState: BillingEmailState = INITIAL_STATE,
  action: Action<Partial<BillingEmailState>>
): BillingEmailState {
  switch ( action.type ) {
    case BillingEmailActions.RESET_BILLING_EMAIL_STATE:
      return INITIAL_STATE;
    case BillingEmailActions.GET_BILLING_EMAIL:
      return {
        ...lastState,
        getInProgress: true
      };
    case BillingEmailActions.SET_BILLING_EMAIL:
      return {
        ...lastState,
        stored: action.payload.stored,
        getInProgress: false
      };
    case BillingEmailActions.SAVE_BILLING_EMAIL:
      return {
        ...lastState,
        postInProgress: true
      };
    case BillingEmailActions.SET_SAVE_SUCCESS:
      return {
        ...lastState,
        postInProgress: false,
        postSuccess: true,
        postError: undefined,
        stored: action.payload.stored
      };
    case BillingEmailActions.SET_SAVE_ERROR:
      return {
        ...lastState,
        postInProgress: false,
        postSuccess: false,
        postError: action.payload.postError
      };
    default:
      return lastState;
  }
}
