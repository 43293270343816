import {Permissions} from '@jive/core/identity/v1';
import {FactoryProvider} from '@angular/core';

export function permissionsServiceFactory() {
  return new Permissions();
}

export const PERMISSIONS_SERVICE_PROVIDER: FactoryProvider = {
  provide: Permissions,
  useFactory: permissionsServiceFactory
};
