<div class="current-balance" role="region" aria-labelledby="current-balance-title">
  <div [attr.aria-hidden]="showPayTotal">
    <div class="component-title current-balance-title"
      id="current-balance-title"
      [innerHTML]="'CURRENT_BALANCE_TITLE'| translate"
      role="heading"
      aria-level="2">
      <!-- Current Balance -->
    </div>

    <div class="current-balance-loading loading"
      *ngIf="(getTotalDueInProgress$| async) !== false"
      [innerHTML]="'LOADING'| translate">
      <!-- loading -->
    </div>

    <div class="current-balance-main" *ngIf="(getTotalDueInProgress$ |async) === false">
      <div class="current-balance-data-error" *ngIf="showError">
        <bp-data-error></bp-data-error>
      </div>

      <div class="current-balance-amount">
        {{totalAmount}}
      </div>
      <div class="current-balance-button" *ngIf="showPayButton">
        <button class="jive-button jive-medium  jive-primary"
          [attr.aria-label]="'CURRENT_BALANCE_PAY_BUTTON_ARIA' | translate:{amount: totalAmount}"
          type="button"
          [innerHTML]="'CURRENT_BALANCE_PAY_BUTTON'| translate"
          (click)="setShowPayTotal(true)">
          <!-- PAY BALANCE -->
        </button>
      </div>

      <bp-contact-info class="current-balance-text-center"></bp-contact-info>
    </div>
  </div>
  <bp-pay-total *ngIf="showPayTotal" (showTotalPayModal)="setShowPayTotal($event)"></bp-pay-total>
</div>

