import { ClassProvider, FactoryProvider, InjectionToken, Optional, ValueProvider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Authentication, AuthenticationInfo, getAuthenticationInfoWhenAvailable } from '@jive/core/authentication';
import { map, mergeMap } from 'rxjs/operators';
import { empty, Observable } from 'rxjs';
import { AuthenticationMissingMode, JiveAuthHook, JiveHttpInterceptorOptions } from './jive-auth-hook';
import { Constructor } from '@jive/common/entities';
import { HttpInterceptorAuthenticationHook } from '../auth/models';

export const HttpInterceptorAuthHookToken = new InjectionToken( 'HttpInterceptorAuthenticationHook' );

export class JiveHttpInterceptor implements HttpInterceptor {

  // in an effort to simplify the interceptor we make jive logic a auth hook
  // we can then treat everything the same, with the exception of the check for
  // jive specific logic below
  jiveAuthHook = new JiveAuthHook( this.authenticationService );

  static factory (
    authentication: Authentication,
    options?: JiveHttpInterceptorOptions,
    authHooks?: HttpInterceptorAuthenticationHook[]
  ) {

    if ( !options ) {
      options = { authenticationMissingMode: AuthenticationMissingMode.AUTO_LOGIN };
    }
    return new JiveHttpInterceptor( authentication, options, authHooks );

  }

  constructor (
    private authenticationService: Authentication,
    private options: JiveHttpInterceptorOptions,
    private authHooks?: HttpInterceptorAuthenticationHook[]
  ) { }

  intercept (
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const allAuthHooks = [ ...( this.authHooks || [] ), this.jiveAuthHook ];
    let isAllowingUnauthenticated = false;
    /**
     * Run through each hook and see if a handler added
     * the auth key for any of them. If so then return the first match, run it through the apply header
     * logic and we are off and running.
     *
     * We dont accept more than one auth header provider per request, since we only use the Authorization Bearer
     * header pattern we couldnt have more than one auth header applied or they would just be overridden anyway.
     */
    const servicesRequestingAuthHeaders = allAuthHooks
      .reduce(
        (
          hooks: Authentication[],
          authHook: HttpInterceptorAuthenticationHook ) => {
          /**
           * As is mentioned in the http-handler file. This is switched to get the auth include off of the
           * object itself. This change made it so that includeAuth did not have to be set or removed from
           * the request params. Both set and delete clone the HttpParams object and return the new instance.
           * This causes issues when you then apply request.clone. This is a known Angular bug which is not being
           * fixed for some reason. So this is a workaround.
           */
          if ( request.params[ authHook.includeAuthHeaderPropName ] ) {
            hooks.push( authHook.getAuthenticationService() );
          }

          /**
           * There are certain apis that accept requests with or without an auth token, so in this case we want to try for a token and if
           * one is not available then we just simply allow it to pass through and make the request anyway
           */
          if ( authHook.allowUnAuthPropName && request.params[ authHook.allowUnAuthPropName ] ) {
            isAllowingUnauthenticated = true;
          }

          return hooks;
        },
        [] );

    if ( servicesRequestingAuthHeaders && servicesRequestingAuthHeaders.length > 1 ) {

      const nameOfServicesRequestingAuthHeaders = allAuthHooks
        .reduce(
          (
            names: string[],
            authHook: HttpInterceptorAuthenticationHook ) => {
            if ( request.params[ authHook.includeAuthHeaderPropName ] ) {
              names.push( authHook.includeAuthHeaderPropName );
            }

            return names;
          },
          [] ).join();

      throw new Error( `
        Too many auth hooks are requesting auth headers on this
        request. There should only be 1, the following services
        requested headers: ${ nameOfServicesRequestingAuthHeaders }
        ` );

    } else if ( servicesRequestingAuthHeaders && servicesRequestingAuthHeaders.length ) {
      const auth = servicesRequestingAuthHeaders[ 0 ];
      if ( isAllowingUnauthenticated && !auth.isAuthenticated ) {
        return next.handle( request );
      } else {
        return this.authAndApplyHeader( next, request, this.resolveAuthStrategy( this.options, auth ) );
      }
    } else {
      return next.handle( request );
    }
  }

  private authAndApplyHeader (
    next: HttpHandler,
    request: HttpRequest<any>,
    authObservable: Observable<AuthenticationInfo>
  ) {
    return authObservable
      .pipe(
        map( ( info: AuthenticationInfo ) => info.token ),
        map( ( token: string ) => {

          return request.clone( {
            params: request.params,
            setHeaders: {
              Authorization: `Bearer ${ token }`
            }
          } );

        } ),
        mergeMap( req => next.handle( req ) )
      );
  }

  /**
   * There are multiple ways to auth and so we allow the consumer to specify. This function
   * allows us to abstract that logic away and simplify the interceptor.
   *
   * @param options The interceptor configs
   */
  private resolveAuthStrategy (
    options: JiveHttpInterceptorOptions,
    authenticationService: Authentication
  ): Observable<AuthenticationInfo> {
    switch ( options.authenticationMissingMode ) {
      case AuthenticationMissingMode.AUTO_LOGIN:
        return authenticationService.login();
      case AuthenticationMissingMode.WAIT_FOR_LOGIN:
        return getAuthenticationInfoWhenAvailable( authenticationService );
      case AuthenticationMissingMode.ABORT_REQUEST:
        if ( !authenticationService.isAuthenticated ) {
          return empty();
        } else {
          return authenticationService.login();
        }
    }
  }

}

/**
 * Creates a provider for the configuration options of the [[JiveHttpInterceptor]]. The options are not required but
 * this is provided as a convenience method to inject your own options.
 *
 * @param {JiveHttpInterceptorOptions} options Options to provide to the [[JiveHttpInterceptor]]
 * @returns {ValueProvider} provider to use in an angular module
 */
export function jiveHttpInterceptorOptionsProviderFactory ( options: JiveHttpInterceptorOptions ): ValueProvider {

  return {
    provide: JiveHttpInterceptorOptions,
    useValue: options
  };

}

/**
 *
 * @param entity The class to instantiate and make injectable into the http interceptor
 */
export function jiveHttpInterceptorAuthHookProviderFactory (
  entity: Constructor<HttpInterceptorAuthenticationHook>
): ClassProvider {

  return {
    provide: HttpInterceptorAuthHookToken,
    useClass: entity
  };

}

export const JIVE_HTTP_INTERCEPTOR_PROVIDER: FactoryProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: JiveHttpInterceptor.factory,
  deps: [
    Authentication,
    [ new Optional(), JiveHttpInterceptorOptions ],
    [ new Optional(), HttpInterceptorAuthHookToken ]
  ],
  multi: true
};
