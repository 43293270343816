<div class="payment-method-generator-ach" role="form">
  <div class="input-title" [innerHTML]="'ACH_ADD_TITLE'| translate" role="heading" aria-level="3">
    <!-- Add Bank Account (ACH) -->
  </div>
  <form [formGroup]="achForm" (ngSubmit)="addAch()">
    <div>
      <label [innerHTML]="'ACH_NAME'| translate" class="payment-method-form-label" for="name">
        <!-- Account holder name -->
      </label>
      <input class="payment-method-form-input medium"
        id="name"
        maxlength="22"
        data-lpignore="true"
        formControlName="name"
        aria-required="true"
        [class.payment-method-form-error]="ach.controls.name.touched && ach.controls.name.errors">
    </div>

    <div>
      <label [innerHTML]="'ACH_ROUTING'| translate" class="payment-method-form-label" for="routingNumber">
        <!-- Routing number -->
      </label>
      <input class="payment-method-form-input medium"
        id="routingNumber"
        maxlength="9"
        data-lpignore="true"
        formControlName="routingNumber"
        aria-required="true"
        [class.payment-method-form-error]="ach.controls.routingNumber.touched &&
          ach.controls.routingNumber.errors">
    </div>

    <div>
      <label [innerHTML]="'ACH_ACCOUNT'| translate" class="payment-method-form-label" for="accountNumber">
        <!-- Account number -->
      </label>
      <input class="payment-method-form-input medium"
        id="accountNumber"
        maxlength="17"
        data-lpignore="true"
        formControlName="accountNumber"
        aria-required="true"
        [class.payment-method-form-error]="ach.controls.accountNumber.touched &&
          ach.controls.accountNumber.errors">
    </div>

    <div>
      <label [innerHTML]="'ACH_ACCOUNT_CONFIRM'| translate" class="payment-method-form-label" for="confirmAccountNumber">
        <!-- Account holder name -->
      </label>
      <input class="payment-method-form-input medium"
        id="confirmAccountNumber"
        maxlength="17"
        data-lpignore="true"
        formControlName="confirmAccountNumber"
        aria-required="true"
        [class.payment-method-form-error]="ach.controls.confirmAccountNumber.touched &&
          (ach.errors || ach.controls.confirmAccountNumber.errors)">
    </div>

    <div *ngIf="showError" class="payment-method-generator-error">
      <div aria-hidden="true">
        <i class="material-icons">
          error_outline
        </i>
      </div>
      <span role="alert">
        {{errorMessage}}
      </span>
    </div>

    <div class="payment-method-generator-action-buttons">
      <button class="jive-button jive-small payment-method-generator-cancel-buttons"
        (click)="cancel()"
        [attr.disabled]="(paymentMethodPostInProgress$| async) ? '' : null"
        [attr.aria-label]="'ACH_CANCEL_ARIA' | translate"
        type="button"
        [innerHTML]="'CANCEL'| translate">
        <!-- cancel -->
      </button>
      <button class="jive-button jive-primary jive-small"
        *ngIf="!(paymentMethodPostInProgress$| async)"
        [disabled]="!achForm.valid"
        [attr.aria-label]="'ACH_ADD_TITLE' | translate"
        type="submit"
        [innerHTML]="'ADD'| translate">
        <!-- Add -->
      </button>
      <button class="jive-button jive-primary jive-small"
        *ngIf="(paymentMethodPostInProgress$| async)"
        [attr.aria-label]="'PROCESSING' | translate"
        type="button"
        disabled
        [innerHTML]="'PROCESSING'| translate">
        <!-- Processing -->
      </button>
    </div>
  </form>
</div>
