import { UntypedFormControl, Validators } from '@angular/forms';

// validation
export const expMonthIsValid = ( control: UntypedFormControl ) => {
  const months = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
  return months.includes( control.value ) ? null : { 'inValidExpMonth': true };
};

export const expYearIsValid = ( control: UntypedFormControl ) => {
  const d = new Date();
  const year = d.getFullYear();

  const inputYear = Number( control.value );
  return year <= inputYear ? null : { 'inValidExpYear': true };
};

// card number, cvv, region, and postal code will be handled outside of the form
export const ccFormSetting = {
  firstName: [ null, { validators: [ Validators.required ] } ],
  lastName: [ null, { validators: [ Validators.required ] } ],
  expMonth: [ null, {
    validators: [
      Validators.required,
      Validators.pattern( '^[0-9]*$' ),
      Validators.minLength( 2 ),
      Validators.maxLength( 2 ),
      expMonthIsValid
    ]
  } ],
  expYear: [ null, {
    validators: [
      Validators.required,
      Validators.pattern( '^[0-9]*$' ),
      Validators.minLength( 4 ),
      Validators.maxLength( 4 ),
      expYearIsValid
    ]
  } ],
  country: [ null, { validators: [ Validators.required ] } ],
  addressLineOne: [ null, { validators: [ Validators.required, Validators.maxLength( 60 ) ] } ],
  addressLineTwo: [ null, { validators: [ Validators.maxLength( 60 ) ] } ],
  city: [ null, { validators: [ Validators.required, Validators.maxLength( 64 ) ] } ],
  region: [ null, { validators: [] } ],
  postalCode: [ null, { validators: [] } ]
};
