import { TranslateService } from '@ngx-translate/core';

export const MONTH_LIST = [ 'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER' ];

export interface DateParts {
  year: string;
  month: string;
  day: string;
}

export type DateFormatterFunction = ( dateParts: DateParts ) => string;
export type TextualDateFormatterFunction = ( dateParts: DateParts, translateService: TranslateService ) => string;

export function formatDayMonthYear ( dateParts: DateParts ) {
  return `${ dateParts.day }/${ dateParts.month }/${ dateParts.year }`;
}

export function formatDefaultDate ( dateParts: DateParts ) {
  return `${ dateParts.month }/${ dateParts.day }/${ dateParts.year }`;
}

export function formatGermanDate ( dateParts: DateParts ) {
  return `${ dateParts.day }.${ dateParts.month }.${ dateParts.year }`;
}

export function formatDefaultTextualDate ( dateParts: DateParts, translateService: TranslateService ) {
  const textualMonth = getTextualMonth( dateParts, translateService );
  return `${ textualMonth } ${ Number( dateParts.day ) }, ${ dateParts.year }`;
}

export function formatTextualDayMonthYear ( dateParts, translateService ) {
  const textualMonth = getTextualMonth( dateParts, translateService );
  return `${ Number( dateParts.day ) } ${ textualMonth } ${ dateParts.year }`;
}

export function formatGermanTextualDayMonthYear ( dateParts, translateService ) {
  const textualMonth = getTextualMonth( dateParts, translateService );
  return `${ Number( dateParts.day ) }. ${ textualMonth } ${ dateParts.year }`;
}

export function getTextualMonth ( dateParts: DateParts, translateService: TranslateService ) {
  const monthKey = MONTH_LIST[ Number( dateParts.month ) - 1 ];
  let textualMonth: string;
  translateService.get( monthKey ).subscribe( it => textualMonth = it );
  return textualMonth;
}
