import { Injectable } from '@angular/core';
import { DEFAULT_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE, ERROR_LIST, LAST_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE } from './error-response.model';

@Injectable( {
  providedIn: 'root'
} )
export class ErrorResponseService {

  constructor () { }

  parseErrorResponse ( errorResponse: any, genericErrorKey: string ) {
    const errorContent = errorResponse?.error?.error ? errorResponse.error.error : undefined;

    if ( errorContent && ERROR_LIST.includes( errorContent.number ) ) {
      const translationKey = this.errorCodeTranslationKeyMapper( errorContent.number );
      return {
        errorCode: errorContent.number,
        genericError: false,
        errorTranslationKey: translationKey
      };
    } else {
      const errorCode = ( errorContent?.number && errorContent?.code !== 'UNKNOWN_ERROR' ) ? errorContent.number : undefined;
      return {
        errorCode: errorCode,
        genericError: true,
        errorTranslationKey: genericErrorKey
      };
    }
  }

  errorCodeTranslationKeyMapper ( errorCode: string ) {
    let translationKey = errorCode;

    if ( errorCode === DEFAULT_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE ) {
      translationKey = 'ERROR_CANNOT_DELETE_DEFAULT';
    } else if ( errorCode === LAST_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE ) {
      translationKey = 'ERROR_CANNOT_DELETE_LAST_PAYMENT_METHOD_AUTOPAY';
    }
    return translationKey;
  }
}
