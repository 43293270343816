import { ModalState } from './modal.model';
import { Action } from '../../../store/action.model';
import { ModalActions } from './modal.actions';

export const INITIAL_STATE: ModalState = {
  visible: false
};

export function modalReducer (
  lastState: ModalState = INITIAL_STATE,
  action: Action<Partial<ModalState>>
): ModalState {
  switch ( action.type ) {
    case ModalActions.SET_MODAL:
      return { ...lastState, visible: action.payload.visible };
    default:
      return lastState;
  }
}
