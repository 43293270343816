import { SupportedLanguage } from '@jive/common/i18n';
import { LanguageService, AcceptedLanguages } from '@jive/core/language';
import groupBy from 'lodash-es/groupBy';
import { Observable, Subject, concat, of } from 'rxjs';
import { mergeMap, first, map, catchError } from 'rxjs/operators';

export class LanguageSelectorService {

  private languageSelectedEvent: Subject<SupportedLanguage> = new Subject();

  static factory ( languageService: LanguageService ) {
    return new LanguageSelectorService( languageService );
  }

  /**
   * @description This is a simple method that provides the lang code as
   *              lowercase. The lowercase version is typically what is used
   *              by the translation services and the flag sprite sheets to get
   *              the respective asset by index
   *
   * @static
   * @param {string} code
   * @returns
   * @memberof LanguageSelectorService
   */
  static getSelectedLanguageRegionCode ( code: string ) {
    return code && code.toLocaleLowerCase();
  }

  get onLanguageUpdated (): Observable<SupportedLanguage> {
    return this.languageSelectedEvent.asObservable();
  }

  constructor ( private langService: LanguageService ) { }

  setLanguageUpdated ( supportedLanguage: SupportedLanguage ) {
    this.languageSelectedEvent.next( supportedLanguage );
  }

  /**
   * @description after retrieving the browser accepted languages we take the supported languages
   *              of the app and figure out the best match
   *
   * @protected
   * @param {SupportedLanguage[]} supportedLanguages
   * @param {string[]} acceptLanguages
   * @returns {string}
   * @memberof LanguageSelectorService
   */
  protected configureLanguage (
    supportedLanguages: SupportedLanguage[],
    acceptLanguages: string[]
  ): SupportedLanguage {

    if ( !supportedLanguages || !supportedLanguages.length ) {
      throw new Error( 'no supported languages provided. We cannot provide service without a SupportedLanguage array.' );
    }
    const supportedLanguagesDict = groupBy( supportedLanguages, 'language' ),
      acceptedLanguageSupported = acceptLanguages.find( language => {
        return !!( supportedLanguagesDict[ language ] && supportedLanguagesDict[ language ][ 0 ] );
      } );

    if ( acceptedLanguageSupported ) {
      return supportedLanguagesDict[ acceptedLanguageSupported ][ 0 ];
    } else {
      return supportedLanguages[ 0 ];
    }

  }

  /**
   * @description take the app specified supported languages and check the users browser specified languages. If
   *              there is a match return it, otherwise return first app supported language.
   *
   *
   * @param {SupportedLanguage[]} supportedLanguages
   * @returns {Observable<string>}
   * @memberof LanguageSelectorService
   */
  getSelectedLanguage (
    supportedLanguages: SupportedLanguage[]
  ): Observable<SupportedLanguage> {

    return this.langService
      .getLanguages()
      .pipe(
        map( ( languages: AcceptedLanguages ) => languages.acceptLanguages ),
        map( this.langService.parseAcceptedLanguages ),
        catchError( () => of([''])), // To allow configureLanguage to set up a default if the HTTP call fails
        map( ( acceptLanguages: string[] ) => this.configureLanguage( supportedLanguages, acceptLanguages ) )
      );

  }
  /**
   * @description take any cached language selection, compare against the app supported languages list and kick
   *              off the selection process. After that create a listener for any language changes and stream them
   *              where appropriate
   *
   * @param {SupportedLanguage[]} supportedLanguages the set of supported languages
   * @param {Observable<SupportedLanguage>} selectedLanguage a currently selected language if applicable
   * @returns {Observable<SupportedLanguage>}
   * @memberof LanguageSelectorService
   */
  initLanguageSelection (
    supportedLanguages: SupportedLanguage[],
    selectedLanguage: Observable<SupportedLanguage | null>
  ): Observable<SupportedLanguage> {

    return concat(
      selectedLanguage
        .pipe(
          first(),
          mergeMap( ( language: SupportedLanguage | null ) => {

            if ( language ) {
              return of( language );
            } else {
              return this.getSelectedLanguage( supportedLanguages );
            }

          } )
        ),
      this.onLanguageUpdated
    );

  }

}
