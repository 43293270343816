import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Organization } from '../organization/redux/organization.model';

@Injectable( {
  providedIn: 'root'
} )
export class OrganizationListService {

  constructor ( private http: HttpClient ) { }

  getOrganizationList ( filter?: string ) {
    const path = `${ environment.billingPortalBffUrl }/organizations`;
    let searchParams = new HttpParams( { encoder: new HttpUrlEncodingCodec() } );

    if ( filter ) {
      searchParams = searchParams.append( 'filter', filter );
    }

    return this.http.get<Organization[]>( `${ path }?${ searchParams.toString() }` );
  }
}
