import { AutopayState } from './autopay.model';
import { AutopayActions } from './autopay.actions';
import { Action } from '../../../store/action.model';

export const INITIAL_STATE: AutopayState = {
  enrolled: undefined,
  desiredEnrollmentState: undefined,
  postError: undefined,
  postSuccess: undefined,
  postInProgress: undefined,
  getInProgress: undefined
};

export function autopayReducer (
  lastState: AutopayState = INITIAL_STATE,
  action: Action<Partial<AutopayState>>
): AutopayState {
  switch ( action.type ) {
    case AutopayActions.RESET_AUTOPAY:
      return INITIAL_STATE;
    case AutopayActions.SET_AUTOPAY_STATUS:
      return {
        ...lastState,
        enrolled: action.payload.enrolled,
        getInProgress: false,
        postInProgress: false
      };
    case AutopayActions.SET_AUTOPAY_DESIRED_NEW_STATE:
      return { ...lastState, desiredEnrollmentState: action.payload.desiredEnrollmentState };
    case AutopayActions.SET_AUTOPAY_POST_ERROR:
      return {
        ...lastState,
        desiredEnrollmentState: undefined,
        postError: action.payload.postError,
        postSuccess: false,
        postInProgress: false
      };
    case AutopayActions.POST_AUTOPAY_ENROLLMENT_COMPLETE:
      return {
        ...lastState,
        enrolled: action.payload.enrolled,
        desiredEnrollmentState: undefined,
        postError: undefined,
        postSuccess: true,
        postInProgress: false
      };
    case AutopayActions.POST_AUTOPAY_ENROLLMENT:
      return { ...lastState, postInProgress: true };
    case AutopayActions.GET_AUTOPAY_STATE:
      return { ...lastState, getInProgress: true };
    case AutopayActions.RESET_AUTOPAY_POST_SUCCESS:
      return { ...lastState, postSuccess: undefined };
    default:
      return lastState;
  }
}
