<div role="region" aria-labelledby="app-switcher app-menu">
  <button mat-icon-button
    id="app-switcher"
    [attr.aria-label]="'LABEL_APP_SWITCHER' | translate"
    type="button"
    class="app-switcher-button"
    [matMenuTriggerFor]="appSwitcherMenu">
    <mat-icon>apps</mat-icon>
  </button>
  <mat-menu #appSwitcherMenu="matMenu" overlapTrigger="false" class="app-switcher-popup-menu">
    <jive-app-switcher-list [language]="(selectedLanguage$ | async)"></jive-app-switcher-list>
  </mat-menu>

  <button mat-icon-button
    id="app-menu"
    [attr.aria-label]="'LABEL_NAV_DROP_DOWN' | translate"
    type="button"
    [matMenuTriggerFor]="navbarDropDownMenu">
    <mat-icon aria-hidden="true"> more_vert</mat-icon>
  </button>
  <mat-menu #navbarDropDownMenu="matMenu" class="nav-bar" overlapTrigger="false">
    <bp-language-selector></bp-language-selector>
    <button mat-menu-item
      (click)="logout()"
      [attr.aria-label]="'LOGOUT' | translate"
      type="submit">
      <mat-icon>exit_to_app</mat-icon>
      {{ 'LOGOUT' | translate }}
    </button>
    <jive-app-version></jive-app-version>
  </mat-menu>
</div>

