import { PAYMENT_COMMAND_PAY_ALL, PAYMENT_COMMAND_PAY_NOW, PaymentState, PostPaymentStatus } from './payment.model';
import { Action } from '../../../store/action.model';
import { PaymentActions } from './payment.actions';

export const INITIAL_STATE: PaymentState = {
  commandType: undefined,
  orgId: undefined,
  currency: undefined,
  selectedPaymentMethod: undefined,
  paymentAmount: undefined,
  invoiceInfo: undefined, // payTotal does not need this
  paymentMethodType: undefined,
  payType: undefined, // payTotal does not need this
  postInProgress: undefined,
  postSuccess: undefined,
  postStatus: PostPaymentStatus.NOT_POSTED,
  postError: undefined
};

export function paymentReducer (
  lastState: PaymentState = INITIAL_STATE,
  action: Action<Partial<PaymentState>>
): PaymentState {
  switch ( action.type ) {
    case PaymentActions.SET_PAYMENT_PAY_TOTAL_BASIC_INFO:
      return {
        ...lastState,
        commandType: PAYMENT_COMMAND_PAY_ALL,
        orgId: action.payload.orgId,
        currency: action.payload.currency,
        paymentAmount: action.payload.paymentAmount,
        invoiceInfo: undefined,
        payType: undefined
      };
    case PaymentActions.SET_PAYMENT_PAY_NOW_BASIC_INFO:
      return {
        ...lastState,
        commandType: PAYMENT_COMMAND_PAY_NOW,
        orgId: action.payload.orgId,
        currency: action.payload.currency
      };
    case PaymentActions.SET_PAYMENT_PAY_NOW_AMOUNT:
      return {
        ...lastState,
        paymentAmount: action.payload.paymentAmount,
        payType: action.payload.payType,
        invoiceInfo: action.payload.invoiceInfo
      };
    case PaymentActions.SET_PAYMENT_SET_PAYMENT_METHOD:
      return {
        ...lastState,
        selectedPaymentMethod: action.payload.selectedPaymentMethod,
        paymentMethodType: action.payload.selectedPaymentMethod.paymentMethodType,
        postInProgress: undefined,
        postSuccess: undefined,
        postError: undefined
      };
    case PaymentActions.RESET_PAYMENT_METHOD:
      return {
        ...lastState,
        selectedPaymentMethod: undefined,
        paymentMethodType: undefined,
        postInProgress: undefined,
        postSuccess: undefined,
        postError: undefined
      };
    case PaymentActions.CREATE_PAYMENT:
    case PaymentActions.POST_VALIDATE_PAYMENT:
      return {
        ...lastState,
        postInProgress: true,
        postStatus: PostPaymentStatus.IN_PROGRESS
      };
    case PaymentActions.SET_POST_PAYMENT_SUCCESS:
      return {
        ...lastState,
        postInProgress: false,
        postSuccess: true,
        postStatus: PostPaymentStatus.COMPLETED,
        postError: undefined
      };
    case PaymentActions.SET_POST_PAYMENT_ERROR:
      return {
        ...lastState,
        postInProgress: false,
        postSuccess: false,
        postStatus: PostPaymentStatus.ERROR,
        postError: action.payload.postError
      };
    case PaymentActions.SET_POST_PAYMENT_COMPLETED_WITH_SCA_REQUIREMENT:
      return {
        ...lastState,
        postInProgress: false,
        postSuccess: true,
        postStatus: PostPaymentStatus.VALIDATION_REQUIRED,
        postError: undefined
      };
    default:
      return lastState;
  }
}
