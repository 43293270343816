import { SmsDetailsState } from './sms-details.model';
import { Action } from '../../../store/action.model';
import { SmsDetailsActions } from './sms-details.actions';

export const INITIAL_STATE: SmsDetailsState = {
  smsDetails: undefined,
  getInProgress: undefined,
};

export function smsDetailsReducer (
  lastState: SmsDetailsState = INITIAL_STATE,
  action: Action<Partial<SmsDetailsState>>
): SmsDetailsState {
  switch ( action.type ) {
    case SmsDetailsActions.SET_SMS_DETAILS:
      return { ...lastState, smsDetails: action.payload.smsDetails, getInProgress: false };
    case SmsDetailsActions.GET_SMS_DETAILS:
      return { ...lastState, getInProgress: true };
    case SmsDetailsActions.RESET_SMS_DETAILS:
      return INITIAL_STATE;
    default:
      return lastState;
  }
}

