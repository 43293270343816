import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Country } from '../location-converter/assets/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable( { providedIn: 'root' } )
export class CallDetailsService {

  constructor ( private http: HttpClient ) { }

  getCallDetails ( orgId: string, year: number, month: number, country: Country, language: string ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/call-detail-reports/${ year }-${ month }?countryCode=${ country.code }`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/zip')
      .set('Accept-Language', language);

    return this.http.get(path, { headers, observe: 'response', responseType: 'blob' })
      .pipe( map( it => it.body ) );
  }
}
