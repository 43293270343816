import { Action } from '../../../store/action.model';
import { ReceiptEmailState } from './receipt-email.model';
import { ReceiptEmailActions } from './receipt-email.actions';

export const INITIAL_STATE: ReceiptEmailState = {
  email: undefined,
  getSuccess: undefined,
  postSuccess: undefined,
  postError: undefined,
  postInProgress: undefined,
  desiredEmail: undefined,
  getInProgress: undefined
};

export function receiptEmailReducer (
  lastState: ReceiptEmailState = INITIAL_STATE,
  action: Action<Partial<ReceiptEmailState>>
): ReceiptEmailState {
  switch ( action.type ) {
    case ReceiptEmailActions.SET_RECEIPT_EMAIL:
      const desiredEmailValue = action.payload.email ? action.payload.email : undefined;
      return {
        ...lastState,
        desiredEmail: desiredEmailValue,
        email: action.payload.email,
        getSuccess: action.payload.getSuccess,
        getInProgress: false
      };
    case ReceiptEmailActions.SET_RECEIPT_EMAIL_DESIRED:
      return { ...lastState, desiredEmail: action.payload.desiredEmail };
    case ReceiptEmailActions.POST_RECEIPT_EMAIL_COMPLETE:
      return {
        ...lastState,
        email: action.payload.email,
        getSuccess: action.payload.getSuccess,
        postSuccess: true,
        postError: undefined,
        desiredEmail: action.payload.email,
        postInProgress: false
      };
    case ReceiptEmailActions.RESET_RECEIPT_EMAIL:
      return INITIAL_STATE;
    case ReceiptEmailActions.SET_RECEIPT_EMAIL_POST_ERROR:
      return {
        ...lastState,
        postSuccess: false,
        postError: action.payload.postError,
        postInProgress: false
      };
    case ReceiptEmailActions.POST_RECEIPT_EMAIL:
      return { ...lastState, postInProgress: true, getSuccess: true };
    case ReceiptEmailActions.GET_RECEIPT_EMAIL:
      return { ...lastState, getInProgress: true, getSuccess: true };
    default:
      return lastState;
  }
}
