import { Injectable } from '@angular/core';
import { LanguageSelectorActions } from './redux/language-selector.actions';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { ENGLISH_UNITED_STATES, Language } from './redux/language-selector.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SELECTED_LANGUAGE_SELECTOR } from '../../store/helper';

@Injectable( {
  providedIn: 'root'
} )

export class LanguageSelectorService {

  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;

  constructor ( private languageSelectorActions: LanguageSelectorActions,
    private reduxStore: NgRedux<AppState>,
    private translate: TranslateService ) { }

  init () {
    this.selectedLanguage$.pipe(
      first()
    ).subscribe( ( selectedLanguage ) => {
      if ( selectedLanguage === undefined ) {
        this.getBrowserLanguageAndSetAsDefaultLanguage();
      } else {
        this.setLanguageForViews( selectedLanguage );
      }
    } );
  }

  setLanguageForViews ( language: Language ) {
    this.translate.setDefaultLang( ENGLISH_UNITED_STATES.locale );
    this.translate.use( Language.viewInstanceOf( language ).locale );
  }

  getBrowserLanguageAndSetAsDefaultLanguage () {
    const language = window.navigator.language;
    const defaultLanguage = Language.instanceFromString( language );
    this.updateSelectedLanguage( defaultLanguage );
  }

  updateSelectedLanguage ( language: Language ) {
    this.setLanguageForViews( language );
    this.reduxStore.dispatch( this.languageSelectorActions.setSelectedLanguage( language ) );
  }
}
