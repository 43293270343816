import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageItemComponent } from './language-item.component';
import { LanguageSelectorDropdownComponent } from './language-selector-dropdown.component';

@NgModule( {
  imports: [
    CommonModule
  ],
  declarations: [ LanguageItemComponent, LanguageSelectorDropdownComponent ],
  exports: [ LanguageItemComponent, LanguageSelectorDropdownComponent ],
  providers: []
} )
export class LanguageSelectorModule { }
