import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { PaymentMethod } from '../../services/payment-method/redux/payment-method.model';
import { PaymentMethodService } from '../../services/payment-method/payment-method.service';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { AppState } from '../../store/app-state.model';
import { first, takeUntil } from 'rxjs/operators';
import { AutopayState } from '../../services/autopay/redux/autopay.model';
import { AUTOPAY_STATE_SELECTOR, DEFAULT_PAYMENT_METHOD_SELECTOR, ORGANIZATION_ID_SELECTOR, PAYMENT_METHOD_DELETE_SELECTOR, PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR, PAYMENT_METHOD_LIST_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-payment-method-list',
  templateUrl: './payment-method-list.component.html',
  styleUrls: [ './payment-method-list.component.scss' ]
} )
export class PaymentMethodListComponent implements OnInit, OnDestroy {

  @select( PAYMENT_METHOD_LIST_SELECTOR ) paymentMethodList$: Observable<PaymentMethod[]>;
  @select( PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR ) paymentMethodListGetInProgress$: Observable<boolean>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( PAYMENT_METHOD_DELETE_SELECTOR ) delete$: Observable<boolean>;
  @select( DEFAULT_PAYMENT_METHOD_SELECTOR ) defaultChange$: Observable<boolean>;
  @select( AUTOPAY_STATE_SELECTOR ) autopay$: Observable<AutopayState>;

  unsubscribe$: Subject<boolean> = new Subject();

  errorMessageKey: string;
  deleteInProgress = false;
  defaultChangeInProgress: boolean;
  show = true;
  initialLoadingFinished = false;
  showLoading = true;

  dataError = false;

  selectedPaymentMethodToDelete: PaymentMethod;
  showDeleteConfirmationModal = false;

  noPaymentMethodImgPath = 'assets/images/noPaymentOption.svg';

  constructor (
    private paymentMethodService: PaymentMethodService,
    private paymentMethodActions: PaymentMethodActions,
    private reduxStore: NgRedux<AppState>
  ) { }

  ngOnInit () {
    combineLatest( [
      this.paymentMethodListGetInProgress$,
      this.paymentMethodList$,
      this.autopay$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ inProgress, list, autopayState ] ) => {
      if ( inProgress === false && autopayState.getInProgress === false && autopayState.enrolled !== undefined ) {
        if ( !this.initialLoadingFinished ) {
          this.initialLoadingFinished = true;
        }

      }
      this.showLoading = this.initialLoadingFinished === false;

      if ( this.initialLoadingFinished ) {
        this.dataError = ( ( list === undefined && inProgress === false ) ||
          ( autopayState.getInProgress === false && autopayState.enrolled === undefined ) );
      }

    } );

    this.paymentMethodList$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( () => {
      this.showDeleteConfirmationModal = false;
    } );

    this.delete$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( deletePaymentMethodState: any ) => {

      if ( deletePaymentMethodState ) {
        this.deleteInProgress = deletePaymentMethodState.deleteInProgress;

        if ( !deletePaymentMethodState.deleteInProgress && deletePaymentMethodState.postError ) {
          this.reload();
          this.errorMessageKey = deletePaymentMethodState.postError.errorTranslationKey;
        }
      }
    } );

    this.defaultChange$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( defaultPaymentMethodState: any ) => {
      if ( defaultPaymentMethodState ) {
        this.defaultChangeInProgress = defaultPaymentMethodState.postInProgress;

        if ( !defaultPaymentMethodState.postInProgress && defaultPaymentMethodState.postError ) {
          this.reload();

          this.errorMessageKey = 'ERROR_UPDATING_DEFAULT_PAYMENT_METHOD';
          // 'Error updating the default payment method. Please try again later.';
        }
      } else if ( defaultPaymentMethodState === undefined ) {
        this.defaultChangeInProgress = false;
      }
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  updateDefaultMethod ( paymentMethodId: string ) {
    if ( !this.deleteInProgress && !this.defaultChangeInProgress ) {
      this.defaultChangeInProgress = true;
      this.organizationId$.pipe( first() )
        .subscribe( ( orgId ) => {
          this.errorMessageKey = undefined;
          this.reduxStore.dispatch(
            this.paymentMethodActions.postDefaultPaymentMethodChange( orgId, paymentMethodId )
          );
        } );
    } else {
      this.errorMessageKey = 'ERROR_PAYMENT_METHOD_LIST_POST_IN_PROGRESS';
      // 'Please try again after requested changes are saved.';
    }
  }

  confirmBeforeDelete ( paymentMethod: PaymentMethod ) {
    this.selectedPaymentMethodToDelete = paymentMethod;
    this.showDeletePaymentMethodConfirmationModal();
  }

  showDeletePaymentMethodConfirmationModal () {
    this.showDeleteConfirmationModal = true;
  }

  hideDeletePaymentMethodConfirmationModal () {
    this.showDeleteConfirmationModal = false;
  }

  deletesSelectedPaymentMethod () {
    this.deleteInProgress = true;
    this.errorMessageKey = undefined;

    this.organizationId$
      .pipe( first() )
      .subscribe( ( orgId ) => {
        this.reduxStore.dispatch(
          this.paymentMethodActions.postDeletePaymentMethod(
            orgId,
            this.selectedPaymentMethodToDelete.id
          )
        );
      } );
  }

  getPaymentMethodImageUrl ( paymentMethod: PaymentMethod ) {
    return this.paymentMethodService.getPaymentMethodImageUrl( paymentMethod );
  }

  // When updating the default payment method fails, we need to put checked attribute on the correct item.
  // The simplest way to trigger re-rendering is this.
  reload () {
    this.show = false;
    setTimeout( () => this.show = true );
  }

  resetErrorMessage () {
    this.errorMessageKey = undefined;
  }

  closeErrorModal () {
    this.resetErrorMessage();
    this.hideDeletePaymentMethodConfirmationModal();
  }

  showDelete ( list: PaymentMethod[], paymentMethod: PaymentMethod ) {
    return list.length != 0;
  }
}
