import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { Observable, Subject } from 'rxjs';
import { PaymentMethod } from '../../services/payment-method/redux/payment-method.model';
import { AppState } from '../../store/app-state.model';
import { takeUntil } from 'rxjs/operators';
import { PaymentActions } from '../../services/payment/redux/payment.actions';
import { PaymentMethodService } from '../../services/payment-method/payment-method.service';
import { IS_MOBILE_SELECTOR, PAYMENT_METHOD_LIST_SELECTOR } from '../../store/helper';

// In order to have the clean state with updated payment method list,
// the parent component should call  paymentMethodActions.resetPaymentMethod  and
// getPaymentMethodList.

@Component( {
  selector: 'bp-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: [ './payment-method-selector.component.scss' ]
} )
export class PaymentMethodSelectorComponent implements OnInit, OnDestroy {
  @Input() paymentInProgress: boolean;
  @select( PAYMENT_METHOD_LIST_SELECTOR ) paymentMethodList$: Observable<PaymentMethod[]>;
  @select( IS_MOBILE_SELECTOR ) isMobile$: Observable<boolean>;

  unsubscribe$: Subject<boolean> = new Subject();

  defaultPayment: PaymentMethod;

  constructor (
    private paymentActions: PaymentActions,
    private reduxStore: NgRedux<AppState>,
    private paymentMethodService: PaymentMethodService
  ) { }

  ngOnInit () {
    this.paymentMethodList$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe(
        ( list ) => {
          if ( list ) {
            list.forEach( ( item ) => {
              if ( item.defaultMethod ) {
                this.defaultPayment = item;
                this.updateSelected( item );
              }
            } );
          }
        }
      );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  updateSelected ( paymentMethod: PaymentMethod ) {
    this.reduxStore.dispatch(
      this.paymentActions.setPaymentMethod( paymentMethod )
    );
  }

  getPaymentMethodImageUrl ( paymentMethod: PaymentMethod ) {
    return this.paymentMethodService.getPaymentMethodImageUrl( paymentMethod );
  }

}
