import { InjectionToken } from '@angular/core';
import { AuthenticationConfig } from '@jive/core/authentication';

export const AUTHENTICATION_CONFIG_TOKEN = new InjectionToken<AuthenticationConfig>( 'AuthenticationConfig' );
/**
 * If you end up having to use a factory with this then you will need to export the function being used for the factory. Otherwise
 * your code will compile locally but not when you try and run it through AOT.
 * Here is the bug https://github.com/gevgeny/angular2-highcharts/issues/156
 */
export function authenticationConfigProvider ( authenticationConfig: AuthenticationConfig ) {
  return {
    provide: AUTHENTICATION_CONFIG_TOKEN,
    useValue: authenticationConfig
  };
}
