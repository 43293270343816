import {Injectable} from '@angular/core';
import {PaymentActions} from './payment.actions';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {PaymentService} from '../payment.service';
import {Observable, of} from 'rxjs';
import {ReceiptEmailActions} from '../../receipt-email/redux/receipt-email.actions';
import {ErrorResponseService} from '../../error-response/error-response.service';
import {
    MERCHANT_TYPE_COPAS,
    PAYMENT_METHOD_TYPE_ACH,
    PAYMENT_METHOD_TYPE_CARD
} from '../../payment-method/redux/payment-method.model';
import {ScaPurpose, ScaRequirement, ScaTokenWithExpiration} from '../../cardinal/redux/cardinal.model';
import {CardinalActions} from '../../cardinal/redux/cardinal.actions';
import {CardinalService} from '../../cardinal/cardinal.service';
import {ERROR_MESSAGE_MAKING_PAYMENT_ACH, ERROR_MESSAGE_MAKING_PAYMENT_CC} from './payment.model';

// tslint:disable:max-line-length
@Injectable( {
  providedIn: 'root'
} )
export class PaymentEpic {

  constructor ( private paymentService: PaymentService,
    private errorResponseService: ErrorResponseService,
    private paymentActions: PaymentActions,
    private cardinalActions: CardinalActions,
    private cardinalService: CardinalService ) { }

  getEpics () {

    return combineEpics(
      this.setPaymentPostSuccess.bind( this ),
      this.postPaymentAfterReceiptEmailPost.bind( this ),
      this.postPaymentAfterReceiptEmailPostFails.bind( this ),
      this.createPayment.bind( this ),
      this.postValidatePayment.bind( this )
    );
  }

  postPaymentAfterReceiptEmailPost ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.POST_RECEIPT_EMAIL_COMPLETE_READY_FOR_PAYMENT),
          map(({payload: {state}}) => this.paymentActions.createPayment(state))
      );
  }

  postPaymentAfterReceiptEmailPostFails ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.POST_RECEIPT_EMAIL_FAILED_READY_FOR_PAYMENT),
          map(({payload: {state}}) => this.paymentActions.createPayment(state))
      );
  }

  getGenericPaymentErrorTranslationKey ( paymentMethodType: string ) {
    let genericErrorTranslationKey = 'ERROR_PROCESSING_PAYMENT_DEFAULT';
    if ( paymentMethodType === PAYMENT_METHOD_TYPE_CARD ) {
      genericErrorTranslationKey = ERROR_MESSAGE_MAKING_PAYMENT_CC;
    } else if ( paymentMethodType === PAYMENT_METHOD_TYPE_ACH ) {
      genericErrorTranslationKey = ERROR_MESSAGE_MAKING_PAYMENT_ACH;
    }

    return genericErrorTranslationKey;
  }

  setPaymentPostSuccess ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentActions.POST_PAYMENT_COMPLETE),
        map( ( { payload: { orgId } } ) => this.paymentActions.setPostPaymentSuccess( orgId ) )
      );
  }

  createPayment ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentActions.CREATE_PAYMENT),
        switchMap( ( { payload: { paymentState } } ) => {
          if ( paymentState.selectedPaymentMethod.merchantProcessorType === MERCHANT_TYPE_COPAS ) {
            return this.cardinalService.getScaTokenWithExpiration( {
              currency: paymentState.currency,
              country: paymentState.selectedPaymentMethod.country
            } )
              .pipe(
                map( ( scaTokenWithExpiration: ScaTokenWithExpiration ) => {
                  const orgId = paymentState.orgId;
                  const createPaymentRequest = this.paymentService.createPaymentRequestConverter( paymentState, scaTokenWithExpiration.scaToken );
                  const paymentMethodType = paymentState.selectedPaymentMethod.paymentMethodType;

                  return {
                    orgId: orgId,
                    createPaymentRequest: createPaymentRequest,
                    paymentMethodType: paymentMethodType
                  };
                } )
              );
          } else {
            return new Observable( observer => {
              const createPaymentRequest = this.paymentService.createPaymentRequestConverter( paymentState, undefined );
              observer.next( {
                orgId: paymentState.orgId,
                createPaymentRequest: createPaymentRequest,
                paymentMethodType: paymentState.paymentMethodType
              } );
            } );
          }
        } ),
        switchMap( ( { orgId, createPaymentRequest, paymentMethodType } ) => {
          return this.paymentService.createPayment( orgId, createPaymentRequest )
            .pipe(
              map( ( paymentMethodCreationResponse ) => {
                if ( paymentMethodCreationResponse?.payerAuthEnrollmentResult ) {
                  const scaRequirement: ScaRequirement = {
                    purpose: ScaPurpose.payment,
                    scaRequired: true,
                    paymentCreationResponse: {
                      transactionId: paymentMethodCreationResponse.paymentId,
                      payerAuthEnrollmentResult: paymentMethodCreationResponse.payerAuthEnrollmentResult
                    }
                  };
                  return this.paymentActions.setPostPaymentCompletedWithScaRequirement( scaRequirement );
                } else {
                  return this.paymentActions.postPaymentCompleted( orgId, createPaymentRequest.payAll, createPaymentRequest.invoiceInfo );
                }
              } ),
              catchError( ( error: any ) => {
                const genericErrorTranslationKey = this.getGenericPaymentErrorTranslationKey( paymentMethodType );
                const preppedError = this.errorResponseService.parseErrorResponse( error, genericErrorTranslationKey );
                return of( this.paymentActions.setPostPaymentError( preppedError ) );
              } )
            );
        } ),
        catchError( ( error: any ) => {
          if ( error?.type === PaymentActions.SET_POST_PAYMENT_ERROR ) {
            return of( error );
          } else {
            const genericErrorTranslationKey = this.getGenericPaymentErrorTranslationKey( undefined );
            const preppedError = this.errorResponseService.parseErrorResponse( error, genericErrorTranslationKey );
            return of( this.paymentActions.setPostPaymentError( preppedError ) );
          }
        } )
      );
  }

  setScaRequirement ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentActions.SET_POST_PAYMENT_COMPLETED_WITH_SCA_REQUIREMENT),
        map( ( { payload: { scaRequirement } } ) => this.cardinalActions.setScaRequirementState( scaRequirement ) )
      );
  }

  postValidatePayment ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentActions.POST_VALIDATE_PAYMENT),
        switchMap( ( { payload: { validatePaymentInfo } } ) => {
          return this.paymentService.validatePayment( validatePaymentInfo.orgId, validatePaymentInfo.paymentId, validatePaymentInfo.validatePaymentRequest )
            .pipe(
              map( () => this.paymentActions.postPaymentCompleted( validatePaymentInfo.orgId, validatePaymentInfo.payAll, validatePaymentInfo.invoiceInfo ) ),
              catchError( ( error: any ) => {
                const genericErrorTranslationKey = this.getGenericPaymentErrorTranslationKey( validatePaymentInfo.paymentMethodType );
                const preppedError = this.errorResponseService.parseErrorResponse( error, genericErrorTranslationKey );
                return of( this.paymentActions.setPostPaymentError( preppedError ) );
              } )
            );
        } )
      );
  }
}
