import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ReceiptEmailState } from './redux/receipt-email.model';
import { HttpClient } from '@angular/common/http';

@Injectable( { providedIn: 'root' } )
export class ReceiptEmailService {

  constructor ( private http: HttpClient ) { }

  getReceiptEmail ( orgId: string ) {
    return this.http.get( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/receipt-email` );
  }

  upsertEmail ( orgId: string, email: string ) {
    return this.http.put<string>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/receipt-email`, { 'email': email } );
  }

  needsToUpdateReceiptEmail ( state: ReceiptEmailState ) {
    return ( state.desiredEmail && state.email === undefined ) ||
      ( state.desiredEmail !== null && state.desiredEmail !== state.email );
  }

  isValidEmail ( email: string ) {
    if ( email ) {
      const validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return validation.test( email );
    } else {
      // we accept empty email to override the currently stored email value.
      return true;
    }
  }
}
