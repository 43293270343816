import { Injectable } from '@angular/core';
import { AuthState, AuthStorageStrategy } from '@jive/core/authentication';
import { Action } from '../../../store/action.model';
import { AppState } from '../../../store/app-state.model';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class AuthActions extends AuthStorageStrategy {

  static LOGIN = 'AUTH:LOGIN';
  static SCOPES = 'AUTH:SCOPES';
  static LOGOUT = 'AUTH:LOGOUT';

  constructor ( protected reduxStore: NgRedux<AppState> ) {
    super();
  }

  getAuthState (): AuthState | undefined {
    return this.reduxStore.getState() ? this.reduxStore.getState().auth : null;
  }

  setAuthState ( authState: AuthState ): void {
    this.authStateDispatcher( authState );
  }

  getScopes () {
    return this.getAuthState() ? this.getAuthState().scopes : null;
  }

  getAuthInfo () {
    return this.getAuthState() ? this.getAuthState().authInfo : null;
  }

  loginAction ( authInfo: AuthState ): Action<AuthState> {
    return { type: AuthActions.LOGIN, payload: authInfo };
  }

  logoutAction () {
    return { type: AuthActions.LOGOUT };
  }

  authStateDispatcher ( authState: AuthState ) {
    return this.reduxStore.dispatch( this.loginAction( authState ) );
  }

  // remove, set, and get are needed by jive/core oauth flow.
  removeAuthState (): Action<any> {
    return this.reduxStore.dispatch( this.logoutAction() );
  }
}
