export interface InvoiceState {
  invoice: Invoice;
  latestPaymentMethodId: string;
  getInProgress: boolean;
  latestPaymentMethodIdGetInProgress: boolean;
  pdfDownload: Download;
  csvDownload: Download;
  mexicoPdfDownload: Download;
  mexicoXmlDownload: Download;
}

export interface TransactionType {
  value: string;
  netsuiteValue: Number;
}

export interface InvoiceDetail {
  description: string;
  quantity: string;
  price: string;
  totalAmount: string;
  customerNumber: string;
  billingGroup: string;
  translatedDescription?: string;
  additionalDescription?: string;
}

export interface Invoice {
  id: string;
  currencyUnit: string;
  dateCreated: string;
  datePosted: string;
  customerNumber: string;
  documentNumber: string;
  total: number;
  totalDue: number;
  transactionType: TransactionType;
  message: string;
  dateDue: string;
  invoiceDetails: Array<InvoiceDetail>;
  customerName: string;
  datePaid: string;
  referenceNotes: string;
  mexicoFolioNumber: string;
  pdfExists?: boolean;
  mexicoFilesExist?: boolean;
  boletoLink?: string;
  notaServicoLink?: string;
  notaTelecomLink?: string;
}

export interface Download {
  inProgress: boolean;
  error: any;
}

export const JIVE_INVOICE = { value: 'Jive - Invoice', netsuiteValue: 170 };

// invoice status keys used for translation
export const OVERDUE = 'OVERDUE';
export const PARTPAID = 'PARTPAID';
export const PAID = 'PAID';
export const UNPAID = 'UNPAID';
export const UNAPPLIED = 'UNAPPLIED';
export const APPLIED = 'APPLIED';
export const PARTAPPLIED = 'PARTAPPLIED';
