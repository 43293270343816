import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallDetailsActions } from './redux/call-details.actions';
import { CallDetailsEpic } from './redux/call-details.epic';
import { CallDetailsService } from './call-details.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    CallDetailsActions,
    CallDetailsEpic,
    CallDetailsService
  ],
  declarations: []
} )
export class CallDetailsModule {}
