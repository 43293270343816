import {FactoryProvider, InjectionToken} from '@angular/core';
import {
    HttpResponse as JiveHttpResponse,
    HttpResponseData,
    JiveApiStandardV2ErrorResponse,
    JiveHttpHandler,
    JiveRequestOptionsArgs
} from '@jive/common/http';
import {HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HttpHandlerStrategy} from './handler-strategy';
import {Constructor} from '@jive/common/entities';
import {HttpHandlerAuthenticationHook} from '../auth/models';

interface AngularRequestOptionArgs {
    headers?: HttpHeaders;
    observe: 'response';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

/**
 * Use this parameter encoder when creating HttpParams objects as a workaround for https://github.com/angular/angular/issues/11058.
 * Angular does not do proper encoding apparently.
 */
export const customParamEncoder: HttpParameterCodec = {
    encodeKey(key: string) {
        return encodeURIComponent(key);
    },
    encodeValue(value: string) {
        return encodeURIComponent(value);
    },
    decodeKey(key: string) {
        return decodeURIComponent(key);
    },
    decodeValue(value: string) {
        return decodeURIComponent(value);
    }
};

export class HttpHandlerService<
    DEFAULT_ERROR_TYPE = JiveApiStandardV2ErrorResponse
> extends JiveHttpHandler<DEFAULT_ERROR_TYPE> {
    // We now rely on a passed in handler strategy, this allows us to use the same
    // code for both lmi and jive.
    constructor(
        private http: HttpClient,
        private handlerStrategy: HttpHandlerAuthenticationHook
    ) {
        super();
    }

    get<T = any, E = DEFAULT_ERROR_TYPE>(
        url: string,
        options?: JiveRequestOptionsArgs
    ): JiveHttpResponse<T, E> {
        return this.http
            .get<T>(url, this.convertJiveArgsToHttpArgs(options))
            .pipe(map(this.convertResponseToJiveHttpResponse.bind(this)));
    }

    head<T = any, E = DEFAULT_ERROR_TYPE>(
        url: string,
        options?: JiveRequestOptionsArgs
    ): JiveHttpResponse<T, E> {
        return this.http
            .head<T>(url, this.convertJiveArgsToHttpArgs(options))
            .pipe(map(this.convertResponseToJiveHttpResponse.bind(this)));
    }

    post<T = any, E = DEFAULT_ERROR_TYPE>(
        url: string,
        body: any | null,
        options?: JiveRequestOptionsArgs
    ): JiveHttpResponse<T, E> {
        return this.http
            .post<T>(url, body, this.convertJiveArgsToHttpArgs(options))
            .pipe(map(this.convertResponseToJiveHttpResponse.bind(this)));
    }

    put<T = any, E = DEFAULT_ERROR_TYPE>(
        url: string,
        body: any | null,
        options?: JiveRequestOptionsArgs
    ): JiveHttpResponse<T, E> {
        return this.http
            .put<T>(url, body, this.convertJiveArgsToHttpArgs(options))
            .pipe(map(this.convertResponseToJiveHttpResponse.bind(this)));
    }

    patch<T = any, E = DEFAULT_ERROR_TYPE>(
        url: string,
        body: any | null,
        options?: JiveRequestOptionsArgs
    ): JiveHttpResponse<T, E> {
        return this.http
            .patch<T>(url, body, this.convertJiveArgsToHttpArgs(options))
            .pipe(map(this.convertResponseToJiveHttpResponse.bind(this)));
    }

    delete<T = any, E = DEFAULT_ERROR_TYPE>(
        url: string,
        options?: JiveRequestOptionsArgs
    ): JiveHttpResponse<T, E> {
        return this.http
            .delete<T>(url, this.convertJiveArgsToHttpArgs(options))
            .pipe(map(this.convertResponseToJiveHttpResponse.bind(this)));
    }

    private convertResponseToJiveHttpResponse<T = any, E = DEFAULT_ERROR_TYPE>(
        response: HttpResponse<T>
    ): HttpResponseData<T, E> {
        const {body: data, statusText, status} = response;
        const headers = new Headers();
        // for now angular sets the prototype of their HttpHeaders implementation to Headers
        // thus at runtime it adheres to the spec but typescript wont let you treat it as such
        // there is a bug ticket in for this here https://github.com/angular/angular/issues/20548
        response.headers.keys().forEach(key => {
            response.headers.getAll(key).forEach(value => headers.append(key, value));
        });

        return {
            data,
            headers,
            statusText,
            status
        };
    }

    private reduceHeadersToJson(values: IterableIterator<[string, string]>) {
        const headers = {};
        for (const [key, value] of values) {
            headers[key] = value;
        }
        return headers;
    }

    private shouldIncludeAuthorization(options: JiveRequestOptionsArgs) {
        if (
            typeof options.includeAuthorization === 'undefined' ||
            options.includeAuthorization
        ) {
            return true;
        } else {
            return false;
        }
    }

    private convertJiveArgsToHttpArgs(
        options?: JiveRequestOptionsArgs
    ): AngularRequestOptionArgs {
        if (options) {
            // tslint:disable-next-line:max-line-length
            // Removed the append since there is a bug in Angular that when you use clone inside of the interceptor it doubles the query params
            // so until the PR (https://github.com/angular/angular/pull/20610) gets merged for this bug
            // (https://github.com/angular/angular/issues/18812) we will have to use set
            const headers = options.headers
                ? new HttpHeaders(this.reduceHeadersToJson(options.headers.entries()))
                : undefined;
            let params: HttpParams = new HttpParams({encoder: customParamEncoder});
            // tslint:disable-next-line:max-line-length
            // had to create this makeshift typeguard because the angular build optimizer was converting the guard to a reassignment of params
            // which was causing errors at runtime
            if (options.params instanceof HttpParams) {
                params = options.params;
            } else if (
                options.params &&
                !!options.params['get'] &&
                !!options.params['set'] &&
                !!options.params['has']
            ) {
                for (const param of options.params as URLSearchParams) {
                    params = params.append(param[0], param[1]);
                }
            } else if (options.params) {
                for (const key of Object.keys(options.params)) {
                    if (Array.isArray(options.params[key])) {
                        for (const paramValue of options.params[key]) {
                            params = params.append(key, paramValue);
                        }
                    } else {
                        params = params.append(key, options.params[key]);
                    }
                }
            }

            // Take the strategy that was passed in when initialized and check to see if the
            // service making the request has explicitly requested for the auth token to be added.
            if (this.shouldIncludeAuthorization(options)) {
                /**
                 * placing this on the object directly, this allows for us to avoid calling set or append on the httpParams,
                 * when set or append is called the query params are doubled and this breaks some api calls. This is a workaround to
                 * the bug mentioned above.
                 */
                params[this.handlerStrategy.includeAuthHeaderPropName] = true;
            }

            /**
             * If there are options passed in and the options have the allow unauthenticated property then
             * add signal for the http interceptor
             */
            if (options.allowUnauthenticated) {
                const allowAuthenticatedPropertyName =
                    this.handlerStrategy.allowUnAuthPropName &&
                    this.handlerStrategy.allowUnAuthPropName;

                /**
                 * Not getting the string directly from the ui-common-sdk so that not just any handler will allow this.
                 * This makes it so it is very explicit as to which handlers we expect to be using this and which
                 * ones definitely should not.
                 */
                if (!allowAuthenticatedPropertyName) {
                    throw new Error(`
              You're trying to allow http requests to fall through unauthenticated, however,
              your http handler strategy is missing the getter for the property name.
            `);
                }
                params[allowAuthenticatedPropertyName] = true;
            }

            const {
                headers: RemovingHeaders,
                params: RemovingParams,
                includeAuthorization,
                // in case there are custom args that need to be sent
                // we are opening it up to allow these to be sent in. The arg that
                // spawned this need is responseType
                ...customArgs
            } = options;

            return {
                ...customArgs,
                headers,
                observe: 'response',
                params
            };
        } else {
            const params = new HttpParams({encoder: customParamEncoder});
            /**
             * placing this on the object directly, this allows for us to avoid calling set or append on the httpParams,
             * when set or append is called the query params are doubled and this breaks some api calls. This is a workaround to
             * the bug mentioned above.
             */
            params[this.handlerStrategy.includeAuthHeaderPropName] = true;
            return {
                observe: 'response',
                params
            };
        }
    }
}

export function httpHandlerServiceFactory(
    http: HttpClient,
    authHook: HttpHandlerAuthenticationHook = new HttpHandlerStrategy()
) {
    return new HttpHandlerService(http, authHook);
}

export const HTTP_HANDLER_SERVICE_PROVIDER: FactoryProvider = {
    provide: JiveHttpHandler,
    useFactory: httpHandlerServiceFactory,
    deps: [HttpClient]
};

/**
 *
 * @param injectionToken This is an alternate provider, use this if you need to
 * @param authHook
 */
export function httpHandlerProvider<
    T extends JiveHttpHandler<any>,
    H extends HttpHandlerAuthenticationHook
>(
    injectionToken: InjectionToken<T> | Constructor<T>,
    authHook: Constructor<H> | InjectionToken<H>
): FactoryProvider {
    return {
        provide: injectionToken,
        useFactory: httpHandlerServiceFactory,
        deps: [HttpClient, authHook]
    };
}
