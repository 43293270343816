import { Component, OnInit, Input } from '@angular/core';
import { LocalizedDetail } from '@jive/core/applications';

@Component( {
  selector: 'jive-app-switcher-application',
  templateUrl: './app-switcher-application.component.html',
  styleUrls: [ './app-switcher-application.component.scss' ]
} )
export class AppSwitcherApplicationComponent implements OnInit {

  @Input() public application?: LocalizedDetail;

  constructor () { }

  ngOnInit () {
  }

}
