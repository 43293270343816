import { Action } from '../../../store/action.model';
import { FlexFormState } from './flex-form.model';
import { FlexFormActions } from './flex-form.actions';

export const INITIAL_STATE: FlexFormState = {
  key: undefined,
  expirationDate: undefined,
  getKeyInProgress: undefined
};

export function flexFormReducer (
  lastState: FlexFormState = INITIAL_STATE,
  action: Action<Partial<FlexFormState>>
): FlexFormState {
  switch ( action.type ) {
    case FlexFormActions.RESET_FLEX_KEY_STATE:
      return INITIAL_STATE;
    case FlexFormActions.GET_FLEX_KEY:
      return {
        ...lastState,
        getKeyInProgress: true
      };
    case FlexFormActions.SET_FLEX_KEY:
      const expiration = ( action.payload.key && action.payload.key.expiresInMs ) ?
        new Date( Date.now() + action.payload.key.expiresInMs ) : undefined;

      return {
        ...lastState,
        key: action.payload.key,
        expirationDate: expiration,
        getKeyInProgress: false
      };
    default:
      return lastState;
  }
}
