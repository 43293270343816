import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { first, takeUntil } from 'rxjs/operators';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { AppState } from '../../store/app-state.model';
import { OrganizationState } from '../../services/organization/redux/organization.model';
import { Country, UNITED_STATES } from '../../services/location-converter/assets/model';
import { CC_GENERATOR_STATE, COPAS_STATE_SELECTOR, ORGANIZATION_ID_SELECTOR, ORGANIZATION_STATE_SELECTOR } from '../../store/helper';
import { CopasState } from '../../services/copas/redux/copas.model';
import { CcGeneratorState } from '../../services/cc-generator/redux/cc-generator.model';

/*
  Because an epic handles the error and not in the component, in order for this component to
  have access to the error, the error has to persist in the store.
  Since we do not want to show the old persisted error from previous lifecycle, it needs to be cleared
  by a parent component by calling paymentMethodActions.resetPaymentMethod
  Also a parent component needs to call BillingEmailActions reset and getBillingEmail.
  This component assumes that the billingEmail state was refreshed.
*/

@Component( {
  selector: 'bp-payment-method-generator',
  templateUrl: './payment-method-generator.component.html',
  styleUrls: [ './payment-method-generator.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )
export class PaymentMethodGeneratorComponent implements OnInit, OnDestroy {
  @Output() showPaymentMethodGenerator = new EventEmitter<boolean>();

  @select( ORGANIZATION_STATE_SELECTOR ) organizationState$: Observable<OrganizationState>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( COPAS_STATE_SELECTOR ) copasState$: Observable<CopasState>;
  @select( CC_GENERATOR_STATE ) ccGeneratorState$: Observable<CcGeneratorState>;

  unsubscribe$: Subject<boolean> = new Subject();

  paymentTypeOptionAvailable: boolean;
  showPaymentGeneratorOption: boolean;
  showCardGenerator: boolean;
  showAchGenerator: boolean;

  useStripeCardGenerator: boolean;

  cardImgPath = 'assets/images/Cards.svg';
  achImgPath = 'assets/images/ACHIcon.svg';

  constructor (
    private paymentMethodActions: PaymentMethodActions,
    private reduxStore: NgRedux<AppState>
  ) { }

  ngOnInit () {
    this.organizationState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state ) => {
        if ( state ) {
          const country = state.selectedOrganization.country;
          this.showInitialState( country );
        }
      } );

    this.copasState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state ) => {
        if ( state === undefined || state.use === undefined ) {
          this.useStripeCardGenerator = false;
        } else {
          this.useStripeCardGenerator = !state.use;
        }
      } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  showInitialState ( country: Country ) {
    if ( country === UNITED_STATES ) {
      this.paymentTypeOptionAvailable = true;
      this.ccGeneratorState$
        .pipe( first() )
        .subscribe( ( state ) => {
          if ( state ) {
            const now = new Date();
            const oldestValidTime = new Date( now.getTime() - 10000 );
            const errorTime = new Date( state.errorDate );
            if ( errorTime > oldestValidTime ) {
              this.switchToCardGenerator();
            } else {
              this.switchToGeneratorOption();
            }
          } else {
            this.switchToGeneratorOption();
          }
        } );
    } else {
      this.paymentTypeOptionAvailable = false;
      this.switchToCardGenerator();
    }
  }

  switchToCardGenerator () {
    this.showPaymentGeneratorOption = false;
    this.showCardGenerator = true;
    this.showAchGenerator = false;
  }

  switchToAchGenerator () {
    this.showPaymentGeneratorOption = false;
    this.showCardGenerator = false;
    this.showAchGenerator = true;
  }

  switchToGeneratorOption () {
    this.showPaymentGeneratorOption = true;
    this.showCardGenerator = false;
    this.showAchGenerator = false;
  }

  cancel () {
    this.organizationState$
      .pipe( first() )
      .subscribe( ( state ) => {
        const country = state.selectedOrganization.country;

        this.showInitialState( country );
        this.reduxStore.dispatch( this.paymentMethodActions.resetPaymentMethodPostStatus() );
      } );

    this.emitHideComponent();
  }

  emitHideComponent () {
    this.showPaymentMethodGenerator.emit( false );
    this.reduxStore.dispatch( this.paymentMethodActions.resetPaymentMethodPostStatus() );
  }
}
