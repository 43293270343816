// if genericError is true, append the error code to translated error string.
export interface BpeError {
  errorCode: string;
  errorTranslationKey: string;
  genericError: boolean;
}

// A list of errors that has specific customer facing error strings.
export const DEFAULT_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE = 'OFC-0012';
export const LAST_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE = 'OFC-0013';

export const ERROR_LIST = [
  DEFAULT_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE,
  LAST_PAYMENT_METHOD_CANNOT_BE_DELETED_ERROR_CODE
];

export const ERROR_MESSAGE_DELETE_PAYMENT_METHOD = 'ERROR_DELETING_PAYMENT_METHOD';

