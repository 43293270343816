<div class="payment-method-generator" role="region">
  <div class="payment-method-generator-options"
    *ngIf="paymentTypeOptionAvailable && showPaymentGeneratorOption">
    <div class="payment-method-option-button"
      (click)="switchToCardGenerator()"
      role="button"
      [attr.aria-label]="'CC_ADD_TITLE' | translate">
      <span [innerHTML]="'CREDIT_DEBIT_CARD'| translate" class="payment-method-option-button-name">
          <!-- Credit / Debit Card -->
      </span>
      <img [src]="cardImgPath" class="payment-method-option-button-img" alt="'CREDIT_DEBIT_CARD' | translate"/>
    </div>

    <div class="payment-method-option-button"
      (click)="switchToAchGenerator()"
      role="button"
      [attr.aria-label]="'ACH_ADD_TITLE' | translate">
      <span [innerHTML]="'BANK_ACCOUNT'| translate" class="payment-method-option-button-name">
        <!-- Bank Account -->
      </span>
      <img [src]="achImgPath" class="payment-method-option-button-img" alt="'BANK_ACCOUNT' | translate"/>
    </div>
  </div>


  <bp-cc-generator *ngIf="showCardGenerator && !useStripeCardGenerator" (cancelEvent)="cancel()"></bp-cc-generator>
  <bp-stripe-cc-generator *ngIf="showCardGenerator && useStripeCardGenerator" (cancelEvent)="cancel()"></bp-stripe-cc-generator>
  <bp-ach-generator *ngIf="showAchGenerator" (cancelEvent)="cancel()"></bp-ach-generator>
</div>
