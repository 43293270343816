/**
 * These are used by the http handler and the interceptor so that they both are synced on property names
 */

/**
 * This one is for what property signals whether to add the auth header or not
 */
export const HTTP_HANDLER_INCLUDE_AUTH_PROPERTY_NAME = '_includeAuth'
/**
 * This one is for what property signals to allow requests to go through as unauthenticated
 */
export const HTTP_HANDLER_ALLOW_UNAUTHENTICATED_PROPERTY_NAME = '_allowUnauthenticated'
