import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationActions } from '../organization/redux/organization.actions';
import { OrganizationListEpic } from './redux/organization-list.epic';
import { OrganizationListService } from './organization-list.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    OrganizationActions,
    OrganizationListEpic,
    OrganizationListService
  ],
  declarations: []
} )
export class OrganizationListModule {}
