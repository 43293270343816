import { PaymentMethodPostStatus, PaymentMethodState } from './payment-method.model';
import { Action } from '../../../store/action.model';
import { PaymentMethodActions } from './payment-method.actions';

export const INITIAL_STATE: PaymentMethodState = {
  list: undefined,
  postInProgress: undefined,
  postError: undefined,
  postSuccess: undefined,
  postStatus: PaymentMethodPostStatus.NOT_POSTED,
  getInProgress: undefined,
  delete: undefined,
  default: undefined
};

export function paymentMethodReducer (
  lastState: PaymentMethodState = INITIAL_STATE,
  action: Action<Partial<PaymentMethodState>>
): PaymentMethodState {
  switch ( action.type ) {
    case PaymentMethodActions.SET_PAYMENT_METHOD_LIST:
      return { ...lastState, list: action.payload.list, getInProgress: false };
    case PaymentMethodActions.POST_PAYMENT_METHOD_CARD:
    case PaymentMethodActions.POST_PAYMENT_METHOD_ACH:
    case PaymentMethodActions.POST_PAYMENT_METHOD_STRIPE:
    case PaymentMethodActions.POST_PAYMENT_METHOD_CARD_AFTER_SCA:
      return { ...lastState, postInProgress: true, postStatus: PaymentMethodPostStatus.IN_PROGRESS };
    case PaymentMethodActions.SET_PAYMENT_METHOD_POST_ERROR:
      return {
        ...lastState,
        postError: action.payload.postError,
        postSuccess: false,
        postStatus: PaymentMethodPostStatus.ERROR,
        postInProgress: false
      };
    case PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE:
    case PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE_GET_LIST_REQUIRED:
      return {
        ...lastState,
        postError: undefined,
        postSuccess: true,
        postStatus: PaymentMethodPostStatus.COMPLETED,
        postInProgress: false
      };
    case PaymentMethodActions.POST_COPAS_PAYMENT_METHOD_COMPLETE_WITH_SCA_REQUIRED:
      return {
        ...lastState,
        postError: undefined,
        postSuccess: true,
        postStatus: PaymentMethodPostStatus.VALIDATION_REQUIRED,
        postInProgress: false
      };
    case PaymentMethodActions.RESET_PAYMENT_METHOD:
      return INITIAL_STATE;
    case PaymentMethodActions.RESET_PAYMENT_METHOD_POST_STATUS:
      return {
        ...lastState,
        postError: undefined,
        postSuccess: undefined,
        postStatus: PaymentMethodPostStatus.NOT_POSTED,
        postInProgress: undefined
      };
    case PaymentMethodActions.GET_PAYMENT_METHOD_LIST:
      return { ...lastState, getInProgress: true };
    case PaymentMethodActions.POST_DELETE_PAYMENT_METHOD:
      return {
        ...lastState,
        delete: { postSuccess: undefined, postError: undefined, deleteInProgress: true }
      };
    case PaymentMethodActions.SET_DELETE_PAYMENT_METHOD_COMPLETED:
      return {
        ...lastState,
        delete: { postSuccess: true, postError: undefined, deleteInProgress: false }
      };
    case PaymentMethodActions.SET_DELETE_PAYMENT_METHOD_ERROR:
      return {
        ...lastState,
        delete: action.payload.delete
      };
    case PaymentMethodActions.POST_DEFAULT_PAYMENT_METHOD_CHANGE:
      return {
        ...lastState,
        default: { postSuccess: undefined, postError: undefined, postInProgress: true }
      };
    case PaymentMethodActions.SET_DEFAULT_PAYMENT_METHOD_COMPLETED:
      return {
        ...lastState,
        default: { postSuccess: true, postError: undefined, postInProgress: false }
      };
    case PaymentMethodActions.SET_DEFAULT_PAYMENT_METHOD_ERROR:
      return {
        ...lastState,
        default: action.payload.default
      };
    case PaymentMethodActions.RESET_DEFAULT_PAYMENT_METHOD:
      return {
        ...lastState,
        default: undefined
      };
    default:
      return lastState;
  }
}
