import {FactoryProvider} from '@angular/core';
import {AuthActions} from './redux/auth.actions';
import {environment} from '../../../environments/environment';
import {AuthenticationConfig, getInflightRequestPath, LMI_LOGIN_AUTHORIZE_PATH} from '@jive/core/authentication';
import {AUTHENTICATION_CONFIG_TOKEN} from '../authentication/authentication-config';

export const scopes = [ 'accounting.v1.create', 'accounting.v1.read', 'accounting.v1.pay',
  'accounting.v1.delete', 'pbx.v1.user', 'pbx.v1.profile', 'pbx.v1.admin', 'mobiledetails.v1.read' ];

export function authConfigProviderFactory ( authActions: AuthActions ) {
  const pathSubstrLen = environment.redirectUri.length - 1;
  return {
    clientId: environment.clientId,
    redirectUri: environment.redirectUri,
    scopes: scopes.join( ' ' ),
    store: authActions,
    authorizePath: LMI_LOGIN_AUTHORIZE_PATH,
    useSpecCompliantTimeParsing: true,
    inflightRequest: getInflightRequestPath( ( path: string ) => {
      return path.substr( pathSubstrLen );
    } ),
    logoutBaseUrl: environment.logoutBaseUrl,
    loginBaseUrl: environment.loginBaseUrl
  } as AuthenticationConfig;
}

export const AUTH_CONFIG_PROVIDER: FactoryProvider = {
  provide: AUTHENTICATION_CONFIG_TOKEN,
  useFactory: authConfigProviderFactory,
  deps: [ AuthActions ]
};
