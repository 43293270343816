import { FactoryProvider, Optional } from '@angular/core';
import { DEFAULT_IDENTITY_CONFIG, DEV_IDENTITY_CONFIG, IdentityServiceV1, STAGING_IDENTITY_CONFIG } from '@jive/core/identity/v1';
import { JiveHttpHandler } from '@jive/common/http';
import { CoreServicesConfig, selectServiceConfiguration, ServiceConfigCore } from '../core/service-helpers';

export function identityServiceFactory (
  http: JiveHttpHandler,
  coreServiceConfig?: CoreServicesConfig
) {

  const customConfig: Partial<ServiceConfigCore> = coreServiceConfig ?
    coreServiceConfig.identityService :
    undefined;

  return IdentityServiceV1.factory(
    http,
    selectServiceConfiguration(
      DEFAULT_IDENTITY_CONFIG,
      STAGING_IDENTITY_CONFIG,
      DEV_IDENTITY_CONFIG,
      coreServiceConfig ? coreServiceConfig.environment : undefined,
      customConfig
    ),
    customConfig ? customConfig.path : undefined
  );

}

export const IDENTITY_SERVICE_PROVIDER: FactoryProvider = {
  provide: IdentityServiceV1,
  useFactory: identityServiceFactory,
  deps: [ JiveHttpHandler, [ new Optional(), CoreServicesConfig ] ]
};
