import { Action } from '../../../store/action.model';
import { UserActions } from './user.actions';
import { UserState } from './user.model';

const INITIAL_STATE: UserState = {
  principalData: undefined,
  currentUser: undefined,
  userPbxs: undefined,
  selectedPbx: undefined,
  selectedPbxPermissions: undefined,
  getPrincipalDataInProgress: false,
  getJifUserInProgress: false
};

export function userReducer ( lastState: UserState = INITIAL_STATE, action: Action<Partial<UserState>> ): UserState {
  switch ( action.type ) {
    case UserActions.SET_USER_PBXS:
      return { ...lastState, userPbxs: action.payload.userPbxs, getJifUserInProgress: false };
    case UserActions.SET_USER_REMOVED:
      return INITIAL_STATE;
    case UserActions.SET_USER_AND_PRINCIPAL_DATA:
      const { principalData, currentUser } = action.payload;
      return { ...lastState, principalData, currentUser, getPrincipalDataInProgress: false };
    case UserActions.SET_USER_SELECTED_PBX_AND_PERMISSIONS:
      const { selectedPbxPermissions, selectedPbx } = action.payload;
      return { ...lastState, selectedPbxPermissions, selectedPbx };
    case UserActions.SET_START:
      return { ...lastState, getPrincipalDataInProgress: true, getJifUserInProgress: true };
    default:
      return lastState;
  }
}
