import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { AuthActions } from '../../services/auth/redux/auth.actions';
import { Observable, Subject } from 'rxjs';
import { SELECTED_LANGUAGE_LOCALE_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'jive-navbar-right-side',
  templateUrl: './navbar-right-side.component.html',
  styleUrls: [ './navbar-right-side.component.scss' ]
} )
export class JiveNavbarRightSideComponent implements OnInit, OnDestroy {

  @select( SELECTED_LANGUAGE_LOCALE_SELECTOR ) selectedLanguage$: Observable<string>;

  unsubscribe$: Subject<boolean> = new Subject();

  constructor (
    private ngRedux: NgRedux<AppState>,
    private authActions: AuthActions
  ) { }

  ngOnInit () { }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  logout () {
    this.ngRedux.dispatch( this.authActions.logoutAction() );
  }

}
