import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Country } from '../location-converter/assets/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable( { providedIn: 'root' } )
export class SmsDetailsService {

  constructor ( private http: HttpClient ) { }

  getSmsDetails ( orgId: string, year: number, month: number, country: Country, language: string ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/sms-detail-reports/${ year }-${ month }?countryCode=${ country.code }`;
    const headers = new HttpHeaders()
      .append( 'Content-Type', 'text/csv' )
      .append( 'Accept-Language', language );

    return this.http.get<Blob>( path, { headers: headers, observe: 'response', responseType: 'blob' as 'json' } )
      .pipe( map( it => it.body ) );
  }
}
