import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../language-selector/redux/language-selector.model';

@Injectable( { providedIn: 'root' } )
export class DateConverterService {
  constructor ( private translate: TranslateService ) { }

  getSimpleLocalizedDate ( dateString: string, locale: string ): string {
    return Language.instanceFromString( locale ).formatDate( dateString );
  }

  getTextualLocalizedDate ( dateString: string, locale: string ): string {
    return Language.instanceFromString( locale ).formatTextualDate( dateString, this.translate );
  }
}
