<div class="content">
  <div class="error-page">
    <h1 [innerHTML]="'400_TITLE'| translate" class="error-page-title">
      <!-- 400 error -->
    </h1>
    <p class="message" [innerHTML]="'400_MAIN_ERROR'| translate">
      <!-- It looks like you requested the content that does not exist. -->
    </p>
    <h3 [innerHTML]="'ERROR_SUGGESTION_TITLE'| translate" class="error-suggestion">
      <!-- We suggest: -->
    </h3>
    <ul class="suggestion">
      <li [innerHTML]="'ERROR_SUGGESTION_URL_CHECK'| translate">
        <!-- Double checking your URL and trying again -->
      </li>
      <li [innerHTML]="'ERROR_SUGGESTION_GO_BACK'| translate">
        <!-- Clicking the back button to go back -->
      </li>
    </ul>
  </div>
</div>
