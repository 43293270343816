import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {FlexFormService} from '../flex-form.service';
import {FlexFormActions} from './flex-form.actions';
import {FlexKey} from './flex-form.model';

@Injectable( {
  providedIn: 'root'
} )
export class FlexFormEpic {

  constructor (
    private flexFormService: FlexFormService,
    private flexFormActions: FlexFormActions ) { }

  getEpics () {
    return combineEpics( this.getAndSetFlexKey.bind( this ) );
  }

  getAndSetFlexKey ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(FlexFormActions.GET_FLEX_KEY),
        switchMap( ( { payload: { currency, country } } ) => {
          return this.flexFormService.getFlexKey( currency, country )
            .pipe(
              map( ( flexKey: FlexKey ) => {
                return this.flexFormActions.setFlexKey( flexKey );
              } ),
              catchError( () =>
                of( this.flexFormActions.setFlexKey( undefined ) )
              )
            );
        } )
      );
  }
}
