import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceActions } from './redux/invoice.actions';
import { InvoiceEpic } from './redux/invoice.epic';
import { InvoiceService } from './invoice.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    InvoiceActions,
    InvoiceEpic,
    InvoiceService
  ],
  declarations: []
} )
export class InvoiceModule {}
