<div class="billing-email" role="form">
  <span class="input-title billing-email-title"
    role="heading"
    aria-level="3"
    [innerHTML]="'BILLING_EMAIL_LABEL'| translate">
    <!-- Where should we email your monthly invoices? -->
  </span>

  <div class="billing-email-loading loading"
    *ngIf="!dataInitialized"
    [innerHTML]="'LOADING'| translate"
    aria-busy="true">
    <!-- loading -->
  </div>

  <div class="billing-email-data-error" *ngIf="dataError">
    <bp-data-error></bp-data-error>
  </div>

  <span *ngIf="dataInitialized && !dataError">
  <div class="billing-email-detail-header billing-email-detail-row">
      <div class="billing-email-detail-header-email">
        <!-- placeholder for email column in header -->
      </div>
      <div class="billing-mail-detail-control">
        <div class="billing-email-detail-is-main" translate="BILLING_EMAIL_COLUMN_MAIN" aria-hidden="true">
          <!-- Main -->
        </div>
        <div class="billing-email-detail-delete">
          <!-- placeholder for delete column in header -->
        </div>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div formArrayName="billingEmails">
        <div class="billing-email-detail-row"
          *ngFor="let billingEmail of billingEmails.controls; index as i"
          [formGroupName]="i">
          <jive-form-field class="billing-email-detail-email">
            <input
              #emailInput
              formControlName="email"
              placeholder="{{'EMAIL_PLACEHOLDER'|translate}}"
              [class.error]="billingEmail.controls.email.errors">
          </jive-form-field>

          <span class="billing-mail-detail-control">
            <input type="radio"
              class="billing-email-detail-is-main"
              name="isEmailMain"
              (change)="updateIsMain(i)"
              [checked]="isMain(i)"
              [attr.aria-label]="'BILLING_EMAIL_COLUMN_MAIN' | translate">
            <i class="material-icons billing-email-detail-delete"
              role="button"
              [attr.aria-label]="'BILLING_EMAIL_DELETE_ARIA' | translate: {email: billingEmail.value['email']}"
              *ngIf="!isMain(i)"
              (click)="deleteItem(i)">
              delete
            </i>
          </span>
        </div>
      </div>
    </form>


    <div class="billing-email-add-button"
      *ngIf="canAddMoreItems()"
      [attr.aria-label]="'ADD_BILLING_EMAIL' | translate"
      (click)="addItem()"
      role="button">
      {{ 'ADD_BILLING_EMAIL' | translate | uppercase }}
    </div>

    <div class="billing-email-control-buttons">
      <button
        type="button"
        class="jive-button jive-medium billing-email-action-cancel"
        [attr.aria-label]="'BILLING_EMAIL_CANCEL_ARIA' | translate"
        [innerHTML]="'CANCEL'| translate"
        (click)="cancel()">
        <!-- cancel -->
      </button>

      <button
        *ngIf="!(postInProgress$| async)"
        type="submit"
        class="jive-button jive-medium jive-primary"
        [attr.disabled]="canSubmit()? null: ''"
        [attr.aria-label]="'BILLING_EMAIL_SAVE_ARIA' | translate"
        [innerHTML]="'SAVE'| translate"
        (click)="submit()">
        <!-- save -->
      </button>

      <button
        *ngIf="(postInProgress$| async)"
        type="submit"
        class="jive-button jive-medium jive-primary"
        [attr.disabled]="''"
        [attr.aria-label]="'SAVING' | translate"
        [innerHTML]="'SAVING'| translate"
        aria-busy="true">
        <!-- saving -->
      </button>
    </div>

    <div class="billing-email-post-error">

    </div>
  </span>
</div>

