import { Injectable } from '@angular/core';
import { BRAZIL, CANADA, Country, UNITED_STATES } from '../location-converter/assets/model';
import { TranslateService } from '@ngx-translate/core';
import { Invoice } from '../invoice/redux/invoice.model';
import { FRENCH_CANADA, Language, PORTUGUESE_BRAZIL } from '../language-selector/redux/language-selector.model';
import { BR_CURRENCY } from '../money-converter/money-converter.model';

@Injectable( { providedIn: 'root' } )
export class CommonPageSettingService {

  constructor ( private translateService: TranslateService ) { }

  canPayOnPortal ( currency: string, hasPayAccess: boolean ) {
    return BR_CURRENCY !== currency && hasPayAccess;
  }

  getEmailForBillingTeam ( country: Country ): string {
    return !country ? UNITED_STATES.billingEmail : country.billingEmail;
  }

  getBillingPhoneNumber ( country: Country ): string {
    if ( !country ) { return UNITED_STATES.billingPhoneNumber; }
    if ( country === BRAZIL ) {
      let option: string;
      this.translateService.get( 'OPTION' ).subscribe( it => option = it );

      return `${ country.billingPhoneNumber } ${ option } 3`;
    }

    return country.billingPhoneNumber;
  }

  getBillingPhoneNumberLocal ( country: Country ): string {
    if ( !country ) { return UNITED_STATES.billingPhoneNumberLocal; }
    if ( !country.billingPhoneNumberLocal ) { return country.billingPhoneNumberLocal; }
    if ( country === BRAZIL ) {
      let option: string;
      this.translateService.get( 'OPTION' ).subscribe( it => option = it );

      return `${ country.billingPhoneNumberLocal } ${ option } 3`;
    }

    return country.billingPhoneNumberLocal;
  }

  checkIfPostHappened ( inProgress: boolean ) {
    return inProgress !== undefined;
  }

  checkIfPostIsInProgress ( inProgress: boolean ) {
    return inProgress === true;
  }

  initialGetInProgress ( getInProgress: boolean, postInProgress: boolean ) {
    return getInProgress !== false && postInProgress === undefined;
  }

  getBillingHelpUrl ( locale: string, country: Country ) {
    const language = Language.instanceFromString( locale );

    if ( country === CANADA ) {
      if ( language.locale === FRENCH_CANADA.locale ) {
        return 'https://support.goto.com/fr/connect/help/goto-connect-billing-user-guide-canada';
      }

      return 'https://support.goto.com/connect/help/goto-connect-billing-user-guide-canada';
    } else if ( country === UNITED_STATES ) {
      return language.supportGuideUrl;
    } else if ( country === BRAZIL ) {
      if ( language.locale === PORTUGUESE_BRAZIL.locale ) {
        return 'https://support.goto.com/pt/connect/help/goto-connect-billing-user-guide-brazil';
      }

      return 'https://support.goto.com/connect/help/goto-connect-billing-user-guide-brazil';
    }
  }

  isInvoice ( invoice: Invoice ): boolean {
    return ( invoice.total >= 0 );
  }
}
