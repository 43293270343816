import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { BillingEmail } from '../../services/billing-email/redux/billing-email.model';
import { BillingEmailActions } from '../../services/billing-email/redux/billing-email.actions';
import { first, takeUntil } from 'rxjs/operators';
import { AppState } from '../../store/app-state.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReceiptEmailService } from '../../services/receipt-email/receipt-email.service';
import { BILLING_EMAIL_GET_IN_PROGRESS_SELECTOR, BILLING_EMAIL_POST_IN_PROGRESS_SELECTOR, ORGANIZATION_ID_SELECTOR, STORED_BILLING_EMAIL_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-billing-email',
  templateUrl: './billing-email.component.html',
  styleUrls: [ './billing-email.component.scss' ]
} )
export class BillingEmailComponent implements OnInit, OnDestroy {
  @select( STORED_BILLING_EMAIL_SELECTOR ) storedBillingEmails$: Observable<BillingEmail[]>;
  @select( BILLING_EMAIL_POST_IN_PROGRESS_SELECTOR ) postInProgress$: Observable<boolean>;
  @select( BILLING_EMAIL_GET_IN_PROGRESS_SELECTOR ) getInProgress$: Observable<boolean>;

  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;

  @ViewChildren( 'emailInput' ) emailInputs$: QueryList<any>;

  unsubscribe$: Subject<boolean> = new Subject();
  initializationUnsubscribe$: Subject<boolean> = new Subject();
  form: UntypedFormGroup;
  dataInitialized = false;
  dataError = false;
  billingEmailAdded = false;
  mainIndex: number;

  get billingEmails (): UntypedFormArray { return this.form.get( 'billingEmails' ) as UntypedFormArray; }

  constructor (
    private billingEmailActions: BillingEmailActions,
    private reduxStore: NgRedux<AppState>,
    private receiptEmailService: ReceiptEmailService,
    private formBuilder: UntypedFormBuilder ) {
  }

  ngOnInit () {
    this.reduxStore.dispatch( this.billingEmailActions.reset() );
    this.organizationId$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( orgId ) => {
      this.reduxStore.dispatch( this.billingEmailActions.getBillingEmail( orgId ) );
    } );

    this.form = this.formBuilder.group( {
      billingEmails: this.formBuilder.array( [] )
    } );

    this.storedBillingEmails$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( billingEmails ) => {
      if ( billingEmails ) {
        this.initForm( billingEmails );
      }
    } );

    this.getInProgress$.pipe(
      takeUntil( this.initializationUnsubscribe$ )
    ).subscribe( ( getInProgress ) => {
      if ( this.dataInitialized === false && getInProgress === false ) {
        this.dataInitialized = true;
        this.initializationUnsubscribe$.next( true );
      }
    } );

    combineLatest( [
      this.getInProgress$,
      this.storedBillingEmails$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ getInProgress, storedBillingEmails ] ) => {
      if ( getInProgress === false ) {
        this.dataError = storedBillingEmails === undefined;
      }
    } );
  }

  // noinspection JSUnusedGlobalSymbols
  ngAfterViewInit () {
    this.emailInputs$.changes.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( () => {
      if ( this.emailInputs$.length >= 1 && this.billingEmailAdded ) {
        this.emailInputs$.last.nativeElement.focus();
      }
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  initForm ( billingEmails: BillingEmail[] ) {
    this.form = this.formBuilder.group( {
      billingEmails: this.formBuilder.array( [] )
    } );

    billingEmails.forEach( ( billingEmail, loopIndex ) => {
      if ( billingEmail.isMain ) {
        this.mainIndex = loopIndex;
      }

      this.addItem( billingEmail );
    } );
  }

  addItem ( billingEmail?: BillingEmail ) {
    const emailItem = this.formBuilder.group( {
      email: new UntypedFormControl(
        billingEmail ? billingEmail.email : '',
        this.formEmailValidation.bind( this ) ),
      isMain: new UntypedFormControl( billingEmail ? billingEmail.isMain : false )
    } );

    this.billingEmails.push( emailItem );
    this.billingEmailAdded = !billingEmail;
  }

  deleteItem ( i: number ) {
    if ( i < this.mainIndex ) {
      this.mainIndex -= 1;
    }

    this.billingEmails.removeAt( i );
    // forcing the form to be dirty
    this.billingEmails.markAsDirty();
  }

  updateIsMain ( index: number ) {
    this.mainIndex = index;

    // forcing the form to be dirty
    this.billingEmails.markAsDirty();
  }

  isMain ( index: number ) {
    return this.mainIndex === index;
  }

  submit () {
    const newList: BillingEmail[] = [];
    // transform formArray to BillingEmail
    this.billingEmails.value.forEach( ( billingEmail, loopIndex ) => {
      if ( billingEmail.email ) {
        newList.push( {
          email: billingEmail.email,
          isMain: this.isMain( loopIndex )
        } );
      }
    } );

    this.organizationId$.pipe(
      first()
    ).subscribe( ( orgId ) => {
      this.reduxStore.dispatch(
        this.billingEmailActions.saveBillingEmail( orgId, newList )
      );
    } );
  }

  cancel () {
    // Re-init the FormArray
    this.storedBillingEmails$.pipe(
      first()
    ).subscribe( ( billingEmails ) => {
      this.initForm( billingEmails );
    } );
  }

  canAddMoreItems () {
    return this.billingEmails.length < 5;
  }

  canSubmit () {
    return this.checkForValidationError() && this.wasItTouched()
  }

  wasItTouched () {
    return this.billingEmails.dirty;
  }

  checkForValidationError () {
    let valid = true;

    this.billingEmails.controls.forEach( ( formGroup: UntypedFormGroup ) => {
      if ( formGroup.controls.email.errors ) {
        valid = false;
      }
    } );
    return valid;
  }

  formEmailValidation ( control: UntypedFormControl ) {
    const email = control.value;

    return this.emailValidation( email ) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  emailValidation ( value: string ) {
    return this.receiptEmailService.isValidEmail( value );
  }
}
