import { Injectable } from '@angular/core';
import { AchPostData, CreatePaymentMethodScaChallengeRequest, PaymentMethod } from './payment-method.model';
import { Address, CreditCard } from '../../flex-form/redux/flex-form.model';
import { ScaRequirement, ScaToken } from '../../cardinal/redux/cardinal.model';

@Injectable( {
  providedIn: 'root'
} )
export class PaymentMethodActions {

  static readonly GET_PAYMENT_METHOD_LIST = 'PAYMENT_METHOD:GET_LIST';
  static readonly SET_PAYMENT_METHOD_LIST = 'PAYMENT_METHOD:SET_LIST';
  static readonly POST_PAYMENT_METHOD_ACH = 'PAYMENT_METHOD:POST_ACH';
  static readonly POST_PAYMENT_METHOD_CARD = 'PAYMENT_METHOD:POST_CARD';
  static readonly POST_PAYMENT_METHOD_CARD_AFTER_SCA = 'PAYMENT_METHOD:POST_CARD_AFTER_SCA';
  static readonly POST_PAYMENT_METHOD_COMPLETE = 'PAYMENT_METHOD:POST_COMPLETE';
  static readonly POST_PAYMENT_METHOD_COMPLETE_GET_LIST_REQUIRED = 'PAYMENT_METHOD:POST_COMPLETE_GET_LIST_REQUIRED';
  static readonly SET_PAYMENT_METHOD_POST_ERROR = 'PAYMENT_METHOD:SET_POST_ERROR';

  // Copas specific actions
  static readonly POST_COPAS_PAYMENT_METHOD_COMPLETE_WITH_SCA_REQUIRED = 'PAYMENT_METHOD:POST_COPAS_COMPLETE_WITH_SCA_REQUIRED';

  // deleting actions
  static readonly POST_DELETE_PAYMENT_METHOD = 'PAYMENT_METHOD:POST_DELETE';
  static readonly SET_DELETE_PAYMENT_METHOD_COMPLETED = 'PAYMENT_METHOD:SET_DELETE_COMPLETED';
  static readonly SET_DELETE_PAYMENT_METHOD_ERROR = 'PAYMENT_METHOD:SET_DELETE_ERROR';

  // default actions
  static readonly POST_DEFAULT_PAYMENT_METHOD_CHANGE = 'PAYMENT_METHOD:POST_DEFAULT_CHANGE';
  static readonly SET_DEFAULT_PAYMENT_METHOD_COMPLETED = 'PAYMENT_METHOD:SET_DEFAULT_CHANGE_COMPLETED';
  static readonly SET_DEFAULT_PAYMENT_METHOD_ERROR = 'PAYMENT_METHOD:SET_DEFAULT_CHANGE_ERROR';
  static readonly RESET_DEFAULT_PAYMENT_METHOD = 'PAYMENT_METHOD:RESET_DEFAULT';

  // reset all except list to be undefined
  static readonly RESET_PAYMENT_METHOD = 'PAYMENT_METHOD:RESET';
  static readonly RESET_PAYMENT_METHOD_POST_STATUS = 'PAYMENT_METHOD:RESET_POST_STATUS';

  // stripe action
  static readonly POST_PAYMENT_METHOD_STRIPE = 'PAYMENT_METHOD:POST_STRIPE';

  constructor () { }

  resetPaymentMethod () {
    return { type: PaymentMethodActions.RESET_PAYMENT_METHOD };
  }

  resetPaymentMethodPostStatus () {
    return { type: PaymentMethodActions.RESET_PAYMENT_METHOD_POST_STATUS };
  }

  getPaymentMethodList ( orgId: string ) {
    return { type: PaymentMethodActions.GET_PAYMENT_METHOD_LIST, payload: { orgId } };
  }

  setPaymentMethodList ( list: PaymentMethod[] ) {
    return { type: PaymentMethodActions.SET_PAYMENT_METHOD_LIST, payload: { list } };
  }

  postCardPaymentMethod ( scaToken: ScaToken, orgId: string, address: Address, card: CreditCard ) {
    return { type: PaymentMethodActions.POST_PAYMENT_METHOD_CARD, payload: { scaToken, orgId, address, card } };
  }

  postCardPaymentMethodAfterSca (
    orgId: string,
    paymentMethodKey: string,
    createPaymentMethodScaChallengeRequest: CreatePaymentMethodScaChallengeRequest ) {
    return {
      type: PaymentMethodActions.POST_PAYMENT_METHOD_CARD_AFTER_SCA,
      payload: { orgId, paymentMethodKey, createPaymentMethodScaChallengeRequest }
    };
  }

  postAchPaymentMethod ( info: AchPostData ) {
    return {
      type: PaymentMethodActions.POST_PAYMENT_METHOD_ACH,
      payload: { info }
    };
  }

  postCopasPaymentMethodCompleteWithScaRequirement ( scaRequirement: ScaRequirement ) {
    return {
      type: PaymentMethodActions.POST_COPAS_PAYMENT_METHOD_COMPLETE_WITH_SCA_REQUIRED,
      payload: { scaRequirement }
    };
  }

  postPaymentMethodComplete ( orgId: string ) {
    return {
      type: PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE,
      payload: { orgId }
    };
  }

  postPaymentMethodCompleteGetListRequired ( orgId: string ) {
    return {
      type: PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE_GET_LIST_REQUIRED,
      payload: { orgId }
    };
  }

  setPostPaymentMethodError ( postError: any ) {
    return { type: PaymentMethodActions.SET_PAYMENT_METHOD_POST_ERROR, payload: { postError } };
  }

  // delete
  postDeletePaymentMethod ( orgId: string, paymentMethodId: string ) {
    return {
      type: PaymentMethodActions.POST_DELETE_PAYMENT_METHOD,
      payload: { orgId, paymentMethodId }
    };
  }

  setDeletePaymentMethodComplete ( orgId: string ) {
    return { type: PaymentMethodActions.SET_DELETE_PAYMENT_METHOD_COMPLETED, payload: { orgId } };
  }

  setDeletePaymentMethodError ( postError: any ) {
    return {
      type: PaymentMethodActions.SET_DELETE_PAYMENT_METHOD_ERROR,
      payload: { delete: { postSuccess: false, postError: postError, deleteInProgress: false } }
    };
  }

  // default
  postDefaultPaymentMethodChange ( orgId: string, paymentMethodId: string ) {
    return {
      type: PaymentMethodActions.POST_DEFAULT_PAYMENT_METHOD_CHANGE,
      payload: { orgId, paymentMethodId }
    };
  }

  setDefaultPaymentMethodChangeCompleted ( orgId: string ) {
    return {
      type: PaymentMethodActions.SET_DEFAULT_PAYMENT_METHOD_COMPLETED,
      payload: { orgId }
    };
  }

  setDefaultPaymentMethodChangeError ( error: any ) {
    return {
      type: PaymentMethodActions.SET_DEFAULT_PAYMENT_METHOD_ERROR,
      payload: { default: { postSuccess: false, postError: error, postInProgress: false } }
    };
  }

  resetDefaultPayment () {
    return { type: PaymentMethodActions.RESET_DEFAULT_PAYMENT_METHOD };
  }

  postStripePaymentMethod ( orgId: string, token: string ) {
    return { type: PaymentMethodActions.POST_PAYMENT_METHOD_STRIPE, payload: { orgId, token } };
  }
}
