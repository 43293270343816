import { HTTP_HANDLER_INCLUDE_AUTH_PROPERTY_NAME } from '../auth/constants';
import { Authentication } from '@jive/core/authentication';
import { HttpInterceptorAuthenticationHook } from '../auth/models';

export enum AuthenticationMissingMode {
  AUTO_LOGIN = 'AUTO_LOGIN',
  WAIT_FOR_LOGIN = 'WAIT_FOR_LOGIN',
  ABORT_REQUEST = 'ABORT_REQUEST'
}

export class JiveHttpInterceptorOptions {
  authenticationMissingMode: AuthenticationMissingMode;
}

/**
 * Jive Auth Hook allows us to simplify the way we process the requests coming in. Each auth instance
 * is expected to have a matching auth hook setup that meets both the HttpInterceptorAuthenticationHook
 * and HttpHandlerAuthenticationHook.
 *
 * Since Jive auth is always around if the consumer is using the interceptor and handler, we create a default
 * auth hook for jive. This encapsulates the logic around Jive auth and makes all of this backwards compatible.
 *
 */
export class JiveAuthHook implements HttpInterceptorAuthenticationHook {

  constructor ( private authenticationService: Authentication ) { }

  includeAuthHeaderPropName = HTTP_HANDLER_INCLUDE_AUTH_PROPERTY_NAME;

  getAuthenticationService () {
    return this.authenticationService;
  }

}
