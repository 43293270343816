<div class="contact-info">
  <div *ngIf="billingHelpUrl"
    class="contact-info-user-guide"
    [innerHTML]="'BILLING_HELP' | translate:{url: billingHelpUrl, ariaLabel: BILLING_HELP_ARIA| translate}">
    <!-- Need help? Visit our <a class='url-link' href='{{url}}'>billing user guide</a> -->
  </div>

  <div *ngIf="billingPhoneNumber && billingEmail && !billingHelpUrl && !billingPhoneNumberLocal"
    class="contact-info-phone-and-email"
    [innerHTML]="'BILLING_CONTACT' | translate: {billingPhoneNumber:billingPhoneNumber, billingEmail: billingEmail}">
    <!-- For payment and billing questions call {{billingPhoneNumber}}
    or email us at {{billingEmail}}. -->
  </div>

  <div *ngIf="billingPhoneNumber && billingEmail && !billingHelpUrl && billingPhoneNumberLocal"
    class="contact-info-phone-local-and-email"
    [innerHTML]="'BILLING_CONTACT_LOCAL' | translate: {billingPhoneNumber:billingPhoneNumber, billingPhoneNumberLocal:billingPhoneNumberLocal, billingEmail: billingEmail}">

    <!-- For payment and billing questions call {{billingPhoneNumber} (toll-free)
       or {{billingPhoneNumberLocal}} (local / long distance) or email us at {{billingEmail}}. -->
  </div>

  <div *ngIf="!billingPhoneNumber && billingEmail && !billingHelpUrl"
    class="contact-info-email"
    [innerHTML]="'BILLING_CONTACT_EMAIL' | translate: {billingEmail: billingEmail}">
    <!-- For payment and billing questions email us at {{billingEmail}} -->
  </div>
</div>

