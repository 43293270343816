import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { LanguageSelectorService } from '../../services/language-selector/language-selector.service';
import { Language, SUPPORTED_LANGUAGES } from '../../services/language-selector/redux/language-selector.model';
import { SELECTED_LANGUAGE_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: [ './language-selector.component.scss' ]
} )
export class LanguageSelectorComponent implements OnInit {

  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;

  supportedLanguages: Language[];

  constructor (
    private languageSelectorService: LanguageSelectorService
  ) { }

  ngOnInit () {
    this.supportedLanguages = SUPPORTED_LANGUAGES;
  }

  setSelectedLanguage ( selectedLanguage ) {
    this.languageSelectorService.updateSelectedLanguage( selectedLanguage );
  }

}
