import {fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {debounceTime, filter, map, share, tap} from 'rxjs/operators';
import {Emitter, Renderer} from './models';
import {isElementVisible} from '../dom/helpers';
import {KeyCode} from '../dom/constants';

export class ListControls {

  onItemSelected?: Emitter<number>;
  queryCallback?: (query: string) => number;
  subscription?: Subscription;
  focusedClassName = 'focused';
  itemIndexAttrName = 'data-index';
  itemsCount?: number;
  keyDownListener = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      share(),
      filter(isElementVisible.bind(null, this.el))
  );

  constructor(
      protected el: any,
      protected renderer: Renderer
  ) {
  }

  init() {
    this.subscription = merge(
        this.upAndDownKeyListener(this.keyDownListener, this.renderer),
        this.onItemSelected ? this.enterKeyListener(this.keyDownListener, this.onItemSelected) : of(),
        this.alphanumericKeyListener(this.keyDownListener),
        this.escapeKeyListener(this.keyDownListener)
    )
        .subscribe();
  }

  // this needs to be called after all elements have been rendered. At the moment this can be called
  // after init for static data but for async data the thought is that you setInterval and run this every
  // n milliseconds until hooked up, this will guarantee it is always connected
  //
  // If called and returns false that is signal that the data is async and that we should start checking
  // for the first item to show up before connecting
  protected hookupControlsOnDom() {

    this.itemsCount = this.el.children ? this.el.children.length - 1 : 0;
    const firstItemInList = this.getItemByDataIndex(0);

    if (firstItemInList) {
      this.renderer.addClass(
          firstItemInList,
          this.focusedClassName
      );

      return true;
    } else {
      return false;
    }

  }

  /**
   *
   * If the escape key is pressed we want to try and close the list, we grab the active element
   * which should be the button and check if it has a blur function, if it does then we blur it out
   */
  escapeKeyListener(
      keydownEvents: Observable<KeyboardEvent>
  ) {
    return keydownEvents
        .pipe(
            filter((evt: KeyboardEvent) => {
              return evt.keyCode === KeyCode.ESCAPE;
            }),
            tap(() => {

              if ((document.activeElement as any).blur) {
                (document.activeElement as any).blur();
              }

            })
        );
  }

  upAndDownKeyListener(
      keydownEvents: Observable<KeyboardEvent>,
      renderer: Renderer
  ) {
    return keydownEvents
        .pipe(
            filter((evt: KeyboardEvent) =>
                evt.keyCode === KeyCode.UP_ARROW ||
                evt.keyCode === KeyCode.DOWN_ARROW),
            tap((evt: KeyboardEvent) => {
              evt.preventDefault();
              evt.stopPropagation();
            }),
            map((evt: KeyboardEvent) => {

              const focusedIndex: number = this.focusedElement ?
                  Number(this.focusedElement
                      .getAttribute(this.itemIndexAttrName)) : 0;

              return evt.keyCode === KeyCode.DOWN_ARROW ?
                  this.nextIndex(focusedIndex) :
                  this.previousIndex(focusedIndex);

            }),
            map((idx: number) => {

              if (!this.focusedElement) {
                throw new Error('No focused element in list controls');
              }

              const nextFocusedEl = this.getItemByDataIndex(idx);
              renderer.removeClass(this.focusedElement, this.focusedClassName);
              renderer.addClass(nextFocusedEl, this.focusedClassName);
              nextFocusedEl.scrollIntoView(false);
              return idx;
            })
        );
  }

  enterKeyListener(
      keydownEvents: Observable<KeyboardEvent>,
      emitter: Emitter<number>
  ) {
    return keydownEvents
        .pipe(
            filter((evt: KeyboardEvent) =>
                // angular material accepts space or enter key for selection
                // so added it here
                evt.keyCode === KeyCode.ENTER || evt.keyCode === KeyCode.SPACE),
            tap(() => {

              if (!this.focusedElement) {
                throw new Error('No focused element in list controls');
              }

              emitter.emit(
                  Number(this.focusedElement
                      .getAttribute(this.itemIndexAttrName))
              );
            })
        );
  }

  alphanumericKeyListener(
      keydownEvents: Observable<KeyboardEvent>
  ) {
    // this "buffer" may seem weird but its the cheapest type I could think of using
    let queryBuffer = '';

    return keydownEvents
        .pipe(
            filter((evt: KeyboardEvent) => {
              return (evt.keyCode >= KeyCode.A && evt.keyCode <= KeyCode.Z) ||
                  (evt.keyCode >= KeyCode.ZERO && evt.keyCode <= KeyCode.NINE);
            }),
            map((evt: KeyboardEvent) => evt.key),
            tap((char: string) => queryBuffer += char),
            debounceTime(300),
            map(() => {
              const query = queryBuffer;
              queryBuffer = '';
              if (this.queryCallback) {
                return this.queryCallback(query);
              } else {
                return undefined;
              }
            }),
            tap((queryIndex?: number) => {
              if (queryIndex) {
                const el = this.getItemByDataIndex(queryIndex);

                if (!this.focusedElement) {
                  throw new Error('No focused element in list controls');
                }

                if (el) {
                  el.scrollIntoView(false);
                  this.renderer.removeClass(this.focusedElement, this.focusedClassName);
                  this.renderer.addClass(el, this.focusedClassName);
                }
              }
            })
        );
  }

  nextIndex(focusedIndex: number) {
    const focusedPlusOne = focusedIndex + 1;
    if (this.itemsCount && focusedPlusOne <= this.itemsCount) {
      return focusedPlusOne;
    } else {
      return 0;
    }
  }

  previousIndex(focusedIndex: number) {
    const focusedMinusOne = focusedIndex - 1;
    if (focusedMinusOne >= 0) {
      return focusedMinusOne;
    } else {
      return 0;
    }
  }

  private getItemByDataIndex(idx: number) {
    return this.el
        .querySelectorAll(`[${this.itemIndexAttrName}="${idx}"]`)[0];
  }

  get focusedElement() {
    const queryEl = this.el.querySelectorAll(`.${this.focusedClassName}`);
    return queryEl ? queryEl[0] : null;
  }

}
