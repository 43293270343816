import { FactoryProvider, Optional } from '@angular/core';
import { DEFAULT_JIF_CONFIG, DEV_JIF_CONFIG, JifService, STAGING_JIF_CONFIG } from '@jive/core/jif';
import { JiveHttpHandler } from '@jive/common/http';
import { CoreServicesConfig, selectServiceConfiguration, ServiceConfigCore } from '../core/service-helpers';

export function jifServiceFactory (
  http: JiveHttpHandler,
  coreServiceConfig?: CoreServicesConfig
) {

  const customConfig: Partial<ServiceConfigCore> = coreServiceConfig ?
    coreServiceConfig.jifService :
    undefined;

  return JifService.factory(
    http,
    selectServiceConfiguration(
      DEFAULT_JIF_CONFIG,
      STAGING_JIF_CONFIG,
      DEV_JIF_CONFIG,
      coreServiceConfig ? coreServiceConfig.environment : undefined,
      customConfig
    ),
    customConfig ? customConfig.path : undefined
  );

}

export const JIF_SERVICE_PROVIDER: FactoryProvider = {
  provide: JifService,
  useFactory: jifServiceFactory,
  deps: [ JiveHttpHandler, [ new Optional(), CoreServicesConfig ] ]
};
