import { CopasState } from './copas.model';
import { Action } from '../../../store/action.model';
import { CopasActions } from './copas.actions';

export const INITIAL_STATE: CopasState = {
  country: undefined,
  use: undefined
};

export function copasReducer (
  lastState: CopasState = INITIAL_STATE,
  action: Action<Partial<CopasState>>
): CopasState {
  switch ( action.type ) {
    case CopasActions.SET_COPAS:
      return {
        ...lastState,
        country: action.payload.country,
        use: action.payload.use
      };
    default:
      return lastState;
  }
}
