import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {OrganizationListActions} from './organization-list.actions';
import {OrganizationListService} from '../organization-list.service';

@Injectable()
export class OrganizationListEpic {

  constructor ( private organizationListService: OrganizationListService,
    private organizationListActions: OrganizationListActions ) { }

  getEpics = () => combineEpics( this.getOrganizationList.bind( this ) );

  getOrganizationList ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(OrganizationListActions.LIST_ORGS_START),
          switchMap(({payload: {filter}}) =>
              this.organizationListService.getOrganizationList(filter)
                  .pipe(
                      map(this.organizationListActions.listComplete),
                      catchError((error: any) =>
                          of(this.organizationListActions.callError(error))
                      )
                  )
          )
      );
  }
}
