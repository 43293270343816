import { Action } from '../../../store/action.model';
import { CcGeneratorState } from './cc-generator.model';
import { CcGeneratorActions } from './cc-generator.actions';

export const INITIAL_STATE: CcGeneratorState = {
  errorDate: undefined,
  errorMessageKey: undefined,
  errorCode: undefined
};

export function ccGeneratorReducer (
  lastState: CcGeneratorState = INITIAL_STATE,
  action: Action<Partial<CcGeneratorState>>
): CcGeneratorState {
  switch ( action.type ) {
    case CcGeneratorActions.SET_CC_GENERATOR_ERROR:
      return {
        ...lastState,
        errorDate: new Date(),
        errorMessageKey: action.payload.errorMessageKey,
        errorCode: action.payload.errorCode
      };
    case CcGeneratorActions.RESET:
      return INITIAL_STATE;
    default:
      return lastState;
  }
}
