import { Injectable } from '@angular/core';
import { BRAZIL, Country, SUPPORTED_COUNTRIES, UNITED_KINGDOM, UNITED_STATES } from './assets/model';
import { BR_CURRENCY, US_CURRENCY } from '../money-converter/money-converter.model';

@Injectable( { providedIn: 'root' } )
export class LocationConverterService {
  getAvailableCountries ( currency: string ): Country[] {
    return SUPPORTED_COUNTRIES.filter( it => it.currencies.includes( currency ) );
  }

  getCountry ( countryCode: string, currency: string ) {
    if ( currency === BR_CURRENCY ) { return BRAZIL; }

    const country = findMatchingCountry( countryCode );

    return isLegacyCustomer( country, currency ) ? UNITED_STATES : country;
  }
}

function findMatchingCountry ( countryCode: string ): Country {
  for ( const it of SUPPORTED_COUNTRIES ) {
    if ( it.code === countryCode ) { return it; }
  }

  return undefined;
}

function isLegacyCustomer ( country: Country, currency: string ) {
  return ( !country || country === UNITED_KINGDOM ) && currency === US_CURRENCY;
}

