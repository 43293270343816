import { Component, OnInit } from '@angular/core';

@Component( {
  selector: 'bp-content',
  templateUrl: './content.component.html',
  styleUrls: [ './content.component.scss' ]
} )

export class ContentComponent implements OnInit {

  constructor () { }

  ngOnInit () { }
}
