export enum KeyCode {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  HOME = 36,
  END = 35,
  ENTER = 13,
  SPACE = 32,
  TAB = 9,
  ESCAPE = 27,
  BACKSPACE = 8,
  DELETE = 46,
  A = 65,
  Z = 90,
  ZERO = 48,
  NINE = 57
}
