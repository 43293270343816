import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Invoice } from '../../services/invoice/redux/invoice.model';
import { NgRedux, select } from '@angular-redux/store';
import { combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PaymentActions } from '../../services/payment/redux/payment.actions';
import { AppState } from '../../store/app-state.model';
import { PaymentService } from '../../services/payment/payment.service';
import { MoneyConverterService } from '../../services/money-converter/money-converter.service';
import { CURRENCY_SELECTOR, INVOICE_SELECTOR, ORGANIZATION_ID_SELECTOR, SELECTED_LANGUAGE_LOCALE_SELECTOR } from '../../store/helper';
import calculateSize from 'calculate-size';
import { PayType } from '../../services/payment/redux/payment.model';
import { Language } from "../../services/language-selector/redux/language-selector.model";

@Component( {
  selector: 'bp-pay-amount-selector',
  templateUrl: './pay-amount-selector.component.html',
  styleUrls: [ './pay-amount-selector.component.scss' ]
} )
export class PayAmountSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() paymentInProgress: boolean;
  @Output() disablePayNow: EventEmitter<boolean> = new EventEmitter<boolean>();

  @select( INVOICE_SELECTOR ) invoice$: Observable<Invoice>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( SELECTED_LANGUAGE_LOCALE_SELECTOR ) selectedLocale$: Observable<string>;

  @ViewChild( 'partialAmount', { static: true } ) partialAmount: ElementRef;

  unsubscribe$: Subject<boolean> = new Subject();

  amountOptionTotal = 'total';
  amountOptionPartial = 'partial';

  neverHadPartialAmountUserInput = true;

  selectedAmountOption: string;
  selectedPartialAmount: number;

  partialAmountValidationError: string;

  currency: string;
  totalDue: number;
  invoiceId: string;
  orgId: string;
  currencySymbol: string;
  prependCurrencySymbol: boolean;

  cursorRemainder: string;

  numbers = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '0' ];

  inputMinWidth = 65;
  inputWidth = this.inputMinWidth;

  constructor (
    private moneyConverterService: MoneyConverterService,
    private paymentActions: PaymentActions,
    private reduxStore: NgRedux<AppState>,
    private paymentService: PaymentService,
    private renderer: Renderer2
  ) { }

  ngOnInit () {
    this.selectedAmountOption = this.amountOptionTotal;

    this.selectedLocale$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( selected ) => {
      this.prependCurrencySymbol = this.getShouldPrependSymbol( selected );
    } );

    this.currency$.pipe(
      first()
    ).subscribe( ( currency ) => {
      this.currency = currency;
      this.currencySymbol = this.getCurrencySymbol( currency );
    } );

    this.organizationId$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( id ) => {
      this.orgId = id;
    } );

    this.invoice$
      .pipe(
        first()
      ).subscribe( ( invoice ) => {
      if ( invoice ) {
        this.totalDue = invoice.totalDue;
        this.invoiceId = invoice.documentNumber;
        this.updateSelected( this.amountOptionTotal, this.currency );
      }
    } );

    fromEvent( this.partialAmount.nativeElement, 'keyup' )
      .pipe(
        map( ( k: any ) => k.target.value ),
        debounceTime( 300 ),
        takeUntil( this.unsubscribe$ )
      )
      .subscribe( () => {
          this.neverHadPartialAmountUserInput = false;
          this.updatePartialAmount( this.selectedPartialAmount, this.currency );
        }
      );

    fromEvent( this.partialAmount.nativeElement, 'keydown' )
      .pipe(
        tap( ( k: any ) => {
          if ( this.numbers.includes( k.key ) || k.key === 'Backspace' ) {
            this.cursorRemainder = this.parseCursorRemainder( k.target.value, k.target.selectionEnd );
          } else {
            this.cursorRemainder = null;
          }
        } ),
        map( ( k: any ) => this.partialAmountInputValueHandler(
          k.target.value,
          k.key,
          k.metaKey,
          k.target.selectionStart,
          k.target.selectionEnd,
          k
        ) ),
        map( this.formatPartialInputValue ),
        tap( ( value: string ) => { this.selectedPartialAmount = Number( value ); } ),
        switchMap( ( value ) => {
          return combineLatest( [
            of( value ),
            this.currency$,
            this.selectedLocale$
          ] ).pipe(
            map( ( [ value, currency, locale ] ) =>
              this.getMoneyFormat( currency, Number( value ), false, locale, false ) )
          );
        } ),
        tap( ( value ) => this.renderPartialInputValue( this.renderer, value ) ),
        debounceTime( 0 ),
        takeUntil( this.unsubscribe$ )
      ).subscribe( () => {
      this.setInputWidth();
      this.setCursor();
    } );

    fromEvent( this.partialAmount.nativeElement, 'focus' )
      .pipe(
        takeUntil( this.unsubscribe$ )
      )
      .subscribe(
        () => {
          this.updateSelected( this.amountOptionPartial, this.currency );
        }
      );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  ngAfterViewInit () {
    document.getElementById( 'amountOptionTotal' ).focus();
  }

  getMoneyFormat ( currency: string, amount: number, forceTwoDecimals: boolean, locale: string, includeCurrencySymbol: boolean ) {
    return this.moneyConverterService.getMoneyDisplayAmountWithCurrency(
      currency, amount, forceTwoDecimals, locale, includeCurrencySymbol );
  }

  getCurrencySymbol ( currency: string ) {
    return this.moneyConverterService.getSymbol( currency );
  }

  getShouldPrependSymbol ( locale: string ) {
    return Language.instanceFromString( locale ).prependCurrencySymbol;
  }

  updateSelected ( selectedOption: string, currency: string ) {
    this.selectedAmountOption = selectedOption;

    if ( selectedOption === this.amountOptionPartial ) {
      this.partialAmount.nativeElement.focus();
      const validation = this.paymentService.validatePartialAmount( this.selectedPartialAmount, this.totalDue, currency );
      this.parseValidation( validation );
    } else if ( selectedOption === this.amountOptionTotal ) {
      this.partialAmount.nativeElement.blur();
      this.setAmount( this.totalDue, PayType.INDIVIDUAL );
    }
  }

  setInputWidth () {
    // Actual font size is 14. but calculateSize seems to underestimate few character's width.
    const stringSize = calculateSize( this.partialAmount.nativeElement.value, {
      font: 'Helvetica Neue',
      fontSize: '15px'
    } );

    const stringWidth = stringSize.width > 132 ? 132 : stringSize.width;

    // 34px is for padding jiveInput requires
    this.inputWidth = Math.max( this.inputMinWidth, stringWidth + 34 );
  }

  /*
   * Handles the input values and removes non-number formatting.
   * Any formatting including decimal and thousand separators will be removed here.
   * A decimal separator is added in function formatPartialInputValue
   * Thousand separators are added in function getMoneyFormat
   * Note: The reason to manually handle the key input is if we wait until keyup the value showing in input box
   *       will show incorrectly formatted number which gets rendered by input and then correctly formatted number will get rendered.
   *       In order to improve the UX, the input functions (except few native functions) need to be prevented and handled manually.
   * Note: Typescript for KeyboardEvent does not support target. So we cannot test this function well if just event is passed.
   *       In order to make testing easier, we pass in the values we need from the event separately.
  */
  partialAmountInputValueHandler ( originalValue: string, pressedKey: string, metaKey: boolean, selectionStart: number, selectionEnd: number, event: KeyboardEvent ) {
    let value = originalValue;

    // If pressed key is a number, add it to the appropriate location
    if ( this.numbers.includes( pressedKey ) ) {
      const splitArray = originalValue.split( '' );
      splitArray.splice( selectionStart, 0, pressedKey );
      value = splitArray.join( '' );
      // Prevent default input functions
      event.preventDefault();
    } else {
      if ( pressedKey === 'Backspace' ) {
        if ( selectionEnd === selectionStart ) {
          if ( selectionStart > 0 ) {
            const splitArray = originalValue.split( '' );

            let indexToRemove = selectionStart - 1;

            // If the cursor is placed after a separator, this will remove the number before the separator.
            if ( !this.numbers.includes( splitArray[ indexToRemove ] ) && indexToRemove > 0 ) {
              indexToRemove = selectionStart - 2;
            }
            splitArray.splice( indexToRemove, 1 );
            value = splitArray.join( '' );
          } else {
            value = originalValue;
          }
        } else {
          const splitArray = originalValue.split( '' );
          splitArray.splice( selectionStart, selectionEnd - selectionStart );
          value = splitArray.join( '' );
        }
        // Prevent default input functions
        event.preventDefault();
      } else if (
        !( pressedKey === 'a' && metaKey ) &&
        pressedKey !== 'ArrowRight' &&
        pressedKey !== 'ArrowLeft' &&
        pressedKey !== 'ArrowUp' &&
        pressedKey !== 'ArrowDown' ) {
        // Prevent default input functions
        event.preventDefault();
      }
    }

    let valueArray = value.split( '' );

    // remove any prepended characters from previous formatting
    while (
      valueArray[ 0 ] === '0' ||
      valueArray[ 0 ] === '.' ||
      valueArray[ 0 ] === ' ' ||
      valueArray[ 0 ] === ',' ) {
      valueArray = valueArray.slice( 1, valueArray.length );
    }

    // remove any remaining formatting characters
    valueArray = valueArray.filter( number => number !== ' ' && number !== ',' && number !== '.' );
    return valueArray.join( '' );
  }

  /*
   * After the input value goes through partialAmountInputValueHandler, it adds the decimal separator
   * Since the locale based decimal separator will be replaced in getMoneyFormat, this function uses '.' for the decimal separator
   * Example 1: the input is '1'. then will turn it into '0.01'
   * Example 2: the input is '123'. then will turn it into '1.23'
  */
  formatPartialInputValue ( value: string ) {
    if ( Number( value ) === 0 ) {
      return '0.00';
    } else if ( value.length === 1 ) {
      return `0.0${ value }`;
    } else if ( value.length === 2 ) {
      return `0.${ value }`;
    } else {
      const valueArray = value.split( '' ).filter( number => number !== '.' );
      const decimalValue = valueArray.slice( valueArray.length - 2, valueArray.length ).join( '' );
      const nonDecimalValue = valueArray.slice( 0, valueArray.length - 2 ).join( '' );
      return `${ nonDecimalValue }.${ decimalValue }`;
    }
  }

  renderPartialInputValue ( renderer: Renderer2, value: string ) {
    renderer.setProperty( this.partialAmount.nativeElement, 'value', value );
  }

  /*
   *  Because the input value get formatted, the most reliable way to figure out the placement of cursor is to remember the value
   *  behind the cursor rather than using the cursor location on keydown.
   *  Set this only if the keydown event would change the partialAmount value.
   */
  parseCursorRemainder ( originalValue: string, cursorLocation: number ) {
    const originalArray = originalValue.split( '' );
    return originalArray.slice( cursorLocation, originalArray.length ).join( '' );
  }

  /*
   *  Manually set the cursor location only when the keydown event change the partialAmount value, and the cursor location was not at the end.
   */
  setCursor () {
    if ( this.partialAmount.nativeElement.value && this.cursorRemainder ) {
      const cursorPosition = this.partialAmount.nativeElement.value.length - this.cursorRemainder.length;
      this.partialAmount.nativeElement.setSelectionRange( cursorPosition, cursorPosition );
    }
  }

  updatePartialAmount ( newAmount: number, currency: string ) {
    const validation = this.paymentService.validatePartialAmount( newAmount, this.totalDue, currency );
    this.parseValidation( validation );
  }

  parseValidation ( validation: any ) {
    if ( validation.valid ) {
      this.partialAmountValidationError = null;

      if ( this.selectedAmountOption === this.amountOptionPartial ) {
        this.setAmount( this.selectedPartialAmount, PayType.PARTIAL );
      }
    } else {
      this.partialAmountValidationError = validation.error;
      this.disablePayNow.emit( true );
    }
  }

  setAmount ( amount: number, payType: string ) {
    this.disablePayNow.emit( false );
    this.partialAmountValidationError = null;
    const invoiceInfo = {};
    invoiceInfo[ this.invoiceId ] = amount;
    this.reduxStore.dispatch( this.paymentActions.setPayNowAmount( amount, payType, invoiceInfo ) );
  }
}
