import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopasActions } from './redux/copas.actions';
import { CopasEpic } from './redux/copas.epic';

@NgModule( {
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CopasActions,
    CopasEpic,
  ]
} )
export class CopasModule {}
