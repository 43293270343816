<header id="banner" *ngIf="message">
  <h3 class="title ng-scope">
    <div class="fade ng-binding">
      <svg class="info-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0h24v24H0z"></path>
          <path d="M12 .995c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm0 2c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zM12.013 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-1.708 5.822l.03-.2.476-2.704h-.825a1 1 0 1 1 0-2H12a1 1 0 0 1 1 1l-.69 4.016c-.038.266.137.54.403.586a.482.482 0 0 0 .3-.052.88.88 0 0 0 .093-.055.586.586 0 0 0 .115-.125c.031-.05.052-.083.06-.112a.496.496 0 0 1 .97.215c-.149.838-1.15 1.363-2.239 1.171-.833-.147-1.472-.673-1.659-1.288a1.226 1.226 0 0 1-.048-.452z" fill="currentColor"></path>
        </g>
      </svg>
      <div role="alert" [innerHTML]="message | translate"></div>
    </div>
  </h3>
</header>
