import { FactoryProvider, NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreServicesConfig } from './services/core/service-helpers';
import { NgReduxModule } from '@angular-redux/store';
import { StoreModule } from './store/store.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { ServicesModule } from './services/services.module';
import { IDENTITY_SERVICE_PROVIDER } from './services/identity-service/identity.service';
import { USER_CONFIGURATION_SERVICE_PROVIDER } from './services/user-configuration-service/user-error.service';
import { environment } from '../environments/environment';
import { AuthModule } from './services/auth/auth.module';
import { API_401_ERROR_HOOK_TOKEN, HttpInterceptor401 } from './services/http/http-interceptor-401.service';
import { Authentication } from './services/auth/auth.service';
import { JIF_SERVICE_PROVIDER } from './services/jif-service/jif.service';
import { APP_INIT_SERVICE_PROVIDER } from './services/app-init/app-init.service';
import { PERMISSIONS_SERVICE_PROVIDER } from './services/permissions-service/permissions.service';

const sharedModules = [
  AuthModule,
  CommonModule,
  NgReduxModule,
  StoreModule,
  HttpClientModule,
  ComponentsModule,
  DirectivesModule,
  ServicesModule
];

export const JIVE_HTTP_INTERCEPTOR_401_PROVIDER: FactoryProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: HttpInterceptor401.factory,
  deps: [ Authentication, [ new Optional(), API_401_ERROR_HOOK_TOKEN ] ],
  multi: true
};

@NgModule( {
  imports: sharedModules,
  exports: sharedModules,
  providers: [
    IDENTITY_SERVICE_PROVIDER,
    JIF_SERVICE_PROVIDER,
    APP_INIT_SERVICE_PROVIDER,
    PERMISSIONS_SERVICE_PROVIDER,
    // here we set up the api environment for all core services provided by jive ui sdk.
    // If you need to set a service to your local running version of that service then use the
    // proper config prop on the CoreServicesConfig that is passed to the provider
    USER_CONFIGURATION_SERVICE_PROVIDER,
    JIVE_HTTP_INTERCEPTOR_401_PROVIDER,
    {
      provide: CoreServicesConfig,
      useValue: {
        environment: environment.environment
      }
    }
  ]
} )
export class AppCommonModule {}
