import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
// tslint:disable-next-line:max-line-length
import { AchPostData, CARD_BRAND_AMEX, CARD_BRAND_DISCOVER, CARD_BRAND_MASTER, CARD_BRAND_VISA, COPAS_CARD_BRAND_AMEX, COPAS_CARD_BRAND_VISA, CreatePaymentMethodScaChallengeRequest, IMAGE_PATH_ACH, IMAGE_PATH_AMEX, IMAGE_PATH_DEFAULT, IMAGE_PATH_DISCOVER, IMAGE_PATH_MASTER, IMAGE_PATH_VISA, PAYMENT_METHOD_TYPE_ACH, PAYMENT_METHOD_TYPE_CARD, PaymentMethod } from './redux/payment-method.model';
import { Address, CreditCard } from '../flex-form/redux/flex-form.model';
import { PaymentMethodCreationResponse, ScaToken } from '../cardinal/redux/cardinal.model';
import { HttpClient } from '@angular/common/http';
import { PaymentMethodCreationResponseDto, PaymentMethodDto } from './dto/payment-method-dto.model';

@Injectable( { providedIn: 'root' } )
export class PaymentMethodService {

  constructor (
    private http: HttpClient ) { }

  getPaymentMethodList = ( orgId: string ) =>
    this.http.get<PaymentMethodDto[]>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payment-methods` )

  parsePaymentMethodListResponse ( payments: PaymentMethodDto[] ) {
    if ( payments ) {
      const paymentMethodList: PaymentMethod[] = [];
      payments.forEach( it => {
        const paymentMethod: PaymentMethod = {
          id: it.id,
          defaultMethod: it.defaultMethod,
          merchantProcessorType: it.merchantProcessorType,
          last4: it.metaData.last4,
          paymentMethodType: it.paymentMethodType,
        };

        if ( paymentMethod.paymentMethodType === PAYMENT_METHOD_TYPE_CARD ) {
          paymentMethod.cardBrand = it.metaData.cardBrand;
          paymentMethod.exp = it.metaData.exp;
        }

        if ( it.metaData.country ) { paymentMethod.country = it.metaData.country; }

        paymentMethodList.push( paymentMethod );
      } );

      return paymentMethodList;

    } else {
      return undefined;
    }
  }

  addCard ( scaToken: ScaToken, orgId: string, address: Address, card: CreditCard ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payment-methods`;

    const params = {
      merchantProcessorType: 'COPAS',
      scaToken: scaToken,
      address: address,
      creditCard: card
    };

    return this.http.post<PaymentMethodCreationResponse>( path, params );
  }

  addCardAfterSca (
    orgId: string,
    paymentMethodKey: string,
    createPaymentMethodScaChallengeRequest: CreatePaymentMethodScaChallengeRequest
  ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payment-methods/${ paymentMethodKey }/sca-challenges`;

    return this.http.post<PaymentMethodCreationResponseDto>( path, createPaymentMethodScaChallengeRequest );
  }

  addAch ( achInfo: AchPostData ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ achInfo.orgId }/payment-methods`;

    const params = {
      merchantProcessorType: 'AUTHORIZE',
      nameOnAccount: achInfo.name,
      routingNumber: achInfo.routingNumber,
      accountNumber: achInfo.accountNumber,
    };

    return this.http.post<PaymentMethodCreationResponseDto>( path, params );
  }

  deletePaymentMethod = ( orgId: string, paymentMethodId: string ) =>
    this.http.delete( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payment-methods/${ paymentMethodId }` )

  changeDefaultPaymentMethod = ( orgId: string, paymentMethodId: string ) =>
    this.http.patch( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payment-methods/${ paymentMethodId }`, null )

  cardPaymentMethod ( paymentMethodType: string ): boolean {
    return paymentMethodType === PAYMENT_METHOD_TYPE_CARD;
  }

  achPaymentMethod ( paymentMethodType: string ): boolean {
    return paymentMethodType === PAYMENT_METHOD_TYPE_ACH;
  }

  getPaymentMethodImageUrl ( paymentMethod: PaymentMethod ) {
    if ( paymentMethod.paymentMethodType === PAYMENT_METHOD_TYPE_CARD ) {
      switch ( paymentMethod.cardBrand ) {
        case CARD_BRAND_VISA:
          return IMAGE_PATH_VISA;
        case COPAS_CARD_BRAND_VISA:
          return IMAGE_PATH_VISA;
        case CARD_BRAND_AMEX:
          return IMAGE_PATH_AMEX;
        case COPAS_CARD_BRAND_AMEX:
          return IMAGE_PATH_AMEX;
        case CARD_BRAND_DISCOVER:
          return IMAGE_PATH_DISCOVER;
        case CARD_BRAND_MASTER:
          return IMAGE_PATH_MASTER;
        default:
          return IMAGE_PATH_DEFAULT;
      }

    } else if ( paymentMethod.paymentMethodType === PAYMENT_METHOD_TYPE_ACH ) {
      return IMAGE_PATH_ACH;
    }
  }

  // Stripe
  addStripe ( orgId: string, token: string ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payment-methods`;

    const params = {
      merchantProcessorType: 'STRIPE',
      stripePaymentToken: token
    };

    return this.http.post<PaymentMethodCreationResponseDto>( path, params );
  }
}
