import { Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class AutopayActions {
  static readonly GET_AUTOPAY_STATE = 'AUTOPAY:GET_STATUS';
  static readonly SET_AUTOPAY_STATUS = 'AUTOPAY:SET_STATUS';
  static readonly SET_AUTOPAY_DESIRED_NEW_STATE = 'AUTOPAY:SET_DESIRED_NEW_STATE';
  static readonly POST_AUTOPAY_ENROLLMENT = 'AUTOPAY:POST_ENROLLMENT';
  static readonly POST_AUTOPAY_ENROLLMENT_COMPLETE = 'AUTOPAY:POST_ENROLLMENT_COMPLETE';
  static readonly SET_AUTOPAY_POST_ERROR = 'AUTOPAY:SET_POST_ERROR';
  static readonly RESET_AUTOPAY = 'AUTOPAY:RESET';
  static readonly RESET_AUTOPAY_POST_SUCCESS = 'AUTOPAY:RESET_POST_SUCESS';

  constructor () { }

  // reset all except enrolled to be undefined
  resetAutopayState () {
    return { type: AutopayActions.RESET_AUTOPAY };
  }

  getAutopayStatus ( orgId: string ) {
    return { type: AutopayActions.GET_AUTOPAY_STATE, payload: { orgId } };
  }

  setAutopayStatus ( enrolled: boolean ) {
    return { type: AutopayActions.SET_AUTOPAY_STATUS, payload: { enrolled } };
  }

  postAutopayEnrollment ( orgId: string, enrolled: boolean ) {
    return { type: AutopayActions.POST_AUTOPAY_ENROLLMENT, payload: { orgId, enrolled } };
  }

  postAutopayEnrollmentComplete ( enrolled: boolean ) {
    return { type: AutopayActions.POST_AUTOPAY_ENROLLMENT_COMPLETE, payload: { enrolled } };
  }

  setAutopayPostError ( postError: any ) {
    return { type: AutopayActions.SET_AUTOPAY_POST_ERROR, payload: { postError } };
  }

  setAutopayDesiredNewState ( desiredEnrollmentState: boolean ) {
    return { type: AutopayActions.SET_AUTOPAY_DESIRED_NEW_STATE, payload: { desiredEnrollmentState } };
  }

  resetAutopayPostSuccess () {
    return { type: AutopayActions.RESET_AUTOPAY_POST_SUCCESS };
  }
}
