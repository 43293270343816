import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationService } from './organization.service';
import { OrganizationActions } from './redux/organization.actions';
import { OrganizationEpic } from './redux/organization.epic';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    OrganizationActions,
    OrganizationEpic,
    OrganizationService
  ],
  declarations: []
} )
export class OrganizationModule {}
