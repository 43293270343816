import {Injectable} from '@angular/core';
import {OrganizationActions} from './organization.actions';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, ignoreElements, map, switchMap} from 'rxjs/operators';
import {OrganizationService} from '../organization.service';
import {of} from 'rxjs';
import {InvoiceService} from '../../invoice/invoice.service';

@Injectable()
export class OrganizationEpic {
  constructor (
    private organizationService: OrganizationService,
    private invoiceService: InvoiceService,
    private organizationActions: OrganizationActions
  ) { }

  getEpics () {
    return combineEpics(
      this.getAndSetOrganizationTotalDue.bind( this ),
      this.navigateIfCurrencyIsMissing.bind( this ),
    );
  }

  getAndSetOrganizationTotalDue ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(OrganizationActions.GET_ORGANIZATION_TOTAL_DUE),
          switchMap(({payload: {orgId}}) => this.invoiceService.getTotalDue(orgId)
              .pipe(
                  map(totalDue => totalDue != null ? `${totalDue}` : undefined),
                  map(this.organizationActions.setOrganizationTotalDue),
                  catchError(() =>
                      of(this.organizationActions.setOrganizationTotalDue(undefined))
                  )
              )
          )
      );
  }

  // if the currency could not get fetched, we send the user to 400 error page
  // since currency is an essential data.
  navigateIfCurrencyIsMissing ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(OrganizationActions.SET_ORGANIZATION_SELECTED_ORGANIZATION),
          map(({payload: {selectedOrganization}}) => {
              if (!this.organizationService.isBillingInfoValid(selectedOrganization)) {
                  window.location.href = '/billing/org-select';
              }
          }),
          ignoreElements());
  }
}
