<form class="org-selector" role="search">
  <input
    class="org-input"
    id="org-search-input"
    type="text"
    aria-label="OrgInput"
    matInput
    bpAutoFocus
    [formControl]="orgControl"
    [matAutocomplete]="auto"
    #orgSearch>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let org of orgs$ | async "
      [value]="org.name"
      (onSelectionChange)="navigateToSelectedOrg(org.domain)">
      {{org.name}}
    </mat-option>
    <mat-option *ngIf="(orgs$| async)?.length==0">
      <span [innerHTML]="'ORG_SELECTOR_NO_RESULT'| translate">
        <!-- No results found -->
      </span>
    </mat-option>
  </mat-autocomplete>
</form>
