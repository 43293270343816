/**
 * https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
 * Per known shortcomings in Typescript, a custom error is no longer an instance of itself. As in
 * (new OrganizationRequestError() instanceOf OrganizationRequestError) === false
 * This breaks downstream functionality that depends on knowing the type of custom error (either through
 * check `instanceOf` or switching on err.constructor). This is fixed by manually setting the
 * prototype of the Error in the constructor per typescript's recommendation.
 */

export class OrganizationRequestError extends Error {
  constructor () {
    super( `
    The request failed: this is most likely due
    to your not being behind the vpn as this request requires
  `);

    Object.setPrototypeOf( this, OrganizationRequestError.prototype );
  }
}

export class OrganizationNotFoundError extends Error {
  constructor ( orgName: string ) {
    super( `The organization ( ${ orgName } ) you attempted to load does not exist` );

    Object.setPrototypeOf( this, OrganizationNotFoundError.prototype );
  }
}

export class OrganizationPermissionsError extends Error {
  constructor ( orgName: string ) {
    super( `
    You do not have the correct permissions for the  organization ( ${orgName } ) you attempted to load
  `);

    Object.setPrototypeOf( this, OrganizationPermissionsError.prototype );
  }
}
