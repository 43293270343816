import { OrganizationState } from './organization.model';
import { Action } from '../../../store/action.model';
import { OrganizationActions } from './organization.actions';

const INITIAL_STATE: OrganizationState = {
  selectedOrganization: undefined,
  totalDue: undefined,
  getTotalDueInProgress: undefined
};

export function organizationReducer (
  lastState: OrganizationState = INITIAL_STATE,
  action: Action<Partial<OrganizationState>>
): OrganizationState {
  switch ( action.type ) {
    case OrganizationActions.SET_ORGANIZATION_SELECTED_ORGANIZATION:
      return {
        ...lastState,
        selectedOrganization: action.payload.selectedOrganization
      };
    case OrganizationActions.SET_ORGANIZATION_TOTAL_DUE:
      return { ...lastState, totalDue: action.payload.totalDue, getTotalDueInProgress: false };
    case OrganizationActions.GET_ORGANIZATION_TOTAL_DUE:
      return { ...lastState, getTotalDueInProgress: true };
    default:
      return lastState;
  }
}
