import { Component, OnDestroy } from '@angular/core';
import { select } from '@angular-redux/store';
import { MODAL_VISIBLE_SELECTOR } from '../../store/helper';
import { Observable, Subject } from 'rxjs';
import 'hammerjs';

@Component( {
  selector: 'bp-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: [ './main-navbar.component.scss' ]
} )
export class MainNavbarComponent implements OnDestroy {

  @select( MODAL_VISIBLE_SELECTOR ) modalVisible$: Observable<boolean>;

  unsubscribe$: Subject<boolean> = new Subject();

  logoToUse = 'assets/images/goto_logo.svg';

  constructor () { }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }
}
