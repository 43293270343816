/**
 * Element type is any here because some of the dom methods available are not on the
 * type Element or NodeElement but are available on the Element itself. Angular 2 uses any for
 * its element as well, I assume for this reason.
 *
 * @export
 * @param {*} el a DOM element
 * @returns {boolean}
 */
export function isElementVisible(el: any): boolean {
  return !!(el.offsetWidth ||
      el.offsetHeight ||
      el.getClientRects().length);
}
