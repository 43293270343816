import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MobileActions } from '../../services/mobile/redux/mobile.actions';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CommonPageSettingService } from '../../services/common-page-setting/common-page-setting.service';
import { takeUntil } from 'rxjs/operators';
import { Organization } from '../../services/organization/redux/organization.model';
import {
  HAS_PAY_ACCESS_SELECTOR, IS_MOBILE_SELECTOR, MODAL_VISIBLE_SELECTOR,
  ORGANIZATION_DOMAIN_SELECTOR, ORGANIZATION_ID_SELECTOR, ORGANIZATION_SELECTOR
} from '../../store/helper';

@Component( {
  selector: 'bp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
} )
export class NavbarComponent implements OnInit, OnDestroy {
  @select( IS_MOBILE_SELECTOR ) isMobile$: boolean;
  @select( ORGANIZATION_DOMAIN_SELECTOR ) selectedDomain$: Observable<string>;
  @select( ORGANIZATION_SELECTOR ) organization$: Observable<Organization>;
  @select( HAS_PAY_ACCESS_SELECTOR ) hasPayAccess$: Observable<boolean>;
  @select( ORGANIZATION_ID_SELECTOR ) selectedOrgId$: Observable<string>;
  @select( MODAL_VISIBLE_SELECTOR ) modalVisible$: Observable<boolean>;

  unsubscribe$: Subject<boolean> = new Subject();

  public showOrgSelector = false;
  public showPaymentOptionTab = false;
  public selectedDomain: string;
  public hasPayAccess: boolean;
  public selectedOrgId: string;
  public showSmsDetailsTab = false;

  constructor (
    public router: Router,
    private mobileActions: MobileActions,
    private readonly ngRedux: NgRedux<AppState>,
    private commonPageSettingService: CommonPageSettingService
  ) { }

  ngOnInit () {
    this.setIsMobile();

    this.selectedDomain$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( domain: string ) => {
      this.selectedDomain = domain;
    } );

    this.selectedOrgId$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( orgId: string ) => this.selectedOrgId = orgId );

    combineLatest( [
      this.organization$,
      this.hasPayAccess$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ organization, hasPayAccess ] ) => {
      this.hasPayAccess = hasPayAccess;
      this.setShowPaymentOptionTab( organization );
      this.setShowSmsDetailsTab( organization );
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  @HostListener( 'window:resize', [ '$event' ] ) onResize () {
    this.setIsMobile();
  }

  setIsMobile () {
    this.ngRedux.dispatch( this.mobileActions.setMobile( window.innerWidth ) );
  }

  setShowOrgSelector ( value: boolean ) {
    this.showOrgSelector = value;
  }

  setShowPaymentOptionTab ( organization: Organization ) {
    if ( organization && organization.currency && organization.country ) {
      this.showPaymentOptionTab =
        this.commonPageSettingService.canPayOnPortal( organization.currency, this.hasPayAccess );
    } else {
      this.showPaymentOptionTab = false;
    }
  }

  setShowSmsDetailsTab ( organization: Organization ) {
    this.showSmsDetailsTab = organization &&
      ( organization.customerEngagementAccess || ( organization.unlimitedSms != null && !organization.unlimitedSms ) );
  }
}
