import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AutopayState } from './redux/autopay.model';
import { HttpClient } from '@angular/common/http';

@Injectable( {
  providedIn: 'root'
} )
export class AutopayService {

  constructor ( private http: HttpClient ) { }

  getAutoPaymentState = ( orgId: string ) =>
    this.http.get<boolean>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/auto-payment-enrollment` )

  saveAutoPaymentState = ( orgId: string, enrolled: boolean ) => this.http.patch<boolean>(
    `${ environment.billingPortalBffUrl }/organizations/${ orgId }/auto-payment-enrollment`,
    { state: enrolled }
  )

  autoPaymentNeedsToBeUpdated = ( state: AutopayState ) =>
    state.desiredEnrollmentState && state.desiredEnrollmentState !== state.enrolled
}
