import { Component, Input, OnInit } from '@angular/core';

@Component( {
  selector: 'bp-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: [ './payment-error.component.css' ]
} )
export class PaymentErrorComponent implements OnInit {
  @Input() errorMessageKey: string;
  @Input() errorCode: string;

  constructor () { }

  ngOnInit (): void {
  }

  close () {
    location.reload();
  }
}
