import { Component, Input, OnInit } from '@angular/core';

@Component( {
  selector: 'jive-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: [ './app-version.component.scss' ]
} )
export class AppVersionComponent implements OnInit {

  private appVersion = '';

  @Input() version: string;

  constructor () { }

  ngOnInit () {}

  get resolveAppVersion () {
    return this.version || this.appVersion;
  }

}
