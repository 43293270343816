import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {UserActions} from './user.actions';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {select} from '@angular-redux/store';
import {IdentityServiceV1, PrincipalData} from '@jive/core/identity/v1';
import {AuthenticationInfo} from '@jive/core/authentication';
import {UserService} from '../user.service';
import {JifUserData} from './user.model';

@Injectable()
export class UserEpic {
  @select( [ 'auth', 'authInfo' ] ) readonly authInfo$: Observable<AuthenticationInfo>;

  constructor (
    private userActions: UserActions,
    private identityService: IdentityServiceV1,
    private userService: UserService
  ) {}

  getEpics () {

    return combineEpics(
      this.setUserEpic.bind( this ),
      this.setUserPbxsEpic.bind( this )
    );
  }

  setUserEpic ( action$: ActionsObservable<Action<any>> ) {
    return action$
      .pipe(
          ofType(UserActions.SET_START),
          mergeMap(() => this.authInfo$),
          mergeMap((authInfo: AuthenticationInfo) => this.identityService.getPrincipal(authInfo.jiveId)),
          map(principalData => {

            return this.userService.principalUserValidator(principalData);
          }),
          // For now, we do not have a good way to check if the first indexed item is the right user.
          // We are assuming that it is the first one.
          map<PrincipalData, any>(principalData => {
            return this.userActions.setUserAndPrincipalDataAction(principalData, principalData.users[0]);
          }),
          catchError((error: any) => of(this.userActions.setErrorAction(error)))
      );
  }

  setUserPbxsEpic ( action$: ActionsObservable<Action<any>> ) {
    return action$
      .pipe(
          ofType(UserActions.SET_START),
          mergeMap(() => this.authInfo$),
          mergeMap((authInfo: AuthenticationInfo) => this.userService.getJifUser(authInfo.jiveId)),
          map<JifUserData, any>(jifUser => jifUser.data.tenants.pbxes),
          map(this.userActions.setUserPbxsAction.bind(this.userActions)),
          catchError((error: any) => of(this.userActions.setErrorAction(error)))
      );
  }
}
