import { Component, Input, OnInit } from '@angular/core';
import { ApplicationCollection, ApplicationsService } from '@jive/core/applications';
import { first, map, tap } from 'rxjs/operators';
import { AppSwitcherListBase } from './appSwitcherList';

@Component({
  selector: 'jive-app-switcher-list',
  templateUrl: './app-switcher-list.component.html',
  styleUrls: ['./app-switcher-list.component.scss']
})
export class AppSwitcherListComponent extends AppSwitcherListBase
    implements OnInit {

  @Input() protected language?: string;

  constructor(
      protected applicationsService: ApplicationsService
  ) {
    super(applicationsService);
  }

  ngOnInit () {}

  resolveAppAndSelect(index: number) {
    this.listApplications
        .pipe(
            map((response: ApplicationCollection) => response.items[index]),
            tap(this.onApplicationSelected.bind(this)),
            first()
        )
        .subscribe();
  }

}
