import { FactoryProvider } from '@angular/core';
import { JifService, JifPbx } from '@jive/core/jif';
import { JiveOrganizationPermissions } from '@jive/core/identity/v1';
import without from 'lodash-es/without';
import keyBy from 'lodash-es/keyBy';
import { of } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppInitOrganizationPermissions, UserOrganizationSelection } from './models';
import { OrganizationPermissionsError, OrganizationRequestError, OrganizationNotFoundError } from './common';

// for the forseeable future we will only provide this service in Angular since all
// apps built in AngularJs will have already had to write this themselves
export class AppInitService {

  constructor (
    private jifService: JifService
  ) { }

  /**
 * This function returns the organization name if one is present in the first
 * part of the path object
 *
 * @param inflightRequest the inflight request taken from the url
 */
  private inflightRequestOrganizationName ( inflightRequest: string ) {
    return inflightRequest ?
      this.pathFirstIndex( inflightRequest ) :
      undefined;
  }

  private createUserSelectedPbxResponse (
    orgFromInflightRequest: boolean,
    inflightRequest: string,
    selectedOrganization: JifPbx,
    orgFromLocationPath: boolean,
    permissions: JiveOrganizationPermissions
  ): UserOrganizationSelection {
    return {
      orgFromInflightRequest,
      inflightRequest,
      selectedOrganization,
      orgFromLocationPath,
      permissions
    };
  }

  /**
  * This function returns the first path value in the provided string.
  *
  * @param path the path taken from the window.location.path
  */
  pathFirstIndex ( path: string ): string | undefined {
    return without( path.split( '/' ), '' )[ 0 ];
  }

  /**
   * Given the params we select the proper org for the user. If there was
   * an inflightRequest or a location path we take those into account. If none of those were
   * provided we fall back to the users first org in the list.
   *
   * @param inflightRequest the inflightRequest returned in the auth location hash
   * @param path the window.location.pathname
   * @param userOrganizations a list of JifPbx objects
   * @param permissionChecker this is the function that takes the organization id
   * and returned the permissions as well as whether the user can view the organization
   * @param orgNameFromPath if you are on the my.jive domain and the staging vs prod url changes
   * due to the app domain then you will need to provide this hook. This will change where
   * app init searches for the pbx name in the window.path and leaves that part of the process up to you.
   * If this is not passed in then the first index of the path will be assumed to be the pbx name.
   */
  getUserSelectedOrganization (
    inflightRequest: string,
    path: string,
    userOrganizations: JifPbx[],
    permissionChecker: ( organizationId: string ) => AppInitOrganizationPermissions,
    orgNameFromPath?: ( path: string ) => string
  ): Observable<UserOrganizationSelection> {

    // index the orgs so our search below is faster
    const orgsIndexedByDomain = keyBy( userOrganizations, 'domain' );

    // check if there is an inflight request url
    // and if so grab the first pathname
    const inflightRequestOrgName = this.inflightRequestOrganizationName( inflightRequest );

    // in case the user is already logged in but saved a previous org url we want to grab that
    // and try and resolve that for the user automatically, so grab the first pathname
    const urlPathOrgName = orgNameFromPath ? orgNameFromPath( path ) : this.pathFirstIndex( path );

    const orgFromLocationPath = orgsIndexedByDomain[ urlPathOrgName ];
    const orgFromInflightRequest = inflightRequestOrgName ? orgsIndexedByDomain[ inflightRequestOrgName ] : undefined;

    // if inflightRequest and didn't match a jif principal data org
    // and/or there was a path that also has no matching org in jif principal data
    // then attempt to grab the org from jif domain

    // check by order of importance and grab the selected org
    const selectedPbxFromPath: JifPbx | undefined = orgFromInflightRequest ||
      orgFromLocationPath;

    /**
     * If there was no org name in the path or inflight request default to
     * first org
    */
    if ( !inflightRequest && !urlPathOrgName ) {
      const selectedOrganization = userOrganizations[ 0 ];
      return of( this.createUserSelectedPbxResponse(
        false,
        inflightRequest,
        selectedOrganization,
        false,
        permissionChecker( selectedOrganization.id ).permissions
      )
      );

      /**
        * If there was an org name and it matched an org the user has a user object in then
        * return that
      */
    } else if ( selectedPbxFromPath ) {

      return of( this.createUserSelectedPbxResponse(
        !!orgFromInflightRequest,
        inflightRequest,
        selectedPbxFromPath,
        !!orgFromLocationPath,
        permissionChecker( selectedPbxFromPath.id ).permissions
      )
      );

      /**
        * If there was an org name and it didn't match an org the user has a user object in then
        * check jif by domain
      */
    } else {

      return this.getOrganizationIfPermitted(
        inflightRequestOrgName || urlPathOrgName,
        !!inflightRequestOrgName,
        !!urlPathOrgName,
        inflightRequest,
        permissionChecker
      );

    }


  }

  private isResponseType ( res: Response | Error ): res is Response {
    return res.hasOwnProperty( 'ok' ) && res.hasOwnProperty( 'status' );
  }

  private getOrganizationIfPermitted (
    organizationName: string,
    orgFromInflightRequest: boolean,
    orgFromLocationPath: boolean,
    inflightRequest: string,
    permissionChecker: ( organizationId: string ) => AppInitOrganizationPermissions
  ) {
    return this.jifService.getPbxByDomain( organizationName )
      .pipe(
        catchError( ( res: Response | Error ) => {

          if ( this.isResponseType( res ) && res.status === 404 ) {
            return throwError( new OrganizationNotFoundError( organizationName ) );
          } else {
            return throwError( new OrganizationRequestError() );
          }

        } ),
        map( ( organization?: JifPbx ) => {
          const appInitPermissions = permissionChecker( organization.id );

          if ( appInitPermissions.canViewOrganization ) {
            return this.createUserSelectedPbxResponse(
              orgFromInflightRequest,
              inflightRequest,
              organization,
              orgFromLocationPath,
              appInitPermissions.permissions
            );
          } else {
            throw new OrganizationPermissionsError( organizationName );
          }

        } )
      );
  }

  /**
   * Take in the UserPBXSelection and return the necessary url update path if one is needed
   */
  private urlPathAssignment (
    userPbxSelection: UserOrganizationSelection
  ): string | undefined {

    if ( userPbxSelection.orgFromInflightRequest ) {
      return userPbxSelection.inflightRequest;
    } else if ( !userPbxSelection.orgFromLocationPath ) {
      return userPbxSelection.selectedOrganization.domain;
    } else {
      return undefined;
    }

  }
  /**
   * There are a few factors that go into whether we should be updating the
   * href of the app. This method checks the basics for us and lets us know
   * if we should and gives us the path to update to.
   *
   * This is being exposed in case you need to add custom logic to the process and/or
   * to allow you to manipulate the path and update the href yourself. There is a method
   * "checkForUrlReassignmentAndUpdate" that checks this method and takes care of the window update itself
   * but it uses history replacement which does not update the router in Angular. If you need
   * to update the router you should call this directly and handle that yourself via a window.location.href update.
   *
   * @param userPbxSelection The users pbx selection
   */
  checkUpdateHref ( userPbxSelection: UserOrganizationSelection ) {
    const path = this.urlPathAssignment( userPbxSelection );
    return { shouldUpdate: !!path, path };
  }

  checkForUrlReassignmentAndUpdate ( userPbxSelection: UserOrganizationSelection ) {
    const hrefUpdateCheck = this.checkUpdateHref( userPbxSelection );
    if ( hrefUpdateCheck.shouldUpdate ) {
      history.replaceState( '', document.title, hrefUpdateCheck.path );
    }
  }
}

export function appInitServiceFactory ( jifService: JifService ) {
  return new AppInitService( jifService );
}

export const APP_INIT_SERVICE_PROVIDER: FactoryProvider = {
  provide: AppInitService,
  useFactory: appInitServiceFactory,
  deps: [ JifService ]
};
