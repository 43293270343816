import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { first, takeUntil } from 'rxjs/operators';
import { AppState } from '../../store/app-state.model';
import { InvoiceActions } from '../../services/invoice/redux/invoice.actions';
import { Download, Invoice, InvoiceDetail, InvoiceState } from '../../services/invoice/redux/invoice.model';
import { DateConverterService } from '../../services/date-converter/date-converter.service';
import { PaymentMethod } from '../../services/payment-method/redux/payment-method.model';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { PaymentMethodService } from '../../services/payment-method/payment-method.service';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { MoneyConverterService } from '../../services/money-converter/money-converter.service';
import { CommonPageSettingService } from '../../services/common-page-setting/common-page-setting.service';
import { COUNTRY_SELECTOR, CURRENCY_SELECTOR, HAS_PAY_ACCESS_SELECTOR, INVOICE_CSV_DOWNLOAD_SELECTOR, INVOICE_GET_IN_PROGRESS_SELECTOR, INVOICE_LATEST_PAYMENT_METHOD_ID_GET_IN_PROGRESS_SELECTOR, INVOICE_MEXICO_PDF_DOWNLOAD_SELECTOR, INVOICE_MEXICO_XML_DOWNLOAD_SELECTOR, INVOICE_PDF_DOWNLOAD_SELECTOR, INVOICE_STATE_SELECTOR, ORGANIZATION_ID_SELECTOR, PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR, PAYMENT_METHOD_LIST_SELECTOR, SELECTED_LANGUAGE_SELECTOR } from '../../store/helper';
import { BRAZIL, Country, MEXICO } from '../../services/location-converter/assets/model';
import { ModalActions } from '../../services/modal/redux/modal.actions';
import { BR_CURRENCY, MX_CURRENCY } from '../../services/money-converter/money-converter.model';
import { Language } from '../../services/language-selector/redux/language-selector.model';

@Component( {
  selector: 'bp-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: [ './invoice-details.component.scss' ]
} )
export class InvoiceDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( INVOICE_STATE_SELECTOR ) invoiceState$: Observable<InvoiceState>;
  @select( INVOICE_GET_IN_PROGRESS_SELECTOR ) invoiceGetInProgress$: Observable<boolean>;
  @select( INVOICE_LATEST_PAYMENT_METHOD_ID_GET_IN_PROGRESS_SELECTOR ) invoiceGetLatestPaymentMethodIdGetInProgress$: Observable<boolean>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( PAYMENT_METHOD_LIST_SELECTOR ) paymentMethodList$: Observable<PaymentMethod[]>;
  @select( PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR ) paymentMethodGetInProgress$: Observable<boolean>;
  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;
  @select( COUNTRY_SELECTOR ) country$: Observable<Country>;
  @select( HAS_PAY_ACCESS_SELECTOR ) hasPayAccess$: Observable<boolean>;
  @select( INVOICE_PDF_DOWNLOAD_SELECTOR ) pdfDownload$: Observable<Download>;
  @select( INVOICE_CSV_DOWNLOAD_SELECTOR ) csvDownload$: Observable<Download>;
  @select( INVOICE_MEXICO_PDF_DOWNLOAD_SELECTOR ) mexicoPdfDownload$: Observable<Download>;
  @select( INVOICE_MEXICO_XML_DOWNLOAD_SELECTOR ) mexicoXmlDownload$: Observable<Download>;

  unsubscribe$: Subject<boolean> = new Subject();

  initialLoadingInProgress = true;

  invoiceLoadingInProgress = true;
  latestPaymentMethodIdLoadingInProgress = true;
  paymentMethodListLoadingInProgress = true;

  latestPaymentMethodId: string;
  usedPaymentMethod: PaymentMethod;
  language: string;
  invoiceId: string;
  isInvoice: boolean;

  showFileDownload: boolean;
  showMexicoFilesDownload: boolean;
  boletoLink = '';
  notaServicoLink = '';
  notaTelecomLink = '';
  isBrazilCountry: boolean = false;

  lineItemsGroupedByBillingGroup: any;
  billingGroups: string[];

  showPayNowButton = false;
  showPayNow = false;

  currencyError = false;
  paymentMethodError = false;
  invoiceError = false;

  showMexicoFolioNumber = false;

  billingGroupInvoiceLineClassOdd = 'invoice-details-line-odd';
  billingGroupInvoiceLineClassEven = 'invoice-details-line-even';

  defaultBillingGroup = 'Primary';

  constructor (
    private route: ActivatedRoute,
    private reduxStore: NgRedux<AppState>,
    private invoiceActions: InvoiceActions,
    private paymentMethodActions: PaymentMethodActions,
    private modalActions: ModalActions,
    private moneyConverterService: MoneyConverterService,
    private dateConverterService: DateConverterService,
    private paymentMethodService: PaymentMethodService,
    private invoiceService: InvoiceService,
    private cdRef: ChangeDetectorRef,
    private commonPageSettingService: CommonPageSettingService
  ) { }

  ngOnInit () {
    this.reduxStore.dispatch( this.invoiceActions.resetInvoice() );
    this.reduxStore.dispatch( this.paymentMethodActions.resetPaymentMethod() );

    this.currency$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( currency ) => {
      this.currencyError = currency === undefined;
    } );

    this.invoiceGetInProgress$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( loading ) => {
      this.invoiceLoadingInProgress = loading;
    } );

    this.invoiceGetLatestPaymentMethodIdGetInProgress$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( loading ) => {
      this.latestPaymentMethodIdLoadingInProgress = loading;
    } );

    this.paymentMethodGetInProgress$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( loading ) => {
      this.paymentMethodListLoadingInProgress = loading;
    } );

    combineLatest( [
      this.route.params,
      this.organizationId$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ param, orgId ] ) => {
      this.invoiceId = param.invoiceId;

      this.reduxStore.dispatch( this.invoiceActions.getInvoice( orgId, param.invoiceId ) );
      this.reduxStore.dispatch(
        this.paymentMethodActions.getPaymentMethodList( orgId )
      );
    } );

    combineLatest( [
      this.paymentMethodList$,
      this.invoiceState$,
      this.country$,
      this.currency$,
      this.hasPayAccess$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ list, invoiceState, country, currency, hasPayAccess ] ) => {
      this.paymentMethodError = list === undefined;
      this.invoiceError = invoiceState.invoice === undefined;

      if ( list && invoiceState ) {
        list.forEach( ( paymentMethod ) => {
          if ( paymentMethod.id === invoiceState.latestPaymentMethodId ) {
            this.usedPaymentMethod = paymentMethod;
          }
        } );
      }

      if ( invoiceState && invoiceState.invoice ) {
        this.lineItemsGroupedByBillingGroup = this.groupLineItemsByBillingGroup( invoiceState.invoice.invoiceDetails );
        this.billingGroups = this.prepBillingGroups();
        this.setFileActionButtonVisibility( invoiceState.invoice );
      }
      this.setIsInvoice( invoiceState );
      this.setShowPayNowButton( country, currency, hasPayAccess );

      if ( country && invoiceState && invoiceState.invoice ) {
        this.SetShowMexicoFolioNumber( country, invoiceState.invoice.mexicoFolioNumber );
      }

      if ( country ) {
        this.setIsBrazilCountry( country );
      }

    } );
  }

  ngAfterViewChecked () {
    this.cdRef.detectChanges();
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  setShowPayNowButton ( country: Country, currency: string, hasPayAccess: boolean ) {
    this.showPayNowButton = currency && country &&
      this.commonPageSettingService.canPayOnPortal( currency, hasPayAccess ) &&
      this.isInvoice;
  }

  readyToShow () {
    const pageIsLoading = this.invoiceLoadingInProgress ||
      this.latestPaymentMethodIdLoadingInProgress ||
      this.paymentMethodListLoadingInProgress;

    if ( !pageIsLoading && this.initialLoadingInProgress ) {
      this.initialLoadingInProgress = false;
    }

    if ( !this.initialLoadingInProgress ) {
      return true;
    }
    return !pageIsLoading;
  }

  showError () {
    return this.readyToShow() &&
      ( this.currencyError || this.invoiceError || this.paymentMethodError );
  }

  setIsInvoice ( invoiceState: InvoiceState ) {
    if ( invoiceState && invoiceState.invoice ) {
      this.isInvoice = this.commonPageSettingService.isInvoice( invoiceState.invoice );
    } else {
      this.isInvoice = false;
    }
  }

  setFileActionButtonVisibility ( invoice: Invoice ) {
    this.showFileDownload = false;
    this.showMexicoFilesDownload = false;
    this.boletoLink = null;
    this.notaServicoLink = null;
    this.notaTelecomLink = null;
    if ( invoice.currencyUnit === BR_CURRENCY ) {
      this.boletoLink = invoice.boletoLink;
      this.notaServicoLink = invoice.notaServicoLink;
      this.notaTelecomLink = invoice.notaTelecomLink;
      this.showFileDownload = invoice.pdfExists;
    } else if ( invoice.currencyUnit === MX_CURRENCY ) {
      this.showMexicoFilesDownload = invoice.mexicoFilesExist;
    } else {
      this.showFileDownload = invoice.pdfExists;
    }
  }

  getSpelledDate ( date, language: Language ) {
    if ( date ) {
      return this.dateConverterService.getTextualLocalizedDate( date, language.locale );
    }
  }

  cardPaymentMethod ( paymentMethodType: string ): boolean {
    return this.paymentMethodService.cardPaymentMethod( paymentMethodType );
  }

  achPaymentMethod ( paymentMethodType: string ): boolean {
    return this.paymentMethodService.achPaymentMethod( paymentMethodType );
  }

  getStatus ( invoice: Invoice ) {
    return this.invoiceService.getInvoiceStatus( invoice );
  }

  getStatusColor ( invoice: Invoice ) {
    return this.invoiceService.getInvoiceStatusColor( invoice );
  }

  getMoneyFormat ( currency: string, amount: number, forceTwoDecimals: boolean, language: Language ) {
    return this.moneyConverterService.getMoneyDisplayAmountWithCurrency(
      currency, amount, forceTwoDecimals, language.locale, true );
  }

  groupLineItemsByBillingGroup ( lineItems: Array<InvoiceDetail> ) {
    const groupedItems = {};
    lineItems.forEach( item => {
      if ( !item.billingGroup ) {
        // Newer invoices should not have any line items which are missing billing group.
        // This is here to handle older invoices.
        item.billingGroup = this.defaultBillingGroup;
      }

      if ( groupedItems.hasOwnProperty( item.billingGroup ) ) {
        groupedItems[ item.billingGroup ].push( item );
      } else {
        groupedItems[ item.billingGroup ] = [ item ];
      }
    } );

    return groupedItems;
  }

  prepBillingGroups () {
    const list = Object.keys( this.lineItemsGroupedByBillingGroup );

    // Sort alphabetically
    list.sort();

    // Old invoices have 'Master' and newer invoices have defaultBillingGroup.
    // In order to handle both cases, we put both master and primary at the end.

    if ( list.includes( 'Master' ) ) {
      // move old defaultBillingGroup: master billing group to last
      list.push( list.splice( list.indexOf( 'Master' ), 1 )[ 0 ] );
    }

    if ( list.includes( this.defaultBillingGroup ) ) {
      // move defaultBillingGroup to last
      list.push( list.splice( list.indexOf( this.defaultBillingGroup ), 1 )[ 0 ] );
    }

    return list;
  }

  getSubtotalForBillingGroup ( group: string ) {
    let subtotal = 0;
    this.lineItemsGroupedByBillingGroup[ group ].forEach( item => {
      subtotal += Number( item.totalAmount );
    } );

    return subtotal;
  }

  getBillingGroupLineClass ( index: number ) {
    // the first line has the index of 0
    if ( index % 2 === 0 ) {
      return this.billingGroupInvoiceLineClassOdd;
    }
    return this.billingGroupInvoiceLineClassEven;
  }

  setShowPayModal ( value: boolean ) {
    this.showPayNow = value;
    this.reduxStore.dispatch( this.modalActions.setModal( value ) );

    if ( value === false ) {
      document.getElementById( 'invoiceDetailsPayNowButton' ).focus();
    }
  }

  getLineItemDescription ( item: InvoiceDetail, language: Language ) {
    let lineDescription = item.description;

    if ( !this.isLanguageEnglish( language ) && item.translatedDescription ) {
      lineDescription = item.translatedDescription;
    }

    if ( item.additionalDescription ) {
      lineDescription = lineDescription.concat( ` - ${ item.additionalDescription }` );
    }

    return lineDescription;
  }

  isLanguageEnglish ( language: Language ) {
    const languageArray = language.locale.split( '-' );
    return 'en' === languageArray[ 0 ];
  }

  SetShowMexicoFolioNumber ( country: Country, mexicoFolioNumber: string ) {
    this.showMexicoFolioNumber = mexicoFolioNumber && country === MEXICO;
  }

  setIsBrazilCountry ( country: Country ) {
    this.isBrazilCountry = country === BRAZIL;
  }

  downloadPdf () {
    this.organizationId$.pipe(
      first()
    ).subscribe( ( orgId ) => {
      this.reduxStore.dispatch( this.invoiceActions.downloadPdf( orgId, this.invoiceId ) );
    } );
  }

  downloadCsv () {
    this.organizationId$.pipe(
      first()
    ).subscribe( ( orgId ) => {
      this.reduxStore.dispatch( this.invoiceActions.downloadCsv( orgId, this.invoiceId ) );
    } );
  }

  openBoleto () {
    window.open( this.boletoLink, '_blank' );
  }

  openNotaServico () {
    window.open( this.notaServicoLink, '_blank' );
  }

  openNotaTelecom () {
    window.open( this.notaTelecomLink, '_blank' );
  }

  downloadMexicoFiles () {
    combineLatest( [
      this.invoiceState$,
      this.organizationId$
    ] ).pipe(
      first()
    ).subscribe( ( [ invoiceState, organizationId ] ) => {
      const fileName = ( invoiceState.invoice.mexicoFolioNumber ) ? invoiceState.invoice.mexicoFolioNumber : this.invoiceId;
      this.reduxStore.dispatch( this.invoiceActions.downloadMexicoFiles( organizationId, this.invoiceId, fileName ) );
    } );
  }
}
