import { Selector } from '@angular-redux/store';

// organization state
export const ORGANIZATION_STATE_SELECTOR = [ 'organization' ] as Selector<any, any>;
export const ORGANIZATION_SELECTOR = [ 'organization', 'selectedOrganization', ] as Selector<any, any>;
export const COUNTRY_SELECTOR = [ 'organization', 'selectedOrganization', 'country' ] as Selector<any, any>;
export const CURRENCY_SELECTOR = [ 'organization', 'selectedOrganization', 'currency' ] as Selector<any, any>;
export const CUSTOMER_NUMBER_SELECTOR = [ 'organization', 'selectedOrganization', 'customerNumber' ] as Selector<any, any>;
export const ORGANIZATION_ID_SELECTOR = [ 'organization', 'selectedOrganization', 'id' ] as Selector<any, any>;
export const ORGANIZATION_NAME_SELECTOR = [ 'organization', 'selectedOrganization', 'name' ] as Selector<any, any>;
export const ORGANIZATION_DOMAIN_SELECTOR = [ 'organization', 'selectedOrganization', 'domain' ] as Selector<any, any>;
export const HAS_PAY_ACCESS_SELECTOR = [ 'organization', 'selectedOrganization', 'payAccess' ] as Selector<any, any>;
export const TOTAL_DUE_SELECTOR = [ 'organization', 'totalDue' ] as Selector<any, any>;
export const TOTAL_DUE_GET_IN_PROGRESS_SELECTOR = [ 'organization', 'getTotalDueInProgress' ] as Selector<any, any>;

// payment method state
export const PAYMENT_METHOD_STATE_SELECTOR = [ 'paymentMethod' ] as Selector<any, any>;
export const PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR = [ 'paymentMethod', 'postInProgress' ] as Selector<any, any>;
export const PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR = [ 'paymentMethod', 'getInProgress' ] as Selector<any, any>;
export const PAYMENT_METHOD_POST_ERROR_SELECTOR = [ 'paymentMethod', 'postError' ] as Selector<any, any>;
export const PAYMENT_METHOD_POST_STATUS_SELECTOR = [ 'paymentMethod', 'postStatus' ] as Selector<any, any>;
export const PAYMENT_METHOD_LIST_SELECTOR = [ 'paymentMethod', 'list' ] as Selector<any, any>;
export const PAYMENT_METHOD_DELETE_SELECTOR = [ 'paymentMethod', 'delete' ] as Selector<any, any>;
export const DEFAULT_PAYMENT_METHOD_SELECTOR = [ 'paymentMethod', 'default' ] as Selector<any, any>;
export const PAYMENT_METHOD_POST_SUCCESS_SELECTOR = [ 'paymentMethod', 'postSuccess' ] as Selector<any, any>;

// autopay state
export const AUTOPAY_STATE_SELECTOR = [ 'autopay' ] as Selector<any, any>;
export const AUTOPAY_ENROLLED_SELECTOR = [ 'autopay', 'enrolled' ] as Selector<any, any>;
export const AUTOPAY_POST_IN_PROGRESS_SELECTOR = [ 'autopay', 'postInProgress' ] as Selector<any, any>;

// billing email state
export const BILLING_EMAIL_STATE_SELECTOR = [ 'billingEmail' ] as Selector<any, any>;
export const STORED_BILLING_EMAIL_SELECTOR = [ 'billingEmail', 'stored' ] as Selector<any, any>;
export const BILLING_EMAIL_GET_IN_PROGRESS_SELECTOR = [ 'billingEmail', 'getInProgress' ] as Selector<any, any>;
export const BILLING_EMAIL_POST_IN_PROGRESS_SELECTOR = [ 'billingEmail', 'postInProgress' ] as Selector<any, any>;

// flex form state
export const FLEX_FORM_STATE_SELECTOR = [ 'flexForm' ] as Selector<any, any>;

// language state
export const SELECTED_LANGUAGE_SELECTOR = [ 'language', 'selectedLanguage' ] as Selector<any, any>;
export const SELECTED_LANGUAGE_LOCALE_SELECTOR = [ 'language', 'selectedLanguage', 'locale' ] as Selector<any, any>;

// call details state
export const CALL_DETAILS_STATE_SELECTOR = [ 'callDetails' ] as Selector<any, any>;

// sms details state
export const SMS_DETAILS_STATE_SELECTOR = [ 'smsDetails' ] as Selector<any, any>;

// invoice state
export const INVOICE_STATE_SELECTOR = [ 'invoice' ] as Selector<any, any>;
export const INVOICE_SELECTOR = [ 'invoice', 'invoice' ] as Selector<any, any>;
export const INVOICE_GET_IN_PROGRESS_SELECTOR = [ 'invoice', 'getInProgress' ] as Selector<any, any>;
export const INVOICE_LATEST_PAYMENT_METHOD_ID_GET_IN_PROGRESS_SELECTOR = [ 'invoice', 'latestPaymentMethodIdGetInProgress' ] as Selector<any, any>;
export const INVOICE_PDF_DOWNLOAD_SELECTOR = [ 'invoice', 'pdfDownload' ] as Selector<any, any>;
export const INVOICE_CSV_DOWNLOAD_SELECTOR = [ 'invoice', 'csvDownload' ] as Selector<any, any>;
export const INVOICE_MEXICO_PDF_DOWNLOAD_SELECTOR = [ 'invoice', 'mexicoPdfDownload' ] as Selector<any, any>;
export const INVOICE_MEXICO_XML_DOWNLOAD_SELECTOR = [ 'invoice', 'mexicoXmlDownload' ] as Selector<any, any>;

// invoice list state
export const INVOICE_LIST_STATE_SELECTOR = [ 'invoiceList' ] as Selector<any, any>;

// mobile state
export const IS_MOBILE_SELECTOR = [ 'mobile', 'isMobile' ] as Selector<any, any>;

// organization list state
export const ORGANIZATION_LIST_SELECTOR = [ 'organizationList', 'items' ] as Selector<any, any>;

// receipt email state
export const RECEIPT_EMAIL_SELECTOR = [ 'receiptEmail' ] as Selector<any, any>;
export const STORED_RECEIPT_EMAIL_VALUE_SELECTOR = [ 'receiptEmail', 'email' ] as Selector<any, any>;

// payment state
export const PAYMENT_STATE_SELECTOR = [ 'payment' ] as Selector<any, any>;
export const PAYMENT_METHOD_TYPE_SELECTOR = [ 'payment', 'paymentMethodType' ] as Selector<any, any>;

// copas state
export const COPAS_STATE_SELECTOR = [ 'copas' ] as Selector<any, any>;

// modal state
export const MODAL_VISIBLE_SELECTOR = [ 'modal', 'visible' ] as Selector<any, any>;

// cardinal state
export const CARDINAL_STATE_SELECTOR = [ 'cardinal' ] as Selector<any, any>;
export const CARDINAL_SCA_TOKEN = [ 'cardinal', 'scaToken' ] as Selector<any, any>;
export const CARDINAL_SCA_REQUIREMENT_SELECTOR = [ 'cardinal', 'scaRequirement' ] as Selector<any, any>;

// CC generator state
export const CC_GENERATOR_STATE = [ 'ccGenerator' ] as Selector<any, any>;
