import {Injectable} from '@angular/core';
import {CopasActions} from './copas.actions';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {map, switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {OrganizationActions} from '../../organization/redux/organization.actions';

@Injectable( {
  providedIn: 'root'
} )
export class CopasEpic {
  constructor (
    private copasActions: CopasActions,
    private route: ActivatedRoute
  ) { }

  getEpics () {
    return combineEpics(
      this.evaluateAndSetCopas.bind( this )
    );
  }

  evaluateAndSetCopas ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(OrganizationActions.SET_ORGANIZATION_SELECTED_ORGANIZATION),
        switchMap( ( { payload: { selectedOrganization } } ) => {
          return this.route.queryParams
            .pipe(
              map( () => {
                if ( selectedOrganization !== undefined ) {
                  return this.copasActions.setCopas( selectedOrganization.country, selectedOrganization.country.useCopas );
                }
                return this.copasActions.setCopas( undefined, undefined );
              } )
            );
        } )
      );
  }
}
