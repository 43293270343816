import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Message } from './redux/message.model';
import { HttpResponseData } from '@jive/common/http';

@Injectable()
export class MessageService {
  constructor () {}

  getMessages (): Observable<HttpResponseData<Message[]>> {
    return of( {
      data: [ { key: 'MAINTENANCE_MESSAGE', expirationTimestamp: '2022-05-14T05:45:00Z' } as Message ]
    } as HttpResponseData );
  }
}
