import { Component, OnInit } from '@angular/core';
import { MODAL_VISIBLE_SELECTOR } from '../../store/helper';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';

@Component( {
  selector: 'bp-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: [ './billing-details.component.scss' ]
} )
export class BillingDetailsComponent implements OnInit {
  @select( MODAL_VISIBLE_SELECTOR ) modalVisible$: Observable<boolean>;

  constructor () { }

  ngOnInit () { }

}
