import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AutopayActions } from '../../services/autopay/redux/autopay.actions';
import { AppState } from '../../store/app-state.model';
import { AUTOPAY_ENROLLED_SELECTOR, AUTOPAY_POST_IN_PROGRESS_SELECTOR } from '../../store/helper';

/*
This component does not actually trigger the post request for the status change.
This is because of the desired UX workflow.
When user toggle, it updates the new desired state in the autopay state in redux
The parent component which uses this needs to check the desired state and
trigger post request if needed.

Things the parent component should do: dispatch getAutopayStatus and resetAutopayState action
*/

@Component( {
  selector: 'bp-autopay',
  templateUrl: './autopay.component.html',
  styleUrls: [ './autopay.component.scss' ]
} )
export class AutopayComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject();

  @Input() paymentInProgress: boolean;
  @select( AUTOPAY_ENROLLED_SELECTOR ) autopayEnrolled$: Observable<boolean>;
  @select( AUTOPAY_POST_IN_PROGRESS_SELECTOR ) autopayPostInProgress$: Observable<boolean>;

  color = 'primary';

  constructor (
    private autopayActions: AutopayActions,
    private reduxStore: NgRedux<AppState> ) { }

  ngOnInit () {

  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  updateDesiredValue ( event ) {
    this.reduxStore.dispatch(
      this.autopayActions.setAutopayDesiredNewState( event.checked ) );
  }
}
