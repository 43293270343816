import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {ReceiptEmailActions} from './receipt-email.actions';
import {ReceiptEmailService} from '../receipt-email.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {defer, EMPTY, of} from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class ReceiptEmailEpic {

  constructor ( private receiptEmailService: ReceiptEmailService,
    private receiptEmailActions: ReceiptEmailActions ) { }

  getEpics () {

    return combineEpics(
      this.getReceiptEmail.bind( this ),
      this.postReceiptEmail.bind( this ),
      this.getReceiptEmailAfterSuccessfulPostBeforePayment.bind( this ),
      this.postReceiptEmailThenPayment.bind( this ),
      this.setReceiptEmailErrorAfterPostFailed.bind( this )
    );
  }

  getReceiptEmail ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.GET_RECEIPT_EMAIL),
        switchMap( ( { payload: { orgId } } ) => {
          return this.receiptEmailService.getReceiptEmail( orgId )
            .pipe(
              map( ( email: any ) => {if ( email === null ) { return undefined; } else {return email; }} ),
              map( ( email: any ) => this.receiptEmailActions.setReceiptEmail( email ) ),
              catchError( () => of( this.receiptEmailActions.setReceiptEmail( undefined, false ) ) )
            );
        } )
      );
  }

  postReceiptEmail ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.POST_RECEIPT_EMAIL),
        switchMap( ( { payload: { state, orgId } } ) => {
          return defer( () => {
            if ( this.receiptEmailService.needsToUpdateReceiptEmail( state ) ) {
              return this.receiptEmailService.upsertEmail( orgId, state.desiredEmail );
            } else {
              return EMPTY;
            }
          } )
            .pipe(
              map( this.receiptEmailActions.postReceiptEmailComplete ),
              catchError( ( error: any ) => {
                return of( this.receiptEmailActions.setReceiptEmailPostError( error ) );
              } )
            );
        } )
      );
  }

  getReceiptEmailAfterSuccessfulPostBeforePayment ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.POST_RECEIPT_EMAIL_COMPLETE_READY_FOR_PAYMENT),
        map( ( { payload: { email } } ) => {
          return this.receiptEmailActions.postReceiptEmailComplete( email );
        } )
      );
  }

  postReceiptEmailThenPayment ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.POST_RECEIPT_EMAIL_THEN_PAYMENT),
        switchMap( ( { payload: { emailState, orgId, paymentState } } ) => {
          return defer( () => {
            if ( this.receiptEmailService.needsToUpdateReceiptEmail( emailState ) ) {
              return this.receiptEmailService.upsertEmail( orgId, emailState.desiredEmail );
            } else {
              return EMPTY;
            }
          } ).pipe(
            map( ( email ) => this.receiptEmailActions.postReceiptEmailCompleteReadyForPayment(
              orgId,
              paymentState,
              email ) ),
            catchError( ( error: any ) => {
              return of( this.receiptEmailActions.postReceiptEmailFailedReadyForPayment( error, paymentState ) );
            } )
          );
        } )
      );
  }

  setReceiptEmailErrorAfterPostFailed ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(ReceiptEmailActions.POST_RECEIPT_EMAIL_FAILED_READY_FOR_PAYMENT),
        map( ( { payload: { error } } ) => this.receiptEmailActions.setReceiptEmailPostError( error ) )
      );
  }
}
