import { Action } from '../../../store/action.model';
import { AuthActions } from './auth.actions';
import { AuthState } from '@jive/core/authentication';

const INITIAL_STATE: AuthState = {
  authInfo: undefined,
  scopes: undefined
};

export function authReducer ( lastState: AuthState = INITIAL_STATE, action: Action<any> ): AuthState {
  switch ( action.type ) {
    case AuthActions.LOGIN:
      const authInfo = { ...action.payload.authInfo, jiveId: decodeURIComponent( action.payload.authInfo.jiveId ) };
      return { ...lastState, authInfo: authInfo, scopes: action.payload.scopes };
    case AuthActions.LOGOUT:
      return null;

    case AuthActions.SCOPES:
      return { ...lastState, scopes: action.payload };
    default:
      return lastState;
  }
}
