import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { InvoiceListState } from '../../services/invoice-list/redux/invoice-list.model';
import { CURRENCY_SELECTOR, INVOICE_LIST_STATE_SELECTOR, IS_MOBILE_SELECTOR, ORGANIZATION_DOMAIN_SELECTOR, ORGANIZATION_ID_SELECTOR, SELECTED_LANGUAGE_SELECTOR } from '../../store/helper';
import { NgRedux, select } from '@angular-redux/store';
import { takeUntil } from 'rxjs/operators';
import { InvoiceListActions } from '../../services/invoice-list/redux/invoice-list.actions';
import { AppState } from '../../store/app-state.model';
import { Invoice } from '../../services/invoice/redux/invoice.model';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { DateConverterService } from '../../services/date-converter/date-converter.service';
import { MoneyConverterService } from '../../services/money-converter/money-converter.service';
import { Language } from "../../services/language-selector/redux/language-selector.model";

@Component( {
  selector: 'bp-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: [ './invoice-list.component.scss' ]
} )
export class InvoiceListComponent implements OnInit, OnDestroy {
  @select( INVOICE_LIST_STATE_SELECTOR ) invoiceListState$: Observable<InvoiceListState>;
  @select( ORGANIZATION_DOMAIN_SELECTOR ) selectedDomain$: Observable<string>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( IS_MOBILE_SELECTOR ) isMobile$: Observable<boolean>;
  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;

  unsubscribe$: Subject<boolean> = new Subject();
  selectedDomain: string;
  initialized: boolean;
  showError = false;

  tableColumns = [
    'invoiceId',
    'dueDate',
    'dateIssued',
    'status',
    'amount',
    'details'
  ];

  constructor (
    private reduxStore: NgRedux<AppState>,
    private invoiceListActions: InvoiceListActions,
    private invoiceService: InvoiceService,
    private dateConverterService: DateConverterService,
    private moneyConverterService: MoneyConverterService
  ) { }

  ngOnInit () {

    this.selectedDomain$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( domain: string ) => {
      this.selectedDomain = domain;
    } );

    this.organizationId$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe(
      ( orgId: string ) => this.reduxStore.dispatch( this.invoiceListActions.getInvoiceList( orgId ) )
    );

    this.invoiceListState$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe(
      ( state: InvoiceListState ) => {
        if ( state.getInProgress === false ) {
          this.initialized = true;

          this.showError = state.list === undefined;
        }
      }
    );

  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  getStatus ( invoice: Invoice ) {
    return this.invoiceService.getInvoiceStatus( invoice );
  }

  getStatusColor ( invoice: Invoice ) {
    return this.invoiceService.getInvoiceStatusColor( invoice );
  }

  getSimpleDate ( date, language: Language ) {
    if ( date ) {
      return this.dateConverterService.getSimpleLocalizedDate( date, language.locale );
    }
  }

  getAmountWithCurrency ( currency: string, rawAmount: number, language: Language ): string {
    return this.moneyConverterService.getMoneyDisplayAmountWithCurrency(
      currency, rawAmount, true, language.locale, true );
  }
}
