import {Injectable} from '@angular/core';
import {PaymentMethodActions} from './payment-method.actions';
import {PaymentMethodService} from '../payment-method.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {of} from 'rxjs';
import {ERROR_MESSAGE_DELETE_PAYMENT_METHOD} from '../../error-response/error-response.model';
import {ErrorResponseService} from '../../error-response/error-response.service';
import {ScaPurpose} from '../../cardinal/redux/cardinal.model';
import {CcGeneratorActions} from '../../cc-generator/redux/cc-generator.actions';
import {PaymentMethodCreationResponseDto} from '../dto/payment-method-dto.model';

@Injectable( {
  providedIn: 'root'
} )
export class PaymentMethodEpic {

  constructor (
    private paymentMethodService: PaymentMethodService,
    private paymentMethodActions: PaymentMethodActions,
    private errorResponseService: ErrorResponseService,
    private ccGeneratorActions: CcGeneratorActions
  ) { }

  getEpics () {

    return combineEpics(
      this.postCardPaymentMethod.bind( this ),
      this.getPaymentMethods.bind( this ),
      this.resetCcGeneratorErrorAfterPostSuccess.bind( this ),
      this.postDeletePaymentMethod.bind( this ),
      this.setDeletePaymentMethodComplete.bind( this ),
      this.postDefaultPaymentMethodChange.bind( this ),
      this.setDefaultPaymentMethodChangeComplete.bind( this ),
      this.postStripePaymentMethod.bind( this ),
      this.postAchPaymentMethod.bind( this ),
      this.postCardPaymentMethodAfterSca.bind( this ),
      this.getListWhenPostCompleted.bind( this )
    );
  }

  getPaymentMethods ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.GET_PAYMENT_METHOD_LIST),
        switchMap( ( { payload: { orgId } } ) => {
            return this.paymentMethodService.getPaymentMethodList( orgId )
              .pipe(
                map( this.paymentMethodService.parsePaymentMethodListResponse ),
                map( this.paymentMethodActions.setPaymentMethodList ),
                catchError( () => of( this.paymentMethodActions.setPaymentMethodList( undefined ) ) )
              );
          }
        )
      );
  }

  postCardPaymentMethod ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_CARD),
        switchMap( ( { payload: { scaToken, orgId, address, card } } ) => {
          return this.paymentMethodService.addCard( scaToken, orgId, address, card )
            .pipe(
              map( ( paymentMethodCreationResponse: PaymentMethodCreationResponseDto ) => {
                // tslint:disable-next-line:max-line-length
                if ( 'payerAuthEnrollmentResult' in paymentMethodCreationResponse && paymentMethodCreationResponse?.payerAuthEnrollmentResult?.acsUrl ) {
                  return this.paymentMethodActions.postCopasPaymentMethodCompleteWithScaRequirement(
                    {
                      purpose: ScaPurpose.paymentMethod,
                      scaRequired: true,
                      paymentMethodCreationResponse: paymentMethodCreationResponse
                    }
                  );
                } else {
                  return this.paymentMethodActions.postPaymentMethodComplete( orgId );
                }
              } ),
              catchError( ( error: any ) =>
                of( this.paymentMethodActions.setPostPaymentMethodError( error ) ) )
            );
        } )
      );
  }

  postCardPaymentMethodAfterSca ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_CARD_AFTER_SCA),
        switchMap( ( { payload: { orgId, paymentMethodKey, createPaymentMethodScaChallengeRequest } } ) => {
          return this.paymentMethodService.addCardAfterSca( orgId, paymentMethodKey, createPaymentMethodScaChallengeRequest )
            .pipe(
              map( () => {
                return this.paymentMethodActions.postPaymentMethodComplete( orgId );
              } ),
              catchError( ( error: any ) =>
                of( this.paymentMethodActions.setPostPaymentMethodError( error ) ) )
            );
        } )
      );
  }

  postAchPaymentMethod ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_ACH),
        switchMap( ( { payload: { info } } ) => {
          return this.paymentMethodService.addAch( info )
            .pipe(
              map( () => this.paymentMethodActions.postPaymentMethodCompleteGetListRequired( info.orgId ) ),
              catchError( ( error: any ) => {
                return of( this.paymentMethodActions.setPostPaymentMethodError( error ) );
              } )
            );
        } )
      );
  }

  resetCcGeneratorErrorAfterPostSuccess ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE),
        map( () =>
          this.ccGeneratorActions.reset()
        )
      );
  }

  // delete
  postDeletePaymentMethod ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_DELETE_PAYMENT_METHOD),
        switchMap( ( { payload: { orgId, paymentMethodId } } ) => {
          return this.paymentMethodService.deletePaymentMethod( orgId, paymentMethodId )
            .pipe(
              map( () => this.paymentMethodActions.setDeletePaymentMethodComplete( orgId ) ),
              catchError( ( error: any ) => {
                const preppedError = this.errorResponseService.parseErrorResponse( error, ERROR_MESSAGE_DELETE_PAYMENT_METHOD );
                return of( this.paymentMethodActions.setDeletePaymentMethodError( preppedError ) );
              } )
            );
        } )
      );
  }

  setDeletePaymentMethodComplete ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.SET_DELETE_PAYMENT_METHOD_COMPLETED),
        map( ( { payload: { orgId } } ) =>
          this.paymentMethodActions.getPaymentMethodList( orgId )
        )
      );
  }

  // default
  postDefaultPaymentMethodChange ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_DEFAULT_PAYMENT_METHOD_CHANGE),
        switchMap( ( { payload: { orgId, paymentMethodId } } ) => {
          return this.paymentMethodService.changeDefaultPaymentMethod( orgId, paymentMethodId )
            .pipe(
              map( () => this.paymentMethodActions.setDefaultPaymentMethodChangeCompleted( orgId ) ),
              catchError( ( error: any ) => {
                return of( this.paymentMethodActions.setDefaultPaymentMethodChangeError( error ) );
              } )
            );
        } )
      );
  }

  setDefaultPaymentMethodChangeComplete ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.SET_DEFAULT_PAYMENT_METHOD_COMPLETED),
        map( ( { payload: { orgId } } ) =>
          this.paymentMethodActions.getPaymentMethodList( orgId )
        )
      );
  }

  postStripePaymentMethod ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_STRIPE),
        switchMap( ( { payload: { orgId, token } } ) => {
          return this.paymentMethodService.addStripe( orgId, token )
            .pipe(
              map( () => this.paymentMethodActions.postPaymentMethodCompleteGetListRequired( orgId ) ),
              catchError( ( error: any ) =>
                of( this.paymentMethodActions.setPostPaymentMethodError( error ) ) )
            );
        } )
      );
  }

  getListWhenPostCompleted ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE_GET_LIST_REQUIRED),
        map( ( { payload: { orgId } } ) =>
          this.paymentMethodActions.getPaymentMethodList( orgId )
        )
      );
  }
}
