<nav
  class="bp-sub-navbar"
  [attr.aria-hidden]="(modalVisible$|async)"
>
  <div class="nav-buttons">
    <a
      *ngIf="router.url !== '/org-select'"
      [attr.aria-label]="'NAV_BAR_INVOICE' | translate"
      [routerLink]="['/', selectedDomain, 'billing-details']"
      routerLinkActive="active"
      [innerHTML]="'NAV_BAR_INVOICE'| translate"
      class="nav-button">
      <!-- Invoices -->
    </a>
    <a
      *ngIf="router.url !== '/org-select' && showPaymentOptionTab"
      [attr.aria-label]="'NAV_BAR_PAYMENT' | translate"
      [routerLink]="['/', selectedDomain, 'payment-methods']"
      routerLinkActive="active"
      [innerHTML]="'NAV_BAR_PAYMENT'| translate"
      class="nav-button">
      <!-- Payment Options -->
    </a>
    <a
      *ngIf="router.url !== '/org-select'"
      [attr.aria-label]="'NAV_BAR_CALL_DETAIL' | translate"
      [routerLink]="['/', selectedDomain, 'call-details']"
      routerLinkActive="active"
      [innerHTML]="'NAV_BAR_CALL_DETAIL'| translate"
      class="nav-button">
      <!-- Billed Call Details -->
    </a>
    <a
      *ngIf="router.url !== '/org-select' &&  showSmsDetailsTab"
      [attr.aria-label]="'NAV_BAR_SMS_DETAIL'| translate"
      [routerLink]="['/', selectedDomain, 'sms-details']"
      routerLinkActive="active"
      [innerHTML]="'NAV_BAR_SMS_DETAIL'| translate"
      class="nav-button">
      <!-- Sms Call Details -->
    </a>
  </div>
  <!-- since showOrgSelector also gets controlled from bp-organization-selector (on blur),
    we need to show the same button without on click function when showOrgSelector is true
    to avoid any toggle war. -->

  <!-- Button 1 with on click function -->
  <button class="nav-button org-selector-toggle"
    (click)="setShowOrgSelector(true)"
    [attr.aria-label]="'NAV_BAR_ACCOUNT_ARIA' | translate"
    *ngIf="!showOrgSelector">

    <span [innerHTML]="'NAV_BAR_ACCOUNT'| translate"
      *ngIf="!showOrgSelector">
        <!-- Select Account -->
    </span>
    <mat-icon class="org-selector-toggle-arrow">
      keyboard_arrow_down
    </mat-icon>
  </button>

  <!-- Button 2 without on click function -->
  <button class="nav-button org-selector-toggle"
    *ngIf="showOrgSelector">
    <span [innerHTML]="'NAV_BAR_ACCOUNT'| translate"
      *ngIf="showOrgSelector">
        <!-- Select Account -->
    </span>
    <mat-icon class="org-selector-toggle-arrow">
      keyboard_arrow_down
    </mat-icon>
  </button>
</nav>
<bp-organization-selector
  *ngIf="showOrgSelector"
  class="selector"
  (showOrgSelector)='setShowOrgSelector($event)'
  role="search">
</bp-organization-selector>
