import { Injectable } from '@angular/core';
import { Organization } from './organization.model';

@Injectable()
export class OrganizationActions {

  static readonly SET_ORGANIZATION_SELECTED_ORGANIZATION = 'ORGANIZATION:SET_SELECTED_ORGANIZATION';
  static readonly SET_ORGANIZATION_TOTAL_DUE = 'ORGANIZATION:SET_TOTAL_DUE';
  static readonly GET_ORGANIZATION_TOTAL_DUE = 'ORGANIZATION:GET_TOTAL_DUE';
  static readonly SET_ORGANIZATION_ERROR = 'ORGANIZATION:ERROR';

  constructor () { }

  setOrganizationSelectedOrganization ( selectedOrganization: Organization ) {
    return { type: OrganizationActions.SET_ORGANIZATION_SELECTED_ORGANIZATION, payload: { selectedOrganization } };
  }

  getOrganizationTotalDue ( orgId: string ) {
    return { type: OrganizationActions.GET_ORGANIZATION_TOTAL_DUE, payload: { orgId } };
  }

  setOrganizationTotalDue ( totalDue: string ) {
    return { type: OrganizationActions.SET_ORGANIZATION_TOTAL_DUE, payload: { totalDue } };
  }

  setErrorAction ( error: any ) {
    return { type: OrganizationActions.SET_ORGANIZATION_ERROR, error: error };
  }
}
