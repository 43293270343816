import { UntypedFormGroup, Validators } from '@angular/forms';

export const matchingAchAccountNumber = ( group: UntypedFormGroup ) => {
  const account = group.get( 'accountNumber' ).value;
  const confirmAccount = group.get( 'confirmAccountNumber' ).value;

  if ( account !== confirmAccount ) {
    return {
      mismatchedAccountNumber: true
    };
  }
};

export const achFormSetting = {
  name: [ null, { validators: [ Validators.required ] } ],
  routingNumber: [
    null,
    {
      validators: [
        Validators.required,
        Validators.pattern( '^[0-9]*$' ),
        Validators.minLength( 9 ),
        Validators.maxLength( 9 )
      ]
    }
  ],
  accountNumber: [ null, { validators: [ Validators.required ] } ],
  confirmAccountNumber: [ null, { validators: [ Validators.required ] } ]
};

