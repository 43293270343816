<div class="receipt-email" role="region">
  <form [formGroup]="emailForm">
    <label class="input-title" for="email">
      <span [innerHTML]="'RECEIPT_EMAIL_LABEL'| translate" role="heading" aria-level="3"></span>
      <!-- Email receipt to -->
    </label>
    <jive-form-field>
      <input
        id="email"
        maxlength="256"
        formControlName="email"
        placeholder="{{'EMAIL_PLACEHOLDER'|translate}}"
        [attr.disabled]="(paymentInProgress || postInProgressEmailReceipt) ? '' : null"
        [class.error]="emailForm.errors">
      <!-- Enter email address -->
    </jive-form-field>
  </form>
  <div class="receipt-email-actions" *ngIf="showActionButtons">
    <button
      [attr.aria-label]="'RECEIPT_EMAIL_CANCEL_ARIA' | translate"
      class="jive-button jive-medium receipt-email-action-cancel"
      [innerHTML]="'CANCEL'| translate"
      (click)="resetEmailValue()"
      type="button">
      <!-- Cancel -->
    </button>
    <button
      *ngIf="!postInProgressEmailReceipt"
      [disabled]="!validateEmail(receiptEmailState$| async) ||
      (receiptEmailState$| async)?.desiredEmail === (receiptEmailState$| async)?.email"
      (click)="saveEmail()"
      class="jive-button jive-medium jive-primary payment-methods-email-save-button"
      [innerHTML]="'SAVE'| translate"
      [attr.aria-label]="'RECEIPT_EMAIL_SAVE_ARIA' | translate"
      type="submit">
      <!-- save -->
    </button>
    <button
      *ngIf="postInProgressEmailReceipt"
      disabled
      [attr.aria-label]="'SAVING' | translate"
      class="jive-button jive-medium jive-primary payment-methods-email-save-button"
      [innerHTML]="'SAVING'| translate"
      type="button">
      <!-- saving -->
    </button>
  </div>
</div>
