import { Country } from '../../location-converter/assets/model';

export interface CardinalState {
  scaToken: ScaToken;
  tokenExpiration: Date;
  generateInProgress: boolean;
  error: any;
  currency: string;
  country: Country;
  scaRequirement: ScaRequirement;
}

export interface ScaToken {
  tokenString: string;
  referenceId: string;
  isScaEnabledForMid: boolean;
}

export interface ScaTokenWithExpiration {
  scaToken: ScaToken;
  expiration: Date;
}

export interface ScaTokenRequest {
  currency: string;
  country: string;
}

export interface PayerAuthEnrollmentResult {
  acsUrl: string;
  authenticationTransactionId: string;
  paReq: string;
}

export interface PaymentMethodCreationResponse {
  paymentMethodKey: string;
  payerAuthEnrollmentResult?: PayerAuthEnrollmentResult;
}

export interface PaymentCreationResponse {
  paymentId?: string;
  transactionId: string;
  payerAuthEnrollmentResult?: PayerAuthEnrollmentResult;
}

export enum ScaPurpose {
  paymentMethod = 'Payment Method',
  payment = 'Payment'
}

export interface ScaRequirement {
  purpose: ScaPurpose;
  scaRequired: boolean;
  paymentMethodCreationResponse?: PaymentMethodCreationResponse;
  paymentCreationResponse?: PaymentCreationResponse;
}

export const INVALID_SCA_TOKEN_ERROR = 'INVALID_SCA_TOKEN_ERROR';
