import { Component, OnInit } from '@angular/core';

@Component( {
  selector: 'bp-error400',
  templateUrl: './error400.component.html',
  styleUrls: [ './error400.component.scss' ]
} )
export class Error400Component implements OnInit {

  constructor () { }

  ngOnInit () {
  }

}
