import { Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class CcGeneratorActions {
  static readonly RESET = 'CCG:RESET';
  static readonly SET_CC_GENERATOR_ERROR = 'CCG:SET_ERROR';

  constructor () { }

  setError ( errorMessageKey: string, errorCode: string ) {
    return { type: CcGeneratorActions.SET_CC_GENERATOR_ERROR, payload: { errorMessageKey, errorCode } };
  }

  reset () {
    return { type: CcGeneratorActions.RESET };
  }
}
