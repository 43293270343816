<div aria-labelledby="language-selector-button" role="region">
  <button mat-menu-item class="language-selector-button"
    id="language-selector-button"
    [attr.aria-label]="'LABEL_LANGUAGE_SELECTOR' | translate"
    type="button"
    [matMenuTriggerFor]="supportedLanguagesMenu">
    <span class="mat-icon material-icons flag-icon">
      <span [className]="'flag flag-'+(selectedLanguage$|async)?.locale"></span>
    </span>
    {{'LANGUAGE' | translate}}
  </button>
  <mat-menu #supportedLanguagesMenu="matMenu" overlapTrigger="false" role="menu">
    <button mat-menu-item
      role="menuitem"
      type="submit"
      *ngFor="let lang of supportedLanguages"
      [attr.aria-label]="lang.name"
      (click)="setSelectedLanguage(lang)">
      <jive-language-item [language]="lang"></jive-language-item>
    </button>
  </mat-menu>
</div>
