<div class="autopay" role="form" aria-labelledby="autopay-input-title">
  <span class="autopay-input">
    <span class="input-title"
      id="autopay-input-title"
      role="heading"
      aria-level="3"
      [attr.aria-label]="'AUTOPAY_INPUT_LABEL_ARIA' | translate"
      [innerHTML]="'AUTOPAY_INPUT_LABEL'| translate">
    </span>
    <mat-slide-toggle
      name="autopay-toggle"
      class="autopay-toggle"
      [checked]="(autopayEnrolled$ | async)"
      (change)="updateDesiredValue($event)"
      [color]="color"
      [disabled]="(autopayPostInProgress$|async) || paymentInProgress">
    </mat-slide-toggle>
  </span>
  <div [innerHTML]="'AUTOPAY_DESCRIPTION' | translate" class="autopay-description">
    <!-- Enabling autopay will prevent service disruptions.
    Once enabled, payment for the current amount due will be
    automatically processed on the 10th of each month. -->
  </div>
</div>
