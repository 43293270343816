import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { Observable } from 'rxjs';

@Injectable()
export class HttpClientOauthInterceptor implements HttpInterceptor {

  constructor ( private readonly reduxStore: NgRedux<AppState> ) { }

  intercept ( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    if ( this.reduxStore.getState().auth && this.reduxStore.getState().auth.authInfo ) {
      if ( !request.headers.has( 'Authorization' ) ) {
        const token = this.reduxStore.getState().auth.authInfo.token;
        request = request.clone( {
          headers: request.headers.append( 'Authorization', `Bearer ${ token }` )
        } );
      }
    }
    return next.handle( request );
  }
}
