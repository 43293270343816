import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgRedux, select } from '@angular-redux/store';
import { CALL_DETAILS_STATE_SELECTOR, ORGANIZATION_DOMAIN_SELECTOR, ORGANIZATION_ID_SELECTOR,
  ORGANIZATION_NAME_SELECTOR, ORGANIZATION_SELECTOR, SELECTED_LANGUAGE_LOCALE_SELECTOR } from '../../store/helper';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppState } from '../../store/app-state.model';
import { CallDetailsActions } from '../../services/call-details/redux/call-details.actions';
import { CallDetailsState } from '../../services/call-details/redux/call-details.model';
import { ManagedOrganization, Organization } from '../../services/organization/redux/organization.model';
import { Country } from '../../services/location-converter/assets/model';
import { MONTH_LIST } from '../../services/language-selector/redux/date-format.model';

@Component( {
  selector: 'bp-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: [ './call-details.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class CallDetailsComponent implements OnInit, OnDestroy {
  @select( CALL_DETAILS_STATE_SELECTOR ) callDetails$: Observable<CallDetailsState>;
  @select( ORGANIZATION_DOMAIN_SELECTOR ) domain$: Observable<string>;
  @select( ORGANIZATION_NAME_SELECTOR ) name$: Observable<string>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( ORGANIZATION_SELECTOR ) organization$: Observable<Organization>;
  @select( SELECTED_LANGUAGE_LOCALE_SELECTOR ) selectedLanguage$: Observable<string>;

  unsubscribe$: Subject<boolean> = new Subject();

  orgId: string;
  availableMonths: String[];
  availableYears: number[];
  organizationForm: UntypedFormGroup;
  form: UntypedFormGroup;
  downloadButtonKey = 'DOWNLOAD';
  errorMessageKey: string;
  loading: boolean;
  country: Country;
  getInProgress = false;
  language: string;
  showAccountSelector = false;
  accountOptions = [];
  defaultOrganization: ManagedOrganization;

  get formValue () { return this.form.value; }

  get formControls () { return this.form.controls; }

  constructor (
    private reduxStore: NgRedux<AppState>,
    private callDetailsActions: CallDetailsActions,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit (): void {

    combineLatest( [
      this.organizationId$,
      this.domain$,
      this.name$,
      this.organization$
    ] ).pipe(
      filter( ( [ orgId, domain, name, organization ] ) => !!orgId && !!domain && !!name && !!organization ),
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ orgId, domain, name, organization ] ) => {
      this.orgId = orgId;
      this.country = organization.country;

      this.defaultOrganization = {
        id: orgId,
        domain: domain,
        name: name
      };

      if ( organization?.managedOrganizations.length > 0 ) {
        // In case the default organization is already included in the managedOrganization, filter it out.
        const filteredManagedOrganizations = organization.managedOrganizations.filter(
          ( managedOrganization ) => managedOrganization.id !== this.defaultOrganization.id
        );

        // The default organization needs to be the first.
        this.accountOptions.push( this.defaultOrganization );
        // managedOrganization is sorted by name by bff.
        this.accountOptions.push( ...filteredManagedOrganizations );
        // Preselecting the default organization.
        this.organizationForm = this.formBuilder.group( {
          organization: [ this.accountOptions[ 0 ].id, { validators: [ Validators.required ] } ]
        } );

        this.showAccountSelector = true;
      }
    } );

    this.selectedLanguage$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe(
      ( selectedLanguage: string ) => this.language = selectedLanguage
    );

    this.callDetails$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe(
      ( state: CallDetailsState ) => {
        if ( state ) {
          if ( state.getInProgress === false ) {
            this.getInProgress = false;
            if ( state.callDetails ) {
              if ( !state.callDetails.data ) {
                this.errorMessageKey = 'ERROR_RETRIEVING_BILLED_CALL_DETAILS';
              }
            }
            this.reduxStore.dispatch( this.callDetailsActions.resetCallDetails() );
          } else if ( state.getInProgress === true ) {
            this.getInProgress = true;
            this.downloadButtonKey = 'DOWNLOADING';
            this.loading = true;
          } else {
            this.getInProgress = false;
            this.downloadButtonKey = 'DOWNLOAD';
            this.loading = false;
          }
        }
      }
    );

    this.formInit();

    this.availableMonths = MONTH_LIST;

    const today = new Date();
    this.availableYears = [];
    for ( let i = today.getFullYear(); i >= 2016; i-- ) {
      this.availableYears.push( i );
    }
  }

  ngOnDestroy (): void {
    this.unsubscribe$.next( true );
  }

  formInit () {
    this.form = this.formBuilder.group( {
      month: [ null, { validators: [ Validators.required ] } ],
      year: [ null, { validators: [ Validators.required ] } ]
    } );
  }

  /**
   * Returns the month number based on a month code. January is 1 and December is 12.
   * @param monthCode
   */
  translateMonth ( monthCode: string ) {
    return this.availableMonths.indexOf( monthCode ) + 1;
  }

  // Making sure that report month is in the past.
  isMonthValid () {
    const inputYear = this.formControls.year.value;
    const inputMonth = this.translateMonth( this.formControls.month.value );

    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    let isValid = true;

    if ( inputYear > year ) {
      isValid = false;
    } else if ( inputYear === year && inputMonth >= month ) {
      isValid = false;
    }

    return isValid;
  }

  isFormValid () {
    if ( !this.formControls.month.value || !this.formControls.year.value ) {
      return false;
    }

    return this.isMonthValid();
  }

  downloadFile () {
    const selectedOrganizationId = this.organizationForm?.value?.organization
      ? this.organizationForm.value.organization
      : this.defaultOrganization.id;
    this.reduxStore.dispatch( this.callDetailsActions.getCallDetails(
      selectedOrganizationId,
      this.formControls.year.value,
      this.translateMonth( this.formControls.month.value ),
      this.country,
      this.language )
    );
  }

  resetErrorMessage () {
    this.errorMessageKey = undefined;
  }

  closeErrorModal () {
    this.resetErrorMessage();
  }
}
