import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CcGeneratorActions } from './redux/cc-generator.actions';
import { CcGeneratorService } from './cc-generator.service';

@NgModule( {
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CcGeneratorActions,
    CcGeneratorService
  ]
} )
export class CcGeneratorModule {}
