import { Injectable } from '@angular/core';
import { CURRENCY_SYMBOLS } from './money-converter.model';
import { Language } from '../language-selector/redux/language-selector.model';

@Injectable( { providedIn: 'root' } )
export class MoneyConverterService {

  constructor () { }

  truncateLastZeroDigit ( originalDigits: string ): string {
    const digitArray = originalDigits.split( '' );
    const digitCount = digitArray.length;

    for ( let i = digitCount; i--; ) {
      if ( i > 1 && digitArray[ i ] === '0' ) {
        digitArray.splice( i, 1 );
      } else {
        break;
      }
    }
    return digitArray.join( '' );
  }

  formatDecimals ( numberToFormat: number, forceTwoDecimals: boolean ): string {
    if ( forceTwoDecimals ) {
      numberToFormat = Math.round( numberToFormat * 100 ) / 100;
    }

    const decimals = numberToFormat.toString().split( '.' )[ 1 ];
    let decimalsToReturn;

    if ( decimals ) {
      const decimalsCount = decimals.length;
      // if there are 4 or more decimal digits, we truncate to 4
      if ( decimalsCount >= 4 ) {
        const truncatedDecimals = decimals.slice( 0, 4 );
        decimalsToReturn = this.truncateLastZeroDigit( truncatedDecimals );
      } else if ( decimalsCount === 3 ) {
        decimalsToReturn = this.truncateLastZeroDigit( decimals );
      } else if ( decimalsCount === 2 ) {
        decimalsToReturn = decimals;
      } else if ( decimalsCount === 1 ) {
        decimalsToReturn = decimals + '0';
      } else {
        // if there is no digits
        decimalsToReturn = '00';
      }
    } else {
      decimalsToReturn = '00';
    }

    return decimalsToReturn;
  }

  formatMoney ( number: number, forceTwoDecimals: boolean, thousandSeparator: string, decimalSeparator: string ): string {
    if ( number ) {
      const decimals = this.formatDecimals( number, forceTwoDecimals );
      let numbers = number.toString().split( '.' )[ 0 ] ? number.toString().split( '.' )[ 0 ] : '0';
      numbers = numbers.replace( /(\d)(?=(\d{3})+$)/g, `$1${ thousandSeparator }` );
      return numbers + decimalSeparator + decimals;
    } else {
      return `0${ decimalSeparator }00`;
    }
  }

  isAmountNegative ( stringToBeTested: string ): boolean {
    const negativeAmountTest = /^-/;
    return negativeAmountTest.test( stringToBeTested );
  }

  getSymbol ( currency ) {
    let symbol = '';

    CURRENCY_SYMBOLS.forEach( ( currencyAndSymbol ) => {
      if ( currencyAndSymbol.currency === currency ) {
        symbol = currencyAndSymbol.symbol;
      }
    } );

    return symbol;
  }

  appendCurrencySymbol ( amount: string, symbol: string, prepend: boolean ): string {
    if ( prepend ) {
      return `${ symbol }${ amount }`;
    } else {
      return `${ amount } ${ symbol }`;
    }
  }

  getAmountWithCurrencySymbol (
    currency: string,
    amount: string,
    language: Language ): string {
    const symbol = this.getSymbol( currency );

    return this.appendCurrencySymbol( amount, symbol, language.prependCurrencySymbol );
  }

  getMoneyDisplayAmountWithCurrency (
    currency: string,
    numberToBeFormatted: number,
    forceTwoDecimals: boolean,
    locale: string,
    includeCurrencySymbol: boolean ) {
    const language = Language.instanceFromString( locale );
    let amount = this.formatMoney( numberToBeFormatted, forceTwoDecimals, language.thousandGroupingSeparator, language.decimalSeparator );

    if ( includeCurrencySymbol ) {
      if ( this.isAmountNegative( amount ) ) {
        amount = amount.slice( 1 );
        return '-' + this.getAmountWithCurrencySymbol( currency, amount, language );
      } else {
        return this.getAmountWithCurrencySymbol( currency, amount, language );
      }
    } else {
      return amount;
    }
  }
}
