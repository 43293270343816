import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {ListControls} from '../listControls/listControls';

@Directive({
  selector: '[jiveListControls]'
})
export class ListControlsDirective extends ListControls
    implements AfterViewInit, OnInit, OnDestroy {


  private domControlsIntervalId: number;
  @Input() queryCallback: (query: string) => number;
  @Output() onItemSelected: EventEmitter<number> = new EventEmitter();

  constructor(
      protected elRef: ElementRef,
      protected renderer: Renderer2
  ) {
    super(elRef.nativeElement, renderer);
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    // This interval setup is not ideal, however, for async data we have to wait until the first
    // dom element is present before we can hook up list controls, this interval guarantees that the controls
    // are always there regardless of static or async data
    this.domControlsIntervalId = window.setInterval(() => {
      const hookedUp = this.hookupControlsOnDom();
      if (hookedUp) {
        window.clearInterval(this.domControlsIntervalId);
        this.domControlsIntervalId = null;
      }
    }, 100);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.domControlsIntervalId) {
      window.clearInterval(this.domControlsIntervalId);
    }
  }

}
