import { Component, Input, OnInit } from '@angular/core';
import { SupportedLanguage } from '@jive/common/i18n';
import { LanguageSelectorService } from './languageSelector.service';

@Component({
  selector: 'jive-language-item',
  templateUrl: './language-item.component.html',
  styleUrls: ['./language-item.component.scss']
})
export class LanguageItemComponent implements OnInit {

  @Input() language: SupportedLanguage;
  @Input() selected: boolean;

  constructor() {
  }

  ngOnInit () {}

  getSelectedLanguageRegionCode(code: string) {
    return LanguageSelectorService.getSelectedLanguageRegionCode(code);
  }

}
