import { Injectable } from '@angular/core';
import { Message, MessageState } from './message.model';
import { Action } from '../../../store/action.model';

@Injectable()
export class MessageActions {
  static SET_COMPLETE = 'MESSAGE:SET_COMPLETE';
  static SET_ERROR = 'MESSAGE:SET_ERROR';

  constructor () {}

  setMessagesAction ( messages: Message[] ): Action<Partial<MessageState>> {
    return { type: MessageActions.SET_COMPLETE, payload: { messages: messages } };
  }

  setErrorAction ( error: any ): Action<Partial<MessageState>> {
    return {
      type: MessageActions.SET_ERROR,
      payload: { error }
    };
  }
}
