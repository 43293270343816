import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FlexKey, FlexKeyCreateRequest } from './redux/flex-form.model';
import { HttpClient } from '@angular/common/http';

@Injectable( {
  providedIn: 'root'
} )

export class FlexFormService {

  constructor ( private http: HttpClient ) { }

  getFlexKey ( currency: string, country: string ) {
    const path = `${ environment.billingPortalBffUrl }/flex-keys`;

    const flexKeyCreateRequest: FlexKeyCreateRequest = {
      currency: currency,
      country: country,
      targetOrigin: environment.portalUrl
    };

    return this.http.post<FlexKey>( path, flexKeyCreateRequest );
  }
}
