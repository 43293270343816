import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if ( environment.environment === 'prod' ) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule( AppModule )
  .catch( err => console.log( err ) );

const songbirdScript = document.createElement( 'script' );
songbirdScript.type = 'text/javascript';
songbirdScript.src = environment.environment === 'prod' ?
  'https://songbird.cardinalcommerce.com/edge/v1/songbird.js' :
  'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js';
document.getElementsByTagName( 'head' )[ 0 ].appendChild( songbirdScript );

const flexMicroformScript = document.createElement( 'script' );
flexMicroformScript.type = 'text/javascript';
flexMicroformScript.src = environment.environment === 'prod' ?
  'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js' :
  'https://testflex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js';
document.getElementsByTagName( 'head' )[ 0 ].appendChild( flexMicroformScript );
