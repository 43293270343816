<div class="payment-confirmation" role="dialog">
  <div class="component-title" [innerHTML]="'PAYMENT_CONFIRMATION_TITLE'| translate">
    <!-- Payment Confirmation -->
  </div>
  <div class="payment-confirmtation-content">
    <div [innerHTML]="'PAYMENT_CONFIRMATION_SUCCESS'| translate" class="payment-confirmtation-content-row">
      <!-- Thank you! Payment was submitted. -->
    </div>
    <div *ngIf="(paymentMethodType$ | async) === 'ach' "
      class="payment-confirmtation-content-row"
      [innerHTML]="'PAYMENT_CONFIRMATION_SUCCESS_ACH'| translate">
      <!-- It may take 2-3 business days for the payment to finalize. -->
    </div>

    <div *ngIf="(autopay$|async)?.postSuccess"
      class="payment-confirmtation-content-row"
      [innerHTML]="'PAYMENT_CONFIRMATION_SUCCESS_AUTOPAY'| translate">
      <!-- Autopay has been turned ON. Payment for the current amount due will 
      be automatically processed around the 10th of each month. -->
    </div>

    <div *ngIf="(autopay$|async)?.postSuccess === false"
      class="payment-confirmtation-content-row"
      [innerHTML]="'PAYMENT_CONFIRMATION_ERROR_AUTOPAY'| translate">
      <!-- Error updating autopay status. -->
    </div>

    <div *ngIf="(receiptEmail$|async)?.postSuccess" class="payment-confirmtation-content-row">
      <span [innerHTML]="'PAYMENT_CONFIRMATION_SUCCESS_EMAIL' | 
        translate: {email:(receiptEmail$|async)?.email}">
        <!-- Payment receipt email was updated to {{email}}. -->
      </span>
    </div>

    <div *ngIf="(receiptEmail$|async)?.postSuccess === false"
      [innerHTML]="'PAYMENT_CONFIRMATION_ERROR_EMAIL'| translate"
      class="payment-confirmtation-content-row">
      <!-- Error updating payment receipt email. -->
    </div>

    <button (click)="hidePaymentModal()"
      class="jive-button jive-primary jive-small payment-confirmation-close-button"
      [attr.aria-label]="'CLOSE' | translate"
      type="button"
      [innerHTML]="'CLOSE'| translate">
      <!-- close -->
    </button>
  </div>
</div>
