import { Injectable } from '@angular/core';
import { FlexKey } from './flex-form.model';

@Injectable( {
  providedIn: 'root'
} )
export class FlexFormActions {
  static readonly GET_FLEX_KEY = 'FLEX_KEY:GET';
  static readonly SET_FLEX_KEY = 'FLEX_KEY:SET';
  static readonly RESET_FLEX_KEY_STATE = 'FLEX_KEY:RESET';

  constructor () { }

  reset () {
    return { type: FlexFormActions.RESET_FLEX_KEY_STATE };
  }

  getFlexKey ( currency: string, country: string ) {
    return { type: FlexFormActions.GET_FLEX_KEY, payload: { currency, country } };
  }

  setFlexKey ( key: FlexKey ) {
    return { type: FlexFormActions.SET_FLEX_KEY, payload: { key } };
  }

}

