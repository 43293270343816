import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppVersionComponent } from './app-version.component';

@NgModule( {
  imports: [
    CommonModule
  ],
  declarations: [ AppVersionComponent ],
  exports: [ AppVersionComponent ]
} )
export class AppVersionModule { }
