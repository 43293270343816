import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject, zip } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { fromPromise } from 'rxjs/internal-compatibility';
import { PaymentMethodState } from '../../services/payment-method/redux/payment-method.model';
import { CURRENCY_SELECTOR, ORGANIZATION_ID_SELECTOR, PAYMENT_METHOD_POST_ERROR_SELECTOR, PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR, PAYMENT_METHOD_POST_SUCCESS_SELECTOR, PAYMENT_METHOD_STATE_SELECTOR, SELECTED_LANGUAGE_SELECTOR } from '../../store/helper';
import { MX_CURRENCY, US_CURRENCY } from '../../services/money-converter/money-converter.model';
import { Language } from "../../services/language-selector/redux/language-selector.model";

/**
 * While we wait for the Mexico CC support, we use stripe.
 */

@Component( {
  selector: 'bp-stripe-cc-generator',
  templateUrl: './stripe-cc-generator.component.html',
  styleUrls: [ './stripe-cc-generator.component.scss' ]
} )

export class StripeCcGeneratorComponent implements OnInit, OnDestroy {
  @Output() cancelEvent = new EventEmitter<boolean>();

  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;
  @select( PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR ) paymentMethodPostInProgress$: Observable<boolean>;
  @select( PAYMENT_METHOD_POST_SUCCESS_SELECTOR ) paymentMethodPostSuccess$: Observable<boolean>;
  @select( PAYMENT_METHOD_POST_ERROR_SELECTOR ) paymentMethodPostError$: Observable<any>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( PAYMENT_METHOD_STATE_SELECTOR ) paymentMethodState$: Observable<PaymentMethodState>;

  unsubscribe$: Subject<boolean> = new Subject();

  showError: boolean;
  errorMessage: string;

  stripe: any;
  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;
  cardPostalCodeElement: any;
  nameOnCard: string;

  stripeZipErrorMessage: string;

  constructor (
    private translate: TranslateService,
    private paymentMethodActions: PaymentMethodActions,
    private reduxStore: NgRedux<AppState>, ) { }

  ngOnInit () {
    this.paymentMethodState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state ) => {
        if ( state.postInProgress === false && state.postSuccess ) {
          this.cancel();
        }
      } );

    this.currency$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( currency ) => {
      if ( currency ) {
        this.stripeInit( currency );
      }
    } );

    this.translate.get( 'STRIPE_ZIP_ERROR' )
      .pipe(
        takeUntil( this.unsubscribe$ )
      )
      .subscribe( ( res ) => {
        this.stripeZipErrorMessage = res;
      } );

    this.paymentMethodPostError$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( error ) => {
        if ( error ) {
          this.translate.get( 'PAYMENT_METHOD_GENERATOR_POST_ERROR' )
            .pipe(
              first()
            ).subscribe( ( text ) => this.triggerShowError( text ) );
        }
      } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  cancel () {
    this.clearCCValues();
    this.cancelEvent.next( true );
  }

  clearCCValues () {
    this.cardNumberElement.clear();
    this.cardExpiryElement.clear();
    this.cardCvcElement.clear();
    this.cardPostalCodeElement.clear();
    this.nameOnCard = null;
  }

  addCard () {
    const extraData = { name: this.nameOnCard };
    const stripeObservable = fromPromise( this.stripe.createToken( this.cardNumberElement, extraData ) );

    const orgIdandStripe = zip( stripeObservable, this.organizationId$,
      ( stripeResponse: any, orgId: string ) => ( { stripeResponse, orgId } ) );

    orgIdandStripe.pipe(
      first(),
      map( this.checkForError ),
      map( ( data ) => this.checkForZipCheckResult( data ) ),
      map( ( data ) => this.reduxStore.dispatch(
        this.paymentMethodActions.postStripePaymentMethod( data.orgId, data.stripeResponse.token.id ) )
      ),
      takeUntil( this.unsubscribe$ )
    ).subscribe(
      () => {
        /* next function placeholder */
      },
      ( error ) => {
        // failing to create stripe token should ends up in here.
        this.triggerShowError( error.message );
      }
    );
  }

  triggerShowError ( message: string ) {
    this.showError = true;
    this.errorMessage = message;
  }

  checkForError ( data: any ) {
    if ( data.stripeResponse.error ) {
      throw { ...data.stripeResponse.error };
    } else {
      return data;
    }
  }

  checkForZipCheckResult ( data: any ) {
    if ( data.stripeResponse.token.card.address_zip_check === 'fail' ) {

      // 'Postal code is invalid. Please check your postal code and try again.'
      throw {
        message: this.stripeZipErrorMessage
      };
    } else {
      return data;
    }
  }

  stripeSetting ( stripeKey ) {
    return window[ 'Stripe' ]( stripeKey );
  }

  getStripeKey ( currency: string ) {
    if ( currency === MX_CURRENCY ) {
      return environment.stripePublishedKey.MX;
    } else if ( currency === US_CURRENCY ) {
      return environment.stripePublishedKey.US;
    }
  }

  stripeInit ( currency: string ) {
    this.selectedLanguage$.pipe(
      first()
    ).subscribe( ( language ) => {
      const stripeKey = this.getStripeKey( currency );

      this.stripe = this.stripeSetting( stripeKey );

      // stripe supported locales: ar, da, de, en, es, fi, fr, he, it, ja, no, nl, pl, sv, zh.
      let stripeLanguage = Language.instanceFromString( language.locale ).languageCode();

      const stripeElements = this.stripe.elements( { locale: stripeLanguage } );

      // Helvetica is being used here because Stripe does not support ProximaNova.
      const style = {
        base: {
          color: '#31325F',
          lineHeight: '32px',
          fontFamily: 'Helvetica',
          fontSize: '13px',
          fontSmoothing: 'antialiased',
        },
        invalid: {
          color: '#e5424d',
          ':focus': { color: '#31325F' },
        },
      };
      this.translate.get( 'ENTER_CARD' )
        .pipe( first() )
        .subscribe( ( translation ) => {
          this.cardNumberElement = stripeElements.create( 'cardNumber', {
            style: style,
            placeholder: translation
          } );
          this.cardNumberElement.mount( '#card-number-element' );
        } );

      this.cardExpiryElement = stripeElements.create( 'cardExpiry', {
        style: style
      } );

      this.cardCvcElement = stripeElements.create( 'cardCvc', {
        style: style,
        placeholder: ''
      } );

      this.cardPostalCodeElement = stripeElements.create( 'postalCode', {
        style: style,
        placeholder: ''
      } );

      this.cardExpiryElement.mount( '#card-expiry-element' );
      this.cardCvcElement.mount( '#card-cvc-element' );
      this.cardPostalCodeElement.mount( '#card-postal-code-element' );
    } );

  }

}
