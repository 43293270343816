import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FeatureService {
  constructor ( private http: HttpClient ) { }

  getFeatures () {
    return this.http.get<Map<string, boolean>>( `${ environment.billingPortalBffUrl }/features` );
  }
}
