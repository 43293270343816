<div class="payment-method-generator-card" role="form">
  <div class="input-title" [innerHTML]="'CC_ADD_TITLE'| translate" role="heading" aria-level="3">
    <!-- Add Credit or Debit Card -->
  </div>
  <div *ngIf="showDataError()" class="cc-data-error">

    <div class="data-error-content">
      <div class="data-error-row">
        <i class="material-icons data-error-icon">
          error_outline
        </i>
      </div>
      <div class="data-error-row" [innerHTML]="'MICROFORM_INITIALIZATION_ERROR'| translate">
        <!-- We're not able to initialize secure credit card form. -->
      </div>
      <div class="data-error-row" [innerHTML]="'MICROFORM_INITIALIZATION_ERROR_TRY_LATER'| translate">
        <!-- Please refresh the page and try again. If the problem persists, please contact our billing team. -->
      </div>
    </div>
  </div>

  <form class="form--card" [formGroup]="ccForm" (ngSubmit)="addCard()" *ngIf="!showDataError()">

    <div class="form-row top-row">
      <div class="cc-section-label" [innerHTML]="'BILLING_ADDRESS'| translate">
        <!-- Billing Address -->
      </div>
    </div>

    <div class="form-row">
      <div class="form-row-element--full">
        <label class="payment-method-form-label" [innerHTML]="'ADDRESS_COUNTRY'| translate" for="country">
          <!-- Country-->
        </label>
        <mat-select id="country"
          formControlName="country"
          (selectionChange)="dispatchCountryChange($event)"
          class="payment-method-form-input medium payment-method-form-select">
          <mat-option *ngFor="let country of availableCountries" [value]="country">
            {{country.labelKey | translate}}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-row-element--full">
        <label class="payment-method-form-label" [innerHTML]="'ADDRESS_LINE_ONE'| translate" for="card-address-one">
          <!-- Address Line 1-->
        </label>
        <input type="text" required
          id="card-address-one"
          autocomplete="off"
          data-lpignore="true"
          class="card-address-one medium"
          formControlName="addressLineOne"
          [class.payment-method-form-input]="!ccControls?.addressLineOne?.touched || !ccControls?.addressLineOne?.errors"
          [class.payment-method-form-error]="ccControls?.addressLineOne?.touched && ccControls?.addressLineOne?.errors">
      </div>
    </div>

    <div class="form-row">
      <div class="form-row-element--full">
        <label class="payment-method-form-label" [innerHTML]="'ADDRESS_LINE_TWO'| translate" for="card-address-two">
          <!-- Address Line 2-->
        </label>

        <input type="text"
          id="card-address-two"
          autocomplete="off"
          data-lpignore="true"
          class="card-address-two payment-method-form-input medium"
          formControlName="addressLineTwo"
          [class.payment-method-form-input]="!ccControls?.addressLineTwo?.touched || !ccControls?.addressLineTwo?.errors"
          [class.payment-method-form-error]="ccControls?.addressLineTwo?.touched && ccControls?.addressLineTwo?.errors">
      </div>
    </div>

    <div class="form-row">
      <div class="form-row-element--full">
        <label class="payment-method-form-label" [innerHTML]="'CITY'| translate" for="card-address-city">
          <!-- City -->
        </label>

        <input type="text"
          id="card-address-city"
          autocomplete="off"
          data-lpignore="true"
          class="card-address-city medium"
          formControlName="city"
          [class.payment-method-form-input]="!ccControls?.city?.touched || !ccControls?.city?.errors"
          [class.payment-method-form-error]="ccControls?.city?.touched && ccControls?.city?.errors">
      </div>
    </div>

    <div class="form-row payment-method-generator-small-element-row">

      <div class="payment-method-generator-small-element" *ngIf="!showRegionDropDown()">
        <label class="payment-method-form-label" [innerHTML]="selectedCountry?.regionType| translate" for="card-region">
          <!-- State / Province/ Region -->
        </label>
        <input type="text"
          id="card-region"
          name="card-region"
          autocomplete="off"
          data-lpignore="true"
          class="card-region payment-method-form-input medium"
          formControlName="region"
          [class.payment-method-form-input]="!ccControls?.region?.touched || isRegionValid()"
          [class.payment-method-form-error]="ccControls?.region?.touched && !isRegionValid()">
      </div>

      <div class="payment-method-generator-small-element" *ngIf="showRegionDropDown()">
        <label class="payment-method-form-label" [innerHTML]="selectedCountry?.regionType| translate" for="region">
          <!-- State / Province/ Region -->
        </label>
        <mat-select
          id="region"
          formControlName="region"
          [class.payment-method-form-input]="!ccControls?.region?.touched || isRegionValid()"
          [class.payment-method-form-error]="ccControls?.region?.touched && !isRegionValid()"
          class="medium payment-method-form-select ">
          <mat-option *ngFor="let region of selectedCountry.regions" [value]="region.code">
            {{region.name}}
          </mat-option>
        </mat-select>
      </div>

      <div class="payment-method-generator-small-element end">
        <label class="payment-method-form-label" [innerHTML]="'POSTAL_CODE'| translate" for="card-postal-code">
          <!-- Postal Code -->
        </label>
        <input type="text"
          id="card-postal-code"
          autocomplete="off"
          data-lpignore="true"
          class="card-postal-code medium"
          formControlName="postalCode"
          [class.payment-method-form-input]="!ccControls?.postalCode?.touched || ccControls?.postalCode.value || (ccControls?.postalCode?.touched && isPostalCodeValid())"
          [class.payment-method-form-error]="ccControls?.postalCode?.touched && !isPostalCodeValid()">
      </div>
    </div>

    <div class="form-row top-row">
      <div class="cc-section-label" [innerHTML]="'CREDIT_CARD_INFO'| translate">
        <!-- Credit Card Info -->
      </div>
    </div>

    <div class="form-row">
      <div class="form-row-element--full">
        <label class="payment-method-form-label" [innerHTML]="'CARD_NUMBER'| translate" for="card-number-container">
          <!-- Card Number -->
        </label>

        <div id="card-number-container"
          class="payment-method-form-input medium">
        </div>
      </div>
    </div>

    <div class="form-row payment-method-generator-small-element-row">
      <div class="payment-method-generator-small-element">
        <label for="card-first-name" [innerHTML]="'CARDHOLDER_FIRST_NAME'| translate" class="payment-method-form-label">
          <!-- First Name -->
        </label>
        <input type="text"
          id="card-first-name"
          autocomplete="off"
          data-lpignore="true"
          class="cc-first-name medium"
          formControlName="firstName" required
          [class.payment-method-form-input]="!ccControls?.firstName?.touched || !ccControls?.firstName.errors"
          [class.payment-method-form-error]="ccControls?.firstName?.touched && ccControls?.firstName?.errors">
      </div>
      <div class="payment-method-generator-small-element end">
        <label for="card-last-name" [innerHTML]="'CARDHOLDER_LAST_NAME'| translate" class="payment-method-form-label">
          <!-- Last Name -->
        </label>
        <input type="text"
          id="card-last-name"
          autocomplete="off"
          data-lpignore="true"
          class="cc-last-name medium"
          formControlName="lastName" required
          [class.payment-method-form-input]="!ccControls?.lastName?.touched || !ccControls?.lastName.errors"
          [class.payment-method-form-error]="ccControls?.lastName?.touched && ccControls?.lastName?.errors">
      </div>
    </div>

    <div class="form-row payment-method-generator-small-element-row">
      <div class="payment-method-generator-small-element">
        <label class="payment-method-form-label" [innerHTML]="'EXP_DATE'| translate">
          <!-- Expiration Date -->
        </label>

        <div class="card-exp">
          <input type="text" placeholder="MM" required
            [attr.aria-label]="'MONTH' | translate"
            maxlength="2"
            id="card-exp-month"
            autocomplete="off"
            data-lpignore="true"
            class="card-exp-month medium"
            formControlName="expMonth"
            [class.payment-method-form-input]="!ccControls?.expMonth?.touched || ( !showErrorForExp() && !ccControls?.expMonth.errors)"
            [class.payment-method-form-error]="(ccControls?.expMonth?.touched && ccControls?.expMonth?.errors ) || showErrorForExp()">

          <input type="text" placeholder="YYYY"
            [attr.aria-label]="'YEAR' | translate"
            maxlength="4"
            id="card-exp-year"
            autocomplete="off"
            data-lpignore="true"
            class="card-exp-year medium"
            formControlName="expYear"
            [class.payment-method-form-input]="!ccControls?.expYear?.touched || ( !showErrorForExp() && !ccControls?.expYear.errors)"
            [class.payment-method-form-error]="(ccControls?.expYear?.touched && ccControls?.expYear?.errors) || showErrorForExp()">
        </div>
      </div>

      <div class="payment-method-generator-small-element end">
        <label class="payment-method-form-label" [innerHTML]="'CVV'| translate" for="card-cvv">
          <!-- Security Code-->
        </label>

        <span class="cvv-tooltip">
          <i class="material-icons cvv-tooltip-icon">
            help
          </i>
          <div class="cvv-tooltip-content">
            <div [innerHTML]="'CVV_THREE_DIGIT'| translate">
              <!-- For Visa, Mastercard, and Discover, the 3-digit code can be found on the
              back of the card. -->
            </div>
            <div [innerHTML]="'CVV_FOUR_DIGIT'| translate" class="cvv-second-paragraph">
              <!-- For American Express, the 4-digit code can be found on the front right
              side of the card. -->
            </div>
          </div>
        </span>
        <div id="cvv-container"
          class="payment-method-form-input medium">
        </div>
      </div>
    </div>

    <div *ngIf="showError" class="payment-method-generator-error">
      <div aria-hidden="true">
        <i class="material-icons">
          error_outline
        </i>
      </div>
      <div role="alert">
        {{errorMessage}}
        <div *ngIf="errorCode">
          Error Code: {{errorCode}}
        </div>
      </div>
    </div>

    <div class="payment-method-generator-action-buttons">
      <button class="jive-button jive-small payment-method-generator-cancel-buttons"
        [attr.disabled]="(paymentMethodPostInProgress$| async) || isCardinalBinProcessOngoing ? '' : null"
        [attr.aria-label]="'CC_CANCEL_ARIA' | translate"
        type="button"
        (click)="cancel()"
        [innerHTML]="'CANCEL'| translate">
        <!-- cancel -->
      </button>
      <button class="jive-button jive-primary jive-small"
        *ngIf="!(paymentMethodPostInProgress$| async) && !isCardinalBinProcessOngoing"
        [attr.disabled]="cardInfoIsValid()? null: ''"
        [attr.aria-label]="'CC_ADD_TITLE' | translate"
        type="submit"
        [innerHTML]="'ADD'| translate">
        <!-- Add -->
      </button>
      <button class="jive-button jive-primary jive-small"
        *ngIf="(paymentMethodPostInProgress$| async) || isCardinalBinProcessOngoing"
        [attr.aria-label]="'PROCESSING' | translate"
        type="button"
        disabled
        [innerHTML]="'PROCESSING'| translate"
        aria-busy="true">
        <!-- Processing -->
      </button>
    </div>
  </form>
</div>
