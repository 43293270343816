import { Injectable } from '@angular/core';
import { Organization } from '../../organization/redux/organization.model';

@Injectable( {
  providedIn: 'root'
} )
export class OrganizationListActions {
  static readonly LIST_ORGS_START = 'ORGANIZATION_LIST:LIST_ORGS_START';
  static readonly LIST_ORGS_END = 'ORGANIZATION_LIST:LIST_ORGS_END';
  static readonly LIST_ORGS_ERROR = 'ORGANIZATION_LIST:LIST_ORGS_ERROR';
  static readonly CLEAR_ORGS_ERROR = 'ORGANIZATION_LIST:CLEAR_ORGS_ERROR';

  constructor () { }

  getOrgList ( filter?: string ) {
    return {
      type: OrganizationListActions.LIST_ORGS_START,
      payload: { filter }
    };
  }

  listComplete ( orgs: Organization[] ) {
    return {
      type: OrganizationListActions.LIST_ORGS_END,
      payload: { items: orgs }
    };
  }

  callError ( error: any ) {
    return {
      type: OrganizationListActions.LIST_ORGS_ERROR,
      payload: { error }
    };
  }
}
