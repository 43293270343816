<div class="payment-method-selector">
  <div *ngIf="(paymentMethodList$ | async)?.length > 0">
    <div class="input-title" [innerHTML]="'PAYMENT_METHOD_SELECTOR_TITLE'| translate">
      <!-- Payment Method -->
    </div>


    <div class="payment-method-selector-list">
      <span class="payment-method-selector-item"
        *ngFor="let paymentMethod of (paymentMethodList$ | async)">
        <input type="radio"
          name="paymentMethod.id"
          value="paymentMethod.defaultMethod"
          [attr.disabled]="paymentInProgress ? '' : null"
          (change)="updateSelected(paymentMethod)"
          [checked]="paymentMethod.defaultMethod">
        <span>
          <img class="payment-method-selector-img"
            [src]="getPaymentMethodImageUrl(paymentMethod)"/>
          <span class="payment-method-selector-item-identifier">
            <span class="payment-method-selector-last-four">
              ** {{paymentMethod.last4}}
            </span>
          </span>
        </span>

        <span class="payment-method-selector-exp">
          {{paymentMethod.exp}}
        </span>

        <span class="payment-method-selector-default"
          [hidden]="!paymentMethod.defaultMethod || !((isMobile$ |async) === false )"
          [innerHTML]="'PAYMENT_METHOD_DEFAULT'| translate">
          <!-- default -->
      </span>
      </span>
    </div>
  </div>
</div>