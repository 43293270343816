import { Component, OnInit } from '@angular/core';

@Component( {
  selector: 'bp-error404',
  templateUrl: './error404.component.html',
  styleUrls: [ './error404.component.scss' ]
} )
export class Error404Component implements OnInit {

  constructor () { }

  ngOnInit () {
  }
}
