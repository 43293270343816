import { Action } from '../../../store/action.model';
import { MessageState } from './message.model';
import { MessageActions } from './message.actions';

export const INITIAL_STATE: MessageState = {
  loaded: false,
  hasError: false,
  messages: [],
  error: undefined
};

export function messageReducer (
  lastState: MessageState = INITIAL_STATE,
  action: Action<Partial<MessageState>>
): MessageState {
  switch ( action.type ) {
    case MessageActions.SET_COMPLETE:
      return {
        ...INITIAL_STATE,
        loaded: true,
        messages: action.payload.messages
      };
    case MessageActions.SET_ERROR:
      return {
        ...INITIAL_STATE,
        loaded: true,
        hasError: true,
        error: action.payload.error
      };
    default:
      return lastState;
  }
}
