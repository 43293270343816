import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CallDetailsActions} from './call-details.actions';
import {CallDetailsService} from '../call-details.service';
import {FileService} from '../../file/file.service';

@Injectable( {
  providedIn: 'root'
} )
export class CallDetailsEpic {

  constructor (
    private callDetailsActions: CallDetailsActions,
    private callDetailsService: CallDetailsService,
    private fileService: FileService
  ) { }

  getEpics () {

    return combineEpics(
      this.getAndSetCallDetails.bind( this ),
    );
  }

  getAndSetCallDetails ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(CallDetailsActions.GET_CALL_DETAILS),
        switchMap(
          ( { payload: { orgId, year, month, country, language } } ) =>
            this.callDetailsService.getCallDetails( orgId, year, month, country, language )
              .pipe(
                map( ( blob: Blob ) => {
                  const monthStr = month.toString().padStart( 2, '0' );
                  this.fileService.openNewSaveDialog( blob, `${ year }_${ monthStr }.zip` );
                  return this.callDetailsActions.resetCallDetails();
                } ),
                catchError( () => {
                  return of( this.callDetailsActions.setCallDetails( { data: undefined, filename: undefined } ) );

                } )
              )
        )
      );
  }

}
