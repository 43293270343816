import { Injectable } from '@angular/core';
import { JifPbx } from '@jive/core/jif';
import { JiveOrganizationPermissions, PrincipalData, PrincipalDataUser } from '@jive/core/identity/v1';

@Injectable()
export class UserActions {

  static SET_START = 'USER:SET_START';
  static SET_ERROR = 'USER:SET_ERROR';
  static SET_USER_PBXS = 'USER:SET_USER_PBXS';
  static SET_USER_SELECTED_PBX_AND_PERMISSIONS = 'USER:SET_USER_SELECTED_PBX_AND_PERMISSIONS';
  static SET_USER_AND_PRINCIPAL_DATA = 'USER:SET_USER_AND_PRINCIPAL_DATA';
  static SET_USER_REMOVED = 'USER:REMOVE';

  constructor () { }

  setStartAction () {
    return { type: UserActions.SET_START };
  }

  setUserRemovedAction () {
    return { type: UserActions.SET_USER_REMOVED };
  }

  setUserPbxsAction ( userPbxs: JifPbx[] ) {
    return { type: UserActions.SET_USER_PBXS, payload: { userPbxs } };
  }

  setUserSelectedPbxAndPermissionsAction ( selectedPbx: JifPbx, selectedPbxPermissions: JiveOrganizationPermissions ) {
    return {
      type: UserActions.SET_USER_SELECTED_PBX_AND_PERMISSIONS,
      payload: { selectedPbx, selectedPbxPermissions }
    };
  }

  setUserAndPrincipalDataAction ( principalData: PrincipalData, currentUser: PrincipalDataUser ) {
    return { type: UserActions.SET_USER_AND_PRINCIPAL_DATA, payload: { principalData, currentUser } };
  }

  setErrorAction ( error: any ) {
    return { type: UserActions.SET_ERROR, error: error };
  }

}
