import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { select } from '@angular-redux/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ORGANIZATION_SELECTOR } from "../../store/helper";
import { Organization } from "../../services/organization/redux/organization.model";
import { UNITED_STATES } from "../../services/location-converter/assets/model";

@Component( {
  selector: 'bp-pbx-select',
  templateUrl: './pbx-select.component.html',
  styleUrls: [ './pbx-select.component.scss' ]
} )
export class PbxSelectComponent implements OnInit, OnDestroy {

  constructor ( private translateService: TranslateService ) { }

  @select( ORGANIZATION_SELECTOR ) organization$: Observable<Organization>;

  unsubscribe$: Subject<boolean> = new Subject();

  ngOnInit () {

    this.organization$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( organization ) => {
      if ( organization ) {
        this.translateService.get( 'ERROR_COUNTRY_AND_CURRENCY',
          { billingPhoneNumber: UNITED_STATES.billingPhoneNumber, billingEmail: UNITED_STATES.billingEmail } )
          .pipe( first() )
          .subscribe( ( translation ) => {
            alert( translation );
          } );
      }
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

}
