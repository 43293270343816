import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodActions } from './redux/payment-method.actions';
import { PaymentMethodEpic } from './redux/payment-method.epic';
import { PaymentMethodService } from './payment-method.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    PaymentMethodActions,
    PaymentMethodEpic,
    PaymentMethodService
  ],
  declarations: []
} )
export class PaymentMethodModule {}
