import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {UserActions} from '../../user/redux/user.actions';
import {NgRedux} from '@angular-redux/store';
import {AppState} from '../../../store/app-state.model';
import {AuthActions} from './auth.actions';
import {first, mergeMap, tap} from 'rxjs/operators';
import {Authentication} from '../auth.service';

@Injectable()
export class AuthEpic {
  constructor (
    private auth: Authentication,
    private userActions: UserActions,
    private ngRedux: NgRedux<AppState>
  ) {}

  getEpics () {
    return combineEpics( this.logoutEpic.bind( this ) );
  }

  logoutEpic ( action$: ActionsObservable<Action<any>> ) {
    return action$
      .pipe(
          ofType(AuthActions.LOGOUT),
          first(),
          tap(() => {
            this.ngRedux.dispatch(this.userActions.setUserRemovedAction());
          }),
          mergeMap(() => this.auth.logout())
      );
  }
}
