import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { Address, CreditCard, FlexFormState, FlexKey } from '../../services/flex-form/redux/flex-form.model';
import { BillingEmailState } from '../../services/billing-email/redux/billing-email.model';
import { Country } from '../../services/location-converter/assets/model';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { AppState } from '../../store/app-state.model';
import { TranslateService } from '@ngx-translate/core';
import { LocationConverterService } from '../../services/location-converter/location-converter.service';
import { FlexFormActions } from '../../services/flex-form/redux/flex-form.actions';
import { ccFormSetting } from './cc-generator.helper';
import { OrganizationState } from '../../services/organization/redux/organization.model';
import { CreatePaymentMethodScaChallengeRequest, PaymentMethodPostStatus, PaymentMethodState } from '../../services/payment-method/redux/payment-method.model';
import { BillingEmailActions } from '../../services/billing-email/redux/billing-email.actions';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CardinalState, ScaPurpose, ScaRequirement, ScaToken } from '../../services/cardinal/redux/cardinal.model';
import { CardinalActions } from '../../services/cardinal/redux/cardinal.actions';
import { CC_ERROR_CODE_CONTACT_ISSUER, CC_ERROR_CODE_SCA_TRY_AGAIN, CC_ERROR_CODE_TRY_AGAIN, CC_GENERATOR_CONTACT_ISSUER_ERROR_KEY,
  CC_GENERATOR_GENERAL_POST_ERROR_KEY, CC_GENERATOR_TRY_AGAIN_ERROR_KEY, CcGeneratorState } from '../../services/cc-generator/redux/cc-generator.model';
import { CcGeneratorActions } from '../../services/cc-generator/redux/cc-generator.actions';
import { environment } from '../../../environments/environment';
import { BILLING_EMAIL_STATE_SELECTOR, CARDINAL_SCA_REQUIREMENT_SELECTOR, CARDINAL_SCA_TOKEN, CARDINAL_STATE_SELECTOR, CC_GENERATOR_STATE,
  COUNTRY_SELECTOR, CURRENCY_SELECTOR, FLEX_FORM_STATE_SELECTOR, ORGANIZATION_ID_SELECTOR, ORGANIZATION_STATE_SELECTOR,
  PAYMENT_METHOD_POST_ERROR_SELECTOR, PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR, PAYMENT_METHOD_POST_STATUS_SELECTOR,
  PAYMENT_METHOD_POST_SUCCESS_SELECTOR, PAYMENT_METHOD_STATE_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-cc-generator',
  templateUrl: './cc-generator.component.html',
  styleUrls: [ './cc-generator.component.scss' ]
} )
export class CcGeneratorComponent implements OnInit, OnDestroy {
  @Output() cancelEvent = new EventEmitter<boolean>();

  @select( BILLING_EMAIL_STATE_SELECTOR ) billingEmailState$: Observable<BillingEmailState>;
  @select( CARDINAL_SCA_TOKEN ) scaToken$: Observable<ScaToken>;
  @select( CARDINAL_SCA_REQUIREMENT_SELECTOR ) cardinalScaRequirement$: Observable<ScaRequirement>;
  @select( CARDINAL_STATE_SELECTOR ) cardinalState$: Observable<CardinalState>;
  @select( CC_GENERATOR_STATE ) componentState$: Observable<CcGeneratorState>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( FLEX_FORM_STATE_SELECTOR ) flexFormState$: Observable<FlexFormState>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( ORGANIZATION_STATE_SELECTOR ) organizationState$: Observable<OrganizationState>;
  @select( COUNTRY_SELECTOR ) storedCountry$: Observable<Country>;
  @select( PAYMENT_METHOD_POST_ERROR_SELECTOR ) paymentMethodPostError$: Observable<any>;
  @select( PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR ) paymentMethodPostInProgress$: Observable<boolean>;
  @select( PAYMENT_METHOD_POST_STATUS_SELECTOR ) paymentMethodPostStatus$: Observable<PaymentMethodPostStatus>;
  @select( PAYMENT_METHOD_POST_SUCCESS_SELECTOR ) paymentMethodPostSuccess$: Observable<boolean>;
  @select( PAYMENT_METHOD_STATE_SELECTOR ) paymentMethodState$: Observable<PaymentMethodState>;

  unsubscribe$: Subject<boolean> = new Subject();

  // variable to keep track whether necessary data is available
  flexDataError = false;
  formInitError = false;
  currencyDataError = false;
  emailDataError = false;
  countryDataError = false;

  // flex form
  microform: any;
  flexKey: FlexKey;
  ccForm: UntypedFormGroup;
  mainEmail: string;
  cardNumberMicroformField: any;
  securityCodeMicroformField: any;

  // flex validation
  isCardValid = false;
  isCvvValid = false;

  microformExpirationDate: Date;

  availableCountries: Country[];
  selectedCountry: Country;

  showError: boolean;
  errorMessage: string;

  initialFormKeyLoaded = false;
  billingEmailLoaded = false;
  errorCode: string;
  // Used only to give customer feedback quickly as we are waiting for bin process to complete before submitting the form
  isCardinalBinProcessOngoing = false;
  securityCodeMicroformFieldLoadHandler: ( data: any ) => void;
  cardNumberMicroformFieldLoadHandler: ( data: any ) => void;
  securityCodeMicroformFieldChangeHandler: ( data: any ) => any;
  cardNumberMicroformFieldChangeHandler: ( data: any ) => any;

  get ccValue () { return this.ccForm.value; }

  get ccControls () { return this.ccForm.controls; }

  constructor (
    private billingEmailActions: BillingEmailActions,
    private cardinalActions: CardinalActions,
    private ccGeneratorActions: CcGeneratorActions,
    private flexFormActions: FlexFormActions,
    private formBuilder: UntypedFormBuilder,
    private locationConverterService: LocationConverterService,
    private paymentMethodActions: PaymentMethodActions,
    private reduxStore: NgRedux<AppState>,
    private translate: TranslateService
  ) { }

  ngOnInit () {
    this.reduxStore.dispatch( this.flexFormActions.reset() );
    this.reduxStore.dispatch( this.cardinalActions.reset() );

    // noinspection TypeScriptValidateJSTypes
    Cardinal.configure( {
      logging: {
        level: environment.cardinalLogging
      }
    } );

    this.ccFormInit();

    this.componentState$.pipe(
      first()
    ).subscribe( ( state ) => {
      const now = new Date();
      const oldestValidTime = new Date( now.getTime() - 10000 );
      const errorTime = new Date( state.errorDate );

      if ( errorTime > oldestValidTime ) {
        this.translate.get( state.errorMessageKey )
          .pipe(
            first()
          ).subscribe( ( text ) => {
          this.triggerShowError( text, state.errorCode );
        } );
      }
    } );

    this.cardinalScaRequirement$
      .pipe(
        filter( ( scaRequirement ) =>
          scaRequirement?.scaRequired &&
          scaRequirement?.purpose === ScaPurpose.paymentMethod
        ),
        takeUntil( this.unsubscribe$ )
      ).subscribe( ( scaRequirement ) => {
      // noinspection TypeScriptValidateJSTypes
      Cardinal.continue( 'cca',
        {
          'AcsUrl': scaRequirement.paymentMethodCreationResponse.payerAuthEnrollmentResult.acsUrl,
          'Payload': scaRequirement.paymentMethodCreationResponse.payerAuthEnrollmentResult.paReq
        },
        {
          'OrderDetails': {
            'TransactionId': scaRequirement.paymentMethodCreationResponse.payerAuthEnrollmentResult.authenticationTransactionId
          }
        } );
    } );

    this.paymentMethodPostInProgress$
      .pipe(
        filter( ( inProgress ) => inProgress === false ),
        takeUntil( this.unsubscribe$ ),
        switchMap( () =>
          combineLatest( [
            this.paymentMethodPostSuccess$,
            this.paymentMethodPostStatus$,
            this.paymentMethodPostError$
          ] )
        )
      )
      .subscribe( ( [ success, postStatus, postError ] ) => {
        if ( success && postStatus === PaymentMethodPostStatus.COMPLETED ) {
          // Cardinal required page refresh
          this.cancel();
        } else if ( !success && postStatus === PaymentMethodPostStatus.ERROR ) {
          const errorCode = postError?.error?.error?.number ? postError.error.error.number : null;
          if ( errorCode === CC_ERROR_CODE_CONTACT_ISSUER ) {
            this.cancelWithError( CC_GENERATOR_CONTACT_ISSUER_ERROR_KEY, CC_ERROR_CODE_CONTACT_ISSUER );
          } else if ( errorCode === CC_ERROR_CODE_TRY_AGAIN || errorCode === CC_ERROR_CODE_SCA_TRY_AGAIN ) {
            this.cancelWithError( CC_GENERATOR_TRY_AGAIN_ERROR_KEY, errorCode );
          } else {
            this.cancelWithError( CC_GENERATOR_GENERAL_POST_ERROR_KEY, null );
          }
        }
      } );

    this.flexFormState$
      .pipe(
        takeUntil( this.unsubscribe$ )
      ).subscribe( ( state ) => {
      this.flexDataError = false;
      if ( state.key && state.getKeyInProgress === false ) {
        this.flexFormInit( state.key );
        this.microformExpirationDate = state.expirationDate;
      } else {
        if ( !state.getKeyInProgress ) {
          this.flexDataError = true;
        }
      }
    } );

    this.billingEmailState$
      .pipe(
        filter( ( state ) => state.getInProgress === false ),
        takeUntil( this.unsubscribe$ )
      ).subscribe( ( state ) => {
      this.emailDataError = false;
      this.billingEmailLoaded = true;
      if ( state.stored ) {
        state.stored.forEach( ( billingEmail ) => {
          if ( billingEmail.isMain ) {
            this.mainEmail = billingEmail.email;
          }
        } );
      } else {
        this.emailDataError = true;
      }
    } );

    combineLatest( [
      this.organizationId$,
      this.billingEmailState$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ organizationId, billingEmailState ] ) => {
      if ( billingEmailState.getInProgress === undefined && billingEmailState.stored === undefined ) {
        this.reduxStore.dispatch( this.billingEmailActions.getBillingEmail( organizationId ) );
      }
    } );

    combineLatest( [
      this.storedCountry$,
      this.currency$
    ] ).pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( [ country, currency ] ) => {
        this.currencyDataError = false;
        /*
        Based on the currency, the available countries are different.
        This set the available countries used in drop down menu and
        use the first value as the default.
        */
        if ( currency ) {
          this.availableCountries = this.locationConverterService.getAvailableCountries( currency );

          if ( this.availableCountries ) {
            this.countryDataError = false;
            this.selectedCountry = this.availableCountries[ 0 ];

            // Set the default selectedCountry to be the same as the organization's country if available
            this.availableCountries.filter( ( availableCountry ) => availableCountry.code === country.code )
              .map( ( filteredCountry ) => {
                this.selectedCountry = filteredCountry;
              } );

            this.ccFormInitWithValue( this.selectedCountry );

            this.reduxStore.dispatch(
              this.flexFormActions.getFlexKey( currency, this.selectedCountry.code )
            );

            this.generateScaToken( currency, this.selectedCountry );
          } else {
            this.countryDataError = true;
          }
        } else {
          this.currencyDataError = true;
        }
      } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  generateScaToken ( currency: string, country: Country ) {
    this.reduxStore.dispatch( this.cardinalActions.generateScaToken( currency, country ) );
  }

  showAuthenticationError () {
    this.cancelWithError( CC_GENERATOR_CONTACT_ISSUER_ERROR_KEY, null );
  }

  initCardinal ( scaToken: ScaToken ) {
    Cardinal.on( 'payments.validated', ( data, jwt ) => {
      if ( data?.ErrorDescription === 'Success' && data?.Payment?.Type === 'CCA' ) {
        this.addCardAfterSca( jwt );
      } else if ( data?.ErrorNumber && data?.Payment?.Type === 'CCA' ) {
        this.showAuthenticationError();
      } else {
        switch ( data.ActionCode ) {
          case 'ERROR':
            this.showAuthenticationError();
            break;
        }
      }
    } );

    Cardinal.setup( 'init', {
      'jwt': scaToken.tokenString
    } );
  }

  cancelWithError ( errorMessageKey: string, errorCode: string ) {
    this.reduxStore.dispatch( this.ccGeneratorActions.setError( errorMessageKey, errorCode ) );
    // forcing to wait just a bit to make sure that error gets stored before reload
    setTimeout( () => this.cancel(), 300 );
  }

  cancel () {
    location.reload();
  }

  dispatchCountryChange ( event: MatOptionSelectionChange ) {
    const country = event.source.value;
    this.currency$.pipe(
      first()
    ).subscribe( ( currency ) => {
      this.reduxStore.dispatch(
        this.flexFormActions.getFlexKey( currency, country.code )
      );
      this.generateScaToken( currency, country );
    } );
    this.selectedCountry = country;
  }

  didMicroFormExpire () {
    return new Date() > this.microformExpirationDate;
  }

  /*
    For the following conditions, it is impossible to accept cc info
      1. Flex key is not available
      2. Microform initializing failed
      3. Currency is not available
      4. Based on the currency, there is no available country
      5. Billing Email address is not available
  */
  showDataError () {
    return this.billingEmailLoaded &&
      this.initialFormKeyLoaded &&
      ( this.flexDataError ||
        this.formInitError ||
        this.currencyDataError ||
        this.emailDataError ||
        this.countryDataError );
  }

  /*
    Few countries require the region when creating payment methods.
    Those countries will have the list of regions.
    Else we make the region optional text input.
  */
  showRegionDropDown () {
    return this.selectedCountry && this.selectedCountry.regions;
  }

  /*
    This will init the reactive form for cc except the card number.
  */
  ccFormInit () {
    this.ccForm = this.formBuilder.group( ccFormSetting );
  }

  ccFormInitWithValue ( country: Country ) {
    this.ccFormInit();
    this.ccControls.country.patchValue( country );
  }

  clearCCValues () {
    if ( this.microform ) {
      this.microform.clear();
    }
    this.ccFormInitWithValue( this.availableCountries[ 0 ] );
  }

  triggerShowError ( message: string, errorCode: string ) {
    this.errorMessage = message;
    this.errorCode = errorCode;
    this.showError = true;
  }

  unsubscribeFromMicroform () {
    if ( this.cardNumberMicroformField ) {
      this.cardNumberMicroformField.off( 'change', this.cardNumberMicroformFieldChangeHandler );
      this.cardNumberMicroformField.off( 'load', this.cardNumberMicroformFieldLoadHandler );
      this.cardNumberMicroformField.dispose();
    }

    if ( this.securityCodeMicroformField ) {
      this.securityCodeMicroformField.off( 'change', this.securityCodeMicroformFieldChangeHandler );
      this.securityCodeMicroformField.off( 'load', this.securityCodeMicroformFieldLoadHandler );
      this.securityCodeMicroformField.dispose();
    }
  }

  flexFormInit ( key: FlexKey ) {
    this.flexKey = key;

    this.unsubscribeFromMicroform();

    try {
      const flex = new Flex( key.keyId );
      const customStyle = {
        'input': {
          'font-family': 'Helvetica, Roboto, "Helvetica Neue", sans-serif',
          'font-size': '1rem',
          'line-height': '18px',
          'color': '#31325F'
        },
        ':focus': { 'color': 'black' },
        ':disabled': { 'cursor': 'not-allowed' },
        'valid': { 'color': '#3c763d' },
        'invalid': { 'color': 'red' }
      };

      this.microform = flex.microform( { styles: customStyle } );

      this.cardNumberMicroformField = this.microform.createField( 'number', { placeholder: '' } );
      this.securityCodeMicroformField = this.microform.createField( 'securityCode', { placeholder: '' } );

      this.cardNumberMicroformFieldChangeHandler = ( data ) => this.isCardValid = data.valid;

      this.securityCodeMicroformFieldChangeHandler = ( data ) => this.isCvvValid = data.valid;

      this.cardNumberMicroformFieldLoadHandler = ( data ) =>
        console.log( 'in cardNumberMicroformFieldLoadHandler: ', data );

      this.securityCodeMicroformFieldLoadHandler = ( data ) =>
        console.log( 'in securityCodeMicroformFieldLoadHandler: ', data );

      this.cardNumberMicroformField.on( 'change', this.cardNumberMicroformFieldChangeHandler );
      this.securityCodeMicroformField.on( 'change', this.securityCodeMicroformFieldChangeHandler );
      this.cardNumberMicroformField.on( 'load', this.cardNumberMicroformFieldLoadHandler );
      this.securityCodeMicroformField.on( 'load', this.securityCodeMicroformFieldLoadHandler );

      this.cardNumberMicroformField.load( '#card-number-container' );
      this.securityCodeMicroformField.load( '#cvv-container' );

    } catch ( error ) {
      console.log( 'In flexFormInit', error );
      this.translate.get( 'PAYMENT_METHOD_GENERATOR_FORM_INIT_ERROR' )
        .pipe(
          first()
        ).subscribe( ( text ) => this.triggerShowError( text, null ) );
    }
  }

  addCardAfterSca ( jwt ) {

    combineLatest( [
      this.organizationId$,
      this.currency$,
      this.cardinalState$,
      this.paymentMethodPostInProgress$ ]
    ).pipe(
      first()
    ).subscribe( ( [ orgId, currency, cardinalState, paymentMethodPostInProgress ] ) => {
      if ( !paymentMethodPostInProgress ) {
        const createPaymentMethodScaChallengeRequest: CreatePaymentMethodScaChallengeRequest = {
          cardinalJwt: jwt,
          currency: currency
        };

        this.reduxStore.dispatch(
          this.paymentMethodActions.postCardPaymentMethodAfterSca(
            orgId,
            cardinalState.scaRequirement.paymentMethodCreationResponse.paymentMethodKey,
            createPaymentMethodScaChallengeRequest
          )
        );
      }
    } );
  }

  addCard () {
    if ( this.didMicroFormExpire() ) {
      this.currency$.pipe( first() ).subscribe( ( currency ) => {
        this.reduxStore.dispatch(
          this.flexFormActions.getFlexKey( currency, this.selectedCountry.code )
        );
        this.translate.get( 'PAYMENT_METHOD_GENERATOR_FORM_EXPIRED' )
          .pipe(
            first()
          ).subscribe( ( text ) => this.triggerShowError( text, null ) );
      } );
    } else {
      this.scaToken$.pipe( first() ).subscribe( ( scaToken ) => {
        if ( scaToken?.isScaEnabledForMid ) {
          this.initCardinal( scaToken );
        }
      } );

      const options = {
        expirationMonth: this.ccValue.expMonth,
        expirationYear: this.ccValue.expYear
      };

      this.microform.createToken( options, async ( error, token ) => {
        if ( error ) {
          this.cancelWithError( CC_GENERATOR_TRY_AGAIN_ERROR_KEY, null );
        }

        const parsedToken = JSON.parse( atob( token.split( '.' )[ 1 ] ) );
        const first6Digits = parsedToken.data.number.substring( 0, 6 );

        try {
          this.isCardinalBinProcessOngoing = true;
          await Cardinal.trigger( 'bin.process', first6Digits );

          const address = this.getAddress();

          const creditCard: CreditCard = {
            flexKeyId: this.flexKey.keyId,
            flexResponse: token
          };

          combineLatest( [
            this.organizationId$,
            this.cardinalState$
          ] ).pipe( first() )
            .subscribe( ( [ orgId, cardinalState ] ) => {
              this.reduxStore.dispatch(
                this.paymentMethodActions.postCardPaymentMethod(
                  cardinalState.scaToken, orgId, address, creditCard
                )
              );
            } );

        } catch (e) {

        }

        this.isCardinalBinProcessOngoing = false;
      } );
    }
  }

  getAddress (): Address {
    return {
      addressLine1: this.ccValue.addressLineOne,
      addressLine2: this.ccValue.addressLineTwo ? this.ccValue.addressLineTwo : null,
      city: this.ccValue.city,
      state: this.ccValue.region ? this.ccValue.region : null,
      postalCode: this.ccValue.postalCode ? this.ccValue.postalCode : null,
      country: this.ccValue.country.code,
      firstName: this.ccValue.firstName,
      lastName: this.ccValue.lastName,
      email: this.mainEmail
    };
  }

  // validation based on cardType from Flex
  cardInfoIsValid () {
    return this.isCvvValid &&
      this.isCardValid &&
      this.formValid() &&
      this.isRegionValid() &&
      this.isPostalCodeValid() &&
      this.isExpValid();
  }

  formValid () {
    for ( const key in this.ccControls ) {
      if ( this.ccControls[ key ].errors ) {
        return false;
      }
    }
    return true;
  }

  isRegionValid = () =>
    !( ( this.selectedCountry.regionRequired ) &&
      !this.ccValue.region || this.ccValue.region?.length > 64 )

  isPostalCodeValid () {
    let isValid = true;
    if ( this.selectedCountry.postalCodeRequired ) {
      if ( !this.ccValue.postalCode || this.ccValue.postalCode.length > 10 ) {
        isValid = false;
      }
    }
    return isValid;
  }

  // Making sure that exp is not in the past.
  isExpValid () {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    let isValid = true;

    if ( this.ccValue.expYear < year ) {
      isValid = false;
    } else if (
      Number( this.ccValue.expYear ) === year &&
      Number( this.ccValue.expMonth ) < month ) {

      isValid = false;
    }

    return isValid;
  }

  showErrorForExp () {
    if ( this.ccControls.expMonth && this.ccControls.expYear ) {
      return this.ccControls.expMonth.touched &&
        this.ccControls.expYear.touched &&
        !this.isExpValid();
    } else {
      return false;
    }
  }
}
