import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { AutopayState } from '../../services/autopay/redux/autopay.model';
import { ReceiptEmailState } from '../../services/receipt-email/redux/receipt-email.model';
import { AUTOPAY_STATE_SELECTOR, PAYMENT_METHOD_TYPE_SELECTOR, RECEIPT_EMAIL_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: [ './payment-confirmation.component.scss' ]
} )
export class PaymentConfirmationComponent implements OnInit {

  @select( PAYMENT_METHOD_TYPE_SELECTOR ) paymentMethodType$: Observable<string>;
  @select( AUTOPAY_STATE_SELECTOR ) autopay$: Observable<AutopayState>;
  @select( RECEIPT_EMAIL_SELECTOR ) receiptEmail$: Observable<ReceiptEmailState>;

  constructor () { }

  ngOnInit () {
  }

  hidePaymentModal () {
    location.reload();
  }
}
