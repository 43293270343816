import { Injectable } from '@angular/core';
import { Action } from '../../../store/action.model';
import { FeatureState } from './feature.model';

@Injectable()
export class FeatureActions {

  static SET_COMPLETE = 'FEATURE:SET_COMPLETE';
  static SET_ERROR = 'FEATURE:SET_ERROR';

  constructor () { }

  setFeaturesAction ( features: Map<string, boolean> ): Action<Partial<FeatureState>> {
    return {
      type: FeatureActions.SET_COMPLETE,
      payload: { features }
    };
  }

  setErrorAction ( error: any ): Action<Partial<FeatureState>> {
    return {
      type: FeatureActions.SET_ERROR,
      payload: { error }
    };
  }
}
