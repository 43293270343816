import { BpeError } from '../../error-response/error-response.model';
import { PaymentMethodCreationResponseDto, PaymentMethodDto } from '../dto/payment-method-dto.model';

export interface PaymentMethod {
  id: string;
  defaultMethod: boolean;
  merchantProcessorType: string;
  cardBrand?: string;
  exp?: string;
  last4: string;
  country?: string;
  paymentMethodType: string;
}

export const MERCHANT_TYPE_STRIPE = 'STRIPE';
export const MERCHANT_TYPE_AUTHORIZE = 'AUTHORIZE';
export const MERCHANT_TYPE_COPAS = 'COPAS';
export const PAYMENT_METHOD_TYPE_CARD = 'card';
export const PAYMENT_METHOD_TYPE_ACH = 'ach';

export const CARD_BRAND_VISA = 'Visa';
export const CARD_BRAND_AMEX = 'American Express';
export const CARD_BRAND_DISCOVER = 'Discover';
export const CARD_BRAND_MASTER = 'MasterCard';

// copas master is the same as CARD_BRAND_MASTER
// copas discover is the same as CARD_BRAND_DISCOVER;
export const COPAS_CARD_BRAND_VISA = 'VISA';
export const COPAS_CARD_BRAND_AMEX = 'AmericanExpress';

export const IMAGE_PATH_VISA = 'assets/images/Visa.svg';
export const IMAGE_PATH_AMEX = 'assets/images/AMEX.svg';
export const IMAGE_PATH_DISCOVER = 'assets/images/Discover.svg';
export const IMAGE_PATH_MASTER = 'assets/images/Mastercard.svg';
export const IMAGE_PATH_DEFAULT = 'assets/images/GenericCard.svg';
export const IMAGE_PATH_ACH = 'assets/images/ACHIcon.svg';

export enum PaymentMethodPostStatus {
  NOT_POSTED,
  IN_PROGRESS,
  COMPLETED,
  VALIDATION_REQUIRED,
  ERROR
}

export interface PaymentMethodState {
  list: PaymentMethod[];
  postInProgress: boolean;
  postSuccess: boolean;
  postStatus: PaymentMethodPostStatus;
  postError: any;
  getInProgress: boolean;
  delete: DeletePaymentMethodState;
  default: DefaultPaymentMethodState;
}

export interface DeletePaymentMethodState {
  postSuccess: boolean;
  postError: BpeError;
  deleteInProgress: boolean;
}

export interface DefaultPaymentMethodState {
  postSuccess: boolean;
  postError: any;
  postInProgress: boolean;
}

export interface AchPostData {
  orgId: string;
  name: string;
  routingNumber: string;
  accountNumber: string;
}

export interface CreatePaymentMethodScaChallengeRequest {
  cardinalJwt: string;
  currency: string;
}

// test data
export const PAYMENT_METHOD_CARD_VISA_COPAS = {
  id: 'testId',
  defaultMethod: true,
  merchantProcessorType: MERCHANT_TYPE_COPAS,
  cardBrand: CARD_BRAND_VISA,
  exp: '12/2022',
  last4: '1234',
  paymentMethodType: PAYMENT_METHOD_TYPE_CARD
};

export const INVALID_PAYMENT_METHOD_CARD_VISA_COPAS = {
  id: '',
  defaultMethod: true,
  merchantProcessorType: MERCHANT_TYPE_COPAS,
  cardBrand: CARD_BRAND_VISA,
  exp: '12/2022',
  last4: '1234',
  paymentMethodType: PAYMENT_METHOD_TYPE_CARD
};

export const PAYMENT_METHOD_CARD_AMEX = {
  id: 'testId2',
  defaultMethod: false,
  merchantProcessorType: MERCHANT_TYPE_STRIPE,
  cardBrand: CARD_BRAND_AMEX,
  exp: '12/2023',
  last4: '4321',
  paymentMethodType: PAYMENT_METHOD_TYPE_CARD
};

export const PAYMENT_METHOD_ACH = {
  id: 'testId3',
  defaultMethod: false,
  merchantProcessorType: MERCHANT_TYPE_AUTHORIZE,
  last4: '6789',
  paymentMethodType: PAYMENT_METHOD_TYPE_ACH
};

const testPaymentMethodId = 'testPaymentMethodId';
const testExp = '12/2023';
const testLast4 = '4321';

export const testParsedPaymentMethod = {
  id: testPaymentMethodId,
  defaultMethod: true,
  merchantProcessorType: MERCHANT_TYPE_STRIPE,
  cardBrand: CARD_BRAND_VISA,
  exp: testExp,
  last4: testLast4,
  country: 'US',
  paymentMethodType: PAYMENT_METHOD_TYPE_CARD
};
export const itemInTestGetPaymentMethodResponse: PaymentMethodDto = {
  id: testPaymentMethodId,
  merchantProcessorType: MERCHANT_TYPE_STRIPE,
  paymentMethodType: PAYMENT_METHOD_TYPE_CARD,
  defaultMethod: true,
  metaData: {
    cardBrand: CARD_BRAND_VISA,
    country: 'US',
    exp: testExp,
    last4: testLast4
  }
};

export const itemInAddCardTest: PaymentMethodCreationResponseDto = {
  paymentMethodKey: 'method-key',
  payerAuthEnrollmentResult: {
    acsUrl: 'acsUrl',
    authenticationTransactionId: 'authenticationTransactionId',
    paReq: 'paReq'
  }
};

export const testGetPaymentMethodResponse = [ itemInTestGetPaymentMethodResponse ];

const copasPaymentMethodId = 'Big Hero 6';
const copasExp = '12/2024';
const copasLast4 = '9876';
const copasCountry = 'IE';

export const copasParsedPaymentMethod = {
  id: copasPaymentMethodId,
  defaultMethod: true,
  merchantProcessorType: MERCHANT_TYPE_COPAS,
  cardBrand: CARD_BRAND_VISA,
  exp: copasExp,
  last4: copasLast4,
  country: copasCountry,
  paymentMethodType: PAYMENT_METHOD_TYPE_CARD
};
