import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Injectable} from '@angular/core';
import {Action} from '../../../store/action.model';
import {MessageService} from '../message.service';
import {UserActions} from '../../user/redux/user.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MessageActions} from './message.actions';
import {of} from 'rxjs';

@Injectable()
export class MessageEpic {
  constructor (
    private messageService: MessageService,
    private messageActions: MessageActions,
  ) {}

  getEpics = () => combineEpics( this.getMessagesEpic.bind( this ) );

  getMessagesEpic ( action$: ActionsObservable<Action<any>> ) {
    return action$.pipe(
        ofType(UserActions.SET_USER_AND_PRINCIPAL_DATA),
        switchMap(() => this.messageService.getMessages().pipe(
            map(it => it.data),
            map(this.messageActions.setMessagesAction),
            catchError((error: any) => of(this.messageActions.setErrorAction(error))),
      ) )
    );
  }
}
