import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { achFormSetting, matchingAchAccountNumber } from './ach-generator.helper';
import { first, takeUntil } from 'rxjs/operators';
import { AchPostData, PaymentMethodState } from '../../services/payment-method/redux/payment-method.model';
// tslint:disable-next-line:max-line-length
import { ORGANIZATION_ID_SELECTOR, PAYMENT_METHOD_POST_ERROR_SELECTOR, PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR, PAYMENT_METHOD_STATE_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-ach-generator',
  templateUrl: './ach-generator.component.html',
  styleUrls: [ './ach-generator.component.scss' ]
} )
export class AchGeneratorComponent implements OnInit, OnDestroy {
  @Output() cancelEvent = new EventEmitter<boolean>();

  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( PAYMENT_METHOD_POST_IN_PROGRESS_SELECTOR ) paymentMethodPostInProgress$: Observable<boolean>;
  @select( PAYMENT_METHOD_POST_ERROR_SELECTOR ) paymentMethodPostError$: Observable<any>;
  @select( PAYMENT_METHOD_STATE_SELECTOR ) paymentMethodState$: Observable<PaymentMethodState>;

  unsubscribe$: Subject<boolean> = new Subject();

  showError: boolean;
  errorMessage: string;

  // ACH form
  achForm: UntypedFormGroup;

  get ach () { return this.achForm; }

  constructor (
    private paymentMethodActions: PaymentMethodActions,
    private reduxStore: NgRedux<AppState>,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit () {
    this.achForm = this.formBuilder.group( achFormSetting,
      {
        validator: matchingAchAccountNumber
      }
    );

    this.paymentMethodState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state ) => {
        if ( state.postInProgress === false && state.postSuccess === true ) {
          this.cancel();
        }
      } );

    this.paymentMethodPostError$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( error ) => {
        if ( error ) {
          this.translate.get( 'PAYMENT_METHOD_GENERATOR_POST_ERROR' )
            .pipe(
              first()
            ).subscribe( ( text ) => this.triggerShowError( text ) );
        }
      } );

    this.paymentMethodState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state ) => {
        if ( state.postInProgress === false && state.postSuccess ) {
          this.cancel();
        }
      } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  cancel () {
    this.cancelEvent.next( true );
  }

  triggerShowError ( message: string ) {
    this.showError = true;
    this.errorMessage = message;
  }

  addAch () {
    const name: string = this.achForm.get( 'name' ).value;
    const routing: string = this.achForm.get( 'routingNumber' ).value;
    const accountNumber: string = this.achForm.get( 'accountNumber' ).value;
    this.organizationId$.pipe(
      first()
    ).subscribe( ( orgId: string ) => {
      const data: AchPostData = {
        orgId: orgId,
        name: name,
        routingNumber: routing,
        accountNumber: accountNumber
      };
      this.reduxStore.dispatch(
        this.paymentMethodActions.postAchPaymentMethod( data )
      );
    } );
  }

}
