import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field.component';
import { HintComponent } from './hint.component';
import { JiveInputDirective } from './input.directive';

@NgModule( {
  imports: [
    CommonModule
  ],
  declarations: [ FormFieldComponent, HintComponent, JiveInputDirective ],
  exports: [ FormFieldComponent, HintComponent, JiveInputDirective ]
} )
export class JiveInputModule { }
