import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive( {
  selector: '[bpAutoFocus]'
} )
export class AutoFocusDirective implements OnInit {

  constructor ( private el: ElementRef ) { }

  ngOnInit () {
    // Without the timeout, by the time, the focus is called, the nativeElement is not ready.
    setTimeout( () => this.el.nativeElement.focus(), 1 );
  }
}
