import { ScaToken } from '../../cardinal/redux/cardinal.model';
import { PaymentMethod } from '../../payment-method/redux/payment-method.model';

export interface PaymentState {
  commandType: string;
  orgId: string;
  currency: string;
  selectedPaymentMethod: PaymentMethod;
  paymentAmount: number;
  invoiceInfo: Object; // payTotal does not need this
  paymentMethodType: string;
  payType: string; // payTotal does not need this
  postInProgress: boolean;
  postSuccess: boolean;
  postStatus: PostPaymentStatus;
  postError: any;
}

export enum PostPaymentStatus {
  NOT_POSTED,
  IN_PROGRESS,
  COMPLETED,
  VALIDATION_REQUIRED,
  ERROR
}

export enum PayType {
  INDIVIDUAL = 'INDIVIDUAL',
  PARTIAL = 'PARTIAL',
  TOTAL = 'TOTAL'
}

// invoiceInfo uses the invoice ID (string) as a key and the payment amount (number) as the value.
// There should be only 1 set of key/Value. Example {IN620000008629: "10"}

export interface CreatePaymentRequest {
  paymentMethodRecordId: string;
  paymentAmount: number;
  payAll: boolean;
  invoiceInfo?: Object;
  payType: string;
  scaToken: ScaToken;
}

export interface ValidatePaymentRequest {
  cardinalJwt: string;
  paymentMethodRecordId: string;
  paymentAmount: number;
  scaToken: ScaToken;
}

export interface ValidatePaymentInfo {
  orgId: string;
  paymentId: string;
  validatePaymentRequest: ValidatePaymentRequest;
  payAll: boolean;
  invoiceInfo: Object;
  paymentMethodType: string;
}

// commandType
export const PAYMENT_COMMAND_PAY_ALL = 'PAY_ALL_COMMAND';
export const PAYMENT_COMMAND_PAY_NOW = 'MAKE_PAYMENT_COMMAND';

// partial validation translate keys

export const VALIDATE_PARTIAL_AMOUNT_ERROR_NOT_VALID = 'VALIDATE_PARTIAL_AMOUNT_ERROR_NOT_VALID';
export const VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_SMALL = 'VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_SMALL';
export const VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_BIG = 'VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_BIG';
export const VALIDATE_PARTIAL_AMOUNT_ERROR_TOO_MANY_DECIMALS = 'VALIDATE_PARTIAL_AMOUNT_ERROR_TOO_MANY_DECIMALS';
export const VALIDATE_PARTIAL_AMOUNT_ERROR_MX_MINIMUM = 'VALIDATE_PARTIAL_AMOUNT_ERROR_MX_MINIMUM';

export const ERROR_MESSAGE_MAKING_PAYMENT_CC = 'PAYMENT_CC_GENERAL_ERROR';
export const ERROR_MESSAGE_MAKING_PAYMENT_ACH = 'PAYMENT_ACH_GENERAL_ERROR';
