<div class="invoice-details" role="main">
  <div [attr.aria-hidden]="showPayNow">
    <div class="invoice-details-title-container">
      <div class="invoice-details-title-section">
        <div class="component-title" [innerHTML]="'INVOICE_DETAILS_TITLE'| translate">
          <!-- Invoice Detail -->
        </div>
        <bp-selected-organization></bp-selected-organization>
      </div>
      <div class="invoice-download-section">

        <div class="invoice-details-download" *ngIf="showFileDownload && readyToShow() && showError() === false">
          <button mat-button
            [matMenuTriggerFor]="menu"
            class="download-invoice-button"
            role="button"
            [attr.aria-label]="'DOWNLOAD_INVOICE_MENU_ARIA' | translate">
            <mat-icon class="invoice-details-download-icon">cloud_download</mat-icon>
            <span *ngIf="!(pdfDownload$ | async)?.inProgress && !(csvDownload$ | async)?.inProgress"
              class="invoice-details-download-text"
              [innerHTML]="'DOWNLOAD_INVOICE'| translate">
              <!-- Download Invoice -->
            </span>

            <span *ngIf="(pdfDownload$ | async)?.inProgress || (csvDownload$ | async)?.inProgress"
              role="alert"
              class="invoice-details-download-text"
              [innerHTML]="'DOWNLOADING_INVOICE'| translate">
              <!-- Downloading Invoice -->
            </span>

          </button>
          <mat-menu #menu="matMenu" class="file-download-menu">
            <button mat-menu-item
              (click)="downloadPdf()"
              class="pdf-download-button"
              role="button"
              [attr.aria-label]="'PDF_DOWNLOAD_ARIA' | translate">
              PDF
            </button>
            <button mat-menu-item
              *ngIf="!isBrazilCountry"
              (click)="downloadCsv()"
              class="csv-download-button"
              role="button"
              [attr.aria-label]="'CSV_DOWNLOAD_ARIA' | translate">
              CSV
            </button>
          </mat-menu>
        </div>

        <div class="nota-fiscais-boleto-button" *ngIf="(boletoLink || notaServicoLink || notaTelecomLink) && readyToShow() && showError() === false">
          <button mat-button
            [matMenuTriggerFor]="menuNota"
            class="download-invoice-button"
            role="button"
            [attr.aria-label]="'NOTA_FISCAIS_BOLETO_MENU_ARIA' | translate">
            <mat-icon class="nota-fiscais-boleto-icon">open_in_new</mat-icon>
            <span class="nota-fiscais-boleto-text"
              [innerHTML]="'NOTA_FISCAIS_BOLETO'| translate">
              <!-- Download Invoice -->
            </span>

          </button>
          <mat-menu #menuNota="matMenu" class="file-download-menu">
            <button *ngIf="boletoLink"
              mat-menu-item
              (click)="openBoleto()"
              class="boleto-download-button"
              role="button"
              [attr.aria-label]="'OPEN_BOLETO_ARIA' | translate">
              <span class="boleto-text" [innerHTML]="'OPEN_BOLETO'| translate">
                <!-- View Boleto file -->
              </span>
            </button>

            <button *ngIf="notaServicoLink"
              mat-menu-item
              (click)="openNotaServico()"
              class="nota-servico-download-button"
              role="button"
              [attr.aria-label]="'OPEN_NOTA_SERVICO_ARIA' | translate">
              <span class="nota-servico-text" [innerHTML]="'OPEN_NOTA_SERVICO'| translate">
                <!-- View Nota Servico file -->
              </span>
            </button>

            <button *ngIf="notaTelecomLink"
              mat-menu-item
              (click)="openNotaTelecom()"
              class="nota-telecom-download-button"
              role="button"
              [attr.aria-label]="'OPEN_NOTA_TELECOM_ARIA' | translate">
              <span class="nota-telecom-text" [innerHTML]="'OPEN_NOTA_TELECOM'| translate">
                <!-- View Nota Telecom file -->
              </span>
            </button>
          </mat-menu>

        </div>
        <div *ngIf="showMexicoFilesDownload && readyToShow() && showError() === false"
          role="button"
          class="mexico-files-download-button"
          [attr.aria-label]="'DOWNLOAD_MEXICO_INVOICE_ARIA' | translate"
          (click)="downloadMexicoFiles()">
          <mat-icon class="invoice-details-download-icon">cloud_download</mat-icon>

          <span *ngIf="!(mexicoPdfDownload$ | async)?.inProgress && !(mexicoXmlDownload$ | async)?.inProgress"
            class="invoice-details-download-text"
            [innerHTML]="'DOWNLOAD_MEXICO_FILES'| translate">
            <!-- Download Invoice -->
          </span>

          <span *ngIf="(mexicoPdfDownload$ | async)?.inProgress || (mexicoXmlDownload$ | async)?.inProgress"
            role="alert"
            class="invoice-details-download-text"
            [innerHTML]="'DOWNLOADING_MEXICO_FILES'| translate">
            <!-- Downloading Invoice -->
          </span>
        </div>

        <div class="download-error" role="alert">
          <div *ngIf="(pdfDownload$ | async)?.error || (mexicoPdfDownload$ | async)?.error || (mexicoXmlDownload$ | async)?.error"
            [innerHTML]="'DOWNLOAD_INVOICE_ERROR'| translate"
            class="download_error-message">
            <!-- Error downloading PDF. Please try again later. -->
          </div>
        </div>
      </div>
    </div>

    <div class="invoice-details-loading loading" *ngIf="readyToShow() === false" [innerHTML]="'LOADING'| translate">
      <!-- loading -->
    </div>

    <div class="invoice-details-error" *ngIf="showError()">
      <bp-data-error></bp-data-error>
    </div>

    <div *ngIf="readyToShow() && showError() === false" class="invoice-details-summary">
      <div class="invoice-details-summary-content">
        <div class="invoice-details-summary-invoice-id">
          <div *ngIf="showMexicoFolioNumber" class="invoice-details-summary-mexico-folio-number">
            Numero de Folio {{( invoiceState$ | async )?.invoice.mexicoFolioNumber}}
          </div>
          <span [innerHTML]="'INVOICE'| translate" *ngIf="isInvoice">
            <!-- Invoice -->
          </span>
          <span [innerHTML]="'CREDIT_MEMO'| translate" *ngIf="!isInvoice">
            <!-- Credit Memo -->
          </span>
          {{ invoiceId }}
        </div>
        <div class="invoice-details-summary-status">
          <div class="invoice-details-summary-status-item" *ngIf="isInvoice">
            <div [innerHTML]="'INVOICE_DATE_DUE'| translate">
              <!-- Date Due -->
            </div>
            <div class="invoice-details-summary-due-date">
              {{ getSpelledDate( ( invoiceState$ | async )?.invoice.dateDue, ( selectedLanguage$ | async ) ) }}
            </div>
          </div>

          <div *ngIf="!isInvoice">
            <div [innerHTML]="'INVOICE_CREATED_DATE'| translate">
              <!-- Date Created -->
            </div>
            <div class="invoice-details-summary-due-date">
              {{ getSpelledDate( ( invoiceState$ | async )?.invoice.dateCreated, ( selectedLanguage$ | async ) )}}
            </div>
          </div>

          <div class="invoice-details-summary-invoice-status invoice-details-summary-status-item">
            <div [innerHTML]="'INVOICE_STATUS'| translate">
              <!-- Status -->
            </div>
            <div [ngStyle]="{'color':getStatusColor((invoiceState$ | async)?.invoice)}"
              [innerHTML]="getStatus((invoiceState$ | async)?.invoice)| translate">
            </div>
          </div>
          <div *ngIf="(invoiceState$ | async)?.invoice.datePaid && isInvoice"
            class="invoice-details-summary-status-item">
            <div [innerHTML]="'INVOICE_DATE_PAID'| translate">
              <!-- Date Paid -->
            </div>
            <div>
              {{getSpelledDate( ( invoiceState$ | async )?.invoice.datePaid, ( selectedLanguage$ | async ) )}}
            </div>
          </div>

          <div *ngIf="usedPaymentMethod && cardPaymentMethod(usedPaymentMethod.paymentMethodType)"
            class="invoice-details-summary-status-item">
            <div [innerHTML]="'INVOICE_PAYMENT_METHOD'| translate">
              <!-- Payment Method -->
            </div>
            <div>
              {{usedPaymentMethod.cardBrand}}
              <span class="invoice-details-summary-last-four">** {{usedPaymentMethod.last4}}</span>
              <span class="invoice-details-summary-exp">{{usedPaymentMethod.exp}}</span>
            </div>
            <div *ngIf="usedPaymentMethod && achPaymentMethod(usedPaymentMethod.paymentMethodType)">
              <span [innerHTML]="'BANK_ACCOUNT'| translate">
                <!-- Bank Account -->
              </span> ** {{usedPaymentMethod.last4}}
            </div>
          </div>
        </div>

        <div class="invoice-details-summary-memo" *ngIf="(invoiceState$ | async)?.invoice.referenceNotes">
          <div [innerHTML]="'INVOICE_MEMO'| translate">
            <!-- Memo -->
          </div>
          <div>
            {{( invoiceState$ | async )?.invoice.referenceNotes}}
          </div>
        </div>

        <div class="invoice-details-summary-total-due" *ngIf="isInvoice">
          <span [innerHTML]="'INVOICE_TOTAL_DUE'| translate">
              <!-- Total Due  -->
          </span>
          <span class="invoice-details-summary-total-due-amount">
            {{getMoneyFormat( ( currency$ | async ),
            ( invoiceState$ | async )?.invoice.totalDue,
            true,
            ( selectedLanguage$| async ) )}}
          </span>
        </div>

        <div class="invoice-details-summary-pay-now" *ngIf="showPayNowButton">
          <button class="jive-button  jive-primary"
            id="invoiceDetailsPayNowButton"
            *ngIf="(invoiceState$ | async)?.invoice.totalDue > 0"
            (click)="setShowPayModal(true)"
            type="button"
            [attr.aria-label]="'PAY_NOW_ARIA' | translate"
            [innerHTML]="'PAY_NOW'| translate">
            <!-- Pay now -->
          </button>
          <button class="jive-button  jive-primary"
            disabled
            *ngIf="(invoiceState$ | async)?.invoice.totalDue === 0"
            [attr.aria-label]="'PAID' | translate"
            [innerHTML]="'PAID'| translate">
            <!-- Paid -->
          </button>
        </div>
      </div>

      <table class="invoice-details-line-item-table">
        <tr class="invoice-details-line-item-table-header">
          <th [innerHTML]="'INVOICE_DETAIL_DESCRIPTION'| translate">
            <!-- Description -->
          </th>
          <th [innerHTML]="'INVOICE_DETAIL_QTY'| translate">
            <!-- Qty -->
          </th>
          <th class="invoice-details-line-item-table-header-money"
            [innerHTML]="'INVOICE_DETAIL_RATE'| translate">
            <!-- Rate -->
          </th>
          <th class="invoice-details-line-item-table-header-money"
            [innerHTML]="'INVOICE_TOTAL'| translate">
            <!-- Total -->
          </th>
        </tr>
        <ng-container *ngFor="let group of billingGroups">
          <tr *ngIf="billingGroups.length > 1">
            <td class="invoice-details-billing-group" colspan="4">{{group}}</td>
          </tr>
          <tr *ngFor="let item of lineItemsGroupedByBillingGroup[group]; let i = index"
            class="invoice-details-line-item-table-line" [className]="getBillingGroupLineClass(i)">
            <td [innerHTML]="getLineItemDescription(item, (selectedLanguage$ | async))"></td>
            <td> {{item.quantity}} </td>
            <td class="invoice-details-line-item-table-line-money">
              {{getMoneyFormat( ( currency$ | async ),
              item.price,
              false,
              ( selectedLanguage$| async ) )}}
            </td>
            <td class="invoice-details-line-item-table-line-money">
              {{getMoneyFormat( ( currency$ | async ),
              item.totalAmount,
              true,
              ( selectedLanguage$| async ) )}}
            </td>
          </tr>
          <tr *ngIf="billingGroups.length > 1" [className]="getBillingGroupLineClass(lineItemsGroupedByBillingGroup[group].length)">
            <td colspan="4" class="billing-group-subtotal"> 
              <span [innerHTML]="'INVOICE_DETAIL_BILLING_GROUP_SUBTOTAL'| translate"
                class="invoice-details-billing-group-subtotal">
                <!-- subtotal -->
              </span>
              {{getMoneyFormat( ( currency$ | async ),
              getSubtotalForBillingGroup( group ),
              true,
              ( selectedLanguage$| async ) )}}
            </td>
          </tr>
        </ng-container>
      </table>

      <div class="invoice-details-total-summary">
        <div [innerHTML]="'INVOICE_TOTAL'| translate">
          <!-- Total  -->
        </div>
        <div class="invoice-details-total-summary-total-amount">
          {{getMoneyFormat( ( currency$ | async ),
          ( invoiceState$ | async )?.invoice.total,
          true,
          ( selectedLanguage$| async ) )}}
        </div>
        <div [innerHTML]="'INVOICE_PAYMENTS_CREDIT'| translate">
          <!-- Payments & Credits -->
        </div>
        <div class="invoice-details-total-summary-payment-amount">
          {{getMoneyFormat( ( currency$ | async ),
          ( ( invoiceState$ | async )?.invoice.total - ( invoiceState$ | async )?.invoice.totalDue ),
          true,
          ( selectedLanguage$| async ) )}}
        </div>
        <div [innerHTML]="'INVOICE_TOTAL_DUE'| translate">
          <!-- Total Due  -->
        </div>
        <div class="invoice-details-total-summary-total-due-amount">
          {{getMoneyFormat( ( currency$ | async ),
          ( invoiceState$ | async )?.invoice.totalDue,
          true,
          ( selectedLanguage$| async ) )}}
        </div>
      </div>
    </div>
  </div>
  <bp-pay-now
    *ngIf="showPayNow"
    (showPayNowModal)="setShowPayModal($event)"></bp-pay-now>

</div>
