import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReceiptEmailActions } from '../../services/receipt-email/redux/receipt-email.actions';
import { NgRedux, select } from '@angular-redux/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { AppState } from '../../store/app-state.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { ReceiptEmailService } from '../../services/receipt-email/receipt-email.service';
import { ReceiptEmailState } from '../../services/receipt-email/redux/receipt-email.model';
import { ORGANIZATION_ID_SELECTOR, RECEIPT_EMAIL_SELECTOR, STORED_RECEIPT_EMAIL_VALUE_SELECTOR } from '../../store/helper';

/*
 Because of the desired UX workflow, we are NOT triggering the update API when the value changes
 unless showActionButtons is true.
 Instead we are updating the desired value in redux on form valueChanges.
 The parent component should call resetReceiptEmail and getReceiptEmail on init.
*/
@Component( {
  selector: 'bp-receipt-email',
  templateUrl: './receipt-email.component.html',
  styleUrls: [ './receipt-email.component.scss' ]
} )
export class ReceiptEmailComponent implements OnInit, OnDestroy {
  @Input() paymentInProgress: boolean;
  @Input() showActionButtons: boolean;

  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( STORED_RECEIPT_EMAIL_VALUE_SELECTOR ) receiptEmail$: Observable<string>;
  @select( RECEIPT_EMAIL_SELECTOR ) receiptEmailState$: Observable<ReceiptEmailState>;

  unsubscribe$: Subject<boolean> = new Subject();
  emailForm: UntypedFormGroup;
  desiredEmail: any;

  postInProgressEmailReceipt = false;

  constructor (
    private reduxStore: NgRedux<AppState>,
    private receiptEmailActions: ReceiptEmailActions,
    private formBuilder: UntypedFormBuilder,
    private receiptEmailService: ReceiptEmailService ) { }

  ngOnInit () {
    this.emailForm = this.formBuilder.group(
      {
        email: [
          null,
          {
            Validators: []
          }
        ]
      },
      {
        validator: this.emailValidation.bind( this )
      }
    );

    this.desiredEmail = this.emailForm.controls[ 'email' ];

    this.desiredEmail.valueChanges
      .pipe( debounceTime( 300 ) )
      .subscribe( ( value: string ) => {
        this.reduxStore.dispatch( this.receiptEmailActions.setDesiredReceiptEmail( value ) );
      } );

    this.receiptEmailState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state ) => {
        this.checkReceiptEmailPostInProgress( state );
      } );

    this.receiptEmail$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( () => {
        this.initEmailValue();
      } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  initEmailValue () {
    this.receiptEmail$.pipe(
      first()
    ).subscribe( ( emailValue ) => {
      this.reduxStore.dispatch( this.receiptEmailActions.setDesiredReceiptEmail( emailValue ) );

      this.emailForm.setValue( {
        email: emailValue === undefined ? null : emailValue
      } );
    } );
  }

  emailValidation ( group: UntypedFormGroup ) {
    const email = group.get( 'email' ).value;

    if ( !this.receiptEmailService.isValidEmail( email ) ) {
      return {
        failedEmailValidation: true
      };
    }
  }

  /*
    Code below here are are used for the action buttons which is available only when showActionButtons is true
  */

  checkReceiptEmailPostInProgress ( emailState: ReceiptEmailState ) {
    this.postInProgressEmailReceipt = emailState.postInProgress;
  }

  saveEmail () {
    this.postInProgressEmailReceipt = true;

    combineLatest( [
      this.receiptEmailState$,
      this.organizationId$
    ] ).pipe( first() )
      .subscribe( ( [ emailState, orgId ] ) => {
        this.reduxStore.dispatch( this.receiptEmailActions.postReceiptEmail( emailState, orgId ) );
      } );
  }

  validateEmail ( state: ReceiptEmailState ) {
    return this.receiptEmailService.isValidEmail( state.desiredEmail );
  }

  resetEmailValue () {
    this.initEmailValue();
  }

}
