import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './components/content/content.component';
import { Error404Component } from './components/errors/error404/error404.component';
import { Error403Component } from './components/errors/error403/error403.component';
import { Error400Component } from './components/errors/error400/error400.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { CallDetailsComponent } from './components/call-details/call-details.component';
import { SmsDetailsComponent } from './components/sms-details/sms-details.component';
import { BillingDetailsComponent } from './components/billing-details/billing-details.component';
import { InvoiceDetailsComponent } from './components/invoice-details/invoice-details.component';
import { PbxSelectComponent } from './components/pbx-select/pbx-select.component';
import { AuthGuardService } from './services/auth/auth.service';

export const ERROR_PATH = 'errors';
export const BASE_PATH = '/billing';

const appRoutes: Routes = [
  {
    path: 'org-select', component: ContentComponent, canActivate: [ AuthGuardService ],
    children: [
      { path: '', component: PbxSelectComponent }
    ]
  },
  {
    path: ':pbxName', component: ContentComponent, canActivate: [ AuthGuardService ],
    children: [
      { path: 'billing-details', component: BillingDetailsComponent },
      { path: 'payment-methods', component: PaymentMethodsComponent },
      { path: 'call-details', component: CallDetailsComponent },
      { path: 'sms-details', component: SmsDetailsComponent },
      { path: 'invoice-details/:invoiceId', component: InvoiceDetailsComponent },
      { path: '', redirectTo: 'billing-details', pathMatch: 'full' }
    ]
  },
  {
    path: ERROR_PATH,
    component: ContentComponent,
    children: [
      { path: '404', component: Error404Component },
      { path: '403', component: Error403Component },
      { path: '400', component: Error400Component }
    ]
  },
  { path: '**', component: ContentComponent }
];

@NgModule( {
  imports: [
    RouterModule.forRoot( appRoutes, { enableTracing: false } )
  ],
  declarations: [],
  exports: [ RouterModule ]
} )

export class AppRouterModule {}
