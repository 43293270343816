import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { PaymentMethodActions } from '../../services/payment-method/redux/payment-method.actions';
import { AppState } from '../../store/app-state.model';
import { takeUntil } from 'rxjs/operators';
import { AutopayState } from '../../services/autopay/redux/autopay.model';
import { AutopayActions } from '../../services/autopay/redux/autopay.actions';
import { DefaultPaymentMethodState, PaymentMethod } from '../../services/payment-method/redux/payment-method.model';
import { ReceiptEmailActions } from '../../services/receipt-email/redux/receipt-email.actions';
import { ReceiptEmailState } from '../../services/receipt-email/redux/receipt-email.model';
import { BillingEmailState } from '../../services/billing-email/redux/billing-email.model';
import { Country, MEXICO, UNITED_STATES } from '../../services/location-converter/assets/model';
import { AUTOPAY_STATE_SELECTOR, BILLING_EMAIL_STATE_SELECTOR, COUNTRY_SELECTOR, CURRENCY_SELECTOR, DEFAULT_PAYMENT_METHOD_SELECTOR, ORGANIZATION_ID_SELECTOR, ORGANIZATION_NAME_SELECTOR, PAYMENT_METHOD_LIST_SELECTOR, RECEIPT_EMAIL_SELECTOR } from '../../store/helper';

export const ACCOUNT_NUMBER_MX = '14796016';
export const CLABE_NUMBER_MX = '106180000147960160';

@Component( {
  selector: 'bp-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: [ './payment-methods.component.scss' ]
} )
export class PaymentMethodsComponent implements OnInit, OnDestroy {

  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( ORGANIZATION_NAME_SELECTOR ) organizationName$: Observable<string>;
  @select( AUTOPAY_STATE_SELECTOR ) autopayState$: Observable<AutopayState>;
  @select( PAYMENT_METHOD_LIST_SELECTOR ) paymentMethodList$: Observable<PaymentMethod[]>;
  @select( DEFAULT_PAYMENT_METHOD_SELECTOR ) paymentMethodDefaultState$: Observable<DefaultPaymentMethodState>;
  @select( RECEIPT_EMAIL_SELECTOR ) receiptEmailState$: Observable<ReceiptEmailState>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( COUNTRY_SELECTOR ) country$: Observable<Country>;
  @select( BILLING_EMAIL_STATE_SELECTOR ) billingEmailsState$: Observable<BillingEmailState>;

  unsubscribe$: Subject<boolean> = new Subject();

  // used for data loading error detection
  dataErrorOrgId: boolean;
  dataErrorAutopay: boolean;
  dataErrorReceiptEmail: boolean;

  postInProgressAutoPay: boolean;
  showAutopay = true;

  errorMessageKey: string;

  isUs: boolean;
  isMx: boolean;
  accountNumber: string;
  clabeNumber: string;

  showMethodGenerator = true;
  showReceiptEmail: boolean;
  savingChangeToastTextKey: string;

  constructor (
    private autopayActions: AutopayActions,
    private paymentMethodActions: PaymentMethodActions,
    private reduxStore: NgRedux<AppState>,
    private receiptEmailActions: ReceiptEmailActions,
  ) { }

  ngOnInit () {
    // reset the state to clean state to avoid any old values being used.
    this.reduxStore.dispatch( this.autopayActions.resetAutopayState() );
    this.reduxStore.dispatch( this.receiptEmailActions.resetReceiptEmail() );
    this.reduxStore.dispatch( this.paymentMethodActions.resetPaymentMethod() );

    this.country$
      .pipe(
        takeUntil( this.unsubscribe$ )
      )
      .subscribe( ( country ) => {
        if ( country ) {
          this.isUs = country === UNITED_STATES;
          this.isMx = country === MEXICO;
          this.accountNumber = ACCOUNT_NUMBER_MX;
          this.clabeNumber = CLABE_NUMBER_MX;

          this.showReceiptEmail = country.canGetReceiptEmail;
        }
      } );

    this.organizationId$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( orgId ) => {
        if ( orgId === undefined ) {
          this.dataErrorOrgId = true;
        } else {
          this.dataErrorOrgId = false;
          this.reduxStore.dispatch(
            this.paymentMethodActions.getPaymentMethodList( orgId )
          );
          this.reduxStore.dispatch( this.autopayActions.getAutopayStatus( orgId ) );
          this.reduxStore.dispatch( this.receiptEmailActions.getReceiptEmail( orgId ) );
        }
      } );

    combineLatest( [
      this.autopayState$,
      this.organizationId$
    ] ).pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( [ autopayState, orgId ] ) => {
        this.checkAutopayStateForDataError( autopayState );
        this.checkAutopayStateForPostError( autopayState );
        this.setAutopayPostInProgress( autopayState );
        this.checkAutopayStateNeedsPosting( autopayState, orgId );
        this.setShowSavingAutopayChangeConfirmation( autopayState );
      } );

    this.receiptEmailState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( emailState: ReceiptEmailState ) => {
        this.checkReceiptEmailPostError( emailState );
        this.checkReceiptEmailDataError( emailState );
      } );

    this.paymentMethodDefaultState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( defaultState: DefaultPaymentMethodState ) => {
        if ( defaultState && defaultState.postSuccess === true ) {
          this.savingChangeToastTextKey = 'PAYMENT_METHODS_DEFAULT_METHOD_SAVED';
          // Default payment method has been updated.
          this.reduxStore.dispatch( this.paymentMethodActions.resetDefaultPayment() );
          this.showToast();
        }
      } );

    this.billingEmailsState$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( state: BillingEmailState ) => {
        if ( state.postInProgress === false &&
          state.postSuccess === false &&
          state.postError !== undefined ) {
          this.errorMessageKey = 'PAYMENT_METHODS_BILLING_EMAIL_POST_ERROR';
        }
      } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  setAutopayPostInProgress ( autopayState: AutopayState ) {
    this.postInProgressAutoPay = autopayState.postInProgress;
  }

  checkAutopayStateForDataError ( autopayState: AutopayState ) {
    // if the value of enrolled is undefined, the initial value was not received properly.
    this.dataErrorAutopay = autopayState.enrolled === undefined;
  }

  checkAutopayStateForPostError ( autopayState: AutopayState ) {
    if ( autopayState.postError !== undefined ) {
      this.errorMessageKey = 'PAYMENT_METHODS_AUTOPAY_POST_ERROR';
      // 'Error saving the autopay status. Please try again later.'
      this.reloadAutopay();
    }
  }

  checkAutopayStateNeedsPosting ( autopayState: AutopayState, orgId ) {
    if ( autopayState.desiredEnrollmentState !== undefined && !autopayState.postInProgress ) {
      this.reduxStore.dispatch(
        this.autopayActions.postAutopayEnrollment(
          orgId,
          autopayState.desiredEnrollmentState )
      );
    }
  }

  setShowSavingAutopayChangeConfirmation ( autopayState: AutopayState ) {
    if ( autopayState.postSuccess === true ) {
      this.savingChangeToastTextKey = 'PAYMENT_METHODS_AUTOPAY_SAVED';
      // Autopay setting has been updated.

      this.showToast();

      this.reduxStore.dispatch( this.autopayActions.resetAutopayPostSuccess() );
    }
  }

  checkReceiptEmailPostError ( emailState: ReceiptEmailState ) {
    if ( emailState.postError !== undefined ) {
      this.errorMessageKey = 'PAYMENT_CONFIRMATION_ERROR_EMAIL';
    }
  }

  checkReceiptEmailDataError ( emailState: ReceiptEmailState ) {
    this.dataErrorReceiptEmail = !emailState.getSuccess;
  }

  // When updating the autopay status fails, we need to show the correct status.
  // The simplest way to trigger re-rendering is this.
  reloadAutopay () {
    this.showAutopay = false;
    setTimeout( () => this.showAutopay = true );
  }

  closeErrorModal () {
    this.errorMessageKey = undefined;
  }

  showToast () {
    const x = document.getElementById( 'toast' );
    x.className = 'show';
    // wait 5 seconds and hide the toast.
    setTimeout( function () { x.className = x.className.replace( 'show', '' ); }, 5000 );
  }

  // quickest way to reset the payment method generator when new payment method was created.
  reloadPaymentMethodGenerator () {
    this.showMethodGenerator = false;
    setTimeout( () => this.showMethodGenerator = true );
  }
}
