import { Component, OnInit } from '@angular/core';
import { select } from "@angular-redux/store";
import { Observable, Subject } from "rxjs";
import { Message, MESSAGES_SELECTOR } from "../../services/message/redux/message.model";
import { takeUntil } from "rxjs/operators";

@Component( {
  selector: 'bp-banner',
  templateUrl: './banner.component.html',
  styleUrls: [ './banner.component.scss' ]
} )

export class BannerComponent implements OnInit {
  @select( MESSAGES_SELECTOR ) messages$: Observable<Message[]>;

  unsubscribe$: Subject<boolean> = new Subject();
  message: string;

  constructor () { }

  ngOnInit () {
    this.messages$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( messages ) => {
      return this.message = filterMessagesToDisplay( messages );
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }
}

export function filterMessagesToDisplay ( messages: Message[] ): string {
  const message = messages[ 0 ];

  if ( isExpired( message ) )
    return undefined;

  return message?.key;
}

function isExpired ( message: Message ): boolean {
  const currentTime = new Date().getTime();
  const expirationTime = getExpirationTime( message );

  return currentTime > expirationTime;
}

function getExpirationTime ( message: Message ): number {
  const expirationTimestamp = message?.expirationTimestamp;
  const expirationTime = expirationTimestamp ? new Date( expirationTimestamp ) : new Date();

  return expirationTime.getTime();
}
