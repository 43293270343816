import { MobileState } from './mobile.model';
import { MobileActions } from './mobile.actions';
import { Action } from '../../../store/action.model';

export const INITIAL_STATE: MobileState = {
  isMobile: undefined
};

export function mobileReducer (
  lastState: MobileState = INITIAL_STATE,
  action: Action<Partial<MobileState>>
): MobileState {
  switch ( action.type ) {
    case MobileActions.SET_MOBILE:
      return { ...lastState, isMobile: action.payload.isMobile };
    default:
      return lastState;
  }
}
