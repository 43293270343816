import { AfterContentInit, ChangeDetectorRef, Component, ContentChild, HostBinding, Input, OnInit } from '@angular/core';
import { JiveInputDirective } from './input.directive';
import { startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'jive-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit, AfterContentInit {

  @ContentChild(JiveInputDirective) _control: JiveInputDirective;
  @HostBinding('class.jive-input-disabled') disabled = false;
  @HostBinding('class.jive-input-invalid') invalid = false;
  @Input() label: string;

  constructor(
      private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit () {}

  ngAfterContentInit () {

    if (this._control) {
      this._control.stateChanges.pipe(
          startWith(null),
          tap(_changes => {
            this.disabled = this._control.disabled;
            this.invalid = this._control.invalid;
            this.changeDetectorRef.detectChanges();
          })
      )
          .subscribe();
    }

  }


}
