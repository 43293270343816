import { Injectable } from '@angular/core';
import { Download, Invoice } from './invoice.model';

@Injectable( {
  providedIn: 'root'
} )
export class InvoiceActions {

  static readonly GET_INVOICE = 'INVOICE:GET';
  static readonly SET_INVOICE = 'INVOICE:SET';
  static readonly GET_INVOICE_LATEST_PAYMENT_METHOD_ID = 'INVOICE:GET_LATEST_PAYMENT_METHOD_ID';
  static readonly SET_INVOICE_LATEST_PAYMENT_METHOD_ID = 'INVOICE:SET_LATEST_PAYMENT_METHOD_ID';
  static readonly DOWNLOAD_INVOICE_PDF = 'INVOICE:DOWNLOAD_PDF';
  static readonly SET_INVOICE_DOWNLOAD = 'INVOICE:SET_DOWNLOAD';
  static readonly DOWNLOAD_INVOICE_CSV = 'INVOICE:DOWNLOAD_CSV';
  static readonly SET_INVOICE_CSV_DOWNLOAD = 'INVOICE:SET_CSV_DOWNLOAD';
  static readonly DOWNLOAD_MEXICO_FILES = 'INVOICE:DOWNLOAD_MEXICO_FILES';
  static readonly SET_MEXICO_PDF_DOWNLOAD = 'INVOICE:SET_MEXICO_PDF_DOWNLOAD';
  static readonly SET_MEXICO_XML_DOWNLOAD = 'INVOICE:SET_MEXICO_XML_DOWNLOAD';

  // reset
  static readonly RESET_INVOICE = 'INVOICE:RESET';

  constructor () { }

  getInvoice ( orgId: string, invoiceId: string ) {
    return { type: InvoiceActions.GET_INVOICE, payload: { orgId, invoiceId } };
  }

  setInvoice ( invoice: Invoice ) {
    return { type: InvoiceActions.SET_INVOICE, payload: { invoice } };
  }

  getLatestPaymentMethodId ( orgId: string, invoiceId: string ) {
    return { type: InvoiceActions.GET_INVOICE_LATEST_PAYMENT_METHOD_ID, payload: { orgId, invoiceId } };

  }

  setLatestPaymentMethodId ( latestPaymentMethodId: String ) {
    return { type: InvoiceActions.SET_INVOICE_LATEST_PAYMENT_METHOD_ID, payload: { latestPaymentMethodId } };
  }

  resetInvoice () {
    return { type: InvoiceActions.RESET_INVOICE };
  }

  downloadPdf ( orgId: string, invoiceId: string ) {
    return { type: InvoiceActions.DOWNLOAD_INVOICE_PDF, payload: { orgId, invoiceId } };
  }

  setPdfDownload ( pdfDownload: Download ) {
    return { type: InvoiceActions.SET_INVOICE_DOWNLOAD, payload: { pdfDownload } };
  }

  downloadCsv ( orgId: string, invoiceId: string ) {
    return { type: InvoiceActions.DOWNLOAD_INVOICE_CSV, payload: { orgId, invoiceId } };
  }

  setCsvDownload ( csvDownload: Download ) {
    return { type: InvoiceActions.SET_INVOICE_CSV_DOWNLOAD, payload: { csvDownload } };
  }

  downloadMexicoFiles ( orgId: string, invoiceId: string, fileName: string ) {
    return { type: InvoiceActions.DOWNLOAD_MEXICO_FILES, payload: { orgId, invoiceId, fileName } };
  }

  setMexicoPdfDownload ( mexicoPdfDownload: Download ) {
    return { type: InvoiceActions.SET_MEXICO_PDF_DOWNLOAD, payload: { mexicoPdfDownload } };
  }

  setMexicoXmlDownload ( mexicoXmlDownload: Download ) {
    return { type: InvoiceActions.SET_MEXICO_XML_DOWNLOAD, payload: { mexicoXmlDownload } };
  }
}
