import {Injectable} from '@angular/core';
import {InvoiceActions} from './invoice.actions';
import {InvoiceService} from '../invoice.service';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {FileService} from '../../file/file.service';
import {MEXICO} from '../../location-converter/assets/model';

@Injectable( {
  providedIn: 'root'
} )
export class InvoiceEpic {

  constructor (
    private invoiceActions: InvoiceActions,
    private invoiceService: InvoiceService,
    private fileService: FileService
  ) { }

  getEpics () {

    return combineEpics(
      this.getAndSetInvoice.bind( this ),
      this.getAndSetLatestPaymentMethodId.bind( this ),
      this.startGetLatestPaymentMethodId.bind( this ),
      this.getAndSetLatestPaymentMethodId.bind( this ),
      this.downloadInvoicePdf.bind( this ),
      this.downloadMexicoPdf.bind( this ),
      this.downloadMexicoXml.bind( this ),
      this.downloadInvoiceCsv.bind( this )
    );
  }

  getAndSetInvoice ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.GET_INVOICE),
        switchMap(
          ( { payload: { orgId, invoiceId } } ) =>
            this.invoiceService.getInvoice( orgId, invoiceId )
              .pipe(
                map( invoice => invoice ? invoice : undefined ),
                map( this.invoiceActions.setInvoice ),
                catchError( () =>
                  of( this.invoiceActions.setInvoice( undefined ) )
                )
              )
        )
      );
  }

  startGetLatestPaymentMethodId ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.GET_INVOICE),
        map( ( { payload: { orgId, invoiceId } } ) =>
          this.invoiceActions.getLatestPaymentMethodId( orgId, invoiceId )
        )
      );
  }

  getAndSetLatestPaymentMethodId ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.GET_INVOICE_LATEST_PAYMENT_METHOD_ID),
        switchMap( ( { payload: { orgId, invoiceId } } ) => this.invoiceService.getLatestPaymentMethodId( orgId, invoiceId )
          .pipe(
            map( this.invoiceActions.setLatestPaymentMethodId ),
            catchError( () =>
              of( this.invoiceActions.setLatestPaymentMethodId( undefined ) )
            )
          )
        )
      );
  }

  downloadInvoicePdf ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.DOWNLOAD_INVOICE_PDF),
        switchMap( ( { payload: { orgId, invoiceId } } ) =>
          this.invoiceService.downloadInvoice( orgId, invoiceId, 'pdf', null )
            .pipe(
              map( ( blob: Blob ) => {
                this.fileService.openSaveDialog( blob, `${ invoiceId }.pdf` );
                return this.invoiceActions.setPdfDownload( { inProgress: false, error: null } );
              } ),
              catchError( ( errorInfo: any ) =>
                of( this.invoiceActions.setPdfDownload( { inProgress: false, error: errorInfo } ) )
              )
            )
        )
      );
  }

  downloadInvoiceCsv ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.DOWNLOAD_INVOICE_CSV),
        switchMap( ( { payload: { orgId, invoiceId } } ) =>
          this.invoiceService.downloadInvoice( orgId, invoiceId, 'csv', null )
            .pipe(
              map( ( blob: Blob ) => {
                this.fileService.openSaveDialog( blob, `${ invoiceId }.csv` );
                return this.invoiceActions.setCsvDownload( { inProgress: false, error: null } );
              } ),
              catchError( ( errorInfo: any ) =>
                of( this.invoiceActions.setCsvDownload( { inProgress: false, error: errorInfo } ) )
              )
            )
        )
      );
  }

  downloadMexicoPdf ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.DOWNLOAD_MEXICO_FILES),
        switchMap( ( { payload: { orgId, invoiceId, fileName } } ) =>
          this.invoiceService.downloadInvoice( orgId, invoiceId, 'pdf', MEXICO )
            .pipe(
              map( ( blob: Blob ) => {
                this.fileService.openSaveDialog( blob, `${ fileName }.pdf` );
                return this.invoiceActions.setMexicoPdfDownload( { inProgress: false, error: null } );
              } ),
              catchError( ( errorInfo: any ) =>
                of( this.invoiceActions.setMexicoPdfDownload( { inProgress: false, error: errorInfo } ) )
              )
            )
        )
      );
  }

  downloadMexicoXml ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(InvoiceActions.DOWNLOAD_MEXICO_FILES),
        switchMap( ( { payload: { orgId, invoiceId, fileName } } ) =>
          this.invoiceService.downloadInvoice( orgId, invoiceId, 'xml', MEXICO )
            .pipe(
              map( ( blob: Blob ) => {
                this.fileService.openSaveDialog( blob, `${ fileName }.xml` );
                return this.invoiceActions.setMexicoXmlDownload( { inProgress: false, error: null } );
              } ),
              catchError( ( errorInfo: any ) =>
                of( this.invoiceActions.setMexicoXmlDownload( { inProgress: false, error: errorInfo } ) )
              )
            )
        )
      );
  }
}
