import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentActions } from '../../services/payment/redux/payment.actions';
import { AppState } from '../../store/app-state.model';
import { NgRedux, select } from '@angular-redux/store';
import { AutopayActions } from '../../services/autopay/redux/autopay.actions';
import { ReceiptEmailActions } from '../../services/receipt-email/redux/receipt-email.actions';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { MERCHANT_TYPE_COPAS, PaymentMethod } from '../../services/payment-method/redux/payment-method.model';
import { ReceiptEmailService } from '../../services/receipt-email/receipt-email.service';
import { PaymentService } from '../../services/payment/payment.service';
import { ReceiptEmailState } from '../../services/receipt-email/redux/receipt-email.model';
import { PaymentState, PostPaymentStatus, ValidatePaymentInfo, ValidatePaymentRequest } from '../../services/payment/redux/payment.model';
import { Invoice } from '../../services/invoice/redux/invoice.model';
import { AutopayState } from '../../services/autopay/redux/autopay.model';
import { AutopayService } from '../../services/autopay/autopay.service';
import { CommonPageSettingService } from '../../services/common-page-setting/common-page-setting.service';
import { BillingEmailActions } from '../../services/billing-email/redux/billing-email.actions';
import { AUTOPAY_ENROLLED_SELECTOR, AUTOPAY_STATE_SELECTOR, CARDINAL_SCA_REQUIREMENT_SELECTOR, CARDINAL_SCA_TOKEN, CARDINAL_STATE_SELECTOR, COUNTRY_SELECTOR, CURRENCY_SELECTOR, INVOICE_SELECTOR, ORGANIZATION_DOMAIN_SELECTOR, ORGANIZATION_ID_SELECTOR, PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR, PAYMENT_METHOD_LIST_SELECTOR, PAYMENT_STATE_SELECTOR, RECEIPT_EMAIL_SELECTOR } from '../../store/helper';
import { CardinalActions } from '../../services/cardinal/redux/cardinal.actions';
import { CardinalState, ScaPurpose, ScaRequirement, ScaToken } from '../../services/cardinal/redux/cardinal.model';
import { environment } from '../../../environments/environment';
import { Country } from "../../services/location-converter/assets/model";

@Component( {
  selector: 'bp-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: [ './pay-now.component.scss' ]
} )

export class PayNowComponent implements OnInit, OnDestroy {
  @Output() showPayNowModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @select( AUTOPAY_ENROLLED_SELECTOR ) autopayEnrolled$: Observable<boolean>;
  @select( AUTOPAY_STATE_SELECTOR ) autopayState$: Observable<AutopayState>;
  @select( CARDINAL_SCA_TOKEN ) scaToken$: Observable<ScaToken>;
  @select( CARDINAL_SCA_REQUIREMENT_SELECTOR ) cardinalScaRequirement$: Observable<ScaRequirement>;
  @select( CARDINAL_STATE_SELECTOR ) cardinalState$: Observable<CardinalState>;
  @select( COUNTRY_SELECTOR ) country$: Observable<Country>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( INVOICE_SELECTOR ) invoice$: Observable<Invoice>;
  @select( ORGANIZATION_DOMAIN_SELECTOR ) selectedDomain$: Observable<string>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( PAYMENT_METHOD_GET_IN_PROGRESS_SELECTOR ) paymentMethodGetInProgress$: Observable<boolean>;
  @select( PAYMENT_METHOD_LIST_SELECTOR ) paymentMethodList$: Observable<PaymentMethod[]>;
  @select( PAYMENT_STATE_SELECTOR ) paymentState$: Observable<PaymentState>;
  @select( RECEIPT_EMAIL_SELECTOR ) receiptEmailState$: Observable<ReceiptEmailState>;

  unsubscribe$: Subject<boolean> = new Subject();

  selectedDomain: string;

  showPaymentGenerator: boolean;
  disablePayNowButton: boolean;
  showReceiptEmail: boolean;

  // used for data loading error detection
  dataErrorOrgId: boolean;
  dataErrorInvoice: boolean;
  dataErrorCurrency: boolean;
  dataErrorPaymentMethodList: boolean;

  // used for data loading detection
  isLoadingAutopay = true;
  isLoadingReceiptEmail = true;
  isLoadingPaymentMethodList = true;
  initialLoadingFinished = false;

  submitPaymentInProgress = false;

  errorMessageKey: string;
  errorCode: string;
  showError = false;

  showResult = false;

  constructor (
    private autopayActions: AutopayActions,
    private autopayService: AutopayService,
    private billingEmailActions: BillingEmailActions,
    private cardinalActions: CardinalActions,
    private cdr: ChangeDetectorRef,
    private commonPageSettingService: CommonPageSettingService,
    private paymentActions: PaymentActions,
    private paymentService: PaymentService,
    private receiptEmailActions: ReceiptEmailActions,
    private receiptEmailService: ReceiptEmailService,
    private reduxStore: NgRedux<AppState>
  ) { }

  ngOnInit () {
    this.reduxStore.dispatch( this.paymentActions.resetPaymentMethod() );
    this.reduxStore.dispatch( this.autopayActions.resetAutopayState() );
    this.reduxStore.dispatch( this.receiptEmailActions.resetReceiptEmail() );
    this.reduxStore.dispatch( this.cardinalActions.reset() );

    // noinspection TypeScriptValidateJSTypes
    Cardinal.configure( {
      logging: {
        level: environment.cardinalLogging
      }
    } );

    // The clean state of billingEmail is expected by the cc-generator.
    this.reduxStore.dispatch( this.billingEmailActions.reset() );

    this.selectedDomain$.pipe(
      first()
    ).subscribe( ( domain: string ) => {
      this.selectedDomain = domain;
    } );

    this.cardinalScaRequirement$
      .pipe(
        takeUntil( this.unsubscribe$ )
      ).subscribe( ( scaRequirement ) => {
      if ( scaRequirement && scaRequirement.scaRequired && scaRequirement.purpose === ScaPurpose.payment ) {
        // noinspection TypeScriptValidateJSTypes
        Cardinal.continue( 'cca',
          {
            'AcsUrl': scaRequirement.paymentCreationResponse.payerAuthEnrollmentResult.acsUrl,
            'Payload': scaRequirement.paymentCreationResponse.payerAuthEnrollmentResult.paReq
          },
          {
            'OrderDetails': {
              'TransactionId': scaRequirement.paymentCreationResponse.payerAuthEnrollmentResult.authenticationTransactionId
            }
          } );
      }
    } );

    this.country$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( country ) =>
      this.showReceiptEmail = country.canGetReceiptEmail
    );

    this.organizationId$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( orgId ) => {
      if ( orgId === undefined ) {
        this.dataErrorOrgId = true;
      } else {
        this.dataErrorOrgId = false;
        this.reduxStore.dispatch( this.autopayActions.getAutopayStatus( orgId ) );
        this.reduxStore.dispatch( this.receiptEmailActions.getReceiptEmail( orgId ) );
        this.reduxStore.dispatch( this.billingEmailActions.getBillingEmail( orgId ) );
      }
    } );

    this.paymentMethodGetInProgress$.pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( paymentMethodGetInProgress ) => {
      this.isLoadingPaymentMethodList =
        this.commonPageSettingService.initialGetInProgress( paymentMethodGetInProgress, undefined );
    } );

    this.paymentMethodList$
      .pipe( takeUntil( this.unsubscribe$ ) )
      .subscribe( ( list: PaymentMethod[] ) => {
        if ( list === undefined ) {
          this.dataErrorPaymentMethodList = true;
        } else {
          this.dataErrorPaymentMethodList = false;
          this.showPaymentGenerator = ( list.length <= 0 );
        }
      } );

    combineLatest( [
      this.invoice$,
      this.organizationId$,
      this.currency$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ invoice, orgId, currency ] ) => {
      if ( invoice === undefined ) {
        this.dataErrorInvoice = true;
      } else if ( orgId === undefined ) {
        this.dataErrorOrgId = true;
      } else {
        this.dataErrorInvoice = false;
        this.dataErrorOrgId = false;
        this.reduxStore.dispatch( this.paymentActions.setPayNowBasicPaymentInfo( orgId, currency ) );
      }
    } );

    combineLatest( [
      this.autopayState$,
      this.receiptEmailState$,
      this.paymentState$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ autopay, receiptEmail, payment ] ) => {

      this.isLoadingReceiptEmail =
        this.commonPageSettingService.initialGetInProgress( receiptEmail.getInProgress, receiptEmail.postInProgress );

      this.isLoadingAutopay =
        this.commonPageSettingService.initialGetInProgress( autopay.getInProgress, autopay.postInProgress );

      if (
        this.commonPageSettingService.checkIfPostIsInProgress( autopay.postInProgress ) ||
        this.commonPageSettingService.checkIfPostIsInProgress( receiptEmail.postInProgress ) ||
        this.commonPageSettingService.checkIfPostIsInProgress( payment.postInProgress ) ) {

        this.submitPaymentInProgress = true;
        this.showError = false;
        this.showResult = false;
      } else if (
        payment.postSuccess === false &&
        payment.postStatus === PostPaymentStatus.ERROR &&
        !this.commonPageSettingService.checkIfPostIsInProgress( payment.postInProgress )
      ) {
        this.submitPaymentInProgress = false;
        this.errorMessageKey = payment?.postError?.errorTranslationKey ?
          payment.postError.errorTranslationKey : 'ERROR_PROCESSING_PAYMENT_DEFAULT';
        this.errorCode = payment.postError?.errorCode;
        this.showError = true;
        this.showResult = false;
        this.cdr.detectChanges();

      } else if (
        payment.postSuccess &&
        payment.postStatus === PostPaymentStatus.COMPLETED &&
        !this.commonPageSettingService.checkIfPostIsInProgress( payment.postInProgress )
      ) {
        this.submitPaymentInProgress = false;
        this.showError = false;
        this.showResult = true;
      }
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  @HostListener( 'window:keydown', [ '$event' ] )
  handleKeyboardEvent ( event: KeyboardEvent ) {
    if ( 'Escape' === event.key ) {
      this.closePayNowModal();
    }
  }

  postPaymentValidation ( jwt ) {
    combineLatest( [
      this.scaToken$,
      this.cardinalScaRequirement$,
      this.paymentState$
    ] ).pipe(
      first()
    ).subscribe( ( [ scaToken, scaRequirement, paymentState ] ) => {
      const validatePaymentRequest: ValidatePaymentRequest = {
        cardinalJwt: jwt,
        paymentMethodRecordId: paymentState.selectedPaymentMethod.id,
        paymentAmount: paymentState.paymentAmount,
        scaToken: scaToken
      };

      const validatePaymentInfo: ValidatePaymentInfo = {
        orgId: paymentState.orgId,
        paymentId: scaRequirement.paymentCreationResponse.transactionId,
        validatePaymentRequest: validatePaymentRequest,
        payAll: false,
        invoiceInfo: paymentState.invoiceInfo,
        paymentMethodType: paymentState.paymentMethodType
      };

      this.reduxStore.dispatch( this.paymentActions.validatePayment( validatePaymentInfo ) );

    } );
  }

  showGenericError () {
    this.errorMessageKey = 'ERROR_PROCESSING_PAYMENT_DEFAULT';
    this.errorCode = null;
    this.showError = true;
  }

  setUpPaymentValidation () {
    const _this = this;
    Cardinal.on( 'payments.validated', function ( data, jwt ) {
      if ( data?.ErrorDescription === 'Success' && data?.Payment?.Type === 'CCA' ) {
        _this.postPaymentValidation( jwt );
      } else if ( data?.ErrorNumber && data?.Payment?.Type === 'CCA' ) {
        _this.showGenericError();
      } else {
        switch ( data.ActionCode ) {
          case 'ERROR':
            _this.showGenericError();
            break;
        }
      }
    } );
  }

  showMain () {
    return this.isThereDataError() === false &&
      this.showLoading() === false &&
      this.showResult === false &&
      this.showError === false;
  }

  isThereDataError () {
    return ( this.dataErrorOrgId === true ) ||
      ( this.dataErrorInvoice === true ) ||
      ( this.dataErrorCurrency === true ) ||
      ( this.dataErrorPaymentMethodList === true );
  }

  showLoading () {
    const isLoading = ( this.isLoadingAutopay === true ) ||
      ( this.isLoadingReceiptEmail === true ) ||
      ( this.isLoadingPaymentMethodList === true );

    if ( isLoading === false && this.initialLoadingFinished === false ) {
      this.initialLoadingFinished = true;
    }

    return ( isLoading === true ) && ( this.initialLoadingFinished === false );
  }

  showDataError () {
    return this.isThereDataError() === true &&
      this.showLoading() === false &&
      this.showResult === false;
  }

  setShowPaymentGenerator ( value: boolean ) {
    if ( !this.submitPaymentInProgress ) {
      this.showPaymentGenerator = value;
    }
  }

  setDisablePayNowButton ( value: boolean ) {
    this.disablePayNowButton = value;
  }

  validateEmail ( state: ReceiptEmailState ) {
    return this.receiptEmailService.isValidEmail( state.desiredEmail );
  }

  validatePayment ( state: PaymentState, currency: string ) {
    return this.paymentService.checkIfPaymentIsValid( state, currency );
  }

  closePayNowModal () {
    this.showPayNowModal.emit( false );
    location.reload();
  }

  submit () {
    // disable the submit button
    this.submitPaymentInProgress = true;

    // if receipt email changed, the post the change then post a payment.
    // Otherwise, just a payment.
    combineLatest( [
      this.receiptEmailState$,
      this.organizationId$,
      this.paymentState$
    ] ).pipe(
      first(),
      map( ( [ emailState, orgId, paymentState ] ) => {

        if ( paymentState.selectedPaymentMethod.merchantProcessorType === MERCHANT_TYPE_COPAS ) {
          this.setUpPaymentValidation();
        }

        if ( this.receiptEmailService.needsToUpdateReceiptEmail( emailState ) ) {
          this.reduxStore.dispatch(
            this.receiptEmailActions.postReceiptEmailThenPayment( emailState, orgId, paymentState )
          );
        } else {
          this.reduxStore.dispatch( this.paymentActions.createPayment( paymentState ) );
        }
      } )
    ).subscribe();

    // check if autopay status changed
    combineLatest( [
      this.autopayState$,
      this.organizationId$
    ] ).pipe(
      first(),
      filter( ( [ autopayState, _ ] ) => this.autopayService.autoPaymentNeedsToBeUpdated( autopayState ) ),
      map( ( [ autopayState, organizationId ] ) => this.reduxStore.dispatch(
        this.autopayActions.postAutopayEnrollment(
          organizationId,
          autopayState.desiredEnrollmentState )
      ) )
    ).subscribe();
  }
}
