<div class="payment-method-list" role="region" aria-labelledby="payment-method-list-title">
  <div
    id="payment-method-list-title"
    class="payment-method-list-title input-title"
    role="heading"
    aria-level="3"
    [attr.aria-label]="'PAYMENT_METHOD_LIST_TITLE_ARIA' | translate"
    [innerHTML]="'PAYMENT_METHOD_LIST_TITLE'| translate">
    <!-- Payment Methods -->
  </div>

  <div class="invoice-table-loading loading"
    *ngIf="showLoading"
    [innerHTML]="'LOADING'| translate"
    aria-busy="true">
    <!-- loading -->
  </div>

  <div class="payment-method-list-empty"
    *ngIf="show && (paymentMethodList$ | async)?.length === 0 && showLoading === false">
    <img class="payment-method-list-empty-img" [src]="noPaymentMethodImgPath" alt="'PAYMENT_METHOD_LIST_NO_METHODS'| translate">
    <div class="payment-method-list-empty-title" [innerHTML]="'PAYMENT_METHOD_LIST_NO_METHODS'| translate">
      <!-- No Payment Methods -->
    </div>
    <div class="payment-method-list-empty-text" [innerHTML]="'PAYMENT_METHOD_LIST_NO_METHODS_TEXT'| translate">
      <!-- No payment methods to manage. Add a payment method. -->
    </div>
  </div>

  <table class="payment-method-list-table"
    *ngIf="show && !dataError && (paymentMethodList$ | async)?.length > 0 && showLoading === false">
    <thead>
    <tr>
      <th [innerHTML]="'PAYMENT_METHOD_LIST_PAYMENT_TYPE'| translate">
        <!-- Type -->
      </th>
      <th [attr.aria-label]="'PAYMENT_METHOD_LIST_EXP_DATE_ARIA' | translate">
        <span aria-hidden="true" [innerHTML]="'PAYMENT_METHOD_LIST_EXP_DATE'| translate"></span>
      </th>
      <th [innerHTML]="'PAYMENT_METHOD_LIST_DEFAULT'| translate">
        <!-- Default -->
      </th>
      <th [attr.aria-label]="'PAYMENT_METHOD_LIST_ACTIONS' | translate"></th>
    </tr>
    </thead>
    <ng-container *ngIf="dataError === false">
      <tr *ngFor="let paymentMethod of (paymentMethodList$ | async)">
        <td>
          <img [src]="getPaymentMethodImageUrl(paymentMethod)" class="payment-method-logo" aria-hidden="true">
          <span class="payment-method-last-four"
            role="text"
            [attr.aria-label]="'PAYMENT_METHOD_LIST_PAYMENT_TYPE_ARIA' | translate: {brandName: paymentMethod.cardBrand, number: paymentMethod.last4}">
            ** {{paymentMethod.last4}}
          </span>
        </td>
        <td>{{paymentMethod.exp}}</td>
        <td class="payment-method-default">
          <input type="radio"
            name="paymentMethod.id"
            value="paymentMethod.defaultMethod"
            [checked]="paymentMethod.defaultMethod"
            (change)="updateDefaultMethod(paymentMethod.id)">
        </td>
        <td>
          <i class="material-icons payment-method-delete"
            role="button"
            [attr.aria-label]="'PAYMENT_METHOD_LIST_DELETE_ARIA' | translate: {brandName: paymentMethod.cardBrand, number: paymentMethod.last4}"
            *ngIf="showDelete((paymentMethodList$ | async), paymentMethod)"
            (click)="confirmBeforeDelete(paymentMethod)">
            delete
          </i>
          <span *ngIf="!showDelete((paymentMethodList$ | async), paymentMethod)" role="button" aria-disabled="true" [attr.aria-label]="'DELETE' | translate"></span>
        </td>
      </tr>
    </ng-container>

    <tr class="payment-method-list-data-error" *ngIf="dataError && showLoading === false">
      <td colspan="4">
        <bp-data-error></bp-data-error>
      </td>
    </tr>
  </table>

  <div class="bp-modal payment-method-list-delete-confirmation-modal" *ngIf="showDeleteConfirmationModal" role="dialog" aria-modal="true">
    <div class="bp-modal-content">
      <div class="component-title" [innerHTML]="'PAYMENT_METHOD_LIST_DELETE_TITLE'| translate">
        <!-- Delete Payment Option -->
      </div>
      <div class="payment-method-list-delete-confirmation-modal-message">
        <span [innerHTML]="'PAYMENT_METHOD_LIST_DELETE_MESSAGE' |
          translate: {paymentMethodImage:getPaymentMethodImageUrl(selectedPaymentMethodToDelete), last4: selectedPaymentMethodToDelete.last4}">
        </span>
      </div>
      <div class="payment-method-list-delete-confirmation-modal-action">
        <button type="reset"
          class="jive-button jive-medium payment-method-list-delete-confirmation-modal-action-cancel"
          [innerHTML]="'CANCEL'| translate"
          [attr.aria-label]="'CANCEL' | translate"
          [attr.disabled]="deleteInProgress ? '' : null"
          (click)="hideDeletePaymentMethodConfirmationModal()">
        </button>
        <button
          class="jive-button jive-primary jive-medium"
          type="submit"
          [attr.aria-label]="'DELETE' | translate"
          *ngIf="deleteInProgress===false"
          (click)="deletesSelectedPaymentMethod()" [innerHTML]="'DELETE'| translate">
          <!-- Delete -->
        </button>
        <button
          *ngIf="deleteInProgress"
          disabled
          [innerHTML]="'DELETING'| translate"
          [attr.aria-label]="'DELETING' | translate"
          class="jive-button jive-primary jive-medium">
          <!-- Deleting -->
        </button>
      </div>
    </div>
  </div>

  <div class="bp-modal payment-method-list-error-modal" *ngIf="errorMessageKey">
    <div class="bp-modal-content">
      <div class="component-title" [innerHTML]="'ERROR'| translate">
        <!-- Error -->
      </div>
      <div class="payment-method-error-content">
        <span [innerHTML]="errorMessageKey| translate" class="payment-method-list-error-message"></span>
        <span *ngIf="(delete$|async)?.postError?.errorCode && (delete$|async)?.postError?.genericError">
          ({{( delete$|async )?.postError?.errorCode}})
        </span>
      </div>
      <div class="payment-method-list-error-action">
        <button
          class="jive-button jive-primary jive-medium"
          type="reset"
          [attr.aria-label]="'CLOSE' | translate"
          *ngIf="deleteInProgress===false"
          (click)="closeErrorModal()"
          [innerHTML]="'CLOSE'| translate">

        </button>
      </div>
    </div>
  </div>
</div>
