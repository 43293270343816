import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgRedux, NgReduxModule} from '@angular-redux/store';
import {rootReducer} from './root.reducer';
import {AppState} from './app-state.model';
import {createLogger} from 'redux-logger';
import * as persistState from 'redux-localstorage';
import {RootEpic} from './root.epics';
import {environment} from '../../environments/environment';
import {createEpicMiddleware} from 'redux-observable-es6-compat';
import {Environment} from '../services/core/service-helpers';
import {applyMiddleware, compose, createStore} from 'redux';


@NgModule( {
  imports: [
    CommonModule,
    NgReduxModule
  ],
  declarations: [],
  providers: [ RootEpic ]
} )

export class StoreModule {
  constructor ( private ngRedux: NgRedux<AppState>, private rootEpic: RootEpic ) {
    const rootEpicList = this.rootEpic.getEpicList();
    const epicMiddleware = createEpicMiddleware();
    const middelwares = [ epicMiddleware ];
    const appStorageName = environment.appName.toLowerCase().split( ' ' ).join( '-' );

    if ( environment.environment !== Environment.PROD ) {
      middelwares.push( createLogger( { collapsed: true } ) );
    }

    const enhancers = compose(
      persistState( 'auth', { key: `jive-${ appStorageName }/auth` } ),
      persistState( 'user', { key: `jive-${ appStorageName }/user` } ),
      persistState( 'language', { key: `jive-${ appStorageName }/language` } ),
      persistState( 'ccGenerator', { key: `jive-${ appStorageName }/ccGenerator` } )
    );

    const store = createStore(
      rootReducer,
      {},
      compose( applyMiddleware( ...middelwares ), enhancers )
    );
    this.ngRedux.provideStore( store );

    epicMiddleware.run( rootEpicList );
  }
}
