import {Injectable} from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class FileService {

  constructor () { }

  openSaveDialog ( blob: Blob, fileName: string ) {

    const encodedBlob = new Blob( [ '\ufeff', blob ] );

    this.openNewSaveDialog(encodedBlob, fileName);
  }

  openNewSaveDialog (blob: Blob, fileName: string) {
    if ( window.navigator.msSaveOrOpenBlob ) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const downloadDoc = document.createElement( 'a' );
      downloadDoc.href = window.URL.createObjectURL( blob );
      downloadDoc.target = '_blank';
      downloadDoc.download = fileName;
      document.body.appendChild( downloadDoc );
      downloadDoc.click();
      document.body.removeChild( downloadDoc );
    }
  }
}

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}
