import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardinalActions } from './redux/cardinal.actions';
import { CardinalService } from './cardinal.service';
import { CardinalEpic } from './redux/cardinal.epic';

@NgModule( {
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CardinalActions,
    CardinalService,
    CardinalEpic
  ]
} )
export class CardinalModule {}
