import { Injectable } from '@angular/core';
import { BillingEmail } from './billing-email.model';

@Injectable( {
  providedIn: 'root'
} )
export class BillingEmailActions {
  static readonly GET_BILLING_EMAIL = 'BILLING_EMAIL:GET';
  static readonly SET_BILLING_EMAIL = 'BILLING_EMAIL:SET';
  static readonly RESET_BILLING_EMAIL_STATE = 'BILLING_EMAIL:RESET';
  static readonly SAVE_BILLING_EMAIL = 'BILLING_EMAIL:SAVE';
  static readonly SET_SAVE_SUCCESS = 'BILLING_EMAIL:SAVE_SUCCCESS';
  static readonly SET_SAVE_ERROR = 'BILLING_EMAIL:SAVE_ERROR';

  constructor () { }

  reset () {
    return { type: BillingEmailActions.RESET_BILLING_EMAIL_STATE };
  }

  getBillingEmail ( orgId: string ) {
    return { type: BillingEmailActions.GET_BILLING_EMAIL, payload: { orgId } };
  }

  setBillingEmail ( stored: BillingEmail[] ) {
    return { type: BillingEmailActions.SET_BILLING_EMAIL, payload: { stored } };
  }

  saveBillingEmail ( orgId: string, billingEmail: BillingEmail[] ) {
    return { type: BillingEmailActions.SAVE_BILLING_EMAIL, payload: { orgId, billingEmail } };
  }

  setSaveSuccess ( stored: BillingEmail[] ) {
    return { type: BillingEmailActions.SET_SAVE_SUCCESS, payload: { stored } };
  }

  setSaveError ( postError: any ) {
    return { type: BillingEmailActions.SET_SAVE_ERROR, payload: { postError } };
  }
}

