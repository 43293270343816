<div class="payment-methods" role="region" aria-labelledby="page-title-text">
  <div class="page-title">
    <div class="page-title-text"
      id="page-title-text"
      role="heading"
      aria-level="1"
      [attr.aria-label]="('PAYMENT_METHODS_TITLE_ARIA' | translate: {organizationName: (organizationName$ | async)})"
      [innerHTML]="'PAYMENT_METHODS_TITLE'| translate">
      <!-- Payment Options -->
    </div>
    <bp-selected-organization></bp-selected-organization>
  </div>
  <div class="component-title payment-methods-settings-title" [innerHTML]="'PAYMENT_METHODS_SETTING_TITLE'| translate" role="heading" aria-level="2">
    <!-- Payment Settings -->
  </div>

  <bp-payment-method-list></bp-payment-method-list>
  <ng-container *ngIf="(paymentMethodList$| async)?.length > 0" id="payment-method-autopay">
    <div class="payment-methods-autopay" *ngIf="dataErrorAutopay === false &&
      dataErrorOrgId === false && showAutopay">
      <bp-autopay></bp-autopay>
    </div>

    <div class="payment-methods-email">
      <ng-container *ngIf="dataErrorReceiptEmail===false && showReceiptEmail" id="receipt-email">
        <bp-receipt-email [showActionButtons]="true"></bp-receipt-email>
      </ng-container>

      <div class="payment-methods-email-error" *ngIf="dataErrorReceiptEmail">
        <div class="input-title payment-methods-email-error-label"
          [innerHTML]="'RECEIPT_EMAIL_LABEL'| translate">
        </div>
        <bp-data-error></bp-data-error>
      </div>
    </div>
  </ng-container>

  <div class="payment-methods-billing-emails">
    <bp-billing-email></bp-billing-email>
  </div>

  <div class="component-title payment-methods-payment-method-generator-title"
    role="heading"
    aria-level="2"
    [innerHTML]="'PAYMENT_METHODS_PAYMENT_METHOD_GENERATOR_TITLE'| translate">
    <!-- Add a Payment Method -->
  </div>
  <div class="payment-methods-payment-methods-generator">
    <div *ngIf="isUs" class="payment-methods-payment-method-generator-text"
      [innerHTML]="'PAYMENT_METHODS_PAYMENT_METHOD_GENERATOR_TEXT_US'| translate">
      <!-- You can pay with Credit/Debit Card, Bank Account (ACH),or by mailing a check. -->
    </div>
    <div *ngIf="isUs === false"
      [innerHTML]="'PAYMENT_METHODS_PAYMENT_METHOD_GENERATOR_TEXT'| translate">
      <!-- You can pay with Credit/Debit Card. -->
    </div>
    <bp-payment-method-generator
      *ngIf="showMethodGenerator"
      (showPaymentMethodGenerator)="reloadPaymentMethodGenerator()">
    </bp-payment-method-generator>

    <div>
      <i class="material-icons payment-methods-encryption-icon" aria-hidden="true">
        lock
      </i>
      <span class="payment-methods-encryption-text"
        [innerHTML]="'PAYMENT_METHODS_ENCRYPTION_TEXT'| translate">
        <!-- Payments are secured with 256-bit encryption. -->
      </span>
    </div>

    <div class="payment-methods-check-address" *ngIf="isUs">
      <div [innerHTML]="'PAYMENT_METHODS_CHECK_TEXT'| translate">
        <!-- To pay by check, please send to: -->
      </div>
      <div [innerHTML]="'GOTO_LEGAL_NAME'| translate">
        <!-- Goto Communications Inc. -->
      </div>
      <div [innerHTML]="'JIVE_PO_BOX'| translate">
        <!-- PO BOX 412252 -->
      </div>
      <div [innerHTML]="'JIVE_ADDRESS'| translate">
        <!-- Boston, MA 02241-2252 -->
      </div>
    </div>

    <div class="payment-methods-bank-transfer" *ngIf="isMx">
      <div [innerHTML]="'PAYMENT_METHODS_BANK_TRANSFER' | translate: {accountNumber:accountNumber, clabeNumber:clabeNumber}">
        <!-- To pay by bank transfer,please submit to: -->
      </div>
      <div [innerHTML]="'PAYMENT_METHODS_INTERNATIONAL_BANK_TRANSFER' | translate">
        <!-- To pay by international bank transfer, please submit to: -->
      </div>
      <div [innerHTML]="'PAYMENT_METHODS_LOCAL_COMPENSATION' | translate">
        <!-- To pay by SPEI/TEF(local compensation) , please submit to: -->
      </div>
    </div>

    <div class="payment-methods-contact-info-text">
      <bp-contact-info></bp-contact-info>
    </div>
  </div>

  <div class="payment-methods-error-modal bp-modal" *ngIf="errorMessageKey">
    <div class="payment-methods-error-modal-content bp-modal-content">
      <div class="component-title" [innerHTML]="'ERROR'| translate">
        <!-- Error -->
      </div>
      <div class="payment-methods-error-modal-message" [innerHTML]="errorMessageKey| translate">
      </div>
      <div class="payment-methods-error-modal-action">
        <button
          (click)="closeErrorModal()"
          class="jive-button jive-small jive-primary"
          [innerHTML]="'CLOSE'| translate"
          type="reset">
          <!-- close -->
        </button>
      </div>
    </div>
  </div>

  <div class="payment-methods-change-saved">
    <div class="payment-methods-change-saved-text" id="toast"
      [innerHTML]="savingChangeToastTextKey| translate">
    </div>
  </div>
</div>
