import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureActions } from './redux/feature.actions';
import { FeatureService } from './feature.service';
import { FeatureEpic } from './redux/feature.epic';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    FeatureActions,
    FeatureEpic,
    FeatureService,
  ],
  declarations: []
} )
export class FeatureModule {}
