import { Action } from '../../../store/action.model';
import { FeatureState } from './feature.model';
import { FeatureActions } from './feature.actions';

export const INITIAL_STATE: FeatureState = {
  loaded: false,
  hasError: false,
  features: new Map<string, boolean>(),
  error: undefined
};

export function featureReducer (
  lastState: FeatureState = INITIAL_STATE,
  action: Action<Partial<FeatureState>>
): FeatureState {
  switch ( action.type ) {
    case FeatureActions.SET_COMPLETE:
      return {
        ...INITIAL_STATE,
        loaded: true,
        features: action.payload.features
      };
    case FeatureActions.SET_ERROR:
      return {
        ...INITIAL_STATE,
        loaded: true,
        hasError: true,
        error: action.payload.error
      };
    default:
      return lastState;
  }
}
