<div class="pay-total" role="region">
  <div class="pay-total-content">
    <div class="pay-total-loading"
      *ngIf="showLoading()"
      [innerHTML]="'LOADING'| translate"
      aria-busy="true">
      <!-- loading -->
    </div>
    <div *ngIf="showDataError()" class="pay-total-data-error">
      <div class="pay-total-data-error-icon">
        <i class="material-icons">
          error_outline
        </i>
      </div>
      <div [innerHTML]="'ERROR_DATA_NOT_AVAILABLE'| translate">
        <!-- This Information is not currently available. -->
      </div>
      <div [innerHTML]="'ERROR_TRY_LATER'| translate">
        <!-- Please refresh or check back later. -->
      </div>
      <button class="jive-button jive-small pay-total-error-cancel"
        (click)="closePaymentModal()"
        [attr.aria-label]="'CLOSE' | translate"
        type="button"
        [innerHTML]="'CLOSE'| translate">
        <!-- close -->
      </button>
    </div>

    <div class="pay-total-main" *ngIf="showMain()">
      <div class="component-title" [innerHTML]="'PAY_TOTAL_TITLE'| translate">
        <!-- Pay Current Balance -->
      </div>

      <bp-payment-method-selector
        [paymentInProgress]="submitPaymentInProgress"
        class="pay-total-child-component">
      </bp-payment-method-selector>

      <div class="pay-total-add-payment-method-button">
        <a
          [routerLink]="['/', selectedDomain, 'payment-methods']"
          routerLinkActive="active"
          class="nav-button pay-total-add-payment-method-link">
          <span class="material-icons add-payment-method-icon">
          launch
          </span>
          <span class="add-payment-method-message">
            <!-- Add new payment method -->
            {{ 'ADD_PAYMENT_METHOD' | translate | uppercase }}
          </span>
        </a>
      </div>

      <bp-autopay class="pay-total-child-component"
        [paymentInProgress]="submitPaymentInProgress"
        *ngIf="(autopayEnrolled$|async) === false">
      </bp-autopay>
      <div class="pay-total-receipt-email">
        <bp-receipt-email class="pay-total-child-component"
          *ngIf="showReceiptEmail"
          [paymentInProgress]="submitPaymentInProgress">
        </bp-receipt-email>
      </div>
      <div class="pay-total-balance-summary">
        <span [innerHTML]="'PAY_TOTAL_BALANCE'| translate" class="input-title">
            <!-- Payment Balance   -->
        </span>
        <span class="pay-total-balance-summary-amount"> {{totalAmount}}</span>
      </div>

      <div class="pay-total-action-buttons">
        <button class="jive-button pay-total-cancel-button"
          [disabled]="submitPaymentInProgress"
          (click)="closePaymentModal()"
          [attr.aria-label]="'CANCEL' | translate"
          type="button"
          [innerHTML]="'CANCEL'| translate">
          <!-- cancel -->
        </button>
        <button class="jive-button jive-primary" (click)="submit()"
          [innerHTML]="'SUBMIT'| translate"
          [attr.aria-label]="'SUBMIT' | translate"
          type="submit"
          *ngIf="submitPaymentInProgress === false"
          [disabled]="(validateEmail(receiptEmailState$| async) === false) ||
          ((paymentMethodList$ | async)?.length === 0 ) ||
          (validatePayment((paymentState$ | async), (currency$ | async)) === false)">
          <!-- Submit -->
        </button>
        <button class="jive-button jive-primary" disabled
          [attr.aria-label]="'PROCESSING' | translate"
          type="button"
          *ngIf="submitPaymentInProgress"
          role="alert"
          aria-busy="true">
          <!-- processing -->
          <bp-processing></bp-processing>
        </button>
      </div>
    </div>

    <bp-payment-confirmation *ngIf="showResult"></bp-payment-confirmation>

    <bp-payment-error *ngIf="showError"
      [errorMessageKey]="errorMessageKey"
      [errorCode]="errorCode">
    </bp-payment-error>
  </div>
</div>

