import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { APPLIED, Invoice, OVERDUE, PAID, PARTAPPLIED, PARTPAID, UNAPPLIED, UNPAID } from './redux/invoice.model';
import { Country } from '../location-converter/assets/model';
import { map } from 'rxjs/operators';

@Injectable( { providedIn: 'root' } )
export class InvoiceService {

  constructor ( private http: HttpClient ) {}

  getInvoice = ( orgId: string, invoiceId: string ) =>
    this.http.get<Invoice>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/invoices/${ invoiceId }` )

  getLatestPaymentMethodId = ( orgId: string, invoiceId: string ) =>
    this.http.get<String>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/invoices/${ invoiceId }/payments/latest/payment-method-id` )

  getTotalDue = ( orgId: string ) =>
    this.http.get<Number>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/invoices/total-due` )

  isOverDue ( dateDue: string ) {
    const now = new Date().valueOf();

    const due = new Date( dateDue );
    // The invoice is not overdue on the date due. It becomes past due the day after the due date.
    const lastDateDueSecond = due.setDate( due.getDate() + 1 ).valueOf();

    return now > lastDateDueSecond;
  }

  getInvoiceStatus ( invoice: Invoice ): string {
    let statusKey: string;
    if ( invoice.total >= 0 ) {
      if ( invoice.totalDue === 0 ) {
        statusKey = PAID;
      } else if ( invoice.totalDue > 0 ) {

        if ( this.isOverDue( invoice.dateDue ) ) {
          statusKey = OVERDUE;
        } else if ( invoice.total === invoice.totalDue ) {
          statusKey = UNPAID;
        } else {
          statusKey = PARTPAID;
        }
      }
    } else {
      if ( invoice.total === invoice.totalDue ) {
        statusKey = UNAPPLIED;
      } else if ( invoice.totalDue === 0 ) {
        statusKey = APPLIED;
      } else {
        statusKey = PARTAPPLIED;
      }
    }

    return statusKey;
  }

  getInvoiceStatusColor ( invoice: Invoice ) {
    const status = this.getInvoiceStatus( invoice );
    if ( status === OVERDUE ) {
      return 'red';
    } else if ( status === PARTPAID || status === UNPAID ) {
      return '#ffc107';
    } else {
      return '';
    }
  }

  downloadInvoice ( orgId: string, invoiceId: string, format: string, country: Country ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/invoice-files/${ invoiceId }`;
    let searchParams = new HttpParams( { encoder: new HttpUrlEncodingCodec() } )
      .append( 'format', format );

    if ( country ) {
      searchParams = searchParams.append( 'countryCode', country?.code );
    }

    return this.http.get<Blob>( `${ path }?${ searchParams.toString() }`, { observe: 'response', responseType: 'blob' as 'json' } )
      .pipe( map( it => it.body ) );
  }
}
