import { Injectable } from '@angular/core';
// tslint:disable:max-line-length
import { CreatePaymentRequest, PAYMENT_COMMAND_PAY_ALL, PAYMENT_COMMAND_PAY_NOW, PaymentState, PayType, VALIDATE_PARTIAL_AMOUNT_ERROR_MX_MINIMUM, VALIDATE_PARTIAL_AMOUNT_ERROR_NOT_VALID, VALIDATE_PARTIAL_AMOUNT_ERROR_TOO_MANY_DECIMALS, VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_BIG, VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_SMALL, ValidatePaymentRequest } from './redux/payment.model';
import { PAYMENT_METHOD_TYPE_ACH, PAYMENT_METHOD_TYPE_CARD } from '../payment-method/redux/payment-method.model';
import { environment } from '../../../environments/environment';
import { PaymentCreationResponse, ScaToken } from '../cardinal/redux/cardinal.model';
import { MX_CURRENCY } from '../money-converter/money-converter.model';
import { HttpClient } from '@angular/common/http';

const STRIPE_MX_MIN_AMOUNT = 10;

@Injectable( {
  providedIn: 'root'
} )

export class PaymentService {

  constructor ( private http: HttpClient ) { }

  checkIfPaymentIsValid ( state: PaymentState, currency ) {
    if ( state?.selectedPaymentMethod ) {
      if ( state.commandType === PAYMENT_COMMAND_PAY_ALL ) {
        return this.checkIfStringIsValid( state.orgId ) &&
          this.checkIfStringIsValid( state.selectedPaymentMethod.id ) &&
          this.checkIfAmountIsValid( state.paymentAmount, state.commandType, currency ) &&
          this.checkIfPaymentMethodTypeIsValid( state.paymentMethodType );
      } else if ( state.commandType === PAYMENT_COMMAND_PAY_NOW ) {
        return this.checkIfStringIsValid( state.orgId ) &&
          this.checkIfStringIsValid( state.selectedPaymentMethod.id ) &&
          this.checkIfAmountIsValid( state.paymentAmount, state.commandType, currency ) &&
          this.checkIfPaymentMethodTypeIsValid( state.paymentMethodType ) &&
          this.checkIfPayTypeIsValid( state.payType ) &&
          this.checkIfInvoiceInfoIsValid( state, currency );
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkIfInvoiceInfoIsValid ( state: PaymentState, currency: string ) {
    const key = Object.keys( state.invoiceInfo );

    return ( key.length === 1 ) &&
      ( this.checkIfAmountIsValid( state.invoiceInfo[ key[ 0 ] ], state.commandType, currency ) );
  }

  checkIfPayTypeIsValid ( value: string ) {
    return value === PayType.INDIVIDUAL || value === PayType.PARTIAL;
  }

  checkIfStringIsValid ( value: string ) {
    return value && ( value.length > 0 );
  }

  checkIfAmountIsValid ( amount: number, commandType: string, currency: string ) {
    if ( MX_CURRENCY === currency && amount < STRIPE_MX_MIN_AMOUNT && amount > 0 ) {
      return false;
    } else if ( commandType === PAYMENT_COMMAND_PAY_ALL ) {
      return ( amount !== undefined ) && ( amount !== null );
    }
    return ( amount !== undefined ) && ( amount !== null ) && ( amount >= 0 );
  }

  checkIfPaymentMethodTypeIsValid ( type: string ) {
    return ( type === PAYMENT_METHOD_TYPE_CARD || type === PAYMENT_METHOD_TYPE_ACH );
  }

  validatePartialAmount ( amount: number, totalDue: number, currency: string ) {
    if ( MX_CURRENCY === currency && amount < STRIPE_MX_MIN_AMOUNT && amount > 0 ) {
      // 'Minimum payment amount is MX$10.00'
      return {
        error: VALIDATE_PARTIAL_AMOUNT_ERROR_MX_MINIMUM,
        valid: false
      };
    }
    if ( amount !== null && amount > 0 ) {
      const decimalCount = this.countDecimals( amount );

      if ( decimalCount > 2 ) {
        // 'Please enter the proper format for the partial payment amount.'
        return {
          error: VALIDATE_PARTIAL_AMOUNT_ERROR_TOO_MANY_DECIMALS,
          valid: false
        };
      } else if ( amount > totalDue ) {
        // 'Please enter a lesser amount than the total due on this invoice.'
        return {
          error: VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_BIG,
          valid: false
        };
      } else {
        return {
          error: '',
          valid: true
        };
      }
    } else {
      if ( amount === null ) {
        // 'Please enter a valid amount.'
        return {
          error: VALIDATE_PARTIAL_AMOUNT_ERROR_NOT_VALID,
          valid: false
        };
      } else if ( amount <= 0 ) {
        // 'Please enter an amount greater than 0.'
        return {
          error: VALIDATE_PARTIAL_AMOUNT_ERROR_TOTAL_TOO_SMALL,
          valid: false
        };
      } else {
        // 'Please enter a valid amount.'
        return {
          error: VALIDATE_PARTIAL_AMOUNT_ERROR_NOT_VALID,
          valid: false
        };
      }
    }
  }

  countDecimals ( numberToTest: number ) {
    const matches = numberToTest.toString().split( '.' );

    let decimalCount = 0;

    if ( matches[ 1 ] ) {
      decimalCount = matches[ 1 ].length;
    }
    return decimalCount;
  }

  createPaymentRequestConverter ( paymentState: PaymentState, scaToken: ScaToken ): CreatePaymentRequest {
    return {
      paymentMethodRecordId: paymentState.selectedPaymentMethod.id,
      paymentAmount: paymentState.paymentAmount,
      payAll: paymentState.commandType === PAYMENT_COMMAND_PAY_ALL,
      invoiceInfo: paymentState.invoiceInfo,
      payType: paymentState.commandType === PAYMENT_COMMAND_PAY_ALL ? PayType.TOTAL : paymentState.payType,
      scaToken: scaToken ? scaToken : null
    };
  }

  createPayment ( orgId: string, createPaymentRequest: CreatePaymentRequest ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payments`;
    return this.http.post<PaymentCreationResponse>( path, createPaymentRequest );
  }

  validatePayment ( orgId: string, paymentId: string, validatePaymentRequest: ValidatePaymentRequest ) {
    const path = `${ environment.billingPortalBffUrl }/organizations/${ orgId }/payments/${ paymentId }/validation-requests`;
    return this.http.post( path, validatePaymentRequest );
  }
}
