import { Action } from '../../../store/action.model';
import { CardinalState } from './cardinal.model';
import { CardinalActions } from './cardinal.actions';
import { PaymentActions } from '../../payment/redux/payment.actions';

export const INITIAL_STATE: CardinalState = {
  scaToken: undefined,
  tokenExpiration: undefined,
  generateInProgress: false,
  error: undefined,
  currency: undefined,
  country: undefined,
  scaRequirement: undefined
};

export function cardinalReducer (
  lastState: CardinalState = INITIAL_STATE,
  action: Action<Partial<CardinalState>>
): CardinalState {
  switch ( action.type ) {
    case CardinalActions.GENERATE_SCA_TOKEN:
      return {
        ...lastState,
        generateInProgress: true,
        error: undefined,
        currency: action.payload.currency,
        country: action.payload.country,
        scaRequirement: undefined
      };
    case CardinalActions.SET_TOKEN_STATE:
      return {
        ...lastState,
        scaToken: action.payload.scaToken,
        tokenExpiration: action.payload.tokenExpiration,
        generateInProgress: false,
        error: action.payload.error
      };
    case CardinalActions.SET_SCA_REQUIREMENT_STATE:
    case PaymentActions.SET_POST_PAYMENT_COMPLETED_WITH_SCA_REQUIREMENT:
      return {
        ...lastState,
        scaRequirement: action.payload.scaRequirement
      };
    case CardinalActions.RESET:
      return INITIAL_STATE;
    default:
      return lastState;
  }
}
