import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
} )
export class AppComponent {
  title = 'app';

  constructor ( angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics ) {
    angulartics2GoogleAnalytics.startTracking();
    AppComponent.appendGaTrackingCode();
  }

  private static appendGaTrackingCode () {
    try {
      const script = document.createElement( 'script' );
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` + environment.googleAnalyticsKey + `', 'auto');
      `;
      document.head.appendChild( script );
    } catch ( ex ) {
      console.error( 'Error appending google analytics' );
      console.error( ex );
    }
  }

}
