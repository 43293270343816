import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { CUSTOMER_NUMBER_SELECTOR, ORGANIZATION_NAME_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-selected-organization',
  templateUrl: './selected-organization.component.html',
  styleUrls: [ './selected-organization.component.scss' ]
} )
export class SelectedOrganizationComponent implements OnInit {
  @select( CUSTOMER_NUMBER_SELECTOR ) customerNumber$: Observable<string>;
  @select( ORGANIZATION_NAME_SELECTOR ) organizationName$: Observable<string>;

  constructor () { }

  ngOnInit () {

  }

}
