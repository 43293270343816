import { Injectable } from '@angular/core';
import { SmsDetails } from './sms-details.model';
import { Country } from '../../location-converter/assets/model';

@Injectable( {
  providedIn: 'root'
} )
export class SmsDetailsActions {

  static readonly GET_SMS_DETAILS = 'SMS_DETAILS:GET';
  static readonly SET_SMS_DETAILS = 'SMS_DETAILS:SET';

  // reset all
  static readonly RESET_SMS_DETAILS = 'SMS_DETAILS:RESET';

  constructor () { }

  getSmsDetails ( orgId: string, year: number, month: number, country: Country, language: string ) {
    return { type: SmsDetailsActions.GET_SMS_DETAILS, payload: { orgId, year, month, country, language } };
  }

  setSmsDetails ( smsDetails: SmsDetails ) {
    return { type: SmsDetailsActions.SET_SMS_DETAILS, payload: { smsDetails } };
  }

  resetSmsDetails () {
    return { type: SmsDetailsActions.RESET_SMS_DETAILS };
  }
}
