import { UserService } from './user.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserActions } from './redux/user.actions';
import { UserEpic } from './redux/user.epic';
import { CoreServicesConfig } from '../core/service-helpers';
import { environment } from '../../../environments/environment';
import { UserConfigurationService } from '@jive/core/dist/src/user';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    UserActions,
    UserService,
    UserEpic,
    {
      provide: UserConfigurationService,
      useValue: {
        environment: environment.environment
      },
    },
    {
      provide: CoreServicesConfig,
      useValue: {
        environment: environment.environment
      }
    }
  ],
  declarations: []
} )
export class UserModule {}
