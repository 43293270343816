import {APP_INITIALIZER, FactoryProvider} from '@angular/core';
import {parseToken,} from '@jive/core/authentication';
import {catchError, mergeMap, switchMap, tap} from 'rxjs/operators';
import {UserService} from '../user/user.service';

import {ERROR_PATH} from '../../app-router.module';
import {NgRedux} from '@angular-redux/store';
import {AppState} from '../../store/app-state.model';
import {UserActions} from '../user/redux/user.actions';
import {noop, without} from 'lodash-es';
import {OrganizationService} from '../organization/organization.service';
import {OrganizationActions} from '../organization/redux/organization.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {of, throwError} from 'rxjs';
import {LanguageSelectorService} from '../language-selector/language-selector.service';
import {Authentication} from '../auth/auth.service';
import {AppInitService} from './app-init.service';
import {UserOrganizationSelection} from './models';
import {OrganizationNotFoundError, OrganizationPermissionsError} from './common';

export class Error404NoOrg {}

export class Error403OfficePBXs {}

export function userInitFactory (
  auth: Authentication,
  userService: UserService,
  appInitService: AppInitService,
  organizationService: OrganizationService,
  reduxStore: NgRedux<AppState>,
  userActions: UserActions,
  organizationActions: OrganizationActions,
  languageSelectorService: LanguageSelectorService
) {

  return function () {
    // set the default language.
    languageSelectorService.init();

    const hashTokens = parseToken( window.location.hash );

    let inflightRequest = window.location.hash &&
    hashTokens.inflightRequest &&
    hashTokens.inflightRequest.length > 1 ? hashTokens.inflightRequest : '';

    // billing is part of the inflight request which makes the userService to think that "billing"
    // is the customer name and messes with the call to jif. In order to avoid it, we remove it.

    if ( inflightRequest !== '' ) {
      let requestArray = without( inflightRequest.split( '/' ), '' );
      if ( requestArray[ 0 ] === 'billing' ) {
        requestArray = requestArray.slice( 1 );
      }
      inflightRequest = requestArray.join( '/' );
    }

    // we cant use just /404 or /403 as the error path. This is due to the fact
    // that we are trying to resolve the org by domain name and since an org can
    // and does use 404 as a domain name we have to prepend the path to tell
    // Angular to short circuit app start
    const pathContainsError: RegExpMatchArray = ( appInitService
      .pathFirstIndex( window.location.pathname ) || '' )
      .match( ERROR_PATH );

    if ( !pathContainsError ) {
      // Tried to run login and getCurrentUser sequentially but due to something going on
      // with auth streaming info before its actually ready we have to run everything else
      // when auth says it done done

      return auth.login()
        .pipe(
          mergeMap( () => {
            return userService.getCurrentUser()
              .pipe(
                mergeMap( userState => {
                  // if org-select path is chosen, we do not want to select an org.
                  // instead, just show the component.
                  if ( '/billing/org-select' === window.location.pathname ) {
                    return of();
                  }
                  if ( '/billing' === window.location.pathname && userState.userPbxs.length === 0 ) {
                    window.location.href = '/billing/org-select';
                  }

                  return appInitService.getUserSelectedOrganization(
                    inflightRequest,
                    window.location.pathname,
                    userState.userPbxs,
                    userService.organizationPermissionsCheck.bind( userService, userState.principalData ),
                    ( path: string ) => {
                      return without( path.split( '/' ), '' )[ 1 ];
                    }
                  ).pipe(
                    switchMap( ( userOrganizationSelection: UserOrganizationSelection ) => {
                      return organizationService.getOrganization( userOrganizationSelection.selectedOrganization.id ).pipe(
                        catchError( ( err: HttpErrorResponse ) => {
                          if ( err.status === 404 ) {
                            throw new Error404NoOrg();
                          } else if ( err.status === 403 ) {
                            throw new Error403OfficePBXs();
                          } else {
                            return throwError( err );
                          }
                        } ),
                        tap( ( organization ) => {
                          const orgData = organizationService.parseOrganizationResponse( organization );
                          reduxStore.dispatch( organizationActions.setOrganizationSelectedOrganization( orgData ) );
                          reduxStore.dispatch( userActions.setUserSelectedPbxAndPermissionsAction(
                            userOrganizationSelection.selectedOrganization,
                            userOrganizationSelection.permissions
                          ) );

                          const updateHrefCheck = appInitService.checkUpdateHref( userOrganizationSelection );
                          if ( updateHrefCheck.shouldUpdate ) {
                            window.location.href = `${ updateHrefCheck.path }`;
                          }
                        } )
                      );
                    } )
                  );
                } ),
                tap( noop, err => {
                  if ( err instanceof OrganizationNotFoundError ||
                    err instanceof Error404NoOrg ) {
                    window.location.href = '/billing/org-select';
                  } else if (
                    err instanceof OrganizationPermissionsError ||
                    err instanceof Error403OfficePBXs
                  ) {
                    window.location.href = `/${ ERROR_PATH }/403`;
                  } else {
                    window.location.href = '/billing/org-select';
                  }
                } )
              );
          } )
        )
        .toPromise();
    } else {
      return Promise.resolve();
    }

  };
}

export const APP_INIT_USER_PROVIDER: FactoryProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: userInitFactory,
  deps: [
    Authentication,
    UserService,
    AppInitService,
    OrganizationService,
    NgRedux,
    UserActions,
    OrganizationActions,
    LanguageSelectorService
  ]
};
