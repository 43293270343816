export interface CcGeneratorState {
  errorDate: Date;
  errorMessageKey: string;
  errorCode: string;
}

export const CC_GENERATOR_GENERAL_POST_ERROR_KEY = 'PAYMENT_METHOD_GENERATOR_POST_ERROR';
export const CC_GENERATOR_TRY_AGAIN_ERROR_KEY = 'CC_GENERATOR_TRY_AGAIN_ERROR';
export const CC_GENERATOR_CONTACT_ISSUER_ERROR_KEY = 'CC_GENERATOR_CONTACT_ISSUER_ERROR';
export const CC_ERROR_CODE_TRY_AGAIN = 'OFC-0007';
export const CC_ERROR_CODE_SCA_TRY_AGAIN = 'OFC-0070';
export const CC_ERROR_CODE_CONTACT_ISSUER = 'OFC-0074';
