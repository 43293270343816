import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsDetailsActions } from './redux/sms-details.actions';
import { SmsDetailsEpic } from './redux/sms-details.epic';
import { SmsDetailsService } from './sms-details.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    SmsDetailsActions,
    SmsDetailsEpic,
    SmsDetailsService
  ],
  declarations: []
} )
export class SmsDetailsModule {}
