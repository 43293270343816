import { OrganizationListState } from './organization-list.model';
import { Action } from '../../../store/action.model';
import { OrganizationListActions } from './organization-list.actions';

export const INITIAL_STATE: OrganizationListState = {
  loading: false,
  hasError: false,
  items: undefined,
  error: undefined
};

export function organizationListReducer (
  lastState: OrganizationListState = INITIAL_STATE,
  action: Action<Partial<OrganizationListState>>
): OrganizationListState {
  switch ( action.type ) {
    case OrganizationListActions.LIST_ORGS_START:
      return { ...lastState, loading: true };
    case OrganizationListActions.LIST_ORGS_END:
      const { items } = action.payload;
      return {
        ...lastState,
        loading: false,
        items: items,
        hasError: false
      };
    case OrganizationListActions.LIST_ORGS_ERROR:
      const { error } = action.payload;
      return {
        ...lastState,
        loading: false,
        hasError: true,
        error: error
      };
    case OrganizationListActions.CLEAR_ORGS_ERROR:
      return {
        ...lastState,
        hasError: false,
        error: undefined
      };

    default:
      return lastState;
  }
}
