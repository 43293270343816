<div class="data-error" role="region" [attr.aria-label]="'ERROR'| translate">
  <div class="data-error-content">
    <div class="data-error-row">
      <i class="material-icons data-error-icon">
        error_outline
      </i>
    </div>
    <div class="data-error-row" [innerHTML]="'ERROR_DATA_NOT_AVAILABLE'| translate">
      <!-- This Information is not currently available. -->
    </div>
    <div class="data-error-row" [innerHTML]="'ERROR_TRY_LATER'| translate">
      <!-- Please refresh or check back later. -->
    </div>
  </div>
</div>
