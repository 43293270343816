import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '../../store/app-state.model';
import { OrganizationListActions } from '../../services/organization-list/redux/organization-list.actions';
import { fromEvent, Observable } from 'rxjs';
import { Organization } from '../../services/organization/redux/organization.model';
import { debounceTime, map } from 'rxjs/operators';
import { BASE_PATH } from '../../app-router.module';
import { UntypedFormControl } from '@angular/forms';
import { ORGANIZATION_LIST_SELECTOR } from '../../store/helper';

@Component( {
  selector: 'bp-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: [ './organization-selector.component.scss' ]
} )
export class OrganizationSelectorComponent implements OnInit, OnDestroy {
  @Output() showOrgSelector = new EventEmitter<boolean>();
  @ViewChild( 'orgSearch', { static: true } ) orgSearch: ElementRef;
  @select( ORGANIZATION_LIST_SELECTOR ) orgs$: Observable<Organization[]>;
  orgControl = new UntypedFormControl();

  constructor (
    private readonly ngRedux: NgRedux<AppState>,
    private organizationListActions: OrganizationListActions
  ) { }

  ngOnInit () {
    this.getOrganizationList();

    // subscribing to the input field in org Selector.
    fromEvent( this.orgSearch.nativeElement, 'keyup' )
      .pipe(
        map( ( k: any ) => k.target.value ),
        debounceTime( 300 )
      )
      .subscribe(
        ( val ) => {
          this.getOrganizationList( val );
        }
      );

    // when user gets off focus from the input box, then reset the search result
    fromEvent( this.orgSearch.nativeElement, 'blur' )
      .pipe( debounceTime( 200 ) )
      .subscribe( () => {
        this.showOrgSelector.emit( false );
      } );
  }

  ngOnDestroy () {
    this.getOrganizationList();
  }

  getOrganizationList = ( filter?: string ) => this.ngRedux.dispatch( this.organizationListActions.getOrgList( filter ) );

  navigateToSelectedOrg = ( domain: string ) => {
    window.location.href = `${ BASE_PATH }/${ domain }/billing-details`;
  };
}
