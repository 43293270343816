import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app-router.module';
import { AppCommonModule } from './app-common.module';
import { AppInitModule } from './services/app-init/app-init.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { Angulartics2Module } from 'angulartics2';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HTTP_HANDLER_SERVICE_PROVIDER } from './services/http/http-handler.service';
import { AuthModule } from './services/auth/auth.module';
import { HttpClientOauthInterceptor } from './services/http/http-oauth.interceptor';
import { HttpErrorInterceptor } from './services/http/http-error.interceptor';

export function translateLoader ( http: HttpClient ) {

  return new MultiTranslateHttpLoader( http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/dynamic/', suffix: '.json' }
  ] );
}

export class MultiTranslateHttpLoader implements TranslateLoader {

  constructor ( private http: HttpClient,
    public resources: { prefix: string, suffix: string }[] = [ {
      prefix: '/assets/i18n/',
      suffix: '.json'
    } ] ) { }

  /**
   * Gets the translations from the server
   * @param lang
   * @returns {any}
   */
  public getTranslation ( lang: string ): any {
    const hash = new Date().getTime();
    return forkJoin( this.resources.map( config => {
      return this.http.get( `${ config.prefix }${ lang }${ config.suffix }?cacheBuster=${ hash }` );
    } ) ).pipe( map( response => {
      return response.reduce( ( a, b ) => {
        return Object.assign( a, b );
      } );
    } ) );
  }
}

@NgModule( {
  declarations: [
    AppComponent,
  ],
  imports: [
    AuthModule,
    BrowserModule,
    AppRouterModule,
    AppCommonModule,
    AppInitModule,
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: ( translateLoader ),
        deps: [ HttpClient ]
      }
    } )
  ],
  providers: [
    HTTP_HANDLER_SERVICE_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientOauthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule {}
