import {Injectable} from '@angular/core';
import {BillingEmailService} from '../billing-email.service';
import {BillingEmailActions} from './billing-email.actions';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {BillingEmail} from './billing-email.model';

@Injectable( {
  providedIn: 'root'
} )
export class BillingEmailEpic {

  constructor (
    private billingEmailService: BillingEmailService,
    private billingEmailActions: BillingEmailActions ) { }

  getEpics () {
    return combineEpics(
      this.getAndSetBillingEmail.bind( this ),
      this.saveBillingEmail.bind( this ) );
  }

  getAndSetBillingEmail ( action$: ActionsObservable<Action<any>> ) {

      return action$
      .pipe(
          ofType(BillingEmailActions.GET_BILLING_EMAIL),
        switchMap( ( { payload: { orgId } } ) => {
          return this.billingEmailService.getBillingEmail( orgId )
            .pipe(
              map( ( emails: BillingEmail[] ) => {
                return this.billingEmailActions.setBillingEmail( emails );
              } ),
              catchError( () =>
                of( this.billingEmailActions.setBillingEmail( undefined ) )
              )
            );
        } )
      );
  }

  saveBillingEmail ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(BillingEmailActions.SAVE_BILLING_EMAIL),
        switchMap( ( { payload: { orgId, billingEmail } } ) => {
          return this.billingEmailService.saveBillingEmail( orgId, billingEmail )
            .pipe(
              map( ( emails: BillingEmail[] ) => {
                return this.billingEmailActions.setSaveSuccess( emails );
              } ),
              catchError( ( error: any ) =>
                of( this.billingEmailActions.setSaveError( error ) )
              )
            );
        } )
      );
  }
}
