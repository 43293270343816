import { Action } from '../../../store/action.model';
import { LanguageSelectorState } from './language-selector.model';
import { LanguageSelectorActions } from './language-selector.actions';

export const INITIAL_STATE: LanguageSelectorState = {
  selectedLanguage: undefined
};

export function languageSelectorReducer (
  lastState: LanguageSelectorState = INITIAL_STATE,
  action: Action<Partial<LanguageSelectorState>>
): LanguageSelectorState {
  switch ( action.type ) {
    case LanguageSelectorActions.SET_SELECTED_LANGUAGE:
      return { ...lastState, selectedLanguage: action.payload.selectedLanguage };
    default:
      return lastState;
  }
}

