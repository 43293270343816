import { InvoiceState } from './invoice.model';
import { Action } from '../../../store/action.model';
import { InvoiceActions } from './invoice.actions';

export const INITIAL_STATE: InvoiceState = {
  invoice: undefined,
  latestPaymentMethodId: undefined,
  getInProgress: undefined,
  latestPaymentMethodIdGetInProgress: undefined,
  pdfDownload: { inProgress: false, error: null },
  csvDownload: { inProgress: false, error: null },
  mexicoPdfDownload: { inProgress: false, error: null },
  mexicoXmlDownload: { inProgress: false, error: null }
};

export function invoiceReducer (
  lastState: InvoiceState = INITIAL_STATE,
  action: Action<Partial<InvoiceState>>
): InvoiceState {
  switch ( action.type ) {
    case InvoiceActions.SET_INVOICE:
      return { ...lastState, invoice: action.payload.invoice, getInProgress: false };
    case InvoiceActions.GET_INVOICE:
      return { ...lastState, getInProgress: true };
    case InvoiceActions.SET_INVOICE_LATEST_PAYMENT_METHOD_ID:
      return {
        ...lastState,
        latestPaymentMethodId: action.payload.latestPaymentMethodId,
        latestPaymentMethodIdGetInProgress: false
      };
    case InvoiceActions.GET_INVOICE_LATEST_PAYMENT_METHOD_ID:
      return { ...lastState, latestPaymentMethodIdGetInProgress: true };
    case InvoiceActions.DOWNLOAD_INVOICE_PDF:
      return { ...lastState, pdfDownload: { inProgress: true, error: null } };
    case InvoiceActions.SET_INVOICE_DOWNLOAD:
      return { ...lastState, pdfDownload: action.payload.pdfDownload };
    case InvoiceActions.DOWNLOAD_INVOICE_CSV:
      return { ...lastState, csvDownload: { inProgress: true, error: null } };
    case InvoiceActions.SET_INVOICE_CSV_DOWNLOAD:
      return { ...lastState, csvDownload: action.payload.csvDownload };
    case InvoiceActions.DOWNLOAD_MEXICO_FILES:
      return {
        ...lastState,
        mexicoPdfDownload: { inProgress: true, error: null },
        mexicoXmlDownload: { inProgress: true, error: null }
      };
    case InvoiceActions.SET_MEXICO_PDF_DOWNLOAD:
      return { ...lastState, mexicoPdfDownload: action.payload.mexicoPdfDownload };
    case InvoiceActions.SET_MEXICO_XML_DOWNLOAD:
      return { ...lastState, mexicoXmlDownload: action.payload.mexicoXmlDownload };
    case InvoiceActions.RESET_INVOICE:
      return INITIAL_STATE;
    default:
      return lastState;
  }
}

