import { Injectable } from '@angular/core';
import { PaymentState, ValidatePaymentInfo } from './payment.model';
import { ScaRequirement } from '../../cardinal/redux/cardinal.model';
import { PaymentMethod } from '../../payment-method/redux/payment-method.model';

@Injectable( {
  providedIn: 'root'
} )
export class PaymentActions {

  static readonly SET_PAYMENT_PAY_TOTAL_BASIC_INFO = 'PAYMENT:SET_PAY_TOTAL_BASIC_INFO';
  static readonly SET_PAYMENT_PAY_NOW_BASIC_INFO = 'PAYMENT:SET_PAY_NOW_BASIC_INFO';
  static readonly SET_PAYMENT_PAY_NOW_AMOUNT = 'PAYMENT:SET_PAY_NOW_AMOUNT';
  static readonly SET_PAYMENT_SET_PAYMENT_METHOD = 'PAYMENT:SET_PAYMENT_METHOD';
  static readonly RESET_PAYMENT_METHOD = 'PAYMENT:RESET_PAYMENT_METHOD';
  static readonly POST_PAYMENT_COMPLETE = 'PAYMENT:POST_COMPLETE';
  static readonly SET_POST_PAYMENT_SUCCESS = 'PAYMENT:SET_POST_SUCCESS';
  static readonly SET_POST_PAYMENT_ERROR = 'PAYMENT:SET_POST_ERROR';

  static readonly CREATE_PAYMENT = 'PAYMENT:CREATE_PAYMENT';
  static readonly SET_POST_PAYMENT_COMPLETED_WITH_SCA_REQUIREMENT = 'PAYMENT:SET_POST_PAYMENT_COMPLETED_WITH_SCA_REQUIREMENT';
  static readonly POST_VALIDATE_PAYMENT = 'PAYMENT:POST_VALIDATE_PAYMENT';

  constructor () { }

  setPayTotalBasicPaymentInfo ( orgId: string, currency: string, paymentAmount: number ) {
    return {
      type: PaymentActions.SET_PAYMENT_PAY_TOTAL_BASIC_INFO,
      payload: { orgId, currency, paymentAmount }
    };
  }

  setPayNowBasicPaymentInfo ( orgId: string, currency: string, ) {
    return {
      type: PaymentActions.SET_PAYMENT_PAY_NOW_BASIC_INFO,
      payload: { orgId, currency }
    };
  }

  setPayNowAmount ( paymentAmount: number, payType: string, invoiceInfo: Object ) {
    return {
      type: PaymentActions.SET_PAYMENT_PAY_NOW_AMOUNT,
      payload: { paymentAmount, payType, invoiceInfo }
    };
  }

  setPaymentMethod ( selectedPaymentMethod: PaymentMethod ) {
    return {
      type: PaymentActions.SET_PAYMENT_SET_PAYMENT_METHOD,
      payload: { selectedPaymentMethod }
    };
  }

  resetPaymentMethod () {
    return { type: PaymentActions.RESET_PAYMENT_METHOD };
  }

  // invoiceInfo uses the invoice ID (string) as a key and the payment amount (number) as the value.
  postPaymentCompleted ( orgId: string, payAll: boolean, invoiceInfo: Object ) {
    return { type: PaymentActions.POST_PAYMENT_COMPLETE, payload: { orgId, payAll, invoiceInfo } };
  }

  setPostPaymentSuccess ( orgId: string ) {
    return { type: PaymentActions.SET_POST_PAYMENT_SUCCESS, payload: { orgId } };
  }

  setPostPaymentError ( postError: any ) {
    return { type: PaymentActions.SET_POST_PAYMENT_ERROR, payload: { postError } };
  }

  createPayment ( paymentState: PaymentState ) {
    return { type: PaymentActions.CREATE_PAYMENT, payload: { paymentState } };
  }

  setPostPaymentCompletedWithScaRequirement ( scaRequirement: ScaRequirement ) {
    return {
      type: PaymentActions.SET_POST_PAYMENT_COMPLETED_WITH_SCA_REQUIREMENT,
      payload: {
        scaRequirement: scaRequirement
      }
    };
  }

  validatePayment ( validatePaymentInfo: ValidatePaymentInfo ) {
    return { type: PaymentActions.POST_VALIDATE_PAYMENT, payload: { validatePaymentInfo } };
  }
}
