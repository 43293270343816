import {Injectable} from '@angular/core';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {CardinalActions} from './cardinal.actions';
import {CardinalService} from '../cardinal.service';
import {Observable, of} from 'rxjs';
import {ScaPurpose, ScaRequirement, ScaToken} from './cardinal.model';
import {PaymentMethodActions} from '../../payment-method/redux/payment-method.actions';
import {select} from '@angular-redux/store';
import {CARDINAL_SCA_REQUIREMENT_SELECTOR, COUNTRY_SELECTOR, CURRENCY_SELECTOR} from '../../../store/helper';
import {Country} from '../../location-converter/assets/model';

@Injectable( {
  providedIn: 'root'
} )
export class CardinalEpic {
  @select( CARDINAL_SCA_REQUIREMENT_SELECTOR ) scaRequirement$: Observable<ScaRequirement>;
  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( COUNTRY_SELECTOR ) country$: Observable<Country>;

  constructor (
    private cardinalActions: CardinalActions,
    private cardinalService: CardinalService
  ) { }

  getEpics () {
    return combineEpics(
      this.getAndSetToken.bind( this ),
      this.setScaRequiredState.bind( this ),
      this.resetScaRequirementForPaymentMethod.bind( this )
    );
  }

  getAndSetToken ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(CardinalActions.GENERATE_SCA_TOKEN),
        switchMap( ( { payload: { currency, country } } ) => {
          return this.cardinalService.generateScaToken( { currency: currency, country: country.code } )
            .pipe(
              map( ( generatedScaToken: ScaToken ) => {
                if ( generatedScaToken ) {
                  const tokenExpiration = new Date();
                  tokenExpiration.setHours( tokenExpiration.getHours() + 2 );
                  return this.cardinalActions.setCardinalTokenState( generatedScaToken, tokenExpiration, undefined );
                } else {
                  return this.cardinalActions.setCardinalTokenState( undefined, undefined, undefined );
                }

              } ),
              catchError( ( error: any ) =>
                of( this.cardinalActions.setCardinalTokenState( undefined, undefined, error ) )
              )
            );
        } )
      );
  }

  setScaRequiredState ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_COPAS_PAYMENT_METHOD_COMPLETE_WITH_SCA_REQUIRED),
        map(
          ( { payload: { scaRequirement } } ) => {
            return this.cardinalActions.setScaRequirementState( scaRequirement );
          }
        )
      );
  }

  resetScaRequirementForPaymentMethod ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(PaymentMethodActions.POST_PAYMENT_METHOD_COMPLETE),
        switchMap( () => {
          return this.scaRequirement$.pipe(
            first(),
            map( ( scaRequirement ) => {
              if ( scaRequirement?.purpose === ScaPurpose.paymentMethod ) {
                return this.cardinalActions.setScaRequirementState(
                  {
                    ...scaRequirement,
                    scaRequired: false,
                    paymentMethodCreationResponse: undefined
                  } );
              } else {
                return { type: '' };
              }
            } )
          );
        } )
      );
  }
}
