import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Subject} from 'rxjs';

@Directive({
  selector: `input[jiveInput], textarea[jiveInput]`,
  exportAs: 'jiveInput'
})
export class JiveInputDirective implements OnDestroy, AfterViewInit {

  public stateChanges: Subject<void> = new Subject();
  @HostBinding('disabled') _disabled = false;
  protected _focused = false;

  @Input()
  get disabled() {
    return this.ngControl ? this.ngControl.disabled : this._disabled;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this._focused = false;
      this.stateChanges.next();
    }
  }

  @HostListener('focus') setFocus() {
    this._focused = true;
    this.stateChanges.next();
  }

  @HostListener('blur') unsetFocus() {
    this._focused = false;
    this.stateChanges.next();
  }

  get invalid() {
    return this.ngControl && this.ngControl.dirty ? this.ngControl.invalid : false;
  }

  get focused() {
    return this._focused;
  }

  ngAfterViewInit() {
    if (this.ngControl) {
      this.disabled = this.ngControl.disabled;
      this.changeDetectionRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  constructor(
      private changeDetectionRef: ChangeDetectorRef,
      @Optional() @Self() public ngControl: NgControl
  ) {
  }
}
