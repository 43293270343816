import {Injectable} from '@angular/core';
import {AutopayActions} from './autopay.actions';
import {AutopayService} from '../autopay.service';
import {ActionsObservable, combineEpics, ofType} from 'redux-observable';
import {Action} from '../../../store/action.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class AutopayEpic {

  constructor (
    private autopayService: AutopayService,
    private autopayActions: AutopayActions ) { }

  getEpics () {
    return combineEpics(
      this.postAndSetAutopayEnrollment.bind( this ),
      this.getAutopayStatus.bind( this )
    );
  }

  postAndSetAutopayEnrollment ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(AutopayActions.POST_AUTOPAY_ENROLLMENT),
        switchMap( ( { payload: { orgId, enrolled } } ) => {
          return this.autopayService.saveAutoPaymentState( orgId, enrolled )
            .pipe(
              map( enrolledInAutoPayment => this.autopayActions.postAutopayEnrollmentComplete( enrolledInAutoPayment ) ),
              catchError( ( error: any ) =>
                of( this.autopayActions.setAutopayPostError( error ) )
              )
            );
        } )
      );
  }

  getAutopayStatus ( action$: ActionsObservable<Action<any>> ) {
      return action$
      .pipe(
          ofType(AutopayActions.GET_AUTOPAY_STATE),
        switchMap( ( { payload: { orgId } } ) => {
          return this.autopayService.getAutoPaymentState( orgId )
            .pipe(
              map( enrolled => this.autopayActions.setAutopayStatus( enrolled ) ),
              catchError( () =>
                of( this.autopayActions.setAutopayStatus( undefined ) )
              )
            );
        } )
      );
  }
}
