import { Injectable } from '@angular/core';
import { Country } from '../../location-converter/assets/model';

@Injectable( {
  providedIn: 'root'
} )
export class CopasActions {
  static readonly SET_COPAS = 'COPAS:SET';

  constructor () { }

  setCopas ( country: Country, use: boolean ) {
    return { type: CopasActions.SET_COPAS, payload: { country, use } };
  }
}
