import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BillingEmail } from './redux/billing-email.model';
import { HttpClient } from '@angular/common/http';

@Injectable( {
  providedIn: 'root'
} )
export class BillingEmailService {

  constructor ( private http: HttpClient ) { }

  getBillingEmail = ( orgId: string ) =>
    this.http.get<BillingEmail[]>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/billing-emails` );

  saveBillingEmail = ( orgId: string, billingEmails: BillingEmail[] ) =>
    this.http.put<BillingEmail[]>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }/billing-emails`, billingEmails );
}
