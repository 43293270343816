<div class="main-navbar" role="region" [attr.aria-hidden]="(modalVisible$|async)">
  <div class="navbar-left-side">
    <div class="navbar-logo">
      <img [src]="logoToUse" class="navbar-logo-img" alt="GoTo">
    </div>

    <div class="app-name" [innerHTML]="'APP_NAME'| translate">
      <!-- BILLING -->
    </div>
  </div>

  <jive-navbar-right-side></jive-navbar-right-side>
</div>
