import { Injectable } from '@angular/core';
import { CallDetails } from './call-details.model';
import { Country } from '../../location-converter/assets/model';

@Injectable( {
  providedIn: 'root'
} )
export class CallDetailsActions {

  static readonly GET_CALL_DETAILS = 'CALL_DETAILS:GET';
  static readonly SET_CALL_DETAILS = 'CALL_DETAILS:SET';

  // reset all
  static readonly RESET_CALL_DETAILS = 'CALL_DETAILS:RESET';

  constructor () { }

  getCallDetails ( orgId: string, year: number, month: number, country: Country, language: string ) {
    return { type: CallDetailsActions.GET_CALL_DETAILS, payload: { orgId, year, month, country, language } };
  }

  setCallDetails ( callDetails: CallDetails ) {
    return { type: CallDetailsActions.SET_CALL_DETAILS, payload: { callDetails } };
  }

  resetCallDetails () {
    return { type: CallDetailsActions.RESET_CALL_DETAILS };
  }
}
