import { CallDetailsState } from './call-details.model';
import { Action } from '../../../store/action.model';
import { CallDetailsActions } from './call-details.actions';

export const INITIAL_STATE: CallDetailsState = {
  callDetails: undefined,
  getInProgress: undefined,
};

export function callDetailsReducer (
  lastState: CallDetailsState = INITIAL_STATE,
  action: Action<Partial<CallDetailsState>>
): CallDetailsState {
  switch ( action.type ) {
    case CallDetailsActions.SET_CALL_DETAILS:
      return { ...lastState, callDetails: action.payload.callDetails, getInProgress: false };
    case CallDetailsActions.GET_CALL_DETAILS:
      return { ...lastState, getInProgress: true };
    case CallDetailsActions.RESET_CALL_DETAILS:
      return INITIAL_STATE;
    default:
      return lastState;
  }
}

