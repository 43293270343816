import { Application, LocalizedDetail, ApplicationsService, ApplicationCollection } from '@jive/core/applications';
import { getLocaleKey } from '@jive/common/i18n';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class AppSwitcherListBase {

  public listApplications: Observable<ApplicationCollection>;
  public applications?: Application[];
  /**
   * iso-639-1 standard. see [https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes].
   */
  protected language?: string;

  constructor (
    protected applicationsService: ApplicationsService
  ) {

    this.listApplications = this.applicationsService
      .listApplications()
      .pipe( first() );
  }

  getApplicationDisplayInfo ( application: Application ):
    LocalizedDetail | undefined {
    return application.localizedDetails
      .find( details => getLocaleKey( details.language ) === getLocaleKey( this.language ) ) ||
      application.localizedDetails
        .find( details => details.language === getLocaleKey( 'en-US' ) );
  }

  onApplicationSelected ( application: Application ) {
    window.open( application.url, '_blank' );
  }

}
