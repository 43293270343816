import { Injectable } from '@angular/core';
import { Invoice } from '../../invoice/redux/invoice.model';

@Injectable( {
  providedIn: 'root'
} )
export class InvoiceListActions {
  static readonly GET_INVOICE_LIST = 'INVOICE_LIST:GET';
  static readonly SET_INVOICE_LIST = 'INVOICE_LIST:SET';

  constructor () { }

  setInvoiceList ( list: Invoice[] ) {
    return { type: InvoiceListActions.SET_INVOICE_LIST, payload: { list } };
  }

  getInvoiceList ( orgId: string ) {
    return { type: InvoiceListActions.GET_INVOICE_LIST, payload: { orgId } };
  }
}
