import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from './message.service';
import { MessageActions } from './redux/message.actions';
import { MessageEpic } from './redux/message.epic';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    MessageActions,
    MessageEpic,
    MessageService,
  ],
  declarations: []
} )
export class MessageModule {}
