import { US_STATES } from './us';
import { CA_PROVINCES } from './ca';
import { AU_CURRENCY, CA_CURRENCY, EUR_CURRENCY, GB_CURRENCY, MX_CURRENCY, US_CURRENCY } from '../../money-converter/money-converter.model';

export interface Region {
  name: string;
  code: string;
}

export interface Country {
  code: string;
  labelKey: string;
  regionType: RegionType;
  regions?: Region[];
  currencies: string[];
  billingPhoneNumber?: string;
  billingPhoneNumberLocal?: string;
  billingEmail?: string;
  showBillingContact: boolean;
  regionRequired: boolean;
  postalCodeRequired: boolean;
  canGetReceiptEmail: boolean;
  useCopas: boolean;
}

export enum RegionType {
  NONE = 'NONE',
  PROVINCE = 'PROVINCE',
  REGION = 'REGION',
  STATE = 'STATE',
}

export const AUSTRALIA: Country = {
  labelKey: 'AUSTRALIA',
  code: 'AU',
  regionType: RegionType.STATE,
  currencies: [ US_CURRENCY, AU_CURRENCY ],
  billingPhoneNumber: null,
  billingPhoneNumberLocal: null,
  billingEmail: 'gtc-billing-aus@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const AUSTRIA: Country = {
  labelKey: 'AUSTRIA',
  code: 'AT',
  regionType: RegionType.NONE,
  currencies: [ US_CURRENCY, EUR_CURRENCY ],
  billingPhoneNumber: '+49 800 181 1942',
  billingPhoneNumberLocal: null,
  billingEmail: 'ar-ie@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const BRAZIL: Country = {
  labelKey: 'BRAZIL',
  code: 'BR',
  regionType: RegionType.PROVINCE,
  currencies: [],
  billingPhoneNumber: '0800-878-3155',
  billingPhoneNumberLocal: null,
  billingEmail: 'jivefinanceiro@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const CANADA: Country = {
  labelKey: 'CANADA',
  code: 'CA',
  regionType: RegionType.PROVINCE,
  currencies: [ US_CURRENCY, CA_CURRENCY ],
  regions: CA_PROVINCES,
  billingPhoneNumber: null,
  billingPhoneNumberLocal: null,
  billingEmail: null,
  showBillingContact: false,
  regionRequired: true,
  postalCodeRequired: true,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const FRANCE: Country = {
  labelKey: 'FRANCE',
  code: 'FR',
  regionType: RegionType.REGION,
  currencies: [ US_CURRENCY, EUR_CURRENCY ],
  billingPhoneNumber: '+33 800 909 966',
  billingPhoneNumberLocal: null,
  billingEmail: 'gtc-billing-fr@goto.com',
  showBillingContact: true,
  regionRequired: true,
  postalCodeRequired: true,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const GERMANY: Country = {
  labelKey: 'GERMANY',
  code: 'DE',
  regionType: RegionType.STATE,
  currencies: [ US_CURRENCY, EUR_CURRENCY ],
  billingPhoneNumber: '+49 (0) 800 184 4226',
  billingPhoneNumberLocal: '+49 (0) 721989779130',
  billingEmail: 'gtc-billing-de@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const IRELAND: Country = {
  labelKey: 'IRELAND',
  code: 'IE',
  regionType: RegionType.PROVINCE,
  currencies: [ US_CURRENCY, EUR_CURRENCY ],
  billingPhoneNumber: '+353 1800 948 857',
  billingPhoneNumberLocal: '+353 1 653 1122',
  billingEmail: 'gtc-billing-en@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const ITALY: Country = {
  labelKey: 'ITALY',
  code: 'IT',
  regionType: RegionType.PROVINCE,
  currencies: [ US_CURRENCY, EUR_CURRENCY ],
  billingPhoneNumber: '+39 800-979-550',
  billingPhoneNumberLocal: null,
  billingEmail: 'gtc-billing-it@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const MEXICO: Country = {
  labelKey: 'MEXICO',
  code: 'MX',
  regionType: RegionType.STATE,
  currencies: [ MX_CURRENCY ],
  billingPhoneNumber: '+52 55 4163-2041',
  billingPhoneNumberLocal: null,
  billingEmail: 'gtcfacturacion@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: true,
  useCopas: false,
};

export const SPAIN: Country = {
  labelKey: 'SPAIN',
  code: 'ES',
  regionType: RegionType.PROVINCE,
  currencies: [ US_CURRENCY, EUR_CURRENCY ],
  billingPhoneNumber: '+34 900 816 440',
  billingPhoneNumberLocal: null,
  billingEmail: 'ar-ie@goto.com',
  showBillingContact: true,
  regionRequired: true,
  postalCodeRequired: true,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const UNITED_KINGDOM: Country = {
  labelKey: 'UNITED_KINGDOM',
  code: 'GB',
  regionType: RegionType.REGION,
  currencies: [ GB_CURRENCY ],
  billingPhoneNumber: '+44 (0) 800 031 4762',
  billingPhoneNumberLocal: '+44 20 7551 0258',
  billingEmail: 'gtc-billing-en@goto.com',
  showBillingContact: true,
  regionRequired: false,
  postalCodeRequired: false,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const UNITED_STATES: Country = {
  labelKey: 'UNITED_STATES',
  code: 'US',
  regionType: RegionType.STATE,
  currencies: [ US_CURRENCY ],
  regions: US_STATES,
  billingPhoneNumber: '1-855-848-0764',
  billingPhoneNumberLocal: null,
  billingEmail: 'gtcbilling@goto.com',
  showBillingContact: false,
  regionRequired: true,
  postalCodeRequired: true,
  canGetReceiptEmail: false,
  useCopas: true,
};

export const SUPPORTED_COUNTRIES: Country[] = [
  AUSTRALIA,
  AUSTRIA,
  BRAZIL,
  CANADA,
  FRANCE,
  GERMANY,
  IRELAND,
  ITALY,
  MEXICO,
  SPAIN,
  UNITED_KINGDOM,
  UNITED_STATES
].sort( ( a, b ) => a.labelKey.localeCompare( b.labelKey ) );
