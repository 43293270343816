<div class="pay-now"
  id="pay-now"
  role="dialog"
  aria-modal="true"
  aria-labelledby="pay-now-title"
  aria-describedby="pay-now-content"
  tabindex=0>
  <div class="pay-now-content">
    <div class="pay-now-loading"
      *ngIf="showLoading()"
      [innerHTML]="'LOADING'| translate"
      aria-busy="true">
      <!-- loading -->
    </div>
    <div *ngIf="showDataError()" class="pay-now-data-error">
      <div class="pay-now-data-error-icon">
        <i class="material-icons">
          error_outline
        </i>
      </div>
      <div [innerHTML]="'ERROR_DATA_NOT_AVAILABLE'| translate">
        <!-- This Information is not currently available. -->
      </div>
      <div [innerHTML]="'ERROR_TRY_LATER'| translate">
        <!-- Please refresh or check back later. -->
      </div>
      <button class="jive-button jive-small pay-now-error-cancel"
        (click)="closePayNowModal()"
        [attr.aria-label]="'CLOSE' | translate"
        type="button"
        [innerHTML]="'CLOSE'| translate">
        <!-- close -->
      </button>
    </div>

    <div *ngIf="showMain()">
      <div class="component-title" id="pay-now-title" [innerHTML]="'PAY_NOW'| translate">
        <!-- Pay Now -->
      </div>
      <bp-pay-amount-selector
        (disablePayNow)="setDisablePayNowButton($event)"
        [paymentInProgress]="submitPaymentInProgress">
      </bp-pay-amount-selector>
      <bp-payment-method-selector [paymentInProgress]="submitPaymentInProgress">
      </bp-payment-method-selector>


      <div class="pay-now-add-payment-method-button">
        <a
          [routerLink]="['/', selectedDomain, 'payment-methods']"
          routerLinkActive="active"
          class="nav-button pay-now-add-payment-method-link">
          <span class="material-icons add-payment-method-icon">
          launch
          </span>
          <span class="add-payment-method-message">
            <!-- Add new payment method -->
            {{ 'ADD_PAYMENT_METHOD' | translate | uppercase }}
          </span>
        </a>
      </div>

      <bp-autopay class="pay-now-child-component"
        [paymentInProgress]="submitPaymentInProgress"
        *ngIf="(autopayEnrolled$|async) === false">
      </bp-autopay>
      <div class="pay-now-receipt-email">
        <bp-receipt-email [paymentInProgress]="submitPaymentInProgress" *ngIf="showReceiptEmail">
        </bp-receipt-email>
      </div>

      <div class="pay-now-action-buttons">
        <button class="jive-button pay-now-cancel-button"
          [disabled]="submitPaymentInProgress"
          (click)="closePayNowModal()"
          [attr.aria-label]="'CANCEL' | translate"
          type="button"
          [innerHTML]="'CANCEL'| translate">
          <!-- cancel -->
        </button>
        <button class="jive-button jive-primary submit-button"
          (click)="submit()"
          [innerHTML]="'SUBMIT'| translate"
          [attr.aria-label]="'SUBMIT' | translate"
          type="submit"
          *ngIf="submitPaymentInProgress === false"
          [disabled]="(validateEmail(receiptEmailState$| async) === false) ||
          (validatePayment((paymentState$ | async), (currency$ | async)) === false) ||
          ((paymentMethodList$ | async)?.length === 0 ) ||
          disablePayNowButton">
          <!-- Submit -->
        </button>
        <button class="jive-button jive-primary" disabled
          [attr.aria-label]="'PROCESSING' | translate"
          type="button"
          *ngIf="submitPaymentInProgress"
          role="alert"
          aria-busy="true">
          <!-- processing -->
          <bp-processing></bp-processing>
        </button>
      </div>
    </div>

    <bp-payment-confirmation *ngIf="showResult"></bp-payment-confirmation>

    <bp-payment-error *ngIf="showError"
      [errorMessageKey]="errorMessageKey"
      [errorCode]="errorCode">
    </bp-payment-error>
  </div>
</div>
