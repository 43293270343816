<div class="payment-method-generator-card" role="region">
  <div class="input-title" [innerHTML]="'CC_ADD_TITLE'| translate" role="heading" aria-level="3">
    <!-- Add Credit or Debit Card -->
  </div>
  <form class="form--card">
    <div class="form-row">
      <div class="form-row-element--full">
        <label class="payment-method-form-label">
          <span [innerHTML]="'CARD_NUMBER'| translate">
              <!-- Card Number -->
          </span>
          <div class="stripe-element medium" id="card-number-element"></div>
        </label>
      </div>
    </div>

    <div class="form-row">
      <div class="form-row-element--full">
        <label for="card-name" [innerHTML]="'CARDHOLDER_NAME'| translate" class="payment-method-form-label">
          <!-- Cardholder Name -->
        </label>
        <input type="text" placeholder="Enter name" id="card-name" autocomplete="off"
          class="cc-name payment-method-form-input medium" [(ngModel)]="nameOnCard" name="nameOnCard">
      </div>
    </div>

    <div class="form-row stripe-generator-small-element-row">
      <div [innerHTML]="'EXP_DATE'| translate" class="stripe-generator-exp-title">
        <!-- Expiration Date -->
      </div>

      <div class="payment-method-generator-small-element stripe-generator-exp-value">
        <label class="payment-method-form-label">
          <div class="stripe-element medium" id="card-expiry-element"></div>
        </label>
      </div>

      <div class="stripe-generator-cvv-title">
        <span [innerHTML]="'CVV'| translate">
          <!-- Security Code-->
        </span>
        <span class="cvv-tooltip">
          <i class="material-icons cvv-tooltip-icon">
            help
          </i>
          <div class="cvv-tooltip-content">
            <div [innerHTML]="'CVV_THREE_DIGIT'| translate">
              <!-- For Visa, Mastercard, and Discover, the 3-digit code can be found on the
                back of the card. -->
            </div>
            <div [innerHTML]="'CVV_FOUR_DIGIT'| translate" class="cvv-second-paragraph">
              <!-- For American Express, the 4-digit code can be found on the front right
                side of the card. -->
            </div>
          </div>
        </span>
      </div>

      <div class="payment-method-generator-small-element stripe-generator-cvv-value">
        <label class="payment-method-form-label">

          <div class="stripe-element medium" id="card-cvc-element"></div>
        </label>
      </div>

      <div [innerHTML]="'POSTAL_CODE'| translate" class="stripe-generator-zip-title">
        <!-- Postal Code -->
      </div>

      <div class="payment-method-generator-small-element stripe-generator-zip-value end">
        <label class="payment-method-form-label">
          <div class="stripe-element medium" id="card-postal-code-element"></div>
        </label>
      </div>
    </div>
  </form>

  <div *ngIf="showError" class="payment-method-generator-error">
    <div aria-hidden="true">
      <i class="material-icons">
        error_outline
      </i>
    </div>
    <span role="alert">
      {{errorMessage}}
    </span>
  </div>

  <div class="payment-method-generator-action-buttons">
    <button class="jive-button jive-small payment-method-generator-cancel-buttons"
      [attr.disabled]="(paymentMethodPostInProgress$| async) ? '' : null"
      (click)="cancel()"
      [innerHTML]="'CANCEL'| translate"
      [attr.aria-label]="'CC_CANCEL_ARIA' | translate">
      <!-- cancel -->
    </button>
    <button class="jive-button jive-primary jive-small"
      *ngIf="!(paymentMethodPostInProgress$| async)"
      (click)="addCard()"
      [innerHTML]="'ADD'| translate"
      [attr.aria-label]="'CC_ADD_TITLE' | translate">
      <!-- Add -->
    </button>
    <button class="jive-button jive-primary jive-small"
      type="submit"
      *ngIf="(paymentMethodPostInProgress$| async)"
      disabled
      [innerHTML]="'PROCESSING'| translate">
      <!-- Processing -->
    </button>
  </div>
</div>
