import { InvoiceListState } from './invoice-list.model';
import { Action } from '../../../store/action.model';
import { InvoiceListActions } from './invoice-list.actions';

export const INITIAL_STATE: InvoiceListState = {
  list: undefined,
  getInProgress: undefined
};

export function invoiceListReducer (
  lastState: InvoiceListState = INITIAL_STATE,
  action: Action<Partial<InvoiceListState>>
): InvoiceListState {
  switch ( action.type ) {
    case InvoiceListActions.SET_INVOICE_LIST:
      return { ...lastState, list: action.payload.list, getInProgress: false };
    case InvoiceListActions.GET_INVOICE_LIST:
      return { ...lastState, getInProgress: true };
    default:
      return lastState;
  }
}

