import {FactoryProvider, NgModule, Optional} from '@angular/core';
import {AuthActions} from './redux/auth.actions';
import {AUTH_CONFIG_PROVIDER} from './auth-config';
import {AuthEpic} from './redux/auth.epic';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Authentication, AuthGuardService, oauthFlowAuthenticationFactory} from './auth.service';
import {JiveHttpInterceptorOptions} from '../http/jive-auth-hook';
import {HttpInterceptorAuthHookToken, JiveHttpInterceptor} from '../http/http-interceptor.service';
import {AUTHENTICATION_CONFIG_TOKEN} from '../authentication/authentication-config';

export const JIVE_HTTP_INTERCEPTOR_PROVIDER: FactoryProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: JiveHttpInterceptor.factory,
  deps: [
    Authentication,
    [ new Optional(), JiveHttpInterceptorOptions ],
    [ new Optional(), HttpInterceptorAuthHookToken ]
  ],
  multi: true
};

const OAUTH_FLOW_AUTHENTICATION_PROVIDER: FactoryProvider = {
  provide: Authentication,
  useFactory: oauthFlowAuthenticationFactory,
  deps: [ AUTHENTICATION_CONFIG_TOKEN ]
};

@NgModule( {
  providers: [
    AuthGuardService,
    AuthActions,
    AuthEpic,
    AUTH_CONFIG_PROVIDER,
    OAUTH_FLOW_AUTHENTICATION_PROVIDER,
    JIVE_HTTP_INTERCEPTOR_PROVIDER
  ]
} )
export class AuthModule {}
