import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Invoice } from '../invoice/redux/invoice.model';
import { HttpClient } from '@angular/common/http';

@Injectable( { providedIn: 'root' } )
export class InvoiceListService {
  constructor ( private http: HttpClient ) { }

  getInvoiceList = organizationId =>
    this.http.get<Invoice[]>( `${ environment.billingPortalBffUrl }/organizations/${ organizationId }/invoices` )
}
