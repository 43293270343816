<div class="pay-amount-selector"
  role="form"
  aria-labelledby="pay-amount-title"
  aria-describedby="amountOptionTotal">
  <div class="input-title"
    id="pay-amount-title"
    [innerHTML]="'PAY_AMOUNT_SELECTOR_PAGE_TITLE'| translate"
    [attr.aria-label]="'PAY_AMOUNT_SELECTOR_ARIA' | translate">
    <!-- Payment Option -->
  </div>

  <div class="pay-amount-selector-content">
    <div class="pay-amount-selector-list">
      <div class="pay-amount-selector-option-pay-full">
        <input type="radio"
          id="amountOptionTotal"
          name="payment-type-total"
          [attr.aria-label]="'PAY_AMOUNT_SELECTOR_FULL_ARIA' | translate: {amount: 
            getMoneyFormat((currency$| async), (invoice$ | async)?.totalDue, true, (selectedLocale$ |async), true)}"
          value="amountOptionTotal"
          (click)="updateSelected('total',currency)"
          [attr.disabled]="paymentInProgress ? '' : null"
          [checked]="selectedAmountOption===amountOptionTotal">
      </div>
      <div>
        <label [innerHTML]="'PAY_AMOUNT_SELECTOR_FULL_TITLE'| translate"
          class="pay-amount-selector-option-title"
          for="amountOptionTotal">
          <!-- Pay Full Balance -->
        </label>
        <div class="pay-amount-selector-option-explanation"
          [innerHTML]="'PAY_AMOUNT_SELECTOR_FULL_EXPLANATION'| translate">
          <!-- Pay the total amount due on this invoice. -->
        </div>
      </div>
      <div class="pay-amount-selector-option-pay-full-amount">
        {{ getMoneyFormat( ( currency$| async ), ( invoice$ | async )?.totalDue, true, ( selectedLocale$ |async ), true )}}
      </div>

      <div class="pay-amount-selector-option-pay-partial">
        <input type="radio"
          name="payment-type-partial"
          id="payment-type-partial"
          aria-labelledby="pay-amount-selector-option-title"
          [attr.aria-label]="'PAY_AMOUNT_SELECTOR_PARTIAL_TITLE' | translate"
          value="amountOptionPartial"
          [attr.disabled]="paymentInProgress ? '' : null"
          (click)="updateSelected('partial', currency)"
          [checked]="selectedAmountOption===amountOptionPartial">

      </div>
      <div class="pay-amount-selector-option-title-content">
        <label
          [innerHTML]="'PAY_AMOUNT_SELECTOR_PARTIAL_TITLE'| translate"
          class="pay-amount-selector-option-title"
          for="payment-type-partial"
          id="pay-amount-selector-option-title">
          <!-- Pay Partial Balance -->
        </label>
        <div class="pay-amount-selector-option-explanation-partial"
          [innerHTML]="'PAY_AMOUNT_SELECTOR_PARTIAL_EXPLANATION'| translate">
          <!-- Enter a partial payment amount. -->
        </div>
      </div>
      <div class="pay-amount-selector-option-pay-partial-amount">
        <jive-form-field>
          <div class="partial-amount-input">
            <span
              *ngIf="prependCurrencySymbol"
              class="prepend-currency-symbol">
              {{currencySymbol}}
            </span>
            <input
              jiveInput
              [attr.aria-label]="'PAY_AMOUNT_SELECTOR_PARTIAL_EXPLANATION' | translate"
              id="partial-amount"
              name="partialAmount"
              autocomplete="off"
              maxlength="256"
              [ngStyle]="{'width.px': inputWidth}"
              class="medium partial-input"
              [attr.disabled]="paymentInProgress ? '' : null"
              placeholder="{{getMoneyFormat((currency$| async), 0, true, (selectedLocale$ |async), false)}}"
              #partialAmount>
            <span
              *ngIf="!prependCurrencySymbol"
              class="append-currency-symbol">
              {{currencySymbol}}
            </span>
          </div>
        </jive-form-field>
      </div>

      <div class="pay-amount-selector-option-error">
        <div *ngIf="partialAmountValidationError === null || neverHadPartialAmountUserInput"
          class="pay-amount-selector-option-error-placeholder">
        </div>
        <div *ngIf="partialAmountValidationError !== null && !neverHadPartialAmountUserInput"
          class="pay-amount-selector-option-error-text"
          [innerHTML]="partialAmountValidationError| translate">
        </div>
      </div>
    </div>
  </div>
</div>
