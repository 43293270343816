import { FactoryProvider, Optional } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { ApplicationsService, ApplicationsServiceBase, DEFAULT_APPLICATIONS_CONFIG, DEV_APPLICATIONS_CONFIG, STAGING_APPLICATIONS_CONFIG } from '@jive/core/applications';
import { JiveHttpHandler } from '@jive/common/http';
import { CoreServicesConfig, selectServiceConfiguration, ServiceConfigCore } from '../core/service-helpers';

export function applicationsServiceProviderFactory (
  http: JiveHttpHandler,
  coreServiceConfig?: CoreServicesConfig
) {

  const customConfig: Partial<ServiceConfigCore> = coreServiceConfig ?
    coreServiceConfig.applicationsService :
    undefined;

  return ApplicationsServiceBase.factory(
    http,
    selectServiceConfiguration(
      DEFAULT_APPLICATIONS_CONFIG,
      STAGING_APPLICATIONS_CONFIG,
      DEV_APPLICATIONS_CONFIG,
      coreServiceConfig ? coreServiceConfig.environment : undefined,
      customConfig
    ),
    customConfig ? customConfig.path : undefined
  );

}

export const APPLICATIONS_SERVICE_PROVIDER: FactoryProvider = {
  provide: ApplicationsService,
  useFactory: applicationsServiceProviderFactory,
  deps: [ JiveHttpHandler, [ new Optional(), CoreServicesConfig ] ]
};
