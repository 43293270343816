import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Organization } from './redux/organization.model';
import { LocationConverterService } from '../location-converter/location-converter.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OrganizationService {
  constructor (
    private http: HttpClient,
    private locationConverterService: LocationConverterService
  ) { }

  getOrganization ( orgId: string ) {
    return this.http.get<Organization>( `${ environment.billingPortalBffUrl }/organizations/${ orgId }` );
  }

  parseOrganizationResponse ( organization: Organization ) {
    if ( organization ) {
      return {
        ...organization,
        country: this.locationConverterService.getCountry( organization.countryCode, organization.currency )
      };
    }

    return undefined;
  }

  isBillingInfoValid ( organization: Organization ) {
    return organization && organization.currency && organization.country && organization.customerNumber;
  }
}
