import { Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class MobileActions {
  static readonly SET_MOBILE = 'MOBILE:SET_MOBILE';

  constructor () { }

  setMobile ( innerWidth: number ) {
    let isMobileValue = false;
    if ( innerWidth < 600 ) {
      isMobileValue = true;
    }

    return {
      type: MobileActions.SET_MOBILE,
      payload: { isMobile: isMobileValue }
    };
  }
}
