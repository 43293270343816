import { FactoryProvider, Optional } from '@angular/core';
import { UserConfigurationService } from '@jive/core/user';
import { CoreServicesConfig } from '../core/service-helpers';

export function userConfigurationServiceFactory (
  configService: CoreServicesConfig
) {
  return new UserConfigurationService(
    configService.environment
  );
}

export const USER_CONFIGURATION_SERVICE_PROVIDER: FactoryProvider = {
  provide: UserConfigurationService,
  useFactory: userConfigurationServiceFactory,
  deps: [ [ new Optional(), CoreServicesConfig ] ]
};
