import { Component, OnInit } from '@angular/core';

@Component( {
  selector: 'bp-ellipsis-animation',
  templateUrl: './ellipsis-animation.component.html',
  styleUrls: [ './ellipsis-animation.component.scss' ]
} )
export class EllipsisAnimationComponent implements OnInit {

  constructor () { }

  ngOnInit () {
  }

}
