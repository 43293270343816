import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutopayActions } from './redux/autopay.actions';
import { AutopayEpic } from './redux/autopay.epic';
import { AutopayService } from './autopay.service';

@NgModule( {
  imports: [
    CommonModule
  ],
  providers: [
    AutopayActions,
    AutopayEpic,
    AutopayService
  ],
  declarations: []
} )
export class AutopayModule {}
