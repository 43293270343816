import { TranslateService } from '@ngx-translate/core';
// tslint:disable-next-line:max-line-length
import { DateFormatterFunction, DateParts, formatDayMonthYear, formatDefaultDate, formatDefaultTextualDate, formatGermanDate, formatGermanTextualDayMonthYear, formatTextualDayMonthYear, getTextualMonth, TextualDateFormatterFunction } from './date-format.model';

const ENGLISH_SUPPORT_GUIDE_URL = 'https://support.goto.com/connect/help/goto-connect-billing-user-guide-united-states';
const FRENCH_SUPPORT_GUIDE_URL = 'https://support.goto.com/fr/connect/help/goto-connect-billing-user-guide-united-states';
const GERMAN_SUPPORT_GUIDE_URL = 'https://support.goto.com/de/connect/help/goto-connect-billing-user-guide-united-states';
const PORTUGUESE_SUPPORT_GUIDE_URL = 'https://support.goto.com/pt/connect/help/goto-connect-billing-user-guide-united-states';
const SPANISH_SUPPORT_GUIDE_URL = 'https://support.goto.com/es/connect/help/goto-connect-billing-user-guide-united-states';

export interface LanguageSelectorState {
  selectedLanguage: Language;
}

export class Language {

  constructor (
    name: string,
    locale: string,
    isLanguageDefault: boolean,
    decimalSeparator: string,
    thousandGroupingSeparator: string,
    prependCurrencySymbol: boolean,
    supportGuideUrl: string,
    dateFormatter?: DateFormatterFunction,
    spelledDateFormatter?: TextualDateFormatterFunction
  ) {
    this.name = name;
    this.locale = locale;
    this.isLanguageDefault = isLanguageDefault;
    this.decimalSeparator = decimalSeparator;
    this.thousandGroupingSeparator = thousandGroupingSeparator;
    this.prependCurrencySymbol = prependCurrencySymbol;
    this.supportGuideUrl = supportGuideUrl;
    this.dateFormatter = dateFormatter;
    this.textualDateFormatter = spelledDateFormatter;
  }

  readonly name: string;
  readonly locale: string;
  readonly isLanguageDefault: boolean;
  readonly decimalSeparator: string;
  readonly thousandGroupingSeparator: string;
  readonly prependCurrencySymbol: boolean;
  readonly supportGuideUrl: string;
  readonly dateFormatter?: DateFormatterFunction;
  readonly textualDateFormatter?: TextualDateFormatterFunction;

  static instanceFromString ( value: string ): Language {
    if ( !value ) { return ENGLISH_UNITED_STATES; }

    const language = Language.instanceFromLocale( value )
      || Language.instanceFromLanguageCode( value );

    return language ? language : ENGLISH_UNITED_STATES;
  }

  private static instanceFromLocale ( locale: string ) {
    return SUPPORTED_LANGUAGES.filter( it => it.locale === locale )[ 0 ];
  }

  private static instanceFromLanguageCode ( value: string ) {
    const languageCode = value.split( '-' )[ 0 ];
    const foundLanguages = SUPPORTED_LANGUAGES
      .filter( it => it.isLanguageDefault )
      .filter( it => it.languageCode() === languageCode );

    return foundLanguages[ 0 ];
  }

  static viewInstanceOf ( language: Language ): Language {
    return this.instanceFromLanguageCode( language.locale );
  }

  languageCode (): string {
    return this.locale.split( '-' )[ 0 ];
  }

  formatDate ( date: string ): string {
    const dateParts = split( date );

    if ( !this.dateFormatter ) { return formatDefaultDate( dateParts ); }

    return this.dateFormatter( dateParts );
  }

  formatTextualDate ( date: string, translateService: TranslateService ): string {
    const dateParts = split( date );

    if ( !this.textualDateFormatter ) { return formatDefaultTextualDate( dateParts, translateService ); }

    return this.textualDateFormatter( dateParts, translateService );
  }
}

const YEAR_INDEX = 0;

const MONTH_INDEX = 1;
const DAY_INDEX = 2;

function split ( date: string ): DateParts {
  const dateParts = date.split( '-' );
  return {
    year: dateParts[ YEAR_INDEX ],
    month: dateParts[ MONTH_INDEX ],
    day: dateParts[ DAY_INDEX ],
  };
}

export const ENGLISH_AUSTRALIA: Language = new Language(
  'English (Australia)',
  'en-AU',
  false,
  '.',
  ',',
  true,
  ENGLISH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  formatTextualDayMonthYear,
);
export const ENGLISH_CANADA: Language = new Language(
  'English (Canada)',
  'en-CA',
  false,
  '.',
  ',',
  true,
  ENGLISH_SUPPORT_GUIDE_URL,
);
export const ENGLISH_GREAT_BRITAIN: Language = new Language(
  'English (Great Britain)',
  'en-GB',
  false,
  '.',
  ',',
  true,
  ENGLISH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  formatTextualDayMonthYear,
);
export const ENGLISH_IRELAND: Language = new Language(
  'English (Ireland)',
  'en-IE',
  false,
  '.',
  ',',
  true,
  ENGLISH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  formatTextualDayMonthYear,
);
export const ENGLISH_UNITED_STATES: Language = new Language(
  'English (USA)',
  'en-US',
  true,
  '.',
  ',',
  true,
  ENGLISH_SUPPORT_GUIDE_URL,
);
export const FRENCH_CANADA: Language = new Language(
  'Français (Canada)',
  'fr-CA',
  false,
  ',',
  ' ',
  false,
  FRENCH_SUPPORT_GUIDE_URL,
  dateParts => `${ dateParts.year }-${ dateParts.month }-${ dateParts.day }`,
  formatTextualDayMonthYear,
);
export const FRENCH_FRANCE: Language = new Language(
  'Français (France)',
  'fr-FR',
  true,
  ',',
  ' ',
  false,
  FRENCH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  formatTextualDayMonthYear
);
export const GERMAN_AUSTRIA: Language = new Language(
  'Deutsch (Österreich)',
  'de-AT',
  false,
  ',',
  ' ',
  false,
  GERMAN_SUPPORT_GUIDE_URL,
  formatGermanDate,
  formatGermanTextualDayMonthYear,
);
export const GERMAN_GERMANY: Language = new Language(
  'Deutsch (Deutschland)',
  'de-DE',
  true,
  ',',
  '.',
  false,
  GERMAN_SUPPORT_GUIDE_URL,
  formatGermanDate,
  formatGermanTextualDayMonthYear,
);
export const ITALIAN_ITALY: Language = new Language(
  'Italiano (Italia)',
  'it-IT',
  true,
  ',',
  '.',
  false,
  ENGLISH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  formatTextualDayMonthYear,
);

export const PORTUGUESE_BRAZIL: Language = new Language(
  'Português (Brasil)',
  'pt-BR',
  true,
  ',',
  '.',
  true,
  PORTUGUESE_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  ( dateParts, translateService ) => {
    const textualMonth = getTextualMonth( dateParts, translateService );
    return `${ Number( dateParts.day ) } de ${ textualMonth } de ${ dateParts.year }`;
  },
);
export const SPANISH_MEXICO: Language = new Language(
  'Español (México)',
  'es-MX',
  false,
  '.',
  ',',
  true,
  SPANISH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  ( dateParts, translateService ) => {
    const textualMonth = getTextualMonth( dateParts, translateService );
    return `${ Number( dateParts.day ) } de ${ textualMonth } de ${ dateParts.year }`;
  },
);
export const SPANISH_SPAIN: Language = new Language(
  'Español (España)',
  'es-ES',
  true,
  '.',
  ',',
  true,
  SPANISH_SUPPORT_GUIDE_URL,
  formatDayMonthYear,
  ( dateParts, translateService ) => {
    const textualMonth = getTextualMonth( dateParts, translateService );
    return `${ Number( dateParts.day ) } de ${ textualMonth } de ${ dateParts.year }`;
  },
);

export const SUPPORTED_LANGUAGES: Language[] = [
  ENGLISH_AUSTRALIA,
  ENGLISH_CANADA,
  ENGLISH_GREAT_BRITAIN,
  ENGLISH_IRELAND,
  ENGLISH_UNITED_STATES,
  FRENCH_CANADA,
  FRENCH_FRANCE,
  GERMAN_AUSTRIA,
  GERMAN_GERMANY,
  ITALIAN_ITALY,
  PORTUGUESE_BRAZIL,
  SPANISH_MEXICO,
  SPANISH_SPAIN
].sort( ( a, b ) => a.name.localeCompare( b.name ) );
