import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { FactoryProvider, InjectionToken, Optional } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

import { Authentication } from '@jive/core/authentication';
import { Api401ErrorHook, API_401_ERROR_HOOK_TOKEN as ERROR_401_TOKEN_NAME, is401Error, isJiveApi, ShouldLogoutState } from './helpers';

export const API_401_ERROR_HOOK_TOKEN = new InjectionToken( ERROR_401_TOKEN_NAME );

export class HttpInterceptor401 implements HttpInterceptor {

  static factory (
    oauthService: Authentication,
    applicationHookService?: Api401ErrorHook[]
  ) {
    return new HttpInterceptor401( oauthService, applicationHookService );
  }

  constructor (
    private readonly oauthService: Authentication,
    private readonly applicationHookService?: Api401ErrorHook[]
  ) { }

  private runHookIfExists ( error: HttpErrorResponse ): Observable<ShouldLogoutState> {
    // because users can create and use the multi flag we want to allow this scenario
    // in addition it appears that optional gives us an array of injectables by default
    if (
      Array.isArray( this.applicationHookService ) &&
      this.applicationHookService.length
    ) {
      // if even one of the hook services says we should logout then lets do so
      return zip( ...this.applicationHookService.map( ( hookService: Api401ErrorHook ) => hookService.shouldLogout( error ) ) )
        .pipe( map( ( results: ShouldLogoutState[] ) => {
          return {
            logout: results.some( ( { logout } ) => logout )
          };
        } ) );
    } else if (
      !Array.isArray( this.applicationHookService ) &&
      this.applicationHookService
    ) {
      return ( this.applicationHookService as Api401ErrorHook ).shouldLogout( error );
    } else {
      return of( { logout: true } );
    }
  }

  intercept ( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

    return next.handle( req )
      .pipe(
        catchError( ( error: HttpErrorResponse ) => {

          if ( is401Error( error, ( val: any ) => val instanceof HttpErrorResponse ) && isJiveApi( error.url ) ) {
            return this.runHookIfExists( error )
              .pipe(
                first(),
                /**
                 * Later this will be expanded to allow logout params sent to
                 * refresh or a function like it. The intention will be to allow
                 * apps to pass redirect uris to logout so when the user logs back
                 * in they are met with intended page
                 */

                mergeMap( ( shouldLogoutState: ShouldLogoutState ) => {
                  if ( shouldLogoutState.logout ) {
                    return this.oauthService.refresh();
                  } else {
                    throw error;
                  }
                } ),
                map( () => { throw error; } )
              );
          } else {
            throw error;
          }

        } )
      );

  }
}

export const JIVE_HTTP_INTERCEPTOR_401_PROVIDER: FactoryProvider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: HttpInterceptor401.factory,
  deps: [ Authentication, [ new Optional(), API_401_ERROR_HOOK_TOKEN ] ],
  multi: true
};
