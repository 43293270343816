<div class="invoice-list" role="region" aria-labelledby="invoice-table-title">
  <div class="component-title invoice-table-title"
    id="invoice-table-title"
    [innerHTML]="'INVOICE_HISTORY_TITLE'| translate"
    role="heading"
    aria-level="2">
    <!-- Invoice History -->
  </div>

  <div class="invoice-table-loading loading"
    *ngIf="(invoiceListState$| async)?.getInProgress !== false"
    [innerHTML]="'LOADING'| translate"
    aria-busy="true">
    <!-- loading -->
  </div>

  <!-- mobile view -->
  <cdk-virtual-scroll-viewport
    id="mobileList"
    itemSize="10"
    class="invoice-list-mobile-viewport"
    *ngIf="!showError && (isMobile$ | async) && ((invoiceListState$| async)?.getInProgress === false)"
    role="list">
    <div class="invoice-list-mobile">
      <div *ngFor="let invoice of (invoiceListState$|async)?.list" class="invoice-mobile" role="listitem">
        <div class="invoice-mobile-row">
          <div class="invoice-mobile-row-title" [innerHTML]="'INVOICE_INVOICE_ID'| translate">
            <!-- Invoice ID  -->
          </div>
          <div>{{invoice.documentNumber}}</div>
        </div>
        <div class="invoice-mobile-row">
          <div class="invoice-mobile-row-title" [innerHTML]="'INVOICE_DATE_DUE'| translate">
            <!-- Date due  -->
          </div>
          <div> {{getSimpleDate( invoice.dateDue, ( selectedLanguage$ | async ) )}} </div>
        </div>
        <div class="invoice-mobile-row">
          <div class="invoice-mobile-row-title" [innerHTML]="'INVOICE_DATE_ISSUED'| translate">
            <!-- Date Issued -->
          </div>
          <div> {{getSimpleDate( invoice.dateCreated, ( selectedLanguage$ | async ) )}} </div>
        </div>
        <div class="invoice-mobile-row">
          <div class="invoice-mobile-row-title" [innerHTML]="'INVOICE_STATUS'| translate">
            <!-- Status -->
          </div>
          <div [ngStyle]="{'color':getStatusColor(invoice)}" [innerHTML]="getStatus(invoice)| translate">
          </div>
        </div>
        <div class="invoice-mobile-row">
          <div class="invoice-mobile-row-title" [innerHTML]="'INVOICE_AMOUNT'| translate">
            <!-- Amount -->
          </div>
          <div [ngStyle]="{'color':getStatusColor(invoice)}" class="invoice-amount">
            {{getAmountWithCurrency( ( currency$ | async ), invoice.total, ( selectedLanguage$ | async ) )}}
          </div>
        </div>
        <div class="invoice-mobile-row">
          <div class="invoice-mobile-row-title" [innerHTML]="'INVOICE_HISTORY_DETAILS'| translate">
            <!-- Details -->
          </div>
          <div>
            <a class="invoice-link"
              [attr.aria-label]="'INVOICE_HISTORY_VIEW_ARIA' | translate:{id: invoice.documentNumber}"
              [routerLink]="['/', (selectedDomain$ | async), 'invoice-details', invoice.documentNumber]"
              [innerHTML]="'INVOICE_HISTORY_VIEW'| translate">
              <!-- View -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>

  <cdk-virtual-scroll-viewport
    id="desktopTable"
    itemSize="10"
    class="invoice-list-viewport"
    *ngIf="!showError && (isMobile$ | async) === false && ((invoiceListState$| async)?.getInProgress === false)">
    <table cdk-table [dataSource]="(invoiceListState$|async)?.list" class="invoice-list-table">
      <ng-container cdkColumnDef="invoiceId">
        <th cdk-header-cell *cdkHeaderCellDef [innerHTML]="'INVOICE_INVOICE_ID'| translate" class="header-cell"> <!-- Invoice ID --> </th>
        <td cdk-cell *cdkCellDef="let invoice" class="cell"> {{invoice.documentNumber}} </td>
      </ng-container>

      <ng-container cdkColumnDef="dueDate">
        <th cdk-header-cell *cdkHeaderCellDef [innerHTML]="'INVOICE_DATE_DUE'| translate" class="header-cell">  <!-- Date due --> </th>
        <td cdk-cell *cdkCellDef="let invoice" class="cell">  {{getSimpleDate( invoice.dateDue, ( selectedLanguage$ | async ) )}} </td>
      </ng-container>

      <ng-container cdkColumnDef="dateIssued">
        <th cdk-header-cell *cdkHeaderCellDef [innerHTML]="'INVOICE_DATE_ISSUED'| translate" class="header-cell"> <!-- Date issued --> </th>
        <td cdk-cell *cdkCellDef="let invoice" class="cell"> {{getSimpleDate( invoice.dateCreated, ( selectedLanguage$ | async ) )}} </td>
      </ng-container>

      <ng-container cdkColumnDef="status">
        <th cdk-header-cell *cdkHeaderCellDef [innerHTML]="'INVOICE_STATUS'| translate" class="header-cell"> <!-- Status --> </th>
        <td cdk-cell *cdkCellDef="let invoice" [ngStyle]="{'color':getStatusColor(invoice)}" [innerHTML]="getStatus(invoice)| translate" class="cell"></td>
      </ng-container>

      <ng-container cdkColumnDef="amount">
        <th cdk-header-cell *cdkHeaderCellDef [innerHTML]="'INVOICE_AMOUNT'| translate" class="header-cell"> <!-- Amount --> </th>
        <td cdk-cell *cdkCellDef="let invoice" [ngStyle]="{'color':getStatusColor(invoice)}" class="cell invoice-amount">
          {{getAmountWithCurrency( ( currency$ | async ), invoice.total, ( selectedLanguage$| async ) )}}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="details">
        <th cdk-header-cell *cdkHeaderCellDef [innerHTML]="'INVOICE_HISTORY_DETAILS'| translate" class="header-cell"> <!-- Details--> </th>
        <td cdk-cell *cdkCellDef="let invoice" class="cell"> <!-- View -->
          <a
            class="invoice-link"
            [routerLink]="['/', selectedDomain, 'invoice-details', invoice.documentNumber]"
            [innerHTML]="'INVOICE_HISTORY_VIEW'| translate"
            [attr.aria-label]="'INVOICE_HISTORY_VIEW_ARIA' | translate:{id: invoice.documentNumber}">
          </a>
        </td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr cdk-header-row *cdkHeaderRowDef="tableColumns; sticky: true"></tr>
      <tr cdk-row *cdkRowDef="let invoice; columns: tableColumns; let i = index; let isOdd = odd; let isLast = last"
        [ngStyle]="{'odd-row':isOdd}"></tr>
    </table>
  </cdk-virtual-scroll-viewport>

  <!-- Overlay for Error in mobile-->
  <div class="invoice-mobile-error-overlay" *ngIf="showError">
    <bp-data-error></bp-data-error>
  </div>
</div>
