import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { CommonPageSettingService } from '../../services/common-page-setting/common-page-setting.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { OrganizationActions } from '../../services/organization/redux/organization.actions';
import { AppState } from '../../store/app-state.model';
import { MoneyConverterService } from '../../services/money-converter/money-converter.service';
import { takeUntil } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import { CURRENCY_SELECTOR, HAS_PAY_ACCESS_SELECTOR, ORGANIZATION_ID_SELECTOR, SELECTED_LANGUAGE_SELECTOR, TOTAL_DUE_GET_IN_PROGRESS_SELECTOR, TOTAL_DUE_SELECTOR } from '../../store/helper';
import { ModalActions } from '../../services/modal/redux/modal.actions';
import { Language } from '../../services/language-selector/redux/language-selector.model';

@Component( {
  selector: 'bp-current-balance',
  templateUrl: './current-balance.component.html',
  styleUrls: [ './current-balance.component.scss' ],
  encapsulation: ViewEncapsulation.None
} )
export class CurrentBalanceComponent implements OnInit, OnDestroy {

  @select( CURRENCY_SELECTOR ) currency$: Observable<string>;
  @select( TOTAL_DUE_SELECTOR ) totalDue$: Observable<string>;
  @select( TOTAL_DUE_GET_IN_PROGRESS_SELECTOR ) getTotalDueInProgress$: Observable<boolean>;
  @select( ORGANIZATION_ID_SELECTOR ) organizationId$: Observable<string>;
  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;
  @select( HAS_PAY_ACCESS_SELECTOR ) hasPayAccess$: Observable<boolean>;

  unsubscribe$: Subject<boolean> = new Subject();

  totalAmount: string;
  showPayTotal = false;
  showError = false;
  showPayButton = false;

  constructor (
    private commonPageSettingService: CommonPageSettingService,
    private moneyConverterService: MoneyConverterService,
    private organizationActions: OrganizationActions,
    private modalActions: ModalActions,
    private reduxStore: NgRedux<AppState> ) { }

  ngOnInit () {
    // In case the data changed without user action (Finance updating the data or someone else making payments), we get the data again.
    this.organizationId$
      .pipe(
        takeUntil( this.unsubscribe$ )
      ).subscribe(
      ( orgId: string ) => {
        this.reduxStore.dispatch( this.organizationActions.getOrganizationTotalDue( orgId ) );
      } );

    combineLatest( [
      this.currency$,
      this.totalDue$,
      this.getTotalDueInProgress$,
      this.selectedLanguage$,
      this.hasPayAccess$
    ] ).pipe(
      takeUntil( this.unsubscribe$ )
    ).subscribe( ( [ currency, totalDueValue, getTotalDueInProgress, language, hasPayAccess ] ) => {
      if ( totalDueValue !== undefined ) {
        this.totalAmount =
          this.moneyConverterService.getMoneyDisplayAmountWithCurrency(
            currency, Number( totalDueValue ), true, language.locale, true );
      } else {
        // if there was an issue getting total due, we need to show error
        this.showError = getTotalDueInProgress === false;
      }

      this.setShowPayButton( currency, hasPayAccess );
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  setShowPayTotal ( value: boolean ) {
    this.showPayTotal = value;
    this.reduxStore.dispatch( this.modalActions.setModal( value ) );
  }

  /*
   * Brazil and Mexico customers do not pay on portal. So we use this to hide pay total button.
   */
  setShowPayButton ( currency: string, hasPayAccess: boolean ) {
    let canPay = false;

    if ( currency ) {
      canPay = this.commonPageSettingService.canPayOnPortal( currency, hasPayAccess );
    }

    this.showPayButton = ( canPay && !this.showError );
  }
}
