import {ServiceConfig} from '@jive/core/environment';

export enum Environment {
  STAGING = 'staging',
  PROD = 'prod',
  /**
   * dev is the local environment, for a more customized local environment
   * utilize the CoreServicesConfig property for that service
   */
  DEV = 'dev'
}

export interface ServiceConfigCore extends ServiceConfig {
  /**
   * The url path of the service, services have a default
   * but can be overridden with this
   */
  path?: string;
}

export abstract class CoreServicesConfig {
  abstract environment: Environment;
  pbxService?: Partial<ServiceConfigCore>;
  contactsService?: Partial<ServiceConfigCore>;
  userService?: Partial<ServiceConfigCore>;
  identityService?: Partial<ServiceConfigCore>;
  jifService?: Partial<ServiceConfigCore>;
  languageService?: Partial<ServiceConfigCore>;
  partnersInfoService?: Partial<ServiceConfigCore>;
  clickToCallService?: Partial<ServiceConfigCore>;
  featureFlagService?: Partial<ServiceConfigCore>;
  applicationsService?: Partial<ServiceConfigCore>;
}

/**
 * Select function to take the params and select the correct environment config
 *
 * @param stagingConfig the staging config supplied from the core service module
 * @param environment the environment string from the apps CoreServicesConfig
 * @param customConfig the specific config for the core service, from the CoreServicesConfig
 * this only works in the dev environment
 */
export function selectServiceConfiguration (
  prodConfig: ServiceConfig,
  stagingConfig: ServiceConfig,
  devConfig: ServiceConfig,
  environment: Environment = Environment.PROD,
  // custom config is the property set on the core service config
  // that belongs to this service, if one exists
  customConfig?: Partial<ServiceConfigCore>
): ServiceConfigCore | undefined {
  if ( environment === Environment.DEV ) {
    // if there is no custom config but we are in the development
    // env then fallback to staging, custom config is meant to
    // allow to call to local services for testing
    //
    // In case a dev config is missing we augment staging with dev. This allows us to
    // always resolve to a config and for the UI to work in a dev environment.
    return { ...stagingConfig, ...devConfig, ...customConfig };
  } else if ( environment === Environment.STAGING ) {
    return stagingConfig;
  } else {
    return prodConfig;
  }
}
