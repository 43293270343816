import {Component, OnDestroy, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {COUNTRY_SELECTOR, SELECTED_LANGUAGE_SELECTOR} from '../../store/helper';
import {combineLatest, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {CommonPageSettingService} from '../../services/common-page-setting/common-page-setting.service';
import {Country} from '../../services/location-converter/assets/model';
import {Language} from '../../services/language-selector/redux/language-selector.model';

@Component( {
  selector: 'bp-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: [ './contact-info.component.css' ]
} )
export class ContactInfoComponent implements OnInit, OnDestroy {

  @select( COUNTRY_SELECTOR ) country$: Observable<Country>;
  @select( SELECTED_LANGUAGE_SELECTOR ) selectedLanguage$: Observable<Language>;

  unsubscribe$: Subject<boolean> = new Subject();

  billingHelpUrl: string;
  billingEmail: string;
  billingPhoneNumber: string;
  billingPhoneNumberLocal: string;

  constructor (
    private commonPageSettingService: CommonPageSettingService
  ) { }

  ngOnInit (): void {

    combineLatest( [
      this.country$,
      this.selectedLanguage$
    ] ).pipe(
      filter( ( [ country, language ] ) => this.isDataReady( country, language ) ),
      takeUntil( this.unsubscribe$ ),
    ).subscribe( ( [ country, language ] ) => {
      this.billingHelpUrl = this.commonPageSettingService.getBillingHelpUrl( language.locale, country );

      if ( !this.billingHelpUrl && country.showBillingContact ) {
        this.billingEmail = this.commonPageSettingService.getEmailForBillingTeam( country );
        this.billingPhoneNumber = this.commonPageSettingService.getBillingPhoneNumber( country );
        this.billingPhoneNumberLocal = this.commonPageSettingService.getBillingPhoneNumberLocal( country );
      }
    } );
  }

  ngOnDestroy () {
    this.unsubscribe$.next( true );
  }

  isDataReady ( country: Country, language: Language ): boolean {
    return !!( country && language );
  }
}
